import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import Voucher from "./Voucher";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  actions: {
    justifyContent: "center",
    padding: "25px 10px"
  },
  buttons: {
    display: "flex",
    flexDirection: "column"
  },
  spacing: {
    height: 20
  }
});

class ConfirmVoucherDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, isOpen, onCancel, onConfirm, bar, voucher } =
      this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={onCancel}
        fullScreen
        TransitionComponent={Transition}
      >
        <DialogTitle>{t("voucher.confirm-voucher")}</DialogTitle>
        <DialogContent>
          {(!voucher || !voucher.isValid) && (
            <DialogContentText>
              {t("voucher.it-is-not-possible-to-confirm-this-voucher")}
              <br />
              {t(
                "voucher.this-voucher-was-already-redeemed-for-the-orders-below"
              )}
            </DialogContentText>
          )}
          <Voucher bar={bar} voucher={voucher} />
        </DialogContent>
        <DialogActions className={classes.actions}>
          {voucher && voucher.isValid ? (
            <div className={classes.buttons}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={onConfirm}
              >
                {t("voucher.confirm-voucher")}
              </Button>
              <div className={classes.spacing} />
              <Button color="primary" onClick={onCancel}>
                {t("label.cancel")}
              </Button>
            </div>
          ) : (
            <Button onClick={onCancel} color="primary">
              {t("label.cancel")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(ConfirmVoucherDialog)
);
