import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import ReactMoment from "react-moment";

import {
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import CropFreeIcon from "@mui/icons-material/CropFree";
import CheckIcon from "@mui/icons-material/Check";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AdjustIcon from "@mui/icons-material/Adjust";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import BlockIcon from "@mui/icons-material/Block";
import withStyles from "@mui/styles/withStyles";

import { scannerService } from "tap-io/client/services";
import OrderStatus from "tap-io/models/order/OrderStatus";
import config from "tap-io/client/env";
import withAuthorization from "../auth/withAuthorization";

const styles = (theme) => ({
  spinner: {
    justifyContent: "center"
  },
  entryLabel: {
    display: "block",
    fontWeight: "bold"
  },
  entryValue: {
    display: "block"
  },
  plus: {
    color: "green"
  },
  minus: {
    color: "red"
  },
  zero: {
    color: "grey"
  },
  disabledColor: {
    color: theme.palette.action.disabled
  }
});

class ScannerLog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    this.refreshScannerLog();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { scanner } = this.props;

    const scannerId = scanner ? scanner.id : undefined;
    const prevScannerId = prevProps.scanner ? prevProps.scanner.id : undefined;

    if (scannerId !== prevScannerId) {
      this.refreshScannerLog();
    }
  }

  refreshScannerLog = async () => {
    const { t, auth, scanner, getPinHash } = this.props;

    if (auth && auth.user && scanner) {
      try {
        this.setState({ isLoading: true });

        const pinHash = await getPinHash();

        const scannerLog = await scannerService.getScannerLog(
          config.functions.api,
          auth.user,
          scanner,
          pinHash,
          20
        );

        this.setState({ isLoading: false, scannerLog });
      } catch (error) {
        console.warn(error);

        toast.error(
          `${t("label.something-went-wrong")} (${
            error ? error.message : t("error.unknown-error")
          })`
        );

        this.setState({ isLoading: false, scannerLog: [] });
      }
    } else {
      this.setState({ isLoading: false, scannerLog: [] });
    }
  };

  handleEntryClick = (entry) => async (event) => {
    const { onLogEntryClick } = this.props;

    onLogEntryClick(entry);
  };

  getEntryIcon = (entry) => {
    switch (entry.newOrderStatusName) {
      case OrderStatus.CREATED:
        return <AdjustIcon />;
        break;
      case OrderStatus.QUEUED:
        return <HourglassEmptyIcon />;
        break;
      case OrderStatus.CLAIMED:
        return <CropFreeIcon />;
        break;
      case OrderStatus.COMPLETE:
        return <CheckIcon />;
        break;
      case OrderStatus.CANCELLED:
        return <BlockIcon />;
        break;
      default:
        return <HelpOutlineIcon />;
        break;
    }
  };

  getEntryLabel = (entry) => {
    const { t } = this.props;

    switch (entry.newOrderStatusName) {
      case OrderStatus.CREATED:
        return t("scanner.order-created");
        break;
      case OrderStatus.QUEUED:
        return t("scanner.order-queued");
        break;
      case OrderStatus.CLAIMED:
        return t("scanner.order-claimed");
        break;
      case OrderStatus.COMPLETE:
        return t("scanner.order-completed");
        break;
      case OrderStatus.CANCELLED:
        return t("scanner.order-cancelled");
        break;
      default:
        return `(${t("label.unknown")})`;
        break;
    }
  };

  hasEntryOrderId = (entry) => {
    return !!entry.orderId;
  };

  render() {
    const { classes, t } = this.props;
    const { isLoading, scannerLog } = this.state;

    return (
      <div>
        <List>
          {isLoading ? (
            <ListItem className={classes.spinner}>
              <CircularProgress />
            </ListItem>
          ) : (
            <div>
              {scannerLog && scannerLog.length > 0 ? (
                scannerLog.map((entry) => (
                  <ListItemButton
                    key={entry.id}
                    onClick={this.handleEntryClick(entry)}
                  >
                    <ListItemIcon>{this.getEntryIcon(entry)}</ListItemIcon>
                    <ListItemText
                      primary={this.getEntryLabel(entry)}
                      secondary={
                        <ReactMoment format="DD/MM/YYYY, HH:mm:ss">
                          {entry.timestamp}
                        </ReactMoment>
                      }
                    />
                  </ListItemButton>
                ))
              ) : (
                <ListItem>{t("scanner.no-orders-found")}</ListItem>
              )}
            </div>
          )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(
  withAuthorization()(withTranslation("common")(ScannerLog))
);
