import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  SESSION_KEY_PIN_VALUE,
  SESSION_MAX_AGE_PIN_VALUE
} from "../../constants/session";
import { PIN_LENGTH } from "../../../constants/payment";
import { sessionStorage } from "../../../storage";
import EnterPinDialog from "../common/EnterPinDialog";

class EnterPaymentPinDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleFetchPin = async () => {
    const { bar } = this.props;

    const pin = await sessionStorage.getSessionItem(
      bar.id,
      SESSION_KEY_PIN_VALUE
    );

    return pin;
  };

  handleStorePin = async (pin) => {
    const { bar } = this.props;

    return await sessionStorage.setSessionItem(
      bar.id,
      SESSION_KEY_PIN_VALUE,
      pin,
      SESSION_MAX_AGE_PIN_VALUE
    );
  };

  render() {
    const { t, isOpen, isDisabled, bar, showRememberPin, onCancel, onConfirm } =
      this.props;

    return (
      <EnterPinDialog
        isOpen={isOpen}
        isDisabled={isDisabled}
        onCancel={onCancel}
        onConfirm={onConfirm}
        title={t("payment.enter-pin-to-checkout-order")}
        bar={bar}
        pinLength={PIN_LENGTH}
        showRememberPin={showRememberPin}
        rememberPinMaxAge={SESSION_MAX_AGE_PIN_VALUE}
        fetchPin={this.handleFetchPin}
        storePin={this.handleStorePin}
      />
    );
  }
}

export default withTranslation("common")(EnterPaymentPinDialog);
