import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { orderService } from "../../services";
import ConfirmDialog from "../common/ConfirmDialog";
import OrderCancellingDialog from "./OrderCancellingDialog";

const styles = (theme) => ({});

class CancelOrderButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmDialogOpen: false,
      isLoading: false
    };
  }

  handleCancelOrder = () => {
    this.setState({ isConfirmDialogOpen: true });
  };

  handleCancelOrderConfirm = async () => {
    this.setState({ isConfirmDialogOpen: false });

    await this.cancelOrder();
  };

  handleCancelOrderCancel = () => {
    this.setState({ isConfirmDialogOpen: false });
  };

  cancelOrder = async (event) => {
    const { t, auth, order, data, onComplete, onError } = this.props;

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    this.setState({ isLoading: true });

    try {
      await orderService.cancelOrder(auth.user.uid, order, data);

      // Button will be unmounted
      /*this.setState({ isLoading: false }, () => {
        if (onComplete) {
          onComplete(data);
        }
      });*/
    } catch (error) {
      console.warn(error);

      this.setState({ isLoading: false }, () => {
        if (onError) {
          onError(error);
        }
      });

      toast.error(
        `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`
      );
    }
  };

  handleOrderCancellingDialogClose = () => {
    this.setState({ isLoading: false });
  };

  render() {
    const { classes, t, color, size, variant, fullWidth, startIcon } =
      this.props;
    const { isConfirmDialogOpen, isLoading } = this.state;

    return (
      <div>
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          title={t("order.confirm-cancellation-of-order")}
          description={t("order.are-you-sure-you-want-to-cancel-this-order")}
          confirmButtonLabel={t("order.cancel-order")}
          cancelButtonLabel={t("order.keep-order")}
          onConfirm={this.handleCancelOrderConfirm}
          onCancel={this.handleCancelOrderCancel}
        />
        <OrderCancellingDialog
          isOpen={isLoading}
          //onClose={this.handleOrderCancellingDialogClose}
        />
        <Button
          color={color}
          size={size}
          variant={variant}
          fullWidth={fullWidth}
          startIcon={startIcon}
          disabled={isLoading}
          onClick={this.handleCancelOrder}
        >
          {t("order.cancel-order")}
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(CancelOrderButton));
