import PropsModel from "../model/PropsModel";
import InvoicePeriod from "./InvoicePeriod";
import InvoiceStatus from "./InvoiceStatus";
import SalesInvoice from "./SalesInvoice";
import BusinessData from "../data/BusinessData";
import Stats from "../mgmt/Stats";

class Invoice extends PropsModel {
  private readonly _barId: string;
  private readonly _barName: string;
  private readonly _period: InvoicePeriod;
  private readonly _status: InvoiceStatus;
  private readonly _businessData: BusinessData;
  private readonly _stats: Stats;
  private readonly _prevCreditCount: number;
  private readonly _creditAmount: number;
  private readonly _salesInvoice: SalesInvoice;
  private readonly _timestamp: Date;

  constructor(id: string, data: any) {
    super(id);

    if (!data) {
      throw new Error("error.data-is-not-defined");
    }

    this._period = new InvoicePeriod(data.period);
    this._barId = data.barId;
    this._barName = data.barName ? data.barName : "Bar";
    this._status = data.status;
    this._businessData = new BusinessData(this.barId, data.businessData);
    this._stats = new Stats(data.stats);
    this._prevCreditCount = data.prevCreditCount;
    this._creditAmount = data.creditAmount;
    this._salesInvoice = data.salesInvoice;
    this._timestamp = data.timestamp;
  }

  public get barId(): string {
    return this._barId;
  }

  public get barName(): string {
    return this._barName;
  }

  public get period(): InvoicePeriod {
    return this._period;
  }

  public get status(): InvoiceStatus {
    return this._status;
  }

  public get businessData(): BusinessData {
    return this._businessData;
  }

  public get stats(): Stats {
    return this._stats;
  }

  public get prevCreditCount(): number {
    return this._prevCreditCount;
  }

  public get creditAmount(): number {
    return this._creditAmount;
  }

  public get salesInvoice(): SalesInvoice {
    return this._salesInvoice;
  }

  public get timestamp(): Date {
    return this._timestamp;
  }
}

export default Invoice;
