import {
  validate,
  isString,
  isNumber,
  isBoolean
} from "../helpers/ValidationHelper";
import Deposit from "../models/deposit/Deposit";

export default class DepositParser {
  public parseDocToDeposit(barId: string, doc): Deposit {
    const data = doc.data();

    const props = {
      isActive: validate(isBoolean)(data.isActive),
      name: validate(isString)(data.name),
      purchaseValue: validate(isNumber)(data.purchaseValue),
      tradeInValue: validate(isNumber)(data.tradeInValue)
    };

    return new Deposit(barId, doc.id, props);
  }
}
