import hmacmd5 from "crypto-js/hmac-md5";
import PaymentCurrency from "../models/payment/PaymentCurrency";
import PaymentMethod from "../models/payment/PaymentMethod";

export default class PaymentHelper {
  hashPin(barId: string, pin: string): string {
    return pin ? hmacmd5(pin, barId).toString() : "";
  }

  public parsePaymentMethod(paymentMethod: string): PaymentMethod {
    switch (paymentMethod) {
      case PaymentMethod.MOBILE:
        return PaymentMethod.MOBILE;
      case PaymentMethod.CARD_CREDIT:
        return PaymentMethod.CARD_CREDIT;
      case PaymentMethod.CARD_DEBIT:
        return PaymentMethod.CARD_DEBIT;
      case PaymentMethod.CASH:
        return PaymentMethod.CASH;
      case PaymentMethod.INVOICE:
        return PaymentMethod.INVOICE;
      case PaymentMethod.INTERNAL_EXPENSES:
        return PaymentMethod.INTERNAL_EXPENSES;
      case PaymentMethod.NONE:
        return PaymentMethod.NONE;
      default:
        throw new Error("error.payment-method-is-invalid");
    }
  }

  // TO FIX
  public parsePaymentCurrency(paymentCurrency: string) {
    if (paymentCurrency === "euro") {
      return PaymentCurrency.EUR;
    }

    return paymentCurrency;
  }
}
