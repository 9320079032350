import React, { PureComponent } from "react";

import { Route, Switch } from "react-router-dom";

import { toast } from "react-toastify";

import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import {
  barService,
  baseService,
  feeService,
  depositService,
  menuService,
  scannerService
} from "tap-io/client/services";
import { deviceStorage } from "tap-io/storage";
import { scannerHelper, menuHelper } from "tap-io/helpers";
import { bugsnagHelper } from "tap-io/client/helpers";

import withLocator from "../auth/withLocator";
import BarSetting from "./BarSetting";
import VerifyPinDialog from "../auth/VerifyPinDialog";
import {
  CACHE_KEY_BAR_LOCATOR,
  CACHE_KEY_PIN_HASH,
  CACHE_KEY_SCANNER_ID
} from "../../constants/cache";

const PIN_HASH_MAX_AGE = 2592000; // 30 days

const styles = (theme) => ({});

class BarWrapper extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isVerifyPinDialogOpen: false,
      isAppLocked: true,
      pinHash: null
    };
  }

  componentDidMount() {
    const { barId } = this.props;

    if (barId) {
      this.setBarId(barId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { barId } = this.props;
    const prevBarId = prevProps.barId;

    // barId
    if (barId !== prevBarId) {
      this.setBarId(barId);
    }
  }

  componentWillUnmount() {
    this.clearBarId();
    this.clearScannerId();
    this.clearBaseId();
    this.clearMenuElements();
  }

  fetchPinHash = async (barId) => {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const pinHash = await deviceStorage.getDeviceCacheItem(
      barId,
      CACHE_KEY_PIN_HASH
    );
    return pinHash;
  };

  storePinHash = async (pinHash) => {
    const { bar, scanner } = this.state;

    if (!bar) {
      throw new Error("error.bar-is-not-defined");
    }
    if (!scanner) {
      throw new Error("error.scanner-is-not-defined");
    }

    this.setState({ pinHash });
    await deviceStorage.setDeviceCacheItem(
      bar.id,
      CACHE_KEY_PIN_HASH,
      pinHash,
      PIN_HASH_MAX_AGE
    );
  };

  unlockApp = (authenticatedUser, pin) => {
    const { isAppLocked, pinHash, bar, scanner, isVerifyPinDialogOpen } =
      this.state;

    return new Promise((resolve, reject) => {
      if (isAppLocked) {
        if (authenticatedUser && scanner && (pin || pinHash)) {
          const pinHashToUse = pin
            ? scannerHelper.hashPin(bar.id, scanner.id, pin)
            : pinHash;

          // Unlock with provided user & pin
          scannerService
            .verifyScannerPin(
              config.functions.api,
              authenticatedUser,
              scanner,
              pinHashToUse
            )
            .then((isVerified) => {
              this.setState(
                {
                  isAppLocked: !isVerified,
                  isVerifyPinDialogOpen: false
                },
                () => {
                  this.storePinHash(pinHashToUse).then(resolve).catch(reject);
                }
              );
            })
            .catch(reject);
        } else {
          // Show dialog to request user & pin
          if (isVerifyPinDialogOpen) {
            return reject("isVerifyPinDialogOpen === TRUE");
          }

          this.setState({ isVerifyPinDialogOpen: true });

          this.verifyPinDialogSuccessCallback = resolve;
          this.verifyPinDialogCancelCallback = reject;
        }
      } else {
        resolve();
      }
    });
  };

  lockApp = () => {
    return new Promise((resolve, reject) => {
      this.setState({ isAppLocked: true }, resolve);
    });
  };

  getPinHash = async () => {
    if (this.state.pinHash) {
      return this.state.pinHash;
    } else {
      await this.unlockApp();

      return this.state.pinHash;
    }
  };

  resetPinHash = async () => {
    const { bar } = this.state;

    this.setState({ isAppLocked: true, pinHash: null });
    await deviceStorage.clearDeviceCacheItem(bar.id, CACHE_KEY_PIN_HASH);
  };

  handlePinVerificationSuccess = (pinHash) => {
    this.setState(
      {
        isVerifyPinDialogOpen: false,
        isAppLocked: false
      },
      () => {
        this.storePinHash(pinHash)
          .then(() => {
            this.verifyPinDialogSuccessCallback();
            this.verifyPinDialogSuccessCallback = null;
          })
          .catch((error) => {
            console.warn(error);
            this.verifyPinDialogSuccessCallback();
            this.verifyPinDialogSuccessCallback = null;
          });
      }
    );
  };

  handlePinVerificationCancel = () => {
    this.setState(
      {
        isVerifyPinDialogOpen: false
      },
      this.verifyPinDialogCancelCallback
    );
    this.verifyPinDialogCancelCallback = null;
  };

  setBarId = async (barId) => {
    const { setThemeColors, setLocale } = this.props;

    await this.clearBarId();

    // bar
    if (barId) {
      this.unsubscribeBar = barService.onBarById(barId, async (bar) => {
        // Bugsnag
        bugsnagHelper.setBar(bar);

        const prevBar = this.state.bar;

        this.setState({ bar });

        // Color
        const prevPrimaryColor = prevBar
          ? prevBar.getPrimaryColor()
          : undefined;
        const primaryColor = bar.getPrimaryColor();
        const prevSecondaryColor = prevBar
          ? prevBar.getSecondaryColor()
          : undefined;
        const secondaryColor = bar.getSecondaryColor();
        if (
          primaryColor !== prevPrimaryColor ||
          secondaryColor !== prevSecondaryColor
        ) {
          setThemeColors(primaryColor, secondaryColor);
        }

        // Language
        const appLocale = bar.getAppLocale();
        const prevAppLocale = prevBar ? prevBar.getAppLocale() : undefined;
        if (appLocale !== prevAppLocale) {
          setLocale(appLocale);
        }
        bar.locale = appLocale;
      });

      // Fees
      this.unsubscribeFees = feeService.onActiveFees(barId, (activeFees) => {
        this.setState({ activeFees });
      });

      // Deposits
      this.unsubscribeDeposits = depositService.onActiveDeposits(
        barId,
        (activeDeposits) => {
          this.setState({ activeDeposits }, this.refreshMenuDeposits);
        }
      );

      const pinHash = await this.fetchPinHash(barId);
      if (pinHash) {
        this.setState({ pinHash });
      }
    }
  };

  clearBarId = () => {
    return new Promise((resolve, reject) => {
      // Bugsnag
      bugsnagHelper.clearBar();

      // Unsubscribe
      if (this.unsubscribeBar) {
        this.unsubscribeBar();
        this.unsubscribeBar = undefined;
      }
      if (this.unsubscribeFees) {
        this.unsubscribeFees();
        this.unsubscribeFees = undefined;
      }
      if (this.unsubscribeDeposits) {
        this.unsubscribeDeposits();
        this.unsubscribeDeposits = undefined;
      }

      this.setState(
        {
          bar: null,
          pinHash: null
        },
        resolve
      );
    });
  };

  setScannerId = async (scannerId) => {
    const { bar } = this.state;

    await this.clearScannerId();

    if (bar && bar.id && scannerId) {
      this.unsubscribeScanner = scannerService.onById(
        bar,
        scannerId,
        (scanner) => {
          if (scanner.baseId !== this.state.base?.id) {
            this.setBaseId(scanner.baseId);
          }

          this.setState({ scanner });

          this.storeBarLocatorAndScannerId();
        },
        (error) => {
          toast.error(error ? error.toString() : "(Unknown Error)");
        }
      );
    }
  };

  clearScannerId = () => {
    return new Promise((resolve, reject) => {
      // Unsubscribe
      if (this.unsubscribeScanner) {
        this.unsubscribeScanner();
        this.unsubscribeScanner = undefined;
      }

      this.setState(
        {
          scanner: null
        },
        resolve
      );
    });
  };

  setBaseId = async (baseId) => {
    const { bar } = this.state;

    await this.clearBaseId();
    await this.clearMenuElements();

    if (bar && bar.id && baseId) {
      this.unsubscribeBase = baseService.onBaseById(bar.id, baseId, (base) => {
        this.setState({ base }, this.refreshMenuElements);
      });
    }
  };

  clearBaseId = () => {
    return new Promise((resolve, reject) => {
      // Unsubscribe
      if (this.unsubscribeBase) {
        this.unsubscribeBase();
        this.unsubscribeBase = undefined;
      }

      this.setState(
        {
          base: null
        },
        resolve
      );
    });
  };

  refreshMenuElements = async () => {
    const { bar, base } = this.state;

    if (this.unsubscribeMenuElements) {
      this.unsubscribeMenuElements();
      this.unsubscribeMenuElements = undefined;
    }

    if (bar && bar.id && base) {
      const menuIds = [];

      base.menuIds.forEach((menuId) => {
        if (menuIds.indexOf(menuId) < 0) {
          menuIds.push(menuId);
        }
      });

      this.unsubscribeMenuElements =
        menuService.onAllElementsFromActiveMenusWithIds(
          bar.id,
          menuIds,
          (menuElements) => {
            const menuCategories = [],
              menuItems = [];

            menuElements.forEach((element) => {
              if (menuHelper.isCategoryMenuElement(element)) {
                menuCategories.push(element);
              } else if (menuHelper.isItemMenuElement(element)) {
                menuItems.push(element);
              }
            });

            this.setState({ menuElements, menuCategories, menuItems }, this.refreshMenuDeposits);
          }
        );
    } else {
      this.setState({
        menuElements: null,
        menuCategories: null,
        menuItems: null
      });
    }
  };

  clearMenuElements = () => {
    return new Promise((resolve, reject) => {
      // Unsubscribe
      if (this.unsubscribeMenuElements) {
        this.unsubscribeMenuElements();
        this.unsubscribeMenuElements = undefined;
      }

      this.setState(
        {
          menuElements: null,
          menuCategories: null,
          menuItems: null
        },
        resolve
      );
    });
  };

  refreshMenuDeposits = () => {
    const { menuItems, activeDeposits } = this.state;

    let menuDeposits = [];

    if (menuItems && activeDeposits) {
      const menuDepositIds = [];

      menuItems.forEach(menuItem => {
        menuItem.depositIds.forEach(depositId => {
          if (menuDepositIds.indexOf(depositId) < 0) {
            menuDepositIds.push(depositId);
          }
        });
      });

      menuDeposits = activeDeposits.filter(
        (deposit) => menuDepositIds.indexOf(deposit.id) >= 0
      );
    }

    this.setState({ menuDeposits });
  }

  storeBarLocatorAndScannerId = async () => {
    const { barLocator } = this.props;
    const { scanner } = this.state;

    if (barLocator && scanner) {
      await deviceStorage.setDeviceCacheItem(
        "",
        CACHE_KEY_BAR_LOCATOR,
        barLocator
      );
      await deviceStorage.setDeviceCacheItem(
        "",
        CACHE_KEY_SCANNER_ID,
        scanner.id
      );
    }
  };

  render() {
    const { barLocator } = this.props;
    const {
      isVerifyPinDialogOpen,
      isAppLocked,
      bar,
      scanner,
      base,
      activeFees,
      activeDeposits,
      menuDeposits,
      menuElements,
      menuCategories,
      menuItems
    } = this.state;

    return (
      <div>
        <VerifyPinDialog
          isOpen={isVerifyPinDialogOpen}
          onSuccess={this.handlePinVerificationSuccess}
          onCancel={this.handlePinVerificationCancel}
          bar={bar}
          scanner={scanner}
        />
        <Switch>
          <Route
            render={(params) => (
              <div>
                <BarSetting
                  isAppLocked={isAppLocked}
                  barLocator={barLocator}
                  bar={bar}
                  scanner={scanner}
                  base={base}
                  activeFees={activeFees}
                  activeDeposits={activeDeposits}
                  menuDeposits={menuDeposits}
                  menuElements={menuElements}
                  menuCategories={menuCategories}
                  menuItems={menuItems}
                  setScannerId={this.setScannerId}
                  unlockApp={this.unlockApp}
                  getPinHash={this.getPinHash}
                  resetPinHash={this.resetPinHash}
                />
              </div>
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withStyles(styles)(withLocator(BarWrapper));
