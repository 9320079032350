import React, { PureComponent } from "react";
import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";

import theme from "../../theme";
import Logo from "../promo/Logo";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
    minHeight: "100vh"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%"
  },
  topContent: {
    justifyContent: "center"
  },
  middleContent: {
    justifyContent: "center"
  },
  bottomContent: {
    justifyContent: "flex-end"
  }
});

class ContentPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, children } = this.props;

    return (
      <div className={classes.container}>
        <div className={classNames(classes.content, classes.topContent)}>
          <Logo size={100} />
        </div>
        <div className={classNames(classes.content, classes.middleContent)}>
          {children}
        </div>
        <div className={classNames(classes.content, classes.bottomContent)}>
          <theme.socials />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ContentPage);
