import React, { PropsWithChildren } from "react";

import { useTranslation } from "react-i18next";

import { List, ListSubheader, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import Bar from "tap-io/models/bar/Bar";
import Deposit from "tap-io/models/deposit/Deposit";
import DepositItem from "./DepositItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: "left",
      background: "transparent",
      color: "black"
    },
    accentuate: {
      fontWeight: "bold",
      color: theme.palette.error.main
    }
  })
);

type DepositItem = {
  deposit: Deposit;
  amount: number;
};

type DepositItems = {
  [depositId: string]: DepositItem;
};

type EditDepositSettingsProps = {
  bar: Bar;
  deposits: Deposit[];
  depositRoundItems: DepositItems;
  depositItems: DepositItems;
  hasTooMuchDeposits: boolean;
  isDisabled: boolean;
  onChange: (depositItems: DepositItems) => void;
};

function DepositList({
  bar,
  deposits,
  depositRoundItems,
  depositItems,
  hasTooMuchDeposits,
  isDisabled,
  onChange
}: PropsWithChildren<EditDepositSettingsProps>) {
  const { t, i18n } = useTranslation("common");
  const classes = useStyles();

  const handleDepositAmountChange = (deposit: Deposit) => (amount: number) => {
    if (!deposit?.id) {
      console.warn("error.deposit-id-is-not-defined");
      return;
    }

    const updatedDepositItems: DepositItems = { ...depositItems };

    if (amount === 0) {
      delete updatedDepositItems[deposit.id];
    } else {
      updatedDepositItems[deposit.id] = {
        deposit,
        amount
      };
    }

    onChange(updatedDepositItems);
  };

  return (
    <List>
      <ListSubheader className={classes.title}>
        <Typography variant="h6">{t("deposit.received-deposits")}</Typography>
      </ListSubheader>
      {hasTooMuchDeposits && <ListSubheader className={classes.title}>
        <Typography className={classes.accentuate}>{t("deposit.total-less-than-zero-lower-received-deposits")}</Typography>
      </ListSubheader>}
      {deposits.map((deposit) => (
        <DepositItem
          key={deposit.id}
          bar={bar}
          deposit={deposit}
          roundAmount={deposit.id && depositRoundItems && depositRoundItems[deposit.id]?.amount ?
            depositRoundItems[deposit.id].amount :
            undefined
          }
          amount={0}
          isDisabled={isDisabled}
          onAmountChange={handleDepositAmountChange(deposit)}
        />
      ))}
    </List>
  );
}

export default DepositList;
