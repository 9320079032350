import IntegrationName from "../integration/IntegrationName";

class Integrations {
  private readonly _showAds: boolean;

  constructor(data: any) {
    this._showAds = data?.[IntegrationName.PARTNER]?.showAds === true;
  }

  public get showAds(): boolean {
    return this._showAds;
  }

  public toJSON() {
    return {
      [IntegrationName.PARTNER]: {
        showAds: this.showAds
      },
      [IntegrationName.ABI]: {}
    };
  }
}

export default Integrations;
