import React from "react";

import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import BlockIcon from "@mui/icons-material/Block";
import withStyles from "@mui/styles/withStyles";

import CancelOrderButton from "tap-io/client/components/order/CancelOrderButton";

import withAuthorization from "../auth/withAuthorization";
import PayOrderButton from "../payment/PayOrderButton";

const styles = (theme) => ({
  spacing: {
    display: "block",
    height: theme.spacing()
  }
});

const CloseOrderButton = ({ t, isDisabled, onClose }) => (
  <Button
    fullWidth
    size="large"
    variant="contained"
    disabled={isDisabled}
    startIcon={<CloseIcon />}
    onClick={onClose}
  >
    {t("scanner.close-order")}
  </Button>
);

const OrderStatusErrorActions = ({ t, isDisabled, onClose }) => (
  <>
    <CloseOrderButton t={t} isDisabled={isDisabled} onClose={onClose} />
  </>
);

const OrderStatusCancelledActions = ({ t, isDisabled, onClose }) => (
  <>
    <CloseOrderButton t={t} isDisabled={isDisabled} onClose={onClose} />
  </>
);

const OrderStatusCreatedActions = ({
  classes,
  barLocator,
  auth,
  bar,
  scanner,
  order
}) => (
  <>
    {!order.isPaymentProcessing() && (
      <>
        <PayOrderButton
          barLocator={barLocator}
          bar={bar}
          scanner={scanner}
          order={order}
        />
        <div className={classes.spacing} />
        <CancelOrderButton
          auth={auth}
          order={order}
          data={{ scannerId: scanner.id }}
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          startIcon={<BlockIcon />}
        />
      </>
    )}
  </>
);
const OrderStatusQueuedActions = ({
  t,
  isDisabled,
  onClaimOrder,
  onClose,
  barLocator,
  bar,
  scanner,
  order,
  base
}) => (
  <>
    {order.containsItemsForBase(base) ? (
      order.canBePaid() ?
        <PayOrderButton
          barLocator={barLocator}
          bar={bar}
          scanner={scanner}
          order={order}
        />
        :
        <Button
          fullWidth
          size="large"
          variant="contained"
          disabled={isDisabled}
          startIcon={<CheckIcon />}
          onClick={onClaimOrder}
        >
          {t("scanner.claim-order")}
        </Button>
    ) : (
      <CloseOrderButton t={t} isDisabled={isDisabled} onClose={onClose} />
    )}
  </>
);

const OrderStatusClaimedActions = ({
  t,
  isDisabled,
  onCompleteOrder,
  onClose,
  barLocator,
  bar,
  scanner,
  order,
  base
}) => (
  <>
    {order.containsItemsForBase(base) ? (
      order.canBePaid() ?
        <PayOrderButton
          barLocator={barLocator}
          bar={bar}
          scanner={scanner}
          order={order}
        />
        :
        <Button
          fullWidth
          size="large"
          variant="contained"
          disabled={isDisabled}
          startIcon={<CheckIcon />}
          onClick={onCompleteOrder}
        >
          {t("scanner.complete-order")}
        </Button>
    ) : (
      <CloseOrderButton t={t} isDisabled={isDisabled} onClose={onClose} />
    )}
  </>
);

const OrderStatusCompleteActions = ({ t, isDisabled, onClose }) => (
  <>
    <CloseOrderButton t={t} isDisabled={isDisabled} onClose={onClose} />
  </>
);

const OrderStatusActions = ({
  classes,
  t,
  barLocator,
  auth,
  bar,
  scanner,
  base,
  order,
  isDisabled,
  onClaimOrder,
  onCompleteOrder,
  onClose
}) =>
  order.isStatusError() ? (
    <OrderStatusErrorActions t={t} onClose={onClose} isDisabled={isDisabled} />
  ) : order.isStatusCancelled(base) ? (
    <OrderStatusCancelledActions
      t={t}
      onClose={onClose}
      isDisabled={isDisabled}
    />
  ) : order.isStatusCreated(base) ? (
    <OrderStatusCreatedActions
      classes={classes}
      barLocator={barLocator}
      auth={auth}
      bar={bar}
      scanner={scanner}
      order={order}
    />
  ) : order.isStatusQueued(base) ? (
    <OrderStatusQueuedActions
      t={t}
      isDisabled={isDisabled}
      onClaimOrder={onClaimOrder}
      onClose={onClose}
      barLocator={barLocator}
      bar={bar}
      scanner={scanner}
      order={order}
      base={base}
    />
  ) : order.isStatusClaimed(base) ? (
    <OrderStatusClaimedActions
      t={t}
      isDisabled={isDisabled}
      barLocator={barLocator}
      bar={bar}
      scanner={scanner}
      order={order}
      base={base}
      onCompleteOrder={onCompleteOrder}
      onClose={onClose}
    />
  ) : order.isStatusComplete(base) ? (
    <OrderStatusCompleteActions
      t={t}
      isDisabled={isDisabled}
      onClose={onClose}
    />
  ) : null;

export default withStyles(styles)(
  withAuthorization()(withTranslation("common")(OrderStatusActions))
);
