class OrderDeposit {
    private readonly _depositId: string;

    private readonly _name: string;
    private readonly _amount: number;
    private readonly _total: number;

    constructor(depositId: string, props: any) {
        if (!depositId) {
            throw new Error("error.deposit-id-is-not-defined");
        }
        if (!props) {
            throw new Error("error.props-is-not-defined");
        }

        this._depositId = depositId;

        this._name = props.name; //serviceHelper.parseString(props.name);
        this._amount = props.amount; //serviceHelper.parseNumber(props.amount);
        this._total = props.total; //serviceHelper.parseNumber(props.total);
    }

    public get depositId(): string {
        return this._depositId;
    }

    public get name(): string {
        return this._name;
    }

    public get amount(): number {
        return this._amount;
    }

    public get total(): number {
        return this._total;
    }

    public toJSON(): any {
        return {
            name: this.name,
            amount: this.amount,
            total: this.total
        };
    }
}

export default OrderDeposit;
