import { utilsHelper } from "../../helpers";
import VatRate from "../menu/VatRate";

class Item {
  private readonly _id: string;
  private readonly _menuId: string;
  private _name: string;
  private _amount: number;
  private _price: number;
  private _vatRate: VatRate | null;
  private _productId: string | null;
  private _externalId: string | null;

  constructor(id: string, data: any) {
    if (!id) {
      throw new Error("id is not defined");
    }
    if (!data) {
      throw new Error("data is not defined");
    }

    this._id = id;

    this._menuId = data.menuId;
    this._name = data.name;
    this._amount = data.amount;
    this._price = data.price;

    this._vatRate = data.vatRate ? data.vatRate : null;
    this._productId = data.productId ? data.productId : null;
    this._externalId = data.externalId ? data.externalId : null;
  }

  public get id(): string {
    return this._id;
  }

  public get menuId(): string {
    return this._menuId;
  }

  public get productId(): string | null {
    return this._productId;
  }

  public get name(): string {
    return this._name;
  }

  public get vatRate(): VatRate | null {
    return this._vatRate;
  }

  public get externalId(): string | null {
    return this._externalId;
  }

  public set name(name: string) {
    if (!name) {
      throw new Error("error.name-cannot-be-empty");
    }

    this._name = name;
  }

  public set externalId(externalId: string | null) {
    this._externalId = externalId ? externalId : null;
  }

  public get amount() {
    return this._amount;
  }

  public get price() {
    return this._price;
  }

  public get totalPrice() {
    if (this._price !== undefined && this._amount !== undefined) {
      return this._price * this._amount;
    }

    return undefined;
  }

  public getFormattedPrice() {
    if (this.price !== undefined) {
      return utilsHelper.formatToTwoDecimals(this.price);
    }

    return "";
  }

  public getFormattedTotalPrice() {
    if (this.totalPrice !== undefined) {
      return utilsHelper.formatToTwoDecimals(this.totalPrice);
    }

    return "";
  }

  public toJSON() {
    return {
      id: this.id,
      menuId: this.menuId,
      name: this.name,
      amount: this.amount,
      price: this.price,
      vatRate: this.vatRate,
      productId: this.productId,
      externalId: this.externalId
    };
  }
}

export default Item;