import React, { PropsWithChildren, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button, Theme } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { createStyles, makeStyles } from "@mui/styles";

import Bar from "tap-io/models/bar/Bar";
import Order from "tap-io/models/order/Order";
// TO FIX
// @ts-ignore
import ConfirmOrderDeferredPaymentDialog from "tap-io/client/components/payment/ConfirmOrderDeferredPaymentDialog";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

type PayOrderCashProps = {
  auth: { user: { uid: string } };
  bar: Bar;
  order: Order;
  onProcessing: () => void;
  onComplete: () => void;
  onError: (error: Error) => void;
  autoRedirect: boolean;
};

function PayOrderCash({
  auth,
  bar,
  order,
  onComplete,
  autoRedirect
}: PropsWithChildren<PayOrderCashProps>) {
  const { t, i18n } = useTranslation("common");
  const classes = useStyles();

  useEffect(() => {
    if (autoRedirect) {
      setTimeout(handleConfirmOrderDeferredPayment, 100);
    }
  }, []);

  const [
    isConfirmOrderDeferredPaymentDialogOpen,
    setIsConfirmOrderDeferredPaymentDialogOpen
  ] = useState(false);

  const handleConfirmOrderDeferredPayment = () => {
    setIsConfirmOrderDeferredPaymentDialogOpen(true);
  };

  const handleConfirmOrderDeferredPaymentDialogClose = () => {
    setIsConfirmOrderDeferredPaymentDialogOpen(false);
    onComplete();
  };

  return (
    <div>
      <ConfirmOrderDeferredPaymentDialog
        isOpen={isConfirmOrderDeferredPaymentDialogOpen}
        onClose={handleConfirmOrderDeferredPaymentDialogClose}
        auth={auth}
        bar={bar}
        order={order}
        alsoRequestPayment={!order.hasBeenQueued()}
        skipPaymentDetails={true}
      />
      <Button
        variant="outlined"
        size="large"
        color="primary"
        startIcon={<AccountBalanceWalletIcon />}
        onClick={handleConfirmOrderDeferredPayment}
      >
        {t("payment.pay-cash")}
      </Button>
    </div>
  );
}

export default PayOrderCash;
