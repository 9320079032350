import React from "react";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import config from "../../env";

Bugsnag.start({
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_VERSION,
  ...config.bugsnag
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const withBugsnag = (Component) => {
  class WithBugsnag extends React.Component {
    componentDidMount() {
      //bugsnagClient.notify(new Error("Test error"));
    }

    render() {
      return (
        <ErrorBoundary>
          <Component {...this.props} />
        </ErrorBoundary>
      );
    }
  }

  return WithBugsnag;
};

export default withBugsnag;
