import Integration from "./Integration";
import IntegrationName from "./IntegrationName";

class DeliverectIntegration extends Integration {
  private readonly _baseId: string;

  constructor(barId: string, props: any) {
    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    super(barId, IntegrationName.DELIVERECT, props);

    if (!props.baseId) {
      throw new Error("error.props-base-id-is-not-defined");
    }

    this._baseId = props.baseId;

    super.setAllProps(["baseId"]);
  }

  public get baseId(): string {
    return this._baseId;
  }
}

export default DeliverectIntegration;
