class Element {
  private readonly _menuId: string;
  private readonly _id: string;
  private readonly _type: string;
  private _name: string;
  private _isHidden: boolean;
  private _imageFilename: string | null;
  private _externalId: string | null;

  constructor(menuId: string, id: string, type: string, data: any) {
    this._menuId = menuId;
    this._id = id;

    if (!type) {
      throw new Error("data.type is not defined");
    }
    if (!data) {
      throw new Error("data is not defined");
    }

    this._type = type;
    this._name = data.name;

    this._isHidden = data.isHidden === true;
    this._imageFilename = data.imageFilename ? data.imageFilename : null;
    this._externalId = data.externalId ? data.externalId : null;
  }

  public get externalId(): string | null {
    return this._externalId;
  }

  public get menuId(): string {
    return this._menuId;
  }

  public get id(): string {
    return this._id;
  }

  public get type(): string {
    return this._type;
  }

  public get name(): string {
    return this._name;
  }

  public get isHidden(): boolean {
    return this._isHidden;
  }

  public get imageFilename(): string | null {
    return this._imageFilename;
  }

  public get hasImage(): boolean {
    return !!this.imageFilename;
  }

  public set name(name: string) {
    if (!name) {
      throw new Error("error.name-cannot-be-empty");
    }

    this._name = name;
  }

  public set isHidden(isHidden: boolean) {
    this._isHidden = isHidden;
  }

  public set imageFilename(imageFilename: string | null) {
    this._imageFilename = imageFilename ? imageFilename : null;
  }

  public toJSON(): any {
    return {
      id: this.id,
      type: this.type,
      name: this.name,
      isHidden: this.isHidden,
      imageFilename: this.imageFilename,
      externalId: this.externalId
    };
  }
}

export default Element;
