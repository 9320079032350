import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";
import ContentCard from "tap-io/client/components/common/ContentCard";
import PaymentProvider from "tap-io/models/payment/PaymentProvider";
import OrderIsPartiallyPaidMessage from "tap-io/client/components/payment/OrderIsPartiallyPaidMessage";
import PayOrderWithVoucher from "tap-io/client/components/payment/PayOrderWithVoucher";
import { AndroidWrapper } from "tap-io/client/native/AndroidWrapper";

import withAuthorization from "../auth/withAuthorization";
import PayOrderWithVivaWallet from "./PayOrderWithVivaWallet";
import PayOrderCash from "./PayOrderCash";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  content: {
    marginTop: `env(safe-area-inset-top, 0px)`
  },
  total: {
    margin: theme.spacing(2, 0),
    textAlign: "center"
  },
  contentCard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  paymentOption: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  },
  orderIsPartiallyPaidMessage: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 20
  }
});

class PayOrderDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    return {
      providerProcessingPayment: null,
      paymentProviderError: null
    };
  };

  componentDidMount() {
    this.checkPaymentStatus();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.props;
    const prevIsOpen = prevProps.isOpen;

    if (isOpen !== prevIsOpen) {
      this.setState(this.initialState());
    }

    this.checkPaymentStatus();
  }

  areVivaWalletPaymentsAvailable = () => {
    const { bar } = this.props;

    return AndroidWrapper.get() && bar.isAllowingVivaWalletPosPayments();
  };

  areCashPaymentsAvailable = () => {
    const { scanner } = this.props;

    return scanner?.allowCashPayments;
  };

  getAvailableOnlinePaymentProvidersCount = () => {
    const { bar } = this.props;

    let paymentProvidersCount = 0;

    if (bar.isAllowingOnlinePayments()) {
      if (this.areVivaWalletPaymentsAvailable()) {
        paymentProvidersCount++;
      }
      if (this.areCashPaymentsAvailable()) {
        paymentProvidersCount++;
      }
      if (bar.isAllowingVoucherPayments()) {
        paymentProvidersCount++;
      }
    }

    return paymentProvidersCount;
  };

  checkPaymentStatus = () => {
    const { t, order, onClose } = this.props;
    const { providerProcessingPayment, paymentProviderError } = this.state;

    if (providerProcessingPayment || paymentProviderError) {
      return;
    }

    if (this.getAvailableOnlinePaymentProvidersCount() === 0) {
      toast.error(t("error.no-payment-providers-available"));
      onClose();
    } else if (!order.canBePaid()) {
      toast.error(t("error.order-can-not-be-paid-at-this-moment"));
      onClose();
    }
  };

  handleProviderProcessingPayment = (provider) => (event) => {
    this.setState({ providerProcessingPayment: provider });
  };

  handleProviderComplete = (provider) => (data) => {
    const { onClose } = this.props;

    if (
      provider === PaymentProvider.VOUCHER &&
      data &&
      data.remainingTotal &&
      data.remainingTotal > 0
    ) {
      this.setState({
        providerProcessingPayment: null,
        paymentProviderError: null
      });
    } else {
      onClose();
    }
  };

  handleProviderError = (provider) => (error) => {
    const { t } = this.props;

    toast.error(
      `${t("label.something-went-wrong")} (${t(
        error ? error.message : "label.unknown-error"
      )})`
    );

    this.setState({
      providerProcessingPayment: null,
      paymentProviderError: error
    });
  };

  render() {
    const { classes, t, auth, isOpen, onClose, bar, order, scanner } =
      this.props;
    const { providerProcessingPayment } = this.state;

    const onlyOneOnlinePaymentProviderAvailable =
      this.getAvailableOnlinePaymentProvidersCount() === 1;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        TransitionComponent={Transition}
        fullScreen
      >
        <DialogContent className={classes.content}>
          <Typography variant="h6" className={classes.total}>
            {t("order.total-price-to-be-charged-is")}{" "}
            <strong>
              {`${utilsHelper.formatToTwoDecimals(order.getTotalToBePaid())} ${
                order.currency
              }`}
            </strong>
          </Typography>
          {order.isPartiallyPaid() && (
            <div className={classes.orderIsPartiallyPaidMessage}>
              <OrderIsPartiallyPaidMessage order={order} />
            </div>
          )}
          {this.areVivaWalletPaymentsAvailable() &&
            (!providerProcessingPayment ||
              providerProcessingPayment === PaymentProvider.VIVAWALLET) && (
              <div className={classes.contentCard}>
                <ContentCard
                  title={t("scanner.pay-with-terminal-title")}
                  subtitle={t("scanner.pay-with-terminal-subtitle")}
                  variant="outlined"
                  maxWidth={600}
                  isResponsive={false}
                >
                  <div className={classes.paymentOption}>
                    <PayOrderWithVivaWallet
                      auth={auth}
                      bar={bar}
                      scanner={scanner}
                      order={order}
                      onProcessing={this.handleProviderProcessingPayment(
                        PaymentProvider.VIVAWALLET
                      )}
                      onComplete={this.handleProviderComplete(
                        PaymentProvider.VIVAWALLET
                      )}
                      onError={this.handleProviderError(
                        PaymentProvider.VIVAWALLET
                      )}
                      autoRedirect={onlyOneOnlinePaymentProviderAvailable}
                    />
                  </div>
                </ContentCard>
              </div>
            )}
          {this.areCashPaymentsAvailable() &&
            (!providerProcessingPayment ||
              providerProcessingPayment === PaymentProvider.DEFERRED) && (
              <div className={classes.contentCard}>
                <ContentCard
                  title={t("scanner.pay-cash-title")}
                  subtitle={t("scanner.pay-cash-subtitle")}
                  variant="outlined"
                  maxWidth={600}
                  isResponsive={false}
                >
                  <div className={classes.paymentOption}>
                    <PayOrderCash
                      auth={auth}
                      bar={bar}
                      scanner={scanner}
                      order={order}
                      onProcessing={this.handleProviderProcessingPayment(
                        PaymentProvider.DEFERRED
                      )}
                      onComplete={this.handleProviderComplete(
                        PaymentProvider.DEFERRED
                      )}
                      onError={this.handleProviderError(
                        PaymentProvider.DEFERRED
                      )}
                      autoRedirect={onlyOneOnlinePaymentProviderAvailable}
                    />
                  </div>
                </ContentCard>
              </div>
            )}
          {bar.isAllowingVoucherPayments() &&
            (!providerProcessingPayment ||
              providerProcessingPayment === PaymentProvider.VOUCHER) && (
              <div className={classes.contentCard}>
                <ContentCard
                  title={t("scanner.pay-with-voucher-title")}
                  subtitle={t("scanner.pay-with-voucher-subtitle")}
                  variant="outlined"
                  maxWidth={600}
                  isResponsive={false}
                >
                  <PayOrderWithVoucher
                    auth={auth}
                    bar={bar}
                    order={order}
                    onProcessing={this.handleProviderProcessingPayment(
                      PaymentProvider.VOUCHER
                    )}
                    onComplete={this.handleProviderComplete(
                      PaymentProvider.VOUCHER
                    )}
                    onError={this.handleProviderError(PaymentProvider.VOUCHER)}
                    autoRedirect={onlyOneOnlinePaymentProviderAvailable}
                  />
                </ContentCard>
              </div>
            )}
        </DialogContent>
        <DialogActions>
          {providerProcessingPayment === null && (
            <Button onClick={onClose} color="secondary">
              {t("label.cancel")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(
    withTranslation("common")(PayOrderDialog)
  )
);
