import React from "react";
//import { PropTypes } from "prop-types";
import ReactGA from "react-ga4";

import config from "../../env";

if (
  config.analytics &&
  config.analytics.trackingIds &&
  config.analytics.trackingIds.length > 0
) {
  ReactGA.gtag("consent", "default", {
    analytics_storage: "denied",
    ad_storage: "denied",
    functionality_storage: "denied",
    personalization_storage: "denied",
    security_storage: "denied"
  });

  ReactGA.initialize(
    config.analytics.trackingIds.map((trackingId) => {
      return {
        trackingId,
        gaOptions: {
          appName: process.env.REACT_APP_NAME,
          appVersion: process.env.REACT_APP_VERSION,
          send_page_view: true
        }
      };
    })
  );
}

let lastLocation;

const withAnalytics = (Component) => {
  class WithAnalytics extends React.Component {
    componentDidMount() {
      // Not needed anymore (send_page_view flag in gaOptions takes care of this)
      /*const { history } = this.props;
      this.sendPageView(history.location);
      history.listen(this.sendPageView);*/
    }

    sendPageView(location) {
      if (!ReactGA.isInitialized) {
        return;
      }

      if (lastLocation && lastLocation.key === location.key) {
        return;
      }

      /*ReactGA.set({
        appName: process.env.REACT_APP_NAME,
        appVersion: process.env.REACT_APP_VERSION,
        page: location.pathname
      });*/
      //ReactGA.send({ hitType: "pageview", page: location.pathname });

      lastLocation = location;
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  return WithAnalytics;
};

export default withAnalytics;
