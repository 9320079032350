class ErrorWithData extends Error {
  private readonly _data: any;

  constructor(message, data) {
    super(message);
    // Ensure the name of this error is the same as the class name
    //this.name = this.constructor.name;
    // This clips the constructor invocation from the stack trace.
    // It's not absolutely essential, but it does make the stack trace a little nicer.
    //  @see Node.js reference (bottom)
    //Error.captureStackTrace(this, this.constructor);

    this._data = data;
  }

  public get data() {
    return this._data;
  }
}

export default class ServiceHelper {
  private readonly fb;

  public readonly IN_QUERY_ARRAY_MAX_SIZE = 10;

  constructor(fb) {
    this.fb = fb;
  }

  public onConnected = (cb: Function) => {
    const connectedRef = this.fb.db.ref(".info/connected");

    connectedRef.on("value", function (snapshot) {
      cb(snapshot.val() === true);
    });
  };

  public get serverTimestamp() {
    return this.fb.serverTimestamp;
  }

  public get incrementField() {
    return this.fb.incrementField;
  }

  public get deleteField() {
    return this.fb.deleteField;
  }

  public get arrayUnionField() {
    return this.fb.arrayUnionField;
  }

  public get arrayRemoveField() {
    return this.fb.arrayRemoveField;
  }

  public get documentId() {
    return this.fb.documentId;
  }

  public batch(...args) {
    return this.fb.store.batch(...args);
  }

  public runTransaction(...args) {
    return this.fb.store.runTransaction(...args);
  }

  public createTask(
    userId: string,
    type: string,
    action: string,
    data: any,
    onProgress?: Function
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const task: any = {
        userId,
        type,
        action,
        timestamp: this.serverTimestamp()
      };
      if (data) {
        task.data = data;
      }

      const tasksRef = this.fb.store.collection("tasks");
      tasksRef
        .add(task)
        .then((taskRef) => {
          if (onProgress) {
            onProgress(taskRef.id);
          }

          const unsubscribe = taskRef.onSnapshot((doc) => {
            const task = doc.data();

            if (task && task.result) {
              unsubscribe();

              if (task.result.success) {
                resolve(task.result.data);
              } else {
                reject(task.result.error);
              }
            }
          });
        })
        .catch(reject);
    });
  }

  public async fetchJSON(url: string, options?: any): Promise<any> {
    const response = await fetch(url, options);

    if (!response) {
      throw new Error("error.response-is-not-defined");
    }

    const json: any = await response.json();

    if (!response.ok) {
      if (json && json.error) {
        throw new ErrorWithData(json.error, json.data);
      } else {
        throw new Error("error.response-is-not-ok");
      }
    }

    return json;
  }

  public async fetchText(url: string): Promise<string> {
    const response = await fetch(url);

    const text: any = await response.text();

    if (!response.ok) {
      if (text) {
        throw new Error(text);
      } else {
        throw new Error("error.response-is-not-ok");
      }
    }

    return text;
  }

  public parseBase64String(base64String: string): any {
    const parsedBase64Data: RegExpMatchArray | null =
      base64String.match(/(data:.*?),\s*(.*)/);

    if (parsedBase64Data && parsedBase64Data.length === 3) {
      return {
        header: parsedBase64Data[1],
        content: parsedBase64Data[2]
      };
    }
  }

  public getStorageLowResFilename(filename: string): string {
    return `lowres_${filename}`;
  }

  public generateId(): string {
    return `${new Date().getTime()}_${Math.random()}`;
  }

  public parseString(str: string): string {
    if (!str) {
      throw new Error("error.string-cannot-be-empty");
    }

    return str;
  }

  public parseBoolean(bool: boolean): boolean {
    if (bool === undefined) {
      throw new Error("error.boolean-cannot-be-empty");
    }

    return bool;
  }

  public parseInt(int: number, min?: number, max?: number): number {
    if (isNaN(int)) {
      throw new Error("error.number-is-nan");
    }
    if (min !== undefined && int < min) {
      throw new Error("error.number-is-less-than-min");
    }
    if (max !== undefined && int > max) {
      throw new Error("error.number-is-greater-than-max");
    }

    return int;
  }

  public parseNumber(num: number, min?: number, max?: number): number {
    if (isNaN(num)) {
      throw new Error("error.number-is-nan");
    }
    if (min !== undefined && num < min) {
      throw new Error("error.number-is-less-than-min");
    }
    if (max !== undefined && num > max) {
      throw new Error("error.number-is-greater-than-max");
    }

    return num;
  }

  public parseDate(date: Date): Date {
    if (date === undefined) {
      throw new Error("error.date-cannot-be-empty");
    }

    return date;
  }

  public parseTimestamp(timestamp: Date): Date {
    if (timestamp === undefined) {
      throw new Error("error.timestamp-cannot-be-empty");
    }

    return timestamp;
  }

  public parseArray(arr: Array<any>, minLength?: number): Array<any> {
    if (!Array.isArray(arr)) {
      throw new Error("error.not-an-array");
    }
    if (minLength !== undefined && arr.length < minLength) {
      throw new Error("error.array-length-less-than-min-length");
    }

    return arr;
  }

  public parseObject(obj: any): any {
    if (typeof obj !== "object") {
      throw new Error("error.type-of-variable-is-not-an-object");
    }
    if (typeof obj === null) {
      throw new Error("error.object-is-null");
    }

    return obj;
  }

  public getOnlyDocFromQuerySnapshot(querySnapshot) {
    switch (querySnapshot.size) {
      case 0:
        throw new Error("error.no-doc-found");
        break;
      case 1:
        return querySnapshot.docs[0];
        break;
      default:
        throw new Error("error.more-than-one-doc-found");
        break;
    }
  }

  public async getByIds<T>(
    getRefFunction: (ids: string[]) => any,
    parseToModelFunction: (doc: any) => T,
    ids: string[]
  ): Promise<T[]> {
    const arr: Array<T> = [];

    for (let i = 0; i < ids.length; i += this.IN_QUERY_ARRAY_MAX_SIZE) {
      const idsSlice = ids.slice(i, i + this.IN_QUERY_ARRAY_MAX_SIZE);

      const docs = await getRefFunction(idsSlice).get();
      docs.forEach((doc) => arr.push(parseToModelFunction(doc)));
    }

    return arr;
  }

  public onByIds<T>(
    getRefFunction: (ids: string[]) => any,
    parseToModelFunction: (doc: any) => T,
    ids: string[],
    cb: Function
  ) {
    const unsubscribeFunctions: (() => void)[] = [];

    if (ids.length > 0) {
      const map: { [id: string]: T } = {};

      for (let i = 0; i < ids.length; i += this.IN_QUERY_ARRAY_MAX_SIZE) {
        const idsSlice = ids.slice(i, i + this.IN_QUERY_ARRAY_MAX_SIZE);

        unsubscribeFunctions.push(
          getRefFunction(idsSlice).onSnapshot((docs) => {
            // Create array of ids that are still valid
            const validIds: string[] = [];

            // Loop over every valid doc
            docs.forEach((doc) => {
              validIds.push(doc.id);
              map[doc.id] = parseToModelFunction(doc);
            });

            // Remove any models from map that are not valid anymore
            idsSlice.forEach((id) => {
              if (validIds.indexOf(id) < 0) {
                delete map[id];
              }
            });

            // Create new array of current models and trigger listener callback
            cb(Object.keys(map).map((id) => map[id]));
          })
        );
      }
    } else {
      cb([]);
    }

    return () => {
      unsubscribeFunctions.forEach((unsubscribeFunction) =>
        unsubscribeFunction()
      );
    };
  }
}
