import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { PIN_LENGTH } from "../../../constants/scanner";
import EnterPinDialog from "../common/EnterPinDialog";

class EnterScannerPinDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, isOpen, isDisabled, bar, onCancel, onConfirm } = this.props;

    return (
      <EnterPinDialog
        isOpen={isOpen}
        isDisabled={isDisabled}
        onCancel={onCancel}
        onConfirm={onConfirm}
        title={t("scanner.enter-scanner-pin")}
        bar={bar}
        pinLength={PIN_LENGTH}
      />
    );
  }
}

export default withTranslation("common")(EnterScannerPinDialog);
