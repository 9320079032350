import Moment from "moment";

import { serviceHelper } from "../../helpers";

const PAYMENT_TIMEOUT = 60; // seconds

class Payment {
  private readonly _id: string;
  private readonly _provider: string;
  private readonly _method: string;
  private readonly _reference: string;
  private readonly _isPaid: boolean;
  private readonly _timestamp: Date;
  private readonly _createdAt: Date | null;
  private readonly _paidAt: Date | null;
  private readonly _amount: number | null;

  private readonly _data: any;

  constructor(id: string, props: any) {
    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    this._id = id;
    this._provider = serviceHelper.parseString(props.provider);
    this._method = serviceHelper.parseString(
      props.method ? props.method : props.provider
    );
    this._reference = props.reference ? props.reference : "";
    this._isPaid = props.isPaid === true;
    this._timestamp = serviceHelper.parseDate(props.timestamp);
    this._createdAt = props.createdAt ? serviceHelper.parseDate(props.createdAt) : null;
    this._paidAt = props.paidAt ? serviceHelper.parseDate(props.paidAt) : null;
    this._amount = props.amount ? serviceHelper.parseNumber(props.amount) : null;

    this._data = props.data ? props.data : {};
  }

  public get id(): string {
    return this._id;
  }

  public get provider(): string {
    return this._provider;
  }

  public get method(): string {
    return this._method;
  }

  public get reference(): string {
    return this._reference;
  }

  public get isPaid(): boolean {
    return this._isPaid;
  }

  public get data(): any {
    return this._data;
  }

  public get timestamp(): Date {
    return this._timestamp;
  }

  public get createdAt(): Date | null {
    return this._createdAt;
  }

  public get paidAt(): Date | null {
    return this._paidAt;
  }

  public get amount(): number | null {
    return this._amount;
  }

  public isPaymentProcessing() {
    if (this.isPaid) {
      return false;
    }

    const paymentSecondsAgo = Moment().diff(this.timestamp, "seconds");

    // If a payment has been initialized, enable a grace period in which payments are not allowed
    return paymentSecondsAgo < PAYMENT_TIMEOUT;
  }

  public toJSON(): any {
    return {
      provider: this.provider,
      method: this.method,
      reference: this.reference,
      isPaid: this.isPaid,
      data: this.data,
      timestamp: this.timestamp,
      createdAt: this.createdAt,
      paidAt: this.paidAt,
      amount: this.amount
    };
  }
}

export default Payment;