import FulfilmentMethods from "../models/fulfilment/FulfilmentMethods";
import FulfilmentMethod from "../models/fulfilment/FulfilmentMethod";

export default class FulfilmentHelper {
  createFulfilmentMethods(
    isAsSoonAsPossibleActive?: boolean,
    isOnDemandActive?: boolean,
    isAutomaticActive?: boolean
  ): FulfilmentMethods {
    return new FulfilmentMethods({
      [FulfilmentMethod.AS_SOON_AS_POSSIBLE]: {
        isActive: isAsSoonAsPossibleActive === true
      },
      [FulfilmentMethod.ON_DEMAND]: { isActive: isOnDemandActive === true },
      [FulfilmentMethod.AUTOMATIC]: { isActive: isAutomaticActive === true }
    });
  }

  createFulfilmentMethodsFromArray(
    fulfilmentMethods: FulfilmentMethod[]
  ): FulfilmentMethods {
    return new FulfilmentMethods({
      [FulfilmentMethod.AS_SOON_AS_POSSIBLE]: {
        isActive:
          fulfilmentMethods &&
          fulfilmentMethods.indexOf(FulfilmentMethod.AS_SOON_AS_POSSIBLE) >= 0
      },
      [FulfilmentMethod.ON_DEMAND]: {
        isActive:
          fulfilmentMethods &&
          fulfilmentMethods.indexOf(FulfilmentMethod.ON_DEMAND) >= 0
      },
      [FulfilmentMethod.AUTOMATIC]: {
        isActive:
          fulfilmentMethods &&
          fulfilmentMethods.indexOf(FulfilmentMethod.AUTOMATIC) >= 0
      }
    });
  }

  mergeFulfilmentMethods(
    fulfilmentMethodsA: FulfilmentMethods,
    fulfilmentMethodsB: FulfilmentMethods
  ): FulfilmentMethods {
    return this.createFulfilmentMethods(
      fulfilmentMethodsA.isAsSoonAsPossibleActive ||
        fulfilmentMethodsB.isAsSoonAsPossibleActive,
      fulfilmentMethodsA.isOnDemandActive ||
        fulfilmentMethodsB.isOnDemandActive,
      fulfilmentMethodsA.isAutomaticActive ||
        fulfilmentMethodsB.isAutomaticActive
    );
  }
}
