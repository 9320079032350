import { FC } from "react";

import config from "../env";

const COOKIE_MANAGER_TYPE_COOKIEBOT = "cookiebot";
const COOKIE_MANAGER_TYPE_ONETRUST = "onetrust";

const COOKIEBOT_ON_CONSENT_READY_EVENT = "CookiebotOnConsentReady";
const ONETRUST_ON_CONSENT_CHANGED_EVENT = "consent.onetrust";

interface Consent {
  hasNecessaryConsent: boolean;
  hasPreferencesConsent: boolean;
  hasMarketingConsent: boolean;
  hasStatisticsConsent: boolean;
}

const ONETRUST_NECESSARY_CONSENT_GROUP_ID = "1";
const ONETRUST_PREFERENCES_CONSENT_GROUP_ID = "3";
const ONETRUST_MARKETING_CONSENT_GROUP_ID = "4";
const ONETRUST_STATISTICS_CONSENT_GROUP_ID = "2";

export default class CookieHelper {
  constructor() {}

  public hasCookieManager(): boolean {
    return this.callCookieManagerFunction(
      () => {
        return !!window?.CookieBot;
      },
      () => {
        return !!window?.OneTrust;
      },
      () => false
    );
  }

  public showCookieManager(): void {
    return this.callCookieManagerFunction(
      () => {
        if (window?.CookieBot?.show) {
          return window.CookieBot.show();
        }
      },
      () => {
        if (window?.OneTrust?.ToggleInfoDisplay) {
          return window.OneTrust.ToggleInfoDisplay();
        }
      },
      () => {}
    );
  }

  public setCookieManagerLocale(locale: string): void {
    return this.callCookieManagerFunction(
      () => {
        console.warn("error.not-supported");
      },
      () => {
        if (window?.OneTrust?.changeLanguage) {
          try {
            const parsedLocale =
              locale === "nl_BE"
                ? "nl-be"
                : locale === "fr_BE"
                ? "fr-be"
                : locale === "de_DE"
                ? "de-de"
                : locale === "es_ES"
                ? "es-es"
                : "en";

            return window.OneTrust.changeLanguage(parsedLocale);
          } catch (error) {
            console.warn(error);
          }
        }
      },
      () => {}
    );
  }

  public getConsent(): Consent {
    return this.callCookieManagerFunction(
      () => this.parseCookiebotConsent(window?.Cookiebot?.consent),
      () => this.parseOneTrustConsent(window?.OnetrustActiveGroups || ""),
      () => this.getDefaultConsent()
    );
  }

  public onConsentReady<T>(callbackFunction: () => T): () => any {
    return this.callCookieManagerFunction(
      () => {
        const listener = this.handleConsentReady(callbackFunction);

        window.addEventListener(
          COOKIEBOT_ON_CONSENT_READY_EVENT,
          listener,
          false
        );

        return () => {
          window.removeEventListener(
            COOKIEBOT_ON_CONSENT_READY_EVENT,
            listener,
            false
          );
        };
      },
      () => {
        const listener = this.handleConsentReady(callbackFunction);

        window.addEventListener(
          ONETRUST_ON_CONSENT_CHANGED_EVENT,
          listener,
          false
        );

        return () => {
          window.removeEventListener(
            ONETRUST_ON_CONSENT_CHANGED_EVENT,
            listener,
            false
          );
        };
      },
      () => {
        return () => {};
      }
    );
  }

  public setHasPreferencesConsent(hasPreferencesConsent: boolean): void {
    return this.callCookieManagerFunction(
      () => {
        if (window?.Cookiebot?.submitCustomConsent) {
          return window.Cookiebot.submitCustomConsent(
            hasPreferencesConsent === true,
            window?.Cookiebot?.consent?.statistics === true,
            window?.Cookiebot?.consent?.marketing === true
          );
        }
      },
      () => {
        if (window?.OneTrust?.UpdateConsent) {
          window.OneTrust.UpdateConsent(
            "Category",
            `${ONETRUST_PREFERENCES_CONSENT_GROUP_ID}:${
              hasPreferencesConsent ? "1" : "0"
            }`
          );
        }
      },
      () => {}
    );
  }

  public renderCookieManagerComponent(
    cookiebotComponent: FC,
    onetrustComponent: FC
  ): FC | null {
    return this.callCookieManagerFunction(
      () => {
        return cookiebotComponent;
      },
      () => {
        return onetrustComponent;
      },
      () => {
        return null;
      }
    );
  }

  private callCookieManagerFunction<T>(
    cookiebotFunction: () => T,
    onetrustFunction: () => T,
    fallbackFunction: () => T
  ): T {
    switch (config?.cookies?.manager?.type) {
      case COOKIE_MANAGER_TYPE_COOKIEBOT:
        return cookiebotFunction();
        break;
      case COOKIE_MANAGER_TYPE_ONETRUST:
        return onetrustFunction();
        break;
    }

    return fallbackFunction();
  }

  private getDefaultConsent() {
    return {
      hasNecessaryConsent: false,
      hasPreferencesConsent: false,
      hasMarketingConsent: false,
      hasStatisticsConsent: false
    };
  }

  private parseCookiebotConsent(cookiebotConsent: any): Consent {
    return {
      hasNecessaryConsent: !!cookiebotConsent?.necessary,
      hasPreferencesConsent: !!cookiebotConsent?.preferences,
      hasMarketingConsent: !!cookiebotConsent?.marketing,
      hasStatisticsConsent: !!cookiebotConsent?.statistics
    };
  }

  private parseOneTrustConsent(oneTrustActiveGroups: string): Consent {
    const activeGroupsAsArray = oneTrustActiveGroups.split(",");

    return {
      hasNecessaryConsent:
        activeGroupsAsArray.indexOf(ONETRUST_NECESSARY_CONSENT_GROUP_ID) >= 0,
      hasPreferencesConsent:
        activeGroupsAsArray.indexOf(ONETRUST_PREFERENCES_CONSENT_GROUP_ID) >= 0,
      hasMarketingConsent:
        activeGroupsAsArray.indexOf(ONETRUST_MARKETING_CONSENT_GROUP_ID) >= 0,
      hasStatisticsConsent:
        activeGroupsAsArray.indexOf(ONETRUST_STATISTICS_CONSENT_GROUP_ID) >= 0
    };
  }

  private handleConsentReady(
    callbackFunction: (consent: Consent) => void
  ): () => any {
    return () => callbackFunction(this.getConsent());
  }
}
