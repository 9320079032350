export type Constructor<Instance, Args extends any[] = any[]> = new (
  ...args: Args
) => Instance;

export class ValidationError extends Error {
  value: any;

  constructor(value: any) {
    super("Failed to validate");
    this.value = value;
  }
}

export type Validator<T> = (v: any) => v is T;

/* --- */
export function isString(v: any): v is string {
  return typeof v === "string";
}

export function isNumber(v: any): v is number {
  return typeof v === "number";
}

export function isBoolean(v: any): v is boolean {
  return typeof v === "boolean";
}

export function isUndefined(v: any): v is undefined {
  return v === undefined;
}

export function isNull(v: any): v is null {
  return v === null;
}

export function isArray(v: any): v is unknown[] {
  return Array.isArray(v);
}

export function isObject(v: any): v is object {
  return typeof v === "object";
}

/* --- */
export function hasKeys<T>(...keys: (keyof T)[]) {
  return function (v: any): v is { [K in keyof T]: unknown } {
    return keys.every((p) => p in v);
  };
}

export function isUndefinable<T>(validator: Validator<T>) {
  return function (v: any): v is T | undefined {
    return isUndefined(v) && validator(v);
  };
}

export function isNullable<T>(validator: Validator<T>) {
  return function (v: any): v is T | null {
    return isNull(v) && validator(v);
  };
}

export function isTypedArray<T>(validator: Validator<T>) {
  return function (v: any): v is T[] {
    return isArray(v) && v.every((p) => validator(p));
  };
}

export function isTypedObject<T>(validators: {
  [K in keyof T]: Validator<T[K]>;
}) {
  return function (v: any): v is T {
    return (
      isObject(v) &&
      Object.entries<Validator<any>>(validators).every(
        ([key, validator]) => hasKeys(key)(v) && validator(v[key])
      )
    );
  };
}

export function isEnum<T>(e: T) {
  return function (v: any): v is T[keyof T] {
    return Object.values(e).includes(v);
  };
}

export function isClass<T>(c: Constructor<T>) {
  return function (v: any): v is T {
    return v instanceof c;
  };
}

/* --- */
export function validate<T>(validator: Validator<T>) {
  return function (v: any): T {
    if (!validator(v)) throw new ValidationError(v);
    return v;
  };
}
