import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import withStyles from "@mui/styles/withStyles";

import { voucherHelper } from "../../../helpers";
import { paymentService, voucherService } from "../../services";
import ScanOrManuallyEnterVoucher from "../voucher/ScanOrManuallyEnterVoucher";
import ConfirmVoucherDialog from "../voucher/ConfirmVoucherDialog";

const styles = (theme) => ({});

class PayOrderWithVoucher extends PureComponent {
  constructor(props) {
    super(props);

    let voucherId = "",
      voucherPin = "";
    if (props.voucherCode) {
      try {
        const decodedVoucherCode = voucherHelper.decodeVoucherCode(
          props.voucherCode
        );

        voucherId = decodedVoucherCode.voucherId;
        voucherPin = decodedVoucherCode.voucherPin;
      } catch (error) {
        console.warn(error);
      }
    }

    this.state = {
      voucherDataToConfirm: null,
      isLoading: false,
      voucherId,
      voucherPin
    };
  }

  componentDidMount() {
    const { autoRedirect } = this.props;
    const { voucherId, voucherPin } = this.state;

    if (autoRedirect && voucherId && voucherPin) {
      setTimeout(this.handlePayOrderWithVoucher, 100);
    }
  }

  handleVoucherIdChange = (voucherId) => {
    this.setState({ voucherId });
  };

  handleVoucherPinChange = (voucherPin) => {
    this.setState({ voucherPin });
  };

  handleCheckVoucher = async (voucherId, voucherPin) => {
    const { bar } = this.props;

    const voucherCode = voucherHelper.encodeVoucherCode(voucherId, voucherPin);
    const voucher = await voucherService.getVoucherById(bar.id, voucherId);

    this.setState({
      voucherDataToConfirm: {
        code: voucherCode,
        voucher
      },
      voucherId,
      voucherPin
    });
  };

  handleVoucherConfirm = () => {
    const { voucherDataToConfirm } = this.state;

    this.setState(
      {
        voucherDataToConfirm: null
      },
      async () => {
        if (voucherDataToConfirm) {
          await this.handlePayOrderWithVoucher(voucherDataToConfirm.code);
        }
      }
    );
  };

  handleCancelVoucherToConfirm = () => {
    this.setState({
      voucherDataToConfirm: null
    });
  };

  handlePayOrderWithVoucher = async (voucherCode) => {
    const { t, auth, bar, order, onProcessing, onComplete, onError } =
      this.props;

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    this.setState({ isLoading: true });

    try {
      if (onProcessing) {
        onProcessing();
      }

      const data = await paymentService.redeemVouchers(auth.user.uid, order, [
        voucherCode
      ]);

      this.setState({ isLoading: false, voucherId: "", voucherPin: "" }, () => {
        onComplete({ ...data, voucherCode });
      });
    } catch (error) {
      console.warn(error);

      this.setState({ isLoading: false }, () => {
        onError(error);
      });
    }
  };

  render() {
    const { classes, t, bar } = this.props;
    const { voucherDataToConfirm, isLoading, voucherId, voucherPin } =
      this.state;

    return (
      <div>
        <ConfirmVoucherDialog
          isOpen={voucherDataToConfirm !== null}
          onConfirm={this.handleVoucherConfirm}
          onCancel={this.handleCancelVoucherToConfirm}
          bar={bar}
          voucher={
            voucherDataToConfirm ? voucherDataToConfirm.voucher : undefined
          }
        />
        <ScanOrManuallyEnterVoucher
          confirmVoucherLabel={t("payment.pay-with-voucher")}
          isLoading={isLoading}
          voucherId={voucherId}
          voucherPin={voucherPin}
          onVoucherIdChange={this.handleVoucherIdChange}
          onVoucherPinChange={this.handleVoucherPinChange}
          onConfirmVoucher={this.handleCheckVoucher}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(PayOrderWithVoucher)
);
