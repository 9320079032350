import Subscription from "../models/subscription/Subscription";

export default class SubscriptionParser {
  public parseDocToSubscription(barId: string, doc): Subscription {
    const data = doc.data();
    return new Subscription(
      barId,
      {
        ...data,
        validUntil: data.validUntil ? data.validUntil.toDate() : null,
        timestamp: data.timestamp.toDate()
      }
    );
  }
}
