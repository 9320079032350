import Moment from "moment-timezone";
import { ParsedNumber, parseNumber } from "libphonenumber-js";
import { parseOneAddress } from "email-addresses";

export default class UtilsHelper {
  setScrollPosition(scrollElement, position) {
    return (scrollElement ? scrollElement : window).scrollTo(
      0,
      position ? position : 0
    );
  }

  resetScrollPosition(scrollElement) {
    return this.setScrollPosition(scrollElement, 0);
  }

  makePromiseCancelable(promise: Promise<any>) {
    let hasCanceled: boolean = false;

    const wrappedPromise = new Promise((resolve, reject) => {
      promise.then(
        (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
        (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
      );
    });

    return {
      promise: wrappedPromise,
      cancel() {
        hasCanceled = true;
      }
    };
  }

  reloadPage() {
    return window.location.reload(true);
  }

  openLink(link: string) {
    return window.open(link, "_blank");
  }

  findIndexInArrayByPropVal(
    arr: Array<any>,
    prop: string,
    val: string
  ): number | undefined {
    let index = 0,
      isFound = false;

    while (!isFound && index < arr.length) {
      if (arr[index] && arr[index][prop] === val) {
        isFound = true;
      } else {
        index++;
      }
    }

    if (isFound) {
      return index;
    }

    return undefined;
  }

  findIndexInArrayById(arr: Array<any>, id: string) {
    return this.findIndexInArrayByPropVal(arr, "id", id);
  }

  findInArrayByPropVal = (arr: Array<any>, prop: string, val: string) => {
    const index = this.findIndexInArrayByPropVal(arr, prop, val);

    if (index !== undefined) {
      return arr[index];
    }
  };

  findInArrayById(arr: Array<any>, id: string) {
    const index = this.findIndexInArrayById(arr, id);

    if (index !== undefined) {
      return arr[index];
    }
  }

  filterArrayWithPropBetween(
    arr: Array<any>,
    prop: string,
    beginVal: string,
    endVal: string,
    includeBeginVal?: boolean,
    includeEndVal?: boolean
  ): Array<any> {
    if (arr) {
      return arr.filter((element) => {
        return (
          element &&
          element[prop] &&
          (includeBeginVal
            ? element[prop] >= beginVal
            : element[prop] > beginVal) &&
          (includeEndVal ? element[prop] <= endVal : element[prop] < endVal)
        );
      });
    }

    return arr;
  }

  removeArrayDuplicates(arr: Array<any>): Array<any> {
    return arr.filter(
      (item: any, pos: number, self: Array<any>) => self.indexOf(item) === pos
    );
  }

  formatToXDecimals(number, decimals): string {
    if (isNaN(number)) {
      throw new Error("number isNaN");
    }
    if (isNaN(decimals)) {
      throw new Error("number isNaN");
    }

    return `${number.toLocaleString("nl-BE", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    })}`;
  }

  formatToTwoDecimals(number: number): string {
    return this.formatToXDecimals(number, 2);
  }

  formatValueInCents(valueInCents: number): string {
    return this.formatToTwoDecimals(valueInCents ? valueInCents / 100 : 0);
  }

  /*roundToTwoDecimals(number: number): string {
    return parseFloat(`${Math.round(number * 100) / 100}`).toFixed(2);
  }*/

  roundToTwoDecimals(number: number): number {
    return Math.round(number * 100) / 100;
  }

  areArraysEqual(
    arrayA: Array<any>,
    arrayB: Array<any>,
    compareFunction: Function
  ): boolean {
    if (arrayA === arrayB) {
      return true;
    }
    if (!arrayA || !arrayB) {
      return false;
    }
    if (arrayA.length !== arrayB.length) {
      return false;
    }

    return arrayA.every((val, index) => compareFunction(val, arrayB[index]));
  }

  areArraysEqualShallow(arrayA: Array<any>, arrayB: Array<any>): boolean {
    return this.areArraysEqual(
      arrayA,
      arrayB,
      (valA: any, valB: any) => valA === valB
    );
  }

  doArraysContainTheSameItemsShallow(
    arrayA: Array<any>,
    arrayB: Array<any>
  ): boolean {
    return this.areArraysEqualShallow(arrayA.sort(), arrayB.sort());
  }

  areObjectsEqual(
    objectA: any,
    objectB: any,
    compareFunction: Function
  ): boolean {
    const keysA = Object.keys(objectA);
    const keysB = Object.keys(objectB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (let key of keysA) {
      if (!compareFunction(objectA[key], objectB[key])) {
        return false;
      }
    }

    return true;
  }

  areObjectsEqualShallow(objectA: any, objectB: any): boolean {
    return this.areObjectsEqual(
      objectA,
      objectB,
      (valA: any, valB: any) => valA === valB
    );
  }

  isObjectEmpty(obj: any): boolean {
    return obj && obj.constructor === Object && Object.keys(obj).length === 0;
  }

  sortNumbersArray(arr: Array<number>): Array<number> {
    return arr.sort((numberA, numberB) => numberA - numberB);
  }

  sortObjectsArray(arr: Array<any>, key: string): Array<any> {
    return arr.sort((objA, objB) =>
      objA[key] < objB[key] ? -1 : objA[key] < objB[key] ? 1 : 0
    );
  }

  getRandomElementFromArray(arr: Array<any>): any {
    if (arr.length === 0) {
      throw new Error("error.array-is-empty");
    }

    const index: number = this.generateRandomInteger(0, arr.length - 1);
    return arr[index];
  }

  generateArray<T>(length: number, item: T): T[] {
    const arr: T[] = [];

    for (let i = 0; i < length; i++) {
      arr[i] = item;
    }

    return arr;
  }

  parsePhoneNumber(
    phone: string,
    allowLandline: boolean = true,
    country: string = "BE"
  ): string | undefined {
    // @ts-ignore
    const parsedPhone: ParsedNumber = parseNumber(phone, country);

    if (
      parsedPhone &&
      parsedPhone.country === "BE" &&
      parsedPhone.phone &&
      (allowLandline || parsedPhone.phone.length === 9)
    ) {
      return `+32${parsedPhone.phone}`;
    }

    return undefined;
  }

  parseEmail(email: string): string | undefined {
    if (email) {
      const parsedEmail: any = parseOneAddress({
        input: email,
        rejectTLD: true
      });
      if (parsedEmail && parsedEmail.address) {
        return parsedEmail.address;
      }
    }

    return undefined;
  }

  obfuscateEmail(email: string) {
    const matches = email.match(/^(.)(.{3,})(.)@(.)(.{3,})(.)$/);

    if (matches && matches.length === 7) {
      return `${matches[1]}${this.generateStringByRepeatingCharacter(
        "*",
        matches[2].length
      )}${matches[3]}@${matches[4]}${this.generateStringByRepeatingCharacter(
        "*",
        matches[5].length
      )}${matches[6]}`;
    }

    return "*****@*****";
  }

  addLeadingPadding(str: string, count: number, char: string) {
    return (
      this.generateStringByRepeatingCharacter(char ? char : "0", count) + str
    ).slice(-count);
  }

  generateStringByRepeatingCharacter(char: string, length: number) {
    return Array(length + 1).join(char);
  }

  generateUUID(): string {
    const s4 = (): string => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };

    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  }

  generateRandomInteger(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  generatePin(length: number): string {
    const numberAsString: string = `${this.generateRandomInteger(
      0,
      Math.pow(10, length) - 1
    )}`;
    return `${this.addLeadingPadding(numberAsString, length, "0")}`;
  }

  generateRandomHexColor(): string {
    const letters: string = "0123456789ABCDEF";
    let color: string = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  convertHexColorToRGBColor(
    hexColor: string
  ): { r: number; g: number; b: number } | null {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex: RegExp = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const parsedHexColor = hexColor.replace(
      shorthandRegex,
      (m: string, r: string, g: string, b: string): string => {
        return r + r + g + g + b + b;
      }
    );

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      parsedHexColor
    );

    if (result && result.length === 4) {
      const r = parseInt(result[1], 16);
      const g = parseInt(result[2], 16);
      const b = parseInt(result[3], 16);

      if (!isNaN(r) && !isNaN(g) && !isNaN(b)) {
        return {
          r,
          g,
          b
        };
      }
    }

    return null;
  }

  convertRGBColorToHexColor(r: number, g: number, b: number): string | null {
    if (r >= 0 && r <= 255 && g >= 0 && g <= 255 && b >= 0 && b <= 255) {
      const componentToHex = (c: number): string => {
        const hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      };

      return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    return null;
  }

  getFirstDateExceptDays(
    exceptDays: Array<boolean>,
    beginDate?: Date
  ): Date | undefined {
    const date = beginDate ? beginDate : new Date();

    if (exceptDays) {
      for (let i = 0; i < 7; i++) {
        const dateAsMoment = Moment(date).add(i, "days");
        if (!exceptDays[dateAsMoment.day()]) {
          return dateAsMoment.toDate();
        }
      }

      return undefined;
    }

    return date;
  }

  getFirstDayFromPreviousMonth(currentDate: Date = new Date()) {
    return new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  }

  jsDateToDateString(jsDate: Date): string {
    return Moment(jsDate).format("YYYY-MM-DD");
  }

  jsDateToTimeString(jsDate: Date): string {
    return Moment(jsDate).format("HH:mm:ss");
  }

  isDateWithinDaysInFuture(date: Date, days: number): boolean {
    return Moment().diff(date, "days") > -days;
  }

  calculateRemainingTime(
    date: Date,
    timeOffset: number = 0,
    unit: "minutes" | "seconds" = "minutes"
  ) {
    const diffInSeconds = -Moment().add(timeOffset, "milliseconds").diff(date, "seconds");

    if (unit === "seconds") {
      return diffInSeconds;
    } else {
      const diffSign = diffInSeconds >= 0 ? 1 : -1;
      const diffAbs = Math.abs(diffInSeconds);

      return diffSign * (diffSign > 0 ? Math.ceil(diffAbs / 60) : Math.floor(diffAbs / 60));
    }
  }

  // Validators

  isValidHexColor(hexColor: string): boolean {
    return /^#[0-9A-F]{6}$/i.test("#AABBCC");
  }

  isValidDate(jsDate: any) {
    if (Object.prototype.toString.call(jsDate) === "[object Date]") {
      // It's a date
      if (isNaN(jsDate.getTime())) {
        // d.valueOf() could also work
        // Date is not valid
        return false;
      } else {
        // Date is valid
        return true;
      }
    } else {
      // Not a date
      return false;
    }
  }

  parseHttpUrl(str: string) {
    let url = new URL(str);

    if (url.protocol !== "http:" && url.protocol !== "https:") {
      throw new Error("error.not-a-valid-http-url");
    }

    return url.toString();
  }

  getLangFromLocale(locale: string) {
    const matches = locale.match(/^(.+)_(.+)$/);

    if (matches && matches.length === 3) {
      return matches[1];
    }

    throw new Error("error.locale-is-invalid");
  }

  async asyncForEach(array: Array<any>, callback: Function) {
    for (let index: number = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  itemsToCsv(items: Array<any>, header: any, separator: string = ";"): string {
    if (items.length === 0) {
      return "";
    }

    const headerKeys: Array<string> = [];
    const headerValues: Array<string> = [];
    if (header) {
      for (let key in header) {
        headerKeys.push(key);
        headerValues.push(header[key]);
      }
    } else {
      for (let key in items[0]) {
        headerKeys.push(key);
        headerValues.push(key);
      }
    }

    const replaceFtn = (key: string, value: any) =>
      value === undefined || value === null ? "" : value;
    const cleanFtn = (value: string) => value.replace(/\\"/g, "");

    return [
      headerValues.join(separator),
      ...items.map((item) =>
        headerKeys
          .map((key) => cleanFtn(JSON.stringify(item[key], replaceFtn)))
          .join(separator)
      )
    ].join("\r\n");
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  camelCaseToDashCase(str: string): string {
    return str.replace(/[A-Z]/g, (c) => "-" + c.toLowerCase());
  }

  encodeQueryParams(queryParams: { [key: string]: string }): string {
    return Object.keys(queryParams)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])
      )
      .join("&");
  }

  completeText(text: string, name: string, value: string) {
    return text.replace(new RegExp(`\\{\\{\\s*${name}\\s*\\}\\}`), value);
  }
}
