import Voucher from "../models/voucher/Voucher";
import CurrencyVoucher from "../models/voucher/CurrencyVoucher";
import ProductVoucher from "../models/voucher/ProductVoucher";
import VoucherType from "../models/voucher/VoucherType";
import VoucherLogEntry from "../models/voucher/VoucherLogEntry";
import ProductVoucherValue from "../models/voucher/ProductVoucherValue";
import ProductVoucherLogEntry from "../models/voucher/ProductVoucherLogEntry";
import CurrencyVoucherLogEntry from "../models/voucher/CurrencyVoucherLogEntry";
import VoucherTemplate from "../models/voucher/VoucherTemplate";
import { serviceHelper, voucherHelper } from "../helpers";

export default class VoucherParser {
  public parseDocToVoucher(barId: string, doc): Voucher {
    const data = doc.data();

    if (data.validUntil) {
      data.validUntil = data.validUntil.toDate();
    }

    const props = {
      ...data,
      timestamp: data.timestamp.toDate()
    };

    return data.type === VoucherType.PRODUCT ?
      new ProductVoucher(barId, doc.id, props) :
      new CurrencyVoucher(barId, doc.id, props);
  }

  public parseDocToVoucherLogEntry(voucher: Voucher, doc): VoucherLogEntry {
    const data = doc.data();

    const id = doc.id;
    const props = {
      ...data,
      timestamp: data.timestamp.toDate()
    };

    if (voucherHelper.isProductVoucher(voucher)) {
      return new ProductVoucherLogEntry(id, props);
    } else if (voucherHelper.isCurrencyVoucher(voucher)) {
      props.currency = (<CurrencyVoucher>voucher).currency;
      return new CurrencyVoucherLogEntry(id, props);
    } else {
      throw new Error("error.voucher-type-is-not-valid");
    }
  }

  public parseCurrencyVoucherValue(value: any): number {
    return serviceHelper.parseNumber(value);
  }

  public parseProductVoucherValue(value: any): Array<ProductVoucherValue> {
    return serviceHelper
      .parseArray(value)
      .map(entry =>
        new ProductVoucherValue(
          entry.productIds,
          entry.discountPercentage,
          entry.amount
        )
      );
  };

  public parseDocToVoucherTemplate(barId: string, doc): VoucherTemplate {
    if (!doc.exists) {
      throw new Error("error.base-does-not-exist");
    }

    const data = doc.data();

    return new VoucherTemplate(
      barId,
      doc.id,
      {
        ...data,
        timestamp: data.timestamp ? data.timestamp.toDate() : undefined
      }
    );
  }
}
