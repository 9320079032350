import React from "react";

import PaidIcon from "@mui/icons-material/Paid";

import Message from "./Message";

const WarningMessage = ({ label }) => (
  <Message icon={<PaidIcon />} label={label} />
);

export default WarningMessage;
