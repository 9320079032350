import { serviceHelper } from "../../helpers";
import Data from "./Data";
import DataName from "./DataName";
import Product from "../catalogue/Product";

class CatalogueData extends Data {
  private _customProducts: Array<Product> = [];

  constructor(barId: string, props: any) {
    super(barId, DataName.CATALOGUE);

    if (props) {
      if (props.customProducts) {
        this.customProducts = props.customProducts
          .map((customProduct: any) =>
            new Product(customProduct.id, true, customProduct.name, customProduct.vatRate)
          );
      }
    }

    super.setAllProps(["customProducts"]);
  }

  public get customProducts(): Array<any> {
    return this._customProducts;
  }

  public set customProducts(customProducts: Array<any>) {
    this._customProducts = serviceHelper.parseArray(customProducts);
    super.setPropUpdated("customProducts");
  }
}

export default CatalogueData;