import { serviceHelper, statsHelper } from "../../helpers";
import StatsData from "./StatsData";
import StatsDataFrame from "./StatsDataFrame";
import StatsDataType from "./StatsDataType";

export default class StatsDataCollection extends StatsData {
  private readonly _collection: { [key: string]: StatsData } = {};

  constructor(props?: any, name?: string) {
    super(StatsDataType.COLLECTION, name);

    if (props) {
      const collection = serviceHelper.parseObject(props);

      for (let id in collection) {
        this._collection[id] = statsHelper.dataToStatsData(collection[id]);
      }
    }
  }

  private get collection(): { [key: string]: StatsData } {
    return this._collection;
  }

  public getStatsDataFrame(id: string): StatsDataFrame {
    if (!this.collection[id]) {
      this.collection[id] = new StatsDataFrame();
    }

    if (statsHelper.isStatsDataFrame(this.collection[id])) {
      return <StatsDataFrame>this.collection[id];
    } else {
      throw new Error("error.stats-data-type-invalid");
    }
  }

  public getStatsDataCollection(id: string): StatsDataCollection {
    if (!this.collection[id]) {
      this.collection[id] = new StatsDataCollection();
    }

    if (statsHelper.isStatsDataCollection(this.collection[id])) {
      return <StatsDataCollection>this.collection[id];
    } else {
      throw new Error("error.stats-data-type-invalid");
    }
  }

  public get length(): number {
    return Object.keys(this.collection).length;
  }

  public forEach(cb: Function): void {
    let index = 0;
    for (let id in this.collection) {
      cb(id, this.collection[id], index++);
    }
  }

  public range(fromId?: string, toId?: string): StatsDataCollection {
    const rangeStatsDataCollection = new StatsDataCollection();

    this.ids.forEach(id => {
      if ((!fromId || id >= fromId) && (!toId || id <= toId)) {
        rangeStatsDataCollection.set(id, this.get(id).clone());
      }
    });

    return rangeStatsDataCollection;
  }

  public max(statsDataFrameKey: string, descendFunction?: Function): StatsDataFrame | null {
    let maxStatsDataFrame: StatsDataFrame | null = null;

    this.forEach((id: string, statsData: StatsData, index: number) => {
      const childStatsData = descendFunction ?
        descendFunction(statsData) :
        statsData;

      if (statsHelper.isStatsDataFrame(childStatsData)) {
        const statsDataFrame = <StatsDataFrame>childStatsData;

        if (!maxStatsDataFrame || statsDataFrame.get(statsDataFrameKey) > maxStatsDataFrame.get(statsDataFrameKey)) {
          maxStatsDataFrame = statsDataFrame;
        }
      } else if (statsHelper.isStatsDataCollection(childStatsData)) {
        const statsDataCollection = <StatsDataCollection>childStatsData;

        let maxStatsDataFrameInSubCollection = statsDataCollection.max(statsDataFrameKey);

        if (maxStatsDataFrameInSubCollection &&
          (!maxStatsDataFrame || maxStatsDataFrameInSubCollection.get(statsDataFrameKey) > maxStatsDataFrame.get(statsDataFrameKey))
        ) {
          maxStatsDataFrame = maxStatsDataFrameInSubCollection;
        }
      }
    });

    return maxStatsDataFrame;
  }

  private getInsertIndexByStatsDataFrameValue(
    statsDataFrame: StatsDataFrame,
    statsDataFrameKey: string,
    arr: Array<any>,
    arrKey: string
  ): number {

    let low = 0,
      high = arr.length;

    while (low < high) {
      let mid = (low + high) >>> 1;

      const midElement: any = arr[mid][arrKey];
      if (!statsHelper.isStatsDataFrame(midElement)) {
        throw new Error("error.element-at-mid-index-in-arr-is-not-a-stats-data-frame");
      }
      const midStatsDataFrame: StatsData = <StatsDataFrame>midElement;

      const statsDataFrameValue: any = statsDataFrame.get(statsDataFrameKey);
      const midStatsDataFrameValue: any = midStatsDataFrame.get(statsDataFrameKey);

      if (midStatsDataFrameValue < statsDataFrameValue) {
        low = mid + 1;
      } else {
        high = mid;
      }
    }

    return low;
  };

  private getInsertIndexByKey(
    key: string,
    arr: Array<any>,
    arrKey: string
  ): number {

    let low = 0,
      high = arr.length;

    while (low < high) {
      let mid = (low + high) >>> 1;

      const midElement: any = arr[mid];
      const midId: string = midElement[arrKey];

      if (midId < key) {
        low = mid + 1;
      } else {
        high = mid;
      }
    }
    return low;
  };

  public map(cb: Function): Array<any> {
    const arr: Array<any> = [];

    this.forEach((id: string, statsData: StatsData, index: number) => {
      arr.push(cb(id, statsData, index++));
    });

    return arr;
  }

  public sortedByIdMap(cb: Function, sortOrder?: number): Array<any> {
    const arr: Array<any> = [];

    this.forEach((id: string, statsData: StatsData, index: number) => {
      const elementToInsert = {
        ...cb(id, statsData, index++),
        _id: id
      };

      arr.splice(
        this.getInsertIndexByKey(
          id,
          arr,
          "_id"
        ),
        0,
        elementToInsert
      );
    });

    return !sortOrder || sortOrder > 0 ?
      arr :
      arr.reverse();
  }

  public sortedByStatsDataFrameKeyMap(cb: Function, sortByStatsDataFrameKey?: string, sortOrder?: number): Array<any> {
    const arr: Array<any> = [];

    this.forEach((id: string, statsData: StatsData, index: number) => {
      const elementToInsert = {
        ...cb(id, statsData, index++),
        _statsData: statsData
      };

      if (sortByStatsDataFrameKey) {
        if (!statsHelper.isStatsDataFrame(statsData)) {
          throw new Error("error.can-not-sort-array-with-non-stats-data-frame-elements");
        }

        arr.splice(
          this.getInsertIndexByStatsDataFrameValue(
            <StatsDataFrame>statsData,
            sortByStatsDataFrameKey,
            arr,
            "_statsData"
          ),
          0,
          elementToInsert
        );
      } else {
        arr.push(elementToInsert);
      }
    });

    return !sortOrder || sortOrder > 0 ?
      arr :
      arr.reverse();
  }

  public exists(id: string): boolean {
    return !!this.collection[id];
  }

  public get(id: string): StatsData {
    return this.collection[id];
  }

  public set(id: string, data: StatsData) {
    this.collection[id] = data;
  }

  public get ids(): Array<string> {
    return Object.keys(this.collection);
  }

  public add(statsData: StatsData) {
    if (statsHelper.isStatsDataCollection(statsData)) {
      const statsDataCollection = <StatsDataCollection>statsData;
      statsDataCollection.forEach((id: string, statsData: StatsData, index: number) => {
        if (this.exists(id)) {
          this.get(id).add(statsData);
        } else {
          this.set(id, statsData);
        }
      });
    } else {
      throw new Error("error.only-stats-data-collection-can-be-added-to-a-data-collection");
    }
  }

  public isEmpty(): boolean {
    return this.length === 0;
  }

  public dataToJSON(incrementFunction?: Function, arrayUnionFunction?: Function): any {
    const json: any = {};

    this.forEach((id: string, statsData: StatsData, index: number) => {
      if (!statsData.isEmpty()) {
        json[id] = statsData.toJSON(incrementFunction, arrayUnionFunction);
      }
    });

    return json;
  }
}