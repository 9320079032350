import CustomerField from "./CustomerField";
import { barParser } from "../../parsers";
import Locale from "../locale/Locale";
import PaymentCurrency from "../payment/PaymentCurrency";
import { isEnum } from "../../helpers/ValidationHelper";
import { paymentHelper } from "../../helpers";

const DEFAULT_ORDER_CURRENCY_IF_ONLINE_PAYMENTS_ARE_ALLOWED: PaymentCurrency = PaymentCurrency.EUR;

class Params {
  private _barDescription: string = "";
  private _orderConfirmationMessage: string = "";
  private _customerAddressedAs: string = "";
  private _defaultCustomerFields: CustomerField[] = [];
  private _customCustomerFields: CustomerField[] = [];
  private _replyToEmailAddress: string = "";
  private _orderCurrency: string = "";
  private _maxAmountOfItemsPerOrder: number = 20;
  private _allowNotes: boolean = false;
  private _allowTips: boolean = false;
  private _allowOnlinePayments: boolean = false;
  private _useBases: boolean = false;
  private _useZones: boolean = false;
  private _payconiqMerchantLink: string | undefined;

  constructor(data: any) {
    if (data) {
      if (data.barDescription) {
        this.barDescription = data.barDescription;
      }
      if (data.orderConfirmationMessage) {
        this.orderConfirmationMessage = data.orderConfirmationMessage;
      }
      if (data.customerAddressedAs) {
        this.customerAddressedAs = data.customerAddressedAs;
      }
      if (data.customerFields) {
        this.customCustomerFields = barParser.parseCustomerFields(
          data.customerFields
        );
      }
      if (data.replyToEmailAddress) {
        this.replyToEmailAddress = data.replyToEmailAddress;
      }
      if (data.orderCurrency) {
        this.orderCurrency = data.orderCurrency;
      }
      if (data.maxAmountOfItemsPerOrder) {
        this.maxAmountOfItemsPerOrder = data.maxAmountOfItemsPerOrder;
      }
      if (data.allowNotes) {
        this.allowNotes = data.allowNotes;
      }
      if (data.allowTips) {
        this.allowTips = data.allowTips;
      }
      if (data.allowOnlinePayments) {
        this.allowOnlinePayments = data.allowOnlinePayments;
      }
      if (data.useBases) {
        this.useBases = data.useBases;
      }
      if (data.useZones) {
        this.setUseZones(data.useZones, data.customerAddressedAs);
      }
      if (data.payconiqMerchantLink) {
        this.payconiqMerchantLink = data.payconiqMerchantLink;
      }
    }
  }

  public set locale(locale: Locale) {
    this.customerFields.forEach(
      (customerField) => (customerField.locale = locale)
    );
  }

  public forEach(cb: Function) {
    const params = this.toJSON();
    return Object.keys(params).forEach((key, index, array) => {
      return cb({ key, value: params[key] }, index, array);
    });
  }

  public map(cb: Function) {
    const params = this.toJSON();
    return Object.keys(params).map((key, index, array) => {
      return cb({ key, value: params[key] }, index, array);
    });
  }

  public get barDescription(): string {
    return this._barDescription;
  }

  public set barDescription(barDescription: string) {
    this._barDescription = barDescription;
  }

  public get orderConfirmationMessage(): string {
    return this._orderConfirmationMessage;
  }

  public set orderConfirmationMessage(orderConfirmationMessage: string) {
    this._orderConfirmationMessage = orderConfirmationMessage;
  }

  public get customerAddressedAs(): string {
    return this._customerAddressedAs;
  }

  public set customerAddressedAs(customerAddressedAs: string) {
    if (this.useZones) {
      throw new Error("error.bar-is-using-zones");
    }

    this._customerAddressedAs = customerAddressedAs;
  }

  public get defaultCustomerFields(): CustomerField[] {
    return this._defaultCustomerFields;
  }

  public set defaultCustomerFields(defaultCustomerFields: CustomerField[]) {
    this._defaultCustomerFields = defaultCustomerFields;
  }

  public get customCustomerFields(): CustomerField[] {
    return this._customCustomerFields;
  }

  public set customCustomerFields(customCustomerFields: CustomerField[]) {
    this._customCustomerFields = customCustomerFields;
  }

  public get customerFields(): CustomerField[] {
    return [...this.defaultCustomerFields, ...this.customCustomerFields];
  }

  public get replyToEmailAddress(): string {
    return this._replyToEmailAddress;
  }

  public set replyToEmailAddress(replyToEmailAddress: string) {
    this._replyToEmailAddress = replyToEmailAddress;
  }

  public get orderCurrency(): string {
    return this._orderCurrency;
  }

  public set orderCurrency(orderCurrency: string) {
    const parsedOrderCurrency = paymentHelper.parsePaymentCurrency(orderCurrency);

    if (
      this.allowOnlinePayments && !isEnum(PaymentCurrency)(parsedOrderCurrency)
    ) {
      throw new Error("error.bar-is-allowing-online-payments");
    }

    this._orderCurrency = parsedOrderCurrency;
  }

  public get maxAmountOfItemsPerOrder(): number {
    return this._maxAmountOfItemsPerOrder;
  }

  public set maxAmountOfItemsPerOrder(maxAmountOfItemsPerOrder: number) {
    this._maxAmountOfItemsPerOrder = maxAmountOfItemsPerOrder;
  }

  public get allowNotes(): boolean {
    return this._allowNotes;
  }

  public set allowNotes(allowNotes: boolean) {
    this._allowNotes = allowNotes;
  }

  public get allowTips(): boolean {
    return this._allowTips;
  }

  public set allowTips(allowTips: boolean) {
    if (this.useBases && allowTips) {
      throw new Error("error.bar-is-using-bases");
    }

    this._allowTips = allowTips;
  }

  public get allowOnlinePayments(): boolean {
    return this._allowOnlinePayments;
  }

  public set allowOnlinePayments(allowOnlinePayments: boolean) {
    this._allowOnlinePayments = allowOnlinePayments;

    if (allowOnlinePayments && !isEnum(PaymentCurrency)(this.orderCurrency)) {
      this._orderCurrency = DEFAULT_ORDER_CURRENCY_IF_ONLINE_PAYMENTS_ARE_ALLOWED;
    }
  }

  public get useBases(): boolean {
    return this._useBases;
  }

  public set useBases(useBases: boolean) {
    this._useBases = useBases;

    if (useBases) {
      this._allowTips = false;
    }
  }

  public get useZones(): boolean {
    return this._useZones;
  }

  public setUseZones(
    useZones: boolean,
    customerAddressedAsIfZonesAreUsed?: string
  ) {
    this._useZones = useZones;

    if (useZones) {
      if (!customerAddressedAsIfZonesAreUsed) {
        throw new Error(
          "error.customer-addressed-as-if-zones-are-used-is-not-defined"
        );
      }

      this._customerAddressedAs = customerAddressedAsIfZonesAreUsed;
    }
  }

  public get payconiqMerchantLink(): string | undefined {
    return this._payconiqMerchantLink;
  }

  public set payconiqMerchantLink(payconiqMerchantLink: string | undefined) {
    this._payconiqMerchantLink = payconiqMerchantLink;
  }

  public toJSON() {
    return {
      barDescription: this.barDescription,
      orderConfirmationMessage: this.orderConfirmationMessage,
      customerAddressedAs: this.customerAddressedAs,
      customerFields: this.customCustomerFields.map((customerField) =>
        customerField.toJSON()
      ),
      replyToEmailAddress: this.replyToEmailAddress,
      orderCurrency: this.orderCurrency,
      maxAmountOfItemsPerOrder: this.maxAmountOfItemsPerOrder,
      allowNotes: this.allowNotes,
      allowTips: this.allowTips,
      allowOnlinePayments: this.allowOnlinePayments,
      useBases: this.useBases,
      useZones: this.useZones,
      payconiqMerchantLink: this.payconiqMerchantLink
        ? this.payconiqMerchantLink
        : null
    };
  }
}

export default Params;
