import LayoutNodeSequenceType from "./LayoutNodeSequenceType";

export default abstract class LayoutNodeSequence {
  protected readonly _type: LayoutNodeSequenceType;

  constructor(type: LayoutNodeSequenceType) {
    this._type = type;
  }

  public get type() {
    return this._type;
  }

  public abstract getGeneratedSequence();

  public abstract map(cb);

  public toJSON(): any {
    return {
      type: this.type
    };
  }

  public abstract toString(): string;

  public abstract clone(): LayoutNodeSequence;
}
