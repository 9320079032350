import Order from "../models/order/Order";
import Base from "../models/base/Base";

export default class OrderHelper {
  getOrderUrl(barUrl: string, orderId: string): string {
    return `${barUrl}/orders/${orderId}`;
  }

  parseOrderUrl(
    orderDomain: string,
    orderUrl: string
  ): { barLocator: string; orderId: string } {
    const regExp = new RegExp(
      `^https:\/\/${orderDomain.replace(
        /\./g,
        "\\."
      )}\/(.*)\/orders\\/(.*?)(\\?.+)?$`
    );
    const matches = orderUrl.match(regExp);

    if (!matches || matches.length !== 4) {
      throw new Error("error.qr-code-is-invalid");
    }

    const barLocator = matches[1];
    const orderId = matches[2];

    return {
      barLocator,
      orderId
    };
  }

  sortOrdersByPriority(orders: Order[]): Order[] {
    return orders.sort(
      (orderA, orderB) => orderB.priority.number - orderA.priority.number
    );
  }

  sortOrdersByEstimatedRemainingPreparationTime(
    orders: Order[],
    calculateRemainingTime: (date: Date) => number,
    base?: Base,
  ): Order[] {
    return orders.sort((orderA, orderB) => {
      const orderAEstimatedRemainingPreparationTime =
        orderA.getEstimatedRemainingPreparationTime(
          calculateRemainingTime,
          base
        );
      const orderBEstimatedRemainingPreparationTime =
        orderB.getEstimatedRemainingPreparationTime(
          calculateRemainingTime,
          base
        );

      // B before A: positive
      // A before B: negative
      return orderAEstimatedRemainingPreparationTime === null
        ? orderBEstimatedRemainingPreparationTime === null
          ? 0
          : 1
        : orderBEstimatedRemainingPreparationTime === null
          ? -1
          : orderAEstimatedRemainingPreparationTime -
          orderBEstimatedRemainingPreparationTime;
    });
  }
}
