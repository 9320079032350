import { serviceHelper, utilsHelper } from "../../helpers";

class ProductVoucherValue {
  private readonly _productIds: Array<string>;
  private readonly _discountPercentage: number;
  private _amount: number;

  constructor(productIds: Array<string>, discountPercentage: number, amount: number) {
    this._productIds = serviceHelper.parseArray(productIds, 1);
    this._discountPercentage = serviceHelper.parseNumber(discountPercentage, 1, 100);
    this._amount = serviceHelper.parseNumber(amount, 0);
  }

  public get productIds(): Array<string> {
    return this._productIds;
  }

  public getProductId(): string {
    return this.productIds[0];
  }

  public get discountPercentage(): number {
    return this._discountPercentage;
  }

  public get amount(): number {
    return this._amount;
  }

  public set amount(amount: number) {
    if (amount < 0) {
      throw new Error("error.amount-must-be-equal-to-or-greater-than-zero");
    }

    if (amount >= this.amount) {
      throw new Error("error.amount-must-be-less-than-current-amount");
    }

    this._amount = amount;
  }

  public toJSON(): any {
    return {
      productIds: this.productIds,
      discountPercentage: this.discountPercentage,
      amount: this.amount
    };
  }

  public isEqualTo(productVoucherValue: ProductVoucherValue): boolean {
    return utilsHelper.areArraysEqualShallow(this.productIds, productVoucherValue.productIds) &&
      this.discountPercentage === productVoucherValue.discountPercentage &&
      this.amount === productVoucherValue.amount;
  }
}

export default ProductVoucherValue;