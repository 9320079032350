import ScannerService from "../../services/ScannerService";
import Bar from "../../models/bar/Bar";
import Scanner from "../../models/scanner/Scanner";
import Order from "../../models/order/Order";
import { authHelper, serviceHelper } from "../../helpers";
import ScannerLogEntry from "../../models/scanner/ScannerLogEntry";
import { scannerParser } from "../../parsers";

export default class ClientScannerService extends ScannerService {
  constructor(fbStore) {
    super(fbStore);
  }

  public async createScanner(
    userId: string,
    bar: Bar,
    scannerName: string,
    baseId: string,
    isActive: boolean,
    allowCashPayments: boolean,
    onProgress?: Function
  ): Promise<string> {
    const taskData: any = {
      barId: bar.id,
      scannerName,
      baseId,
      isActive,
      allowCashPayments
    };

    const data = await serviceHelper.createTask(
      userId,
      "scanner",
      "create",
      taskData,
      onProgress
    );

    if (!data) {
      throw new Error("error.data-is-not-defined");
    }
    if (!data.scannerId) {
      throw new Error("error.data-scanner-id-is-not-defined");
    }

    return data.scannerId;
  }

  public async verifyScannerPin(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    scanner: Scanner,
    pinHash: string
  ): Promise<boolean> {
    if (!scanner.id) {
      throw new Error("error.scanner-id-is-not-defined");
    }

    const link = `${apiFunctionsEndpoint}/public/scanner/verify`;
    const params = {
      barId: scanner.barId,
      scannerId: scanner.id,
      pinHash
    };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    const data = await serviceHelper.fetchJSON(url);

    return data && data.isVerified === true;
  }

  public async claimOrder(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    scanner: Scanner,
    order: Order,
    pinHash: string
  ) {
    if (!scanner.id) {
      throw new Error("error.scanner-id-is-not-defined");
    }

    const link = `${apiFunctionsEndpoint}/public/scanner/claim`;
    const params = {
      barId: scanner.barId,
      scannerId: scanner.id,
      orderId: order.id,
      pinHash
    };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    await serviceHelper.fetchJSON(url);
  }

  public async completeOrder(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    scanner: Scanner,
    order: Order,
    pinHash: string
  ) {
    if (!scanner.id) {
      throw new Error("error.scanner-id-is-not-defined");
    }

    const link = `${apiFunctionsEndpoint}/public/scanner/complete`;
    const params = {
      barId: scanner.barId,
      scannerId: scanner.id,
      orderId: order.id,
      pinHash
    };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    await serviceHelper.fetchJSON(url);
  }

  public async getScannerLog(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    scanner: Scanner,
    pinHash: string,
    maxCount: number
  ): Promise<Array<ScannerLogEntry>> {
    if (!scanner.id) {
      throw new Error("error.scanner-id-is-not-defined");
    }

    const link = `${apiFunctionsEndpoint}/public/scanner/log`;
    const params = {
      barId: scanner.barId,
      scannerId: scanner.id,
      pinHash,
      maxCount
    };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    const data = await serviceHelper.fetchJSON(url);

    if (!data) {
      throw new Error("error.data-is-not-defined");
    }
    if (!data.log) {
      throw new Error("error.data-log-is-not-defined");
    }

    return data.log.map((entry: any) =>
      scannerParser.parseDataToScannerLogEntry(entry)
    );
  }

  public async findOrders(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    scanner: Scanner,
    pinHash: string,
    query: string
  ): Promise<Array<any>> {
    if (!scanner.id) {
      throw new Error("error.scanner-id-is-not-defined");
    }

    const link = `${apiFunctionsEndpoint}/public/scanner/find`;
    const params = {
      barId: scanner.barId,
      scannerId: scanner.id,
      pinHash,
      query
    };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    const data = await serviceHelper.fetchJSON(url);

    if (!data) {
      throw new Error("error.data-is-not-defined");
    }
    if (!data.orders) {
      throw new Error("error.data-orders-is-not-defined");
    }

    return data.orders;
  }
}
