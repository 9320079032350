import CustomerField from "./CustomerField";
import CustomerFieldProps from "./CustomerFieldProps";
import CustomerFieldType from "./CustomerFieldType";

interface EmailCustomerFieldProps extends CustomerFieldProps {
  sendOrderConfirmationToThisAddress?: boolean;
}

export default class EmailCustomerField extends CustomerField {
  private _sendOrderConfirmationToThisAddress: boolean;

  constructor(props: Omit<EmailCustomerFieldProps, "type">) {
    super({ ...props, type: CustomerFieldType.EMAIL });

    const { sendOrderConfirmationToThisAddress } = props;

    this._sendOrderConfirmationToThisAddress =
      sendOrderConfirmationToThisAddress || false;
  }

  public get sendOrderConfirmationToThisAddress() {
    return this._sendOrderConfirmationToThisAddress;
  }

  public set sendOrderConfirmationToThisAddress(
    sendOrderConfirmationToThisAddress: boolean
  ) {
    this._sendOrderConfirmationToThisAddress =
      sendOrderConfirmationToThisAddress;
  }

  public clone(): EmailCustomerField {
    return new EmailCustomerField({
      id: this.id,
      name: this.localisedName.toJSON(),
      description: this.localisedDescription.toJSON(),
      isRequired: this.isRequired,
      sendOrderConfirmationToThisAddress:
        this.sendOrderConfirmationToThisAddress || undefined
    });
  }

  public toJSON(): any {
    return {
      ...super.toJSON(),
      sendOrderConfirmationToThisAddress:
        this.sendOrderConfirmationToThisAddress
    };
  }
}
