import Invoice from "../models/invoice/Invoice";
import { invoiceParser } from "../parsers";

export default class InvoiceService {
  private readonly fbStore;

  constructor(fbStore) {
    this.fbStore = fbStore;
  }

  protected getInvoicesRef() {
    return this.fbStore.collection("invoices");
  }

  public onBarInvoices(barId: string, maxCount: number, cb: Function) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    const ref = this.getInvoicesRef()
      .where("barId", "==", barId)
      .orderBy("timestamp", "desc");

    return ref.onSnapshot((docs) => {
      const invoices: Array<Invoice> = [];
      docs.forEach(doc => invoices.push(invoiceParser.parseDocToInvoice(doc.id, doc)));
      cb(invoices);
    });
  }
}
