import DataName from "../models/data/DataName";
import AdministrationData from "../models/data/AdministrationData";
import BusinessData from "../models/data/BusinessData";
import CatalogueData from "../models/data/CatalogueData";
import Product from "../models/catalogue/Product";

export default class DataService {
  private readonly fbStore;

  constructor(fbStore) {
    this.fbStore = fbStore;
  }

  private getDataRef(barId: string, dataName: string) {
    return this.fbStore.collection("bars").doc(barId).collection("data").doc(dataName);
  }

  private parseDocToProps(doc): any {
    const data = doc.data();

    return {
      ...data
      //timestamp: data.timestamp.toDate()
    };
  }

  private async setData(barId: string, dataName: string, data: any) {
    if (!dataName) {
      throw new Error("error.data-name-is-not-defined");
    }

    await this.getDataRef(barId, dataName).set(data);
  }

  public async getAdministrationData(barId: string): Promise<AdministrationData> {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const doc = await this.getDataRef(barId, DataName.ADMINISTRATION).get();
    return new AdministrationData(barId, this.parseDocToProps(doc));
  }

  public createAdministrationData(barId: string, data: any): AdministrationData {
    return new AdministrationData(barId, data);
  }

  public async setAdministrationData(administrationData: AdministrationData) {
    await this.setData(administrationData.barId, DataName.ADMINISTRATION, administrationData.allPropsToJSON());
  }

  public async getBusinessData(barId: string): Promise<BusinessData> {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    const doc = await this.getDataRef(barId, DataName.BUSINESS).get();
    return new BusinessData(barId, this.parseDocToProps(doc));
  }

  public onBusinessData(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    return this.getDataRef(barId, DataName.BUSINESS)
      .onSnapshot((doc) => {
        cb(new BusinessData(barId, this.parseDocToProps(doc)));
      });
  }

  public createBusinessData(barId: string, data: any): BusinessData {
    return new BusinessData(barId, data);
  }

  public async setBusinessData(businessData: BusinessData) {
    await this.setData(businessData.barId, DataName.BUSINESS, businessData.allPropsToJSON());
  }

  public async getCatalogueData(barId: string): Promise<CatalogueData> {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    const doc = await this.getDataRef(barId, DataName.CATALOGUE).get();
    return new CatalogueData(barId, this.parseDocToProps(doc));
  }

  public onCatalogueData(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    return this.getDataRef(barId, DataName.CATALOGUE)
      .onSnapshot((doc) => {
        cb(new CatalogueData(barId, this.parseDocToProps(doc)));
      });
  }

  public createCatalogueData(barId: string, data: any): CatalogueData {
    return new CatalogueData(barId, data);
  }

  public async setCatalogueData(catalogueData: CatalogueData) {
    await this.setData(catalogueData.barId, DataName.CATALOGUE, catalogueData.allPropsToJSON());
  }

  public async getAndUpdateCustomProductsInCatalogueData(barId: string, customProductsUpdateFunction) {
    const catalogueDataRef = this.getDataRef(barId, DataName.CATALOGUE);

    await this.fbStore
      .runTransaction((fTransaction) => {
        return fTransaction.get(catalogueDataRef).then((catalogueDataDoc) => {
          const catalogueData = catalogueDataDoc.data();

          const currentCustomProducts: Array<Product> = catalogueData && catalogueData.customProducts ?
            catalogueData.customProducts.map(product =>
              new Product(product.id, product.isCustom, product.name, product.vatRate)
            ) :
            [];

          const updatedCustomProducts: Array<Product> = customProductsUpdateFunction(currentCustomProducts);
          const customProductsAsJSON = updatedCustomProducts.map(product => product.toJSON());

          if (catalogueData) {
            fTransaction.update(catalogueDataRef, { customProducts: customProductsAsJSON });
          } else {
            fTransaction.set(catalogueDataRef, { customProducts: customProductsAsJSON });
          }
        });
      });
  }

  public async addCustomProductToCatalogueData(barId: string, productToAdd: Product) {
    return this.getAndUpdateCustomProductsInCatalogueData(barId, (customProducts) => [...customProducts, productToAdd]);
  }

  public async updateCustomProductInCatalogueData(barId: string, updatedProduct: Product) {
    return this.getAndUpdateCustomProductsInCatalogueData(barId, (customProducts: Array<Product>) => customProducts.map((product: Product) => {
      if (updatedProduct.id === product.id) {
        return updatedProduct;
      }

      return product;
    }));
  }
}
