import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/nl-be";

// window.fetch polyfill
import "whatwg-fetch";

// window.BarcodeDetector polyfill
//import "tap-io/client/polyfills/BarcodeDetector";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
