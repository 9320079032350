import Integration from "./Integration";
import IntegrationName from "./IntegrationName";

class AbiIntegration extends Integration {
  private _credentials: {
    username: string;
    password: string;
    pocId: string;
  };

  private _pos: {
    id: string;
    name: string;
    syncedAt: Date;
  } | null;

  private _currentEvent: {
    id: string;
    syncedAt: Date | null;
  } | null;

  constructor(barId: string, props: any) {
    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    super(barId, IntegrationName.ABI, props);

    this._credentials = props.credentials;
    this._pos = props.pos ? props.pos : null;
    this._currentEvent = props.currentEvent ? props.currentEvent : null;

    super.setAllProps(["credentials", "currentEvent"]);
  }

  public get credentials() {
    return this._credentials;
  }

  public set credentials(credentials) {
    this._credentials = credentials;
    super.setPropUpdated("credentials");
  }

  public get pos() {
    return this._pos;
  }

  public get currentEvent() {
    return this._currentEvent;
  }

  public set currentEventId(currentEventId: string) {
    if (currentEventId !== this.currentEvent?.id) {
      this._currentEvent = {
        id: currentEventId,
        syncedAt: null
      };
      super.setPropUpdated("currentEvent");
    }
  }

  public get canEventsBeFetched(): boolean {
    return this.pos !== null;
  }

  public get isConfigured(): boolean {
    return (
      this.pos !== null &&
      this.currentEvent !== null &&
      this.currentEvent.syncedAt !== null
    );
  }
}

export default AbiIntegration;
