import React, { PureComponent } from "react";

import { withRouter } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";
import LocatorErrorPage from "../../pages/LocatorErrorPage";

const styles = (theme) => ({
  root: {
    width: "100vw",
    height: "100vh"
  },
  loading: {
    position: "absolute",
    zIndex: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
});

const withLocator = (Component) => {
  class WithLocator extends PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        isLoading: false,
        error: null,
        barLocator:
          props.match && props.match.params && props.match.params.barLocator
            ? props.match.params.barLocator
            : null,
        barId: null
      };
    }

    componentDidMount() {
      this.findBarIdOrRedirect()
        .then(() => {})
        .catch((error) => {
          console.warn(error);
        });
      /*const { barLocator } = this.state;

      if (barLocator) {
        this.setState({ isLoading: true });
        barService.searchBarIdByLocator(barLocator).then(
          (barId) => {
            this.setState({ isLoading: false, barId });
          },
          (error) => {
            this.setState({
              isLoading: false,
              error
            });
          }
        );
      } else {
        this.setState({ error: "error.no-locator-found" });
      }*/
    }

    findBarIdOrRedirect = async () => {
      const { location } = this.props;
      const { barLocator } = this.state;

      if (barLocator) {
        this.setState({ isLoading: true });

        try {
          const barId = await barService.searchBarIdByLocator(barLocator);
          this.setState({ isLoading: false, barId });
        } catch (error) {
          try {
            const originLocator =
              await barService.searchRedirectOriginByLocator(barLocator);
            window.location.href = `${location.pathname.replace(
              barLocator,
              originLocator
            )}${location.search}`;
          } catch (error) {
            this.setState({
              isLoading: false,
              error
            });
          }
        }
      } else {
        this.setState({ error: "error.no-locator-found" });
      }
    };

    render() {
      const { classes, match, ...other } = this.props;
      const { isLoading, barLocator, barId, error } = this.state;

      if (barId) {
        return <Component barId={barId} barLocator={barLocator} {...other} />;
      }

      return (
        <div className={classes.root}>
          {isLoading ? (
            <div className={classes.loading}>
              <CircularProgress className={classes.spinner} />
            </div>
          ) : (
            <LocatorErrorPage error={error} />
          )}
        </div>
      );
    }
  }

  return withRouter(withStyles(styles)(WithLocator));
};

export default withLocator;
