import BasePreparationOption from "./BasePreparationOption";

class BasePreparationOptions {
  private _preparationOptions: BasePreparationOption[];

  constructor(
    data: { duration: number }[] = [
      { duration: 5 },
      { duration: 10 },
      { duration: 15 }
    ]
  ) {
    this._preparationOptions = data.map(
      (item) => new BasePreparationOption(item)
    );
  }

  private get preparationOptions() {
    return this._preparationOptions;
  }

  private set preparationOptions(preparationOptions: BasePreparationOption[]) {
    this._preparationOptions = preparationOptions;
  }

  public has(preparationOption: BasePreparationOption): boolean {
    for (let i = 0; i < this.preparationOptions.length; i++) {
      if (this.preparationOptions[i].equals(preparationOption)) {
        return true;
      }
    }

    return false;
  }

  public add(preparationOptionToAdd: BasePreparationOption) {
    for (let i = 0; i < this.preparationOptions.length; i++) {
      if (this.preparationOptions[i].equals(preparationOptionToAdd)) {
        throw new Error("error.preparation-option-already-exists");
      } else if (
        this.preparationOptions[i].duration > preparationOptionToAdd.duration
      ) {
        this.preparationOptions.splice(i, 0, preparationOptionToAdd);
        return;
      }
    }

    this.preparationOptions.push(preparationOptionToAdd);
  }

  public remove(preparationOptionToRemove: BasePreparationOption) {
    if (!this.has(preparationOptionToRemove)) {
      throw new Error("error.preparation-option-does-not-exist");
    }

    this.preparationOptions = this.preparationOptions.filter(
      (preparationOption) =>
        !preparationOption.equals(preparationOptionToRemove)
    );
  }

  public get length(): number {
    return this.preparationOptions.length;
  }

  public map<T>(
    cb: (
      value: BasePreparationOption,
      index: number,
      array: BasePreparationOption[]
    ) => T
  ): T[] {
    return this.preparationOptions.map(cb);
  }

  public toJSON(): any {
    return this.preparationOptions.map((preparationOption) =>
      preparationOption.toJSON()
    );
  }

  public clone(): BasePreparationOptions {
    return new BasePreparationOptions(this.toJSON());
  }
}

export default BasePreparationOptions;
