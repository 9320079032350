import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import PaymentMethod from "../../../models/payment/PaymentMethod";

const styles = (theme) => ({});

class SelectPaymentMethod extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: ""
    };
  }

  componentDidMount() {
    const { paymentMethod } = this.props;

    this.setState({ paymentMethod: paymentMethod || "" });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { paymentMethod } = this.props;
    const prevPaymentMethod = prevProps.paymentMethod;

    if (paymentMethod !== prevPaymentMethod) {
      this.setState({ paymentMethod: paymentMethod || "" });
    }
  }

  handlePaymentMethodChange = (event) => {
    const { onChange } = this.props;

    onChange(event.target.value);
  };

  render() {
    const { classes, t, isDisabled, addEmptyOption, label } = this.props;
    const { paymentMethod } = this.state;

    return (
      <FormControl fullWidth variant="standard">
        {label && <InputLabel>{label}</InputLabel>}
        <Select
          fullWidth
          variant="standard"
          margin="none"
          value={paymentMethod}
          disabled={isDisabled}
          onChange={this.handlePaymentMethodChange}
        >
          {addEmptyOption && <MenuItem value="">{t("label.none")}</MenuItem>}
          {Object.keys(PaymentMethod).map((paymentMethod) => (
            <MenuItem key={paymentMethod} value={PaymentMethod[paymentMethod]}>
              {t(`payment.payment-method-${PaymentMethod[paymentMethod]}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SelectPaymentMethod)
);
