import { serviceHelper } from "../../helpers";

class VoucherCustomer {
  private readonly _email: string;

  constructor(props: any) {
    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    this._email = serviceHelper.parseString(props.email);
  }

  public get email(): string {
    return this._email;
  }

  public toJSON(): any {
    return {
      email: this.email
    };
  }
}

export default VoucherCustomer;