import CustomerField from "./CustomerField";
import CustomerFieldProps from "./CustomerFieldProps";
import CustomerFieldType from "./CustomerFieldType";
import SelectCustomerFieldOption from "./SelectCustomerFieldOption";
import { utilsHelper } from "../../helpers";

interface SelectCustomerFieldProps extends CustomerFieldProps {
  options: SelectCustomerFieldOption[];
}

export default class SelectCustomerField extends CustomerField {
  private _options: SelectCustomerFieldOption[];

  constructor(props: Omit<SelectCustomerFieldProps, "type">) {
    super({ ...props, type: CustomerFieldType.SELECT });

    const { options } = props;

    this._options = options;
  }

  public get options() {
    return this._options;
  }

  public set options(options: SelectCustomerFieldOption[]) {
    this._options = options;
  }

  public hasOption(id: string): boolean {
    return !!utilsHelper.findInArrayById(this.options, id);
  }

  public clone(): SelectCustomerField {
    return new SelectCustomerField({
      id: this.id,
      name: this.localisedName.toJSON(),
      description: this.localisedDescription.toJSON(),
      isRequired: this.isRequired,
      options: this.options.map((option) => option.clone())
    });
  }

  public toJSON(): any {
    return {
      ...super.toJSON(),
      options: this.options.map((option) => option.toJSON())
    };
  }
}
