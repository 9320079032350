import { firebase } from "../firebase";

import SessionStorage from "./SessionStorage";
import DeviceStorage from "./DeviceStorage";
import CloudStorage from "./CloudStorage";

const sessionStorage = new SessionStorage();
const deviceStorage = new DeviceStorage();
const cloudStorage = new CloudStorage(firebase.storage);

export { sessionStorage, deviceStorage, cloudStorage };
