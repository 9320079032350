import CustomerField from "./CustomerField";
import CustomerFieldProps from "./CustomerFieldProps";
import CustomerFieldType from "./CustomerFieldType";

interface ToggleCustomerFieldProps extends CustomerFieldProps {
  type: CustomerFieldType.TOGGLE;
}

export default class ToggleCustomerField extends CustomerField {
  constructor(props: Omit<ToggleCustomerFieldProps, "type">) {
    super({ ...props, type: CustomerFieldType.TOGGLE });
  }

  public clone(): ToggleCustomerField {
    return new ToggleCustomerField({
      id: this.id,
      name: this.localisedName.toJSON(),
      description: this.localisedDescription.toJSON(),
      isRequired: this.isRequired
    });
  }
}
