import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import ShowCompleteOrderButton from "../order/ShowCompleteOrderButton";
import EnterPaymentPinDialog from "./EnterPaymentPinDialog";
import { paymentService } from "../../services";
import SelectPaymentMethod from "./SelectPaymentMethod";
import PaymentMethod from "../../../models/payment/PaymentMethod";

const styles = {
  accentuate: {
    color: "red"
  }
};

class ConfirmOrderDeferredPaymentDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEnterPaymentPinDialogOpen: false,
      isDisabled: false,
      paymentMethod: PaymentMethod.CASH,
      paymentReference: ""
    };
  }

  componentDidMount() {
    const { isOpen, skipPaymentDetails } = this.props;

    if (isOpen) {
      if (skipPaymentDetails) {
        this.setState({ isEnterPaymentPinDialogOpen: true });
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen, skipPaymentDetails } = this.props;
    const wasOpen = prevProps.isOpen;

    if (isOpen && !wasOpen) {
      if (skipPaymentDetails) {
        this.setState({ isEnterPaymentPinDialogOpen: true });
      }
    }
  }

  handleCheckPin = (event) => {
    this.setState({ isEnterPaymentPinDialogOpen: true });
  };

  handlePinDialogConfirm = async (pin) => {
    await this.handleConfirm(pin);
  };

  handlePinDialogCancel = () => {
    this.setState({ isEnterPaymentPinDialogOpen: false });
  };

  handlePaymentMethodChange = (paymentMethod) => {
    this.setState({ paymentMethod });
  };

  handlePaymentReferenceChange = (event) => {
    const val = event.target.value;
    this.setState({ paymentReference: val });
  };

  handleConfirm = async (pin) => {
    const { t, auth, order, alsoRequestPayment, onClose } = this.props;
    const { paymentMethod, paymentReference } = this.state;

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(t("error.no-valid-user-found"));
    }

    const toastId = toast(t("payment.settling-order"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      if (alsoRequestPayment) {
        await paymentService.requestAndConfirmDeferredPayment(
          auth.user.uid,
          order,
          pin,
          paymentMethod,
          paymentReference
        );
      } else {
        await paymentService.confirmDeferredPayment(
          auth.user.uid,
          order,
          pin,
          paymentMethod,
          paymentReference
        );
      }

      this.setState({
        isDisabled: false,
        isEnterPaymentPinDialogOpen: false
      });

      toast.update(toastId, {
        render: t("payment.order-settled"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      onClose(true);
    } catch (error) {
      console.warn(error);

      this.setState({
        isDisabled: false
      });
      toast.update(toastId, {
        render:
          error && error.text ? error && error.text : t("error.unknown-error"),
        type: toast.TYPE.ERROR,
        autoClose: 3000
      });
    }
  };

  handleClose = () => {
    const { onClose } = this.props;

    onClose(false);
  };

  render() {
    const { classes, t, isOpen, bar, order } = this.props;
    const {
      isEnterPaymentPinDialogOpen,
      isDisabled,
      paymentMethod,
      paymentReference
    } = this.state;

    return (
      <div>
        <EnterPaymentPinDialog
          isOpen={isEnterPaymentPinDialogOpen}
          isDisabled={isDisabled}
          onCancel={this.handlePinDialogCancel}
          onConfirm={this.handlePinDialogConfirm}
          showRememberPin={true}
          bar={bar}
        />
        <Dialog open={isOpen} onClose={this.handleClose}>
          <DialogTitle>{t("payment.checkout-order-manually")}</DialogTitle>
          <DialogContent>
            {order ? (
              <div>
                {order.containsItemsFromMultipleMenus() && (
                  <div>
                    <DialogContentText>
                      <strong className={classes.accentuate}>
                        {`${t("label.attention")} ${t(
                          "order.order-contains-items-from-multiple-menus"
                        )}`}
                      </strong>
                    </DialogContentText>
                    <ShowCompleteOrderButton bar={bar} order={order} />
                    <br />
                  </div>
                )}
                <DialogContentText>
                  <span>
                    {t("payment.charge-remaining-amount-of-complete-order")}:
                    <br />
                    <strong>{`${order.getFormattedTotalToBePaid()} ${
                      order.currency
                    }`}</strong>
                  </span>
                  <br />
                  <br />
                  {t("payment.add-a-payment-method-and-reference-below")}
                </DialogContentText>
                <SelectPaymentMethod
                  //label={t("payment.payment-method")}
                  disabled={isDisabled}
                  paymentMethod={paymentMethod}
                  onChange={this.handlePaymentMethodChange}
                />
                <TextField
                  variant="standard"
                  margin="dense"
                  label={t("payment.payment-reference")}
                  helperText={t("payment.such-as-cash-register-or-terminal-id")}
                  fullWidth
                  value={paymentReference}
                  onChange={this.handlePaymentReferenceChange}
                  disabled={isDisabled}
                />
              </div>
            ) : (
              <Typography>{t("order.no-order-found")}</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              disabled={isDisabled}
              color="secondary"
            >
              {t("label.cancel")}
            </Button>
            {order && (
              <Button
                onClick={this.handleCheckPin}
                disabled={isDisabled}
                color="primary"
              >
                {t("label.confirm")}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(ConfirmOrderDeferredPaymentDialog)
);
