export default {
  firebase: {
    apiKey: "AIzaSyB2V9iSF_owVPqZR1EfyE0R6OjmUStR0_s",
    authDomain: "tap-io-dev.firebaseapp.com",
    databaseURL: "https://tap-io-dev.firebaseio.com",
    projectId: "tap-io-dev",
    storageBucket: "tap-io-dev.appspot.com",
    messagingSenderId: "392993384107",
    appId: "1:392993384107:web:3fdbb3e618911b25"
  },
  gtm: {
    id: null
  },
  analytics: {
    trackingIds: {
      app: ["G-LG105K8WP8"],
      order: ["G-KJ9FYV74FP"],
      scan: ["G-37NB75GLKJ"]
    }
  },
  functions: {
    api: "https://europe-west1-tap-io-dev.cloudfunctions.net/api",
    statsApi: "http://127.0.0.1:5001/tap-io-dev/europe-west1/Stats-API",
    promoApi: "https://europe-west1-tap-io-dev.cloudfunctions.net/promo",
    integrationsApi:
      "https://europe-west1-tap-io-dev.cloudfunctions.net/integrations",
    vivaWalletWebhook:
      "https://europe-west1-tap-io-dev.cloudfunctions.net/vivawallet/webhook",
    vivaWalletCheckout:
      "https://europe-west1-tap-io-dev.cloudfunctions.net/vivawallet/checkout"
  },
  stripe: {
    pubApiKey: "pk_test_dZVR4MK45ku6EegIk0UJShO000Pd4MVoqL"
  },
  hosting: {
    domain: "lab.watdrinkje.be",
    appDomain: "app.lab.watdrinkje.be",
    orderDomain: "bestel.lab.watdrinkje.be",
    scanDomain: "scan.lab.watdrinkje.be"
  },
  bugsnag: {
    apiKey: "79f6bd403606f99b7a3c06353cd526ed",
    releaseStage: "development"
  },
  cookies: {
    manager: {
      type: "cookiebot",
      domainIds: {
        app: null,
        order: "5aac2af7-d8cf-4166-be95-a905c0b7b7af",
        scan: null
      }
    }
  },
  links: {
    promo: "https://watdrinkje.be",
    termsOfUse: "https://watdrinkje.be/gebruikersvoorwaarden",
    privacyPolicy: "https://watdrinkje.be/privacy-policy",
    starnetPinReset: "https://guard-abi-dev.web.app"
  },
  defaults: {
    name: "bo"
  },
  product: {
    name: "Wat Drink Je?",
    tagline: null
  },
  support: {
    email: null
  },
  paymentProviders: {
    mollie: true,
    ccv: true,
    payconiq: true,
    starnet: true,
    vivawallet: true,
    multisafepay: true
  },
  integrations: {
    abi: true
  },
  native: {
    appStoreUrl: "https://apps.apple.com/be/app/wat-drink-je/id1490103401"
  }
};
