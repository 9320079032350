import { serviceHelper } from "../../helpers";

class OrderFee {
  private readonly _id: string;
  private readonly _name: string;
  private readonly _value: number;
  private readonly _externalId: string | null;

  constructor(props: any) {
    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    this._id = serviceHelper.parseString(props.id);
    this._name = serviceHelper.parseString(props.name);
    this._value = serviceHelper.parseNumber(props.value);
    this._externalId = props.externalId
      ? serviceHelper.parseString(props.externalId)
      : null;
  }

  public get id(): string {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get value(): number {
    return this._value;
  }

  public get externalId(): string | null {
    return this._externalId;
  }

  public toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      value: this.value,
      externalId: this.externalId
    };
  }
}

export default OrderFee;
