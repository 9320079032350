import { authHelper, serviceHelper } from "../../helpers";
import { ReportSchema } from "../../models/stats/ReportSchema";
import StatsService from "../../services/StatsService";

export default class ClientStatsService extends StatsService {
  constructor(fbStore) {
    super(fbStore);
  }

  fetchReport<K extends keyof ReportSchema>(type: K) {
    return async function (
      apiFunctionsEndpoint: string,
      authenticatedUser: any,
      filter: ReportSchema[K]["Filter"]
    ): Promise<ReportSchema[K]["Data"][]> {
      try {
        let f = {} as any;

        if (filter.from) f.from = filter.from.toISOString();
        if (filter.to) f.to = filter.to.toISOString();
        if (filter.organisationId) f.organisationId = filter.organisationId;
        if ((filter as any).eventId) f.eventId = (filter as any).eventId;
        if ((filter as any).baseId) f.baseId = (filter as any).baseId;

        const url = await authHelper.generateAuthorizedLink(
          authenticatedUser,
          `${apiFunctionsEndpoint}/report/${type}`,
          f
        );
        const result = await serviceHelper.fetchJSON(url);

        if (!result?.code || result.code !== 200) {
          console.error(result);
          throw new Error("error.failed-to-fetch-report");
        }

        return result.data;
      } catch (error) {
        throw error;
      }
    };
  }
}
