import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  text: {}
});

class OrderError extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const { classes, t, bar, base, order } = this.props;

    if (!order.isStatusError()) {
      return null;
    }

    const overallStatus = order.getOverallStatus();

    return (
      <span className={classes.text}>
        {overallStatus.error && overallStatus.error.text
          ? overallStatus.error.text
          : `${t("error.unknown-error")}`}
      </span>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(OrderError));
