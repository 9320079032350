import Subscription from "../models/subscription/Subscription";
import SubscriptionStatus from "../models/subscription/SubscriptionStatus";
import { subscriptionParser } from "../parsers";
import { utilsHelper } from "../helpers";

export default class SubscriptionService {
  private readonly fbStore;

  public readonly VALID_UNTIL_WARNING_NUMBER_OF_DAYS: number = 14;

  constructor(fbStore) {
    this.fbStore = fbStore;
  }

  protected getSubscriptionsRef() {
    return this.fbStore.collection("subscriptions");
  }

  protected getSubscriptionRef(barId: string) {
    return this.getSubscriptionsRef().doc(barId);
  }

  public async getSubscriptionById(barId: string): Promise<Subscription> {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const doc = await this.getSubscriptionRef(barId).get();

    return subscriptionParser.parseDocToSubscription(barId, doc);
  }

  public onSubscriptionById(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.getSubscriptionRef(barId).onSnapshot((doc) => {
      cb(subscriptionParser.parseDocToSubscription(barId, doc));
    });
  }

  public onRequestedSubscriptions(cb: Function) {
    const ref = this.getSubscriptionsRef().where(
      "status",
      "==",
      SubscriptionStatus.REQUESTED
    );

    return ref.onSnapshot((docs) => {
      const subscriptions: Array<Subscription> = [];
      docs.forEach((doc) => {
        subscriptions.push(
          subscriptionParser.parseDocToSubscription(doc.id, doc)
        );
      });
      cb(subscriptions);
    });
  }

  public isSubscriptionAboutToExpire(subscription: Subscription): boolean {
    return subscription.validUntil
      ? utilsHelper.isDateWithinDaysInFuture(
          subscription.validUntil,
          this.VALID_UNTIL_WARNING_NUMBER_OF_DAYS
        )
      : false;
  }
}
