import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  content: {
    fontSize: 12
  },
  confirmationCode: {
    fontFamily: "'Courier New', Courier, monospace",
    textTransform: "uppercase"
  }
});

class OrderConfirmationCode extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, order } = this.props;

    return (
      <ListItem>
        <Typography className={classes.content}>
          {t("order.confirmation-code")}{" "}
          <strong className={classes.confirmationCode}>
            {order.confirmationCode
              ? order.confirmationCode
              : `(${t("label.unknown")})`}
          </strong>
        </Typography>
      </ListItem>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(OrderConfirmationCode)
);
