import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import InfoIcon from "@mui/icons-material/Info";
import withStyles from "@mui/styles/withStyles";

import PaymentMessage from "./PaymentMessage";

const styles = (theme) => ({});

class OrderIsPartiallyPaidMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, order } = this.props;

    return (
      <PaymentMessage
        color="#1976d2"
        icon={<InfoIcon />}
        text={t("order.remaining-order-total-to-be-paid", {
          amountToBePaid: `${order.getFormattedTotalToBePaid()} ${
            order.currency
          }`
        })}
        description={t("order.order-is-partially-paid", {
          amountAlreadyPaid: `${order.getFormattedTotalAlreadyPaid()} ${
            order.currency
          }`
        })}
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(OrderIsPartiallyPaidMessage)
);
