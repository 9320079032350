import { serviceHelper } from "../../helpers";
import BarPropsModel from "../model/BarPropsModel";
import VoucherType from "./VoucherType";

class VoucherTemplate extends BarPropsModel {
  private _name: string;
  private _voucherType: string;
  private _voucherValue: null | number;
  private _voucherMaxAgeInDays: number;
  private _combineMultipleVouchers: boolean;
  private readonly _timestamp: Date | undefined;

  constructor(barId: string, id?: string, props?: any) {
    super(barId, id);

    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    this._name = serviceHelper.parseString(props.name);
    if (props.voucherType !== VoucherType.CURRENCY) {
      throw new Error("error.voucher-type-is-invalid");
    }
    this._voucherType = props.voucherType;
    this._voucherValue = props.voucherValue === null ? null : serviceHelper.parseNumber(props.voucherValue, 0.01);
    this._voucherMaxAgeInDays = serviceHelper.parseInt(props.voucherMaxAgeInDays, 1);
    this._combineMultipleVouchers = props.combineMultipleVouchers === true;

    if (props.timestamp) {
      this._timestamp = serviceHelper.parseTimestamp(props.timestamp);
    }

    super.setAllProps(["name", "voucherType", "voucherValue", "voucherMaxAgeInDays", "combineMultipleVouchers", "timestamp"]);
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = serviceHelper.parseString(name);
    super.setPropUpdated("name");
  }

  public get voucherType(): string {
    return this._voucherType;
  }

  public set voucherType(voucherType: string) {
    if (voucherType !== VoucherType.CURRENCY) {
      throw new Error("error.voucher-type-is-invalid");
    }
    this._voucherType = voucherType;
    super.setPropUpdated("voucherType");
  }

  public get voucherValue(): null | number {
    return this._voucherValue;
  }

  public set voucherValue(voucherValue: null | number) {
    this._voucherValue = voucherValue === null ? null : serviceHelper.parseNumber(voucherValue, 0.01);
    super.setPropUpdated("voucherValue");
  }

  public get voucherMaxAgeInDays(): number {
    return this._voucherMaxAgeInDays;
  }

  public set voucherMaxAgeInDays(voucherMaxAgeInDays: number) {
    this._voucherMaxAgeInDays = serviceHelper.parseInt(voucherMaxAgeInDays, 1);
    super.setPropUpdated("voucherMaxAgeInDays");
  }

  public get combineMultipleVouchers(): boolean {
    return this._combineMultipleVouchers;
  }

  public set combineMultipleVouchers(combineMultipleVouchers: boolean) {
    this._combineMultipleVouchers = combineMultipleVouchers;
    super.setPropUpdated("combineMultipleVouchers");
  }

  public get timestamp(): Date | undefined {
    return this._timestamp;
  }

  public clone(): VoucherTemplate {
    return new VoucherTemplate(this.barId, this.id, this.allPropsToJSON());
  }
}

export default VoucherTemplate;
