import PaymentService from "../../services/PaymentService";
import Order from "../../models/order/Order";
import { paymentHelper, serviceHelper } from "../../helpers";
import PaymentMethod from "../../models/payment/PaymentMethod";

export default class ClientPaymentService extends PaymentService {
  constructor() {
    super();
  }

  public requestDeferredPayment(
    userId: string,
    order: Order,
    onProgress?: Function
  ) {
    return serviceHelper.createTask(
      userId,
      "order",
      "requestDeferredPayment",
      {
        barId: order.barId,
        orderId: order.id
      },
      onProgress
    );
  }

  public confirmDeferredPayment(
    userId: string,
    order: Order,
    pin: string,
    paymentMethod?: PaymentMethod,
    paymentReference?: string,
    onProgress?: Function
  ) {
    const data: any = {
      barId: order.barId,
      orderId: order.id,
      pinHash: paymentHelper.hashPin(order.barId, pin)
    };

    if (paymentMethod) {
      data.paymentMethod = paymentMethod;
    }
    if (paymentReference) {
      data.paymentReference = paymentReference;
    }

    return serviceHelper.createTask(
      userId,
      "order",
      "confirmDeferredPayment",
      data,
      onProgress
    );
  }

  public requestAndConfirmDeferredPayment(
    userId: string,
    order: Order,
    pin: string,
    paymentMethod?: PaymentMethod,
    paymentReference?: string,
    onProgress?: Function
  ) {
    const data: any = {
      barId: order.barId,
      orderId: order.id,
      pinHash: paymentHelper.hashPin(order.barId, pin)
    };

    if (paymentMethod) {
      data.paymentMethod = paymentMethod;
    }
    if (paymentReference) {
      data.paymentReference = paymentReference;
    }

    return serviceHelper.createTask(
      userId,
      "order",
      "requestAndConfirmDeferredPayment",
      data,
      onProgress
    );
  }

  public redeemVoucher(
    userId: string,
    order: Order,
    voucherId: string,
    voucherPin: string,
    onProgress?: Function
  ) {
    return serviceHelper.createTask(
      userId,
      "order",
      "redeemVoucher",
      {
        barId: order.barId,
        orderId: order.id,
        voucherId,
        voucherPin
      },
      onProgress
    );
  }

  public redeemVouchers(
    userId: string,
    order: Order,
    voucherCodes: Array<string>,
    onProgress?: Function
  ) {
    return serviceHelper.createTask(
      userId,
      "order",
      "redeemVouchers",
      {
        barId: order.barId,
        orderId: order.id,
        voucherCodes
      },
      onProgress
    );
  }
}
