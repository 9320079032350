import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Link, Redirect } from "react-router-dom";

import { CircularProgress, Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { deviceStorage } from "tap-io/storage";
import { barService } from "tap-io/client/services";
import { barHelper } from "tap-io/helpers";
import config from "tap-io/client/env";
import ContentPage from "tap-io/client/components/common/ContentPage";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";

import {
  CACHE_KEY_BAR_LOCATOR,
  CACHE_KEY_WAITER_CODE
} from "../constants/cache";
import { SCAN_QR_CODE } from "../constants/routes";

const TIMEOUT = 3000;

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  smallSpacing: {
    height: 20
  },
  largeSpacing: {
    height: 40
  },
  link: {
    textDecoration: "none"
  },
  error: {
    color: theme.palette.error.main
  }
});

class WaiterOrderPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmClearCacheDialogOpen: false,
      isDisabled: false,
      isCancelled: false
    };
  }

  componentDidMount() {
    this.redirectTimeout = setTimeout(
      this.checkBarLocatorAndWaiterCode,
      TIMEOUT
    );
  }

  checkBarLocatorAndWaiterCode = async () => {
    const { t, match } = this.props;

    this.setState({ isDisabled: true });

    try {
      const barLocator = match?.params?.barLocator;
      const waiterCode = match?.params?.waiterCode;

      if (barLocator && waiterCode) {
        const bar = await barService.getBarByLocator(barLocator);

        const isWaiterCodeValid = await barService.isBarWaiterCodeValid(
          bar,
          waiterCode
        );
        if (!isWaiterCodeValid) {
          throw new Error("error.waiter-code-is-not-valid");
        }

        await deviceStorage.setDeviceCacheItem(
          "",
          CACHE_KEY_BAR_LOCATOR,
          barLocator
        );
        await deviceStorage.setDeviceCacheItem(
          "",
          CACHE_KEY_WAITER_CODE,
          waiterCode
        );

        window.location.href = barHelper.getWaiterUrl(
          config.hosting.orderDomain,
          barLocator,
          waiterCode
        );
      }
    } catch (error) {
      console.warn(error);

      this.setState({
        isDisabled: false,
        error: t(error ? error.message : "error.unknown-error")
      });
    }
  };

  handleRedirectNow = () => {
    if (this.redirectTimeout) {
      clearTimeout(this.redirectTimeout);
      this.redirectTimeout = undefined;
    }

    this.checkBarLocatorAndWaiterCode();
  }

  handleCancelRedirect = () => {
    if (this.redirectTimeout) {
      clearTimeout(this.redirectTimeout);
      this.redirectTimeout = undefined;
    }

    this.setState({ isConfirmClearCacheDialogOpen: true, isDisabled: true, });

  };

  handleConfirmClearCache = async () => {
    await deviceStorage.clearDeviceCacheItem("", CACHE_KEY_BAR_LOCATOR);
    await deviceStorage.clearDeviceCacheItem("", CACHE_KEY_WAITER_CODE);

    this.setState({ isCancelled: true });
  }

  handleCancelClearCache = async () => {
    this.setState({ isCancelled: true });
  }

  render() {
    const { classes, t } = this.props;
    const { isConfirmClearCacheDialogOpen, isDisabled, isCancelled, error } = this.state;

    if (isCancelled) {
      return <Redirect to={SCAN_QR_CODE} />;
    }

    return (
      <>
        <ConfirmDialog
          isOpen={isConfirmClearCacheDialogOpen}
          title={t("label.do-you-want-to-clear-the-cache")}
          description={t("label.you-will-need-to-scan-the-waiter-qr-code-again")}
          confirmButtonLabel={t("label.clear-cache")}
          onConfirm={this.handleConfirmClearCache}
          onCancel={this.handleCancelClearCache}
        />
        <ContentPage>
          {error ? (
            <div className={classes.content}>
              <Typography className={classes.error}>{error}</Typography>
              <div className={classes.largeSpacing} />
              <Link onClick={event => isDisabled ? event.preventDefault() : null} disabled={isDisabled} to={SCAN_QR_CODE} className={classes.link}>
                <Button disabled={isDisabled}>{t("label.try-again")}</Button>
              </Link>
            </div>
          ) : (
            <div className={classes.content}>
              <CircularProgress />
              <div className={classes.largeSpacing} />
              <Typography>{t("label.redirecting-to-waiter-page")}</Typography>
              <div className={classes.largeSpacing} />
              <Button disabled={isDisabled} onClick={this.handleRedirectNow} variant="contained" size="large">
                {t("label.redirect-now")}
              </Button>
              <div className={classes.largeSpacing} />
              <Button disabled={isDisabled} onClick={this.handleCancelRedirect} size="small">
                {t("label.cancel")}
              </Button>
            </div>
          )}
        </ContentPage>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(WaiterOrderPage));
