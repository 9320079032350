import StatsDataType from "./StatsDataType";
import { statsHelper } from "../../helpers";

export default class StatsData {
  private readonly _type: string;
  private readonly _name: string | undefined;

  constructor(type: string, name?: string) {
    if (type !== StatsDataType.COLLECTION && type !== StatsDataType.FRAME) {
      throw new Error("error.stats-data-type-is-invalid");
    }

    this._type = type;

    if (name) {
      this._name = name;
    }
  }

  public get type(): string {
    return this._type;
  }

  public get name(): string | undefined {
    return this._name;
  }

  public get(id: string): any {
    throw new Error("error.not-implemented");
  }

  public add(statsData: StatsData) {
    throw new Error("error.not-implemented");
  }

  public isEmpty(): boolean {
    throw new Error("error.not-implemented");
  }

  public dataToJSON(incrementFunction?: Function, arrayUnionFunction?: Function): any {
    throw new Error("error.not-implemented");
  }

  public toJSON(incrementFunction?: Function, arrayUnionFunction?: Function): any {
    const json: any = {
      t: this.type,
      d: this.dataToJSON(incrementFunction, arrayUnionFunction)
    };

    if (this.name) {
      json.n = this.name;
    }

    return json;
  }

  public clone(): StatsData {
    return statsHelper.dataToStatsData(this.toJSON());
  }
}