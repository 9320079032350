import OrderDeposit from "./OrderDeposit";

class OrderCollectedDeposit extends OrderDeposit {
    private readonly _amountAtPurchaseValue: number;
    private readonly _amountAtTradeInValue: number;
    private readonly _totalAtPurchaseValue: number;
    private readonly _totalAtTradeInValue: number;

    constructor(depositId: string, props: any) {
        super(depositId, props);

        this._amountAtPurchaseValue = props.amountAtPurchaseValue; //serviceHelper.parseNumber(props.amountAtPurchaseValue);
        this._amountAtTradeInValue = props.amountAtTradeInValue; //serviceHelper.parseNumber(props.amountAtTradeInValue);
        this._totalAtPurchaseValue = props.totalAtPurchaseValue; //serviceHelper.parseNumber(props.totalAtPurchaseValue);
        this._totalAtTradeInValue = props.totalAtTradeInValue; //serviceHelper.parseNumber(props.totalAtTradeInValue);
    }

    public get amountAtPurchaseValue(): number {
        return this._amountAtPurchaseValue;
    }

    public get amountAtTradeInValue(): number {
        return this._amountAtTradeInValue;
    }

    public get totalAtPurchaseValue(): number {
        return this._totalAtPurchaseValue;
    }

    public get totalAtTradeInValue(): number {
        return this._totalAtTradeInValue;
    }

    public toJSON() {
        return {
            ...super.toJSON(),
            amountAtPurchaseValue: this.amountAtPurchaseValue,
            amountAtTradeInValue: this.amountAtTradeInValue,
            totalAtPurchaseValue: this.totalAtPurchaseValue,
            totalAtTradeInValue: this.totalAtTradeInValue
        }
    }
}

export default OrderCollectedDeposit;
