import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 25
  }
});

class OrderSubmittingDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClose = (event, reason) => {
    const { onClose } = this.props;

    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      //onClose();
    }
  };

  render() {
    const { classes, t, isOpen } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={this.handleClose}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>{t("order.adding-order")}</DialogTitle>
        <DialogContent className={classes.content}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderSubmittingDialog));
