import { serviceHelper } from "../../helpers";

class VoucherPayment {
  private readonly _method: string;

  constructor(props: any) {
    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    this._method = serviceHelper.parseString(props.method ? props.method : props.provider);
  }

  public get method(): string {
    return this._method;
  }

  public toJSON(): any {
    return {
      method: this.method
    };
  }
}

export default VoucherPayment;