class OrderContactAddress {
  private readonly _type: string;
  private readonly _email: string;

  constructor(props: any) {
    if (!props) {
      throw new Error("error.props-are-not-defined");
    }

    this._type = props.type;
    this._email = props && props.email ? props.email : "";
  }

  public get type(): string {
    return this._type;
  }

  public get email(): string {
    return this._email;
  }

  public toJSON(): any {
    return {
      type: this.type,
      email: this.email
    };
  }
}

export default OrderContactAddress;