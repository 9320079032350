import LayoutNodeSequence from "./LayoutNodeSequence";
import LayoutNodeSequenceType from "./LayoutNodeSequenceType";

export default class WholeNumbersLayoutNodeSequence extends LayoutNodeSequence {
  private _begin: number;
  private _end: number;
  private _incrementor: number;

  constructor(begin: number, end: number, incrementor: number) {
    super(LayoutNodeSequenceType.WHOLE_NUMBERS);

    this._begin = begin;
    this._end = end;
    this._incrementor = incrementor;
  }

  public get begin() {
    return this._begin;
  }

  public set begin(begin: number) {
    this._begin = begin;
  }

  public get end() {
    return this._end;
  }

  public set end(end: number) {
    this._end = end;
  }

  public get incrementor() {
    return this._incrementor;
  }

  public set incrementor(incrementor: number) {
    this._incrementor = incrementor;
  }

  public getGeneratedSequence(): string[] {
    const generatedSequence: string[] = [];

    for (let i = this.begin; i <= this.end; i += this.incrementor) {
      generatedSequence.push(`${i}`);
    }

    return generatedSequence;
  }

  public map(cb: (element: number) => any) {
    let current = this.begin;

    const mapped: any[] = [];

    while (current <= this.end) {
      mapped.push(cb(current as any) as any);
      current += this.incrementor;
    }

    return mapped;
  }

  public toJSON(): any {
    return {
      ...super.toJSON(),
      begin: this.begin,
      end: this.end,
      incrementor: this.incrementor
    };
  }

  public toString(): string {
    return `${this.begin} - ${this.end}`;
  }

  public clone(): WholeNumbersLayoutNodeSequence {
    return new WholeNumbersLayoutNodeSequence(
      this.begin,
      this.end,
      this.incrementor
    );
  }
}
