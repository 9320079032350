import OrderStatus from "./OrderStatus";

class OrderLog {
  private readonly _barId: string;
  private readonly _orderId: string;
  private readonly _id: string;
  private readonly _status: OrderStatus;
  private readonly _statusForMenu: { [menuId: string]: OrderStatus };
  private readonly _timestamp: Date;

  constructor(barId: string, orderId: string, id: string, data: any) {
    this._barId = barId;
    this._orderId = orderId;
    this._id = id;

    if (!data) {
      throw new Error("error.data-is-not-defined");
    }
    if (data.status && data.status.name) {
      this._status = data.status.name;
    } else if (data.name) {
      // Legacy status name
      this._status = data.name;
    } else {
      throw new Error("error.data-status-name-is-not-defined");
    }

    this._statusForMenu = data.statusForMenu || {};
    this._timestamp = data.timestamp;
  }

  public get barId() {
    return this._barId;
  }

  public get orderId() {
    return this._orderId;
  }

  public get id() {
    return this._id;
  }

  public get status() {
    return this._status;
  }

  public get statusForMenu() {
    return this._statusForMenu;
  }

  public get timestamp() {
    return this._timestamp;
  }
}

export default OrderLog;
