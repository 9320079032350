import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "../../../helpers";

const styles = (theme) => ({
  container: {
    flexDirection: "column"
  },
  fee: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  },
  feeText: {
    fontSize: 14
  }
});

class OrderFees extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { order, classes } = this.props;

    if (order && order.fees && order.fees.length > 0) {
      return (
        <ListItem className={classes.container}>
          {order.fees.map((fee, index) => (
            <div key={index} className={classes.fee}>
              <Typography className={classes.feeText}>{fee.name}</Typography>
              <Typography
                align="right"
                color="textSecondary"
                className={classes.feeText}
              >{`${utilsHelper.formatToTwoDecimals(fee.value)} ${order.currency
                }`}</Typography>
            </div>
          ))}
        </ListItem>
      );
    }


    return null
  }
}

export default withStyles(styles)(withTranslation("common")(OrderFees));
