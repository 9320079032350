import ServiceOption from "./ServiceOption";
import ServiceOptionName from "./ServiceOptionName";
import FulfilmentMethod from "../fulfilment/FulfilmentMethod";
import DeliveryMethod from "../delivery/DeliveryMethod";
import PriorityNumber from "../priority/PriorityNumber";

export default class ShowAndGoServiceOption extends ServiceOption {
  constructor() {
    super(
      ServiceOptionName.SHOW_AND_GO,
      FulfilmentMethod.ON_DEMAND,
      DeliveryMethod.PICKUP,
      PriorityNumber.MEDIUM
    );
  }
}
