import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import appTheme from "../../theme";

const styles = (theme) => ({
  logo: {
    backgroundImage: `url(${appTheme.assets.logo})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "contain"
  }
});

class Logo extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, size } = this.props;

    return (
      <div
        className={classes.logo}
        style={{
          height: size
        }}
      />
    );
  }
}

export default withStyles(styles)(Logo);
