import { paymentHelper, serviceHelper, utilsHelper } from "../../helpers";
import { voucherParser } from "../../parsers";
import Voucher from "./Voucher";
import VoucherType from "./VoucherType";
import Order from "../order/Order";

class CurrencyVoucher extends Voucher {
  private readonly _currency: string;

  constructor(barId: string, id?: string, props?: any) {
    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    super(
      barId,
      VoucherType.CURRENCY,
      id,
      {
        ...props,
        originalValue: voucherParser.parseCurrencyVoucherValue(props.originalValue),
        remainingValue: voucherParser.parseCurrencyVoucherValue(props.remainingValue)
      }
    );

    this._currency = serviceHelper.parseString(paymentHelper.parsePaymentCurrency(props.currency));

    super.setAllProps(["currency"]);
  }

  public get originalValue(): number {
    return this._originalValue;
  }

  public get remainingValue(): number {
    return this._remainingValue;
  }

  public get usedValue(): number {
    return this.originalValue - this.remainingValue;
  }

  public get hasRemainingValue(): boolean {
    return this.remainingValue > 0;
  }

  public canBeRedeemedForOrder(order: Order): boolean {
    if (!this.isValid) {
      return false;
    }
    if (!order) {
      return false;
    }
    if (this.currency !== order.currency) {
      return false;
    }

    return true;
  }

  public redeemForOrder(order: Order): number {
    const valuePaidWithVoucher = Math.min(this.remainingValue, order.getTotalToBePaid());

    this._remainingValue = utilsHelper.roundToTwoDecimals(this.remainingValue - valuePaidWithVoucher);
    super.setPropUpdated("remainingValue");

    return valuePaidWithVoucher;
  }

  public refund(amount: number): number {
    const newValue = utilsHelper.roundToTwoDecimals(this.remainingValue + amount);
    if (newValue > this.originalValue) {
      throw new Error("error.new-value-is-greater-than-original-value");
    }

    this._remainingValue = newValue;
    super.setPropUpdated("remainingValue");

    return newValue;
  }

  public originalValueToString(translator: any): string {
    return `${this.originalValue} ${this.currency}`;
  }

  public remainingValueToString(translator: any): string {
    return `${this.remainingValue} ${this.currency}`;
  }

  public usedValueToString(translator: any): string {
    return `${this.usedValue} ${this.currency}`;
  }

  public get currency(): string {
    return this._currency;
  }

  public getFormattedOriginalValue(): string {
    if (this.originalValue !== undefined) {
      return utilsHelper.formatToTwoDecimals(this.originalValue);
    }

    return "";
  }

  public getFormattedUsedValue(): string {
    if (this.usedValue !== undefined) {
      return utilsHelper.formatToTwoDecimals(this.usedValue);
    }

    return "";
  }

  public getFormattedRemainingValue(): string {
    if (this.remainingValue !== undefined) {
      return utilsHelper.formatToTwoDecimals(this.remainingValue);
    }

    return "";
  }

  public serializeRemainingValue(): number {
    return this.remainingValue;
  }

  public clone(): CurrencyVoucher {
    return new CurrencyVoucher(this.barId, this.id, super.allPropsToJSON());
  }
}

export default CurrencyVoucher;
