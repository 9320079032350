import StatsDataCollection from "../models/stats/StatsDataCollection";
import Stats from "../models/stats/Stats";
import StatsDataUnit from "../models/stats/StatsDataUnit";
import { statsHelper } from "../helpers";

export default class StatsService {
  private readonly fbStore;

  constructor(fbStore) {
    this.fbStore = fbStore;
  }

  protected getStatsRef(barId: string) {
    return this.fbStore.collection("bars").doc(barId).collection("orderStats");
  }

  protected getStatsRefForDate(barId: string, date: string) {
    return this.getStatsRef(barId).doc(date);
  }

  protected getStatsRefForAllOrdersBeforeDate(date: Date) {
    return this.fbStore.collectionGroup("orderStats").where("t", "<", date);
  }

  private getLeastAccurateStatsDataUnit(statsPerOrder: Array<Stats>): string {
    if (statsPerOrder.length === 0) {
      return StatsDataUnit.DAY;
    }

    return statsPerOrder.reduce((leastAccurateStatsDataUnit: string, statsForOrder: Stats): string => {
      if (!statsForOrder.unit) {
        throw new Error("error.stats-for-order-unit-is-not-defined");
      }
      if (statsForOrder.hasLessAccurateUnitThan(leastAccurateStatsDataUnit)) {
        return statsForOrder.unit;
      }

      return leastAccurateStatsDataUnit;
    }, StatsDataUnit.QUARTER);
  }

  private getMergedStatsFormats(statsPerOrder: Array<Stats>): Array<string> {
    if (statsPerOrder.length === 0) {
      return [];
    }

    return statsPerOrder.reduce((mergedStatsFormats: Array<string>, statsForOrder: Stats): Array<string> => {
      if (statsForOrder.formats) {
        statsForOrder.formats.forEach(format => {
          if (mergedStatsFormats.indexOf(format) < 0) {
            mergedStatsFormats.push(format);
          }
        });
      }

      return mergedStatsFormats;
    }, []);
  }


  public async doStatsExistForDate(barId: string, date: string) {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }
    if (!date) {
      throw new Error("error.date-is-not-defined");
    }

    const doc = await this.getStatsRefForDate(barId, date).get();
    return doc.exists === true;
  }

  public mergeStats(barId: string, date: string, defaultStatsDataUnit: string, defaultStatsDataKeys: Array<string>, statsPerOrder: Array<Stats>): Stats {
    const statsDataUnit = statsPerOrder.length > 0 ?
      this.getLeastAccurateStatsDataUnit(statsPerOrder) :
      defaultStatsDataUnit;
    const statsFormats = statsPerOrder.length > 0 ?
      this.getMergedStatsFormats(statsPerOrder) :
      [statsHelper.getStatsFormat(statsDataUnit, defaultStatsDataKeys)];

    const mergedStatsDataCollection = new StatsDataCollection();

    statsPerOrder.forEach(statsForOrder => {
      const statsForOrderForUnit = statsForOrder.perUnit(statsDataUnit);

      if (statsForOrderForUnit) {
        mergedStatsDataCollection.getStatsDataCollection(statsDataUnit).add(
          statsForOrderForUnit.clone()
        );
      }
    });

    return new Stats(
      barId,
      date,
      {
        f: statsFormats,
        [statsDataUnit]: mergedStatsDataCollection
          .getStatsDataCollection(statsDataUnit).toJSON()
      });
  }
}
