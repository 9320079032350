import VoucherLogEntry from "./VoucherLogEntry";
import { voucherParser } from "../../parsers";
import { paymentHelper, serviceHelper } from "../../helpers";

class CurrencyVoucherLogEntry extends VoucherLogEntry {
  private readonly currency: string;

  constructor(id: string, props: any) {
    super(
      id,
      {
        ...props,
        previousVoucherValue: voucherParser.parseCurrencyVoucherValue(props.previousVoucherValue),
        newVoucherValue: voucherParser.parseCurrencyVoucherValue(props.newVoucherValue),
        valuePaidWithVoucher: voucherParser.parseCurrencyVoucherValue(props.valuePaidWithVoucher)
      }
    );

    this.currency = serviceHelper.parseString(paymentHelper.parsePaymentCurrency(props.currency));
  }

  public get previousVoucherValue(): number {
    return this._previousVoucherValue;
  }

  public get newVoucherValue(): number {
    return this._newVoucherValue;
  }

  public get valuePaidWithVoucher(): number {
    return this._valuePaidWithVoucher;
  }

  public get usedVoucherValue(): number {
    return this.valuePaidWithVoucher; //this.newVoucherValue - this.previousVoucherValue;
  }

  public get voucherValueDifference(): number {
    return -this.usedVoucherValue;
  }

  public previousVoucherValueToString(translator: Function): string {
    return `${this.previousVoucherValue} ${this.currency}`;
  }

  public newVoucherValueToString(translator: Function): string {
    return `${this.newVoucherValue} ${this.currency}`;
  }

  public valuePaidWithVoucherToString(translator: Function): string {
    return `${this.valuePaidWithVoucher} ${this.currency}`;
  }

  public voucherValueDifferenceToString(translator: Function): string {
    return `${this.voucherValueDifference < 0 ? "-" : "+"}${Math.abs(this.voucherValueDifference)} ${this.currency}`;
  }
}

export default CurrencyVoucherLogEntry;