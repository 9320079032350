import StatsData from "./StatsData";
import StatsDataType from "./StatsDataType";
import { statsHelper, utilsHelper } from "../../helpers";

export default class StatsDataFrame extends StatsData {
  private _amountOfOrders: number;
  private _amountOfProducts: number;
  private _amountOfItems: number;
  private _totalPrice: number;

  constructor(props?: any, name?: string) {
    super(StatsDataType.FRAME, name);

    this._amountOfOrders = props && props.oA ? props.oA : 0;
    this._amountOfProducts = props && props.pA ? props.pA : 0;
    this._amountOfItems = props && props.iA ? props.iA : 0;
    this._totalPrice = props && props.tP ? props.tP : 0;
  }

  public get(name: string): any {
    switch (name) {
      case "amountOfOrders":
        return this.amountOfOrders;
        break;
      case "amountOfProducts":
        return this.amountOfProducts;
        break;
      case "amountOfItems":
        return this.amountOfItems;
        break;
      case "totalPrice":
        return this.totalPrice;
        break;
      default:
        throw new Error("error.name-is-not-valid");
        break;
    }
  }

  public get amountOfOrders(): number {
    return this._amountOfOrders;
  }

  public set amountOfOrders(amountOfOrders: number) {
    this._amountOfOrders = amountOfOrders;
  }

  public get amountOfProducts(): number {
    return this._amountOfProducts;
  }

  public set amountOfProducts(amountOfProducts: number) {
    this._amountOfProducts = amountOfProducts;
  }

  public get amountOfItems(): number {
    return this._amountOfItems;
  }

  public set amountOfItems(amountOfItems: number) {
    this._amountOfItems = amountOfItems;
  }

  public get totalPrice(): number {
    return this._totalPrice;
  }

  public set totalPrice(totalPrice: number) {
    this._totalPrice = utilsHelper.roundToTwoDecimals(totalPrice);
  }

  public add(statsData: StatsData) {
    if (statsHelper.isStatsDataFrame(statsData)) {
      const statsDataFrame = <StatsDataFrame>statsData;
      this.amountOfOrders += statsDataFrame.amountOfOrders;
      this.amountOfProducts += statsDataFrame.amountOfProducts;
      this.amountOfItems += statsDataFrame.amountOfItems;
      this.totalPrice += statsDataFrame.totalPrice;
    } else {
      throw new Error("error.only-stats-data-frame-can-be-added-to-a-data-frame");
    }
  }

  public isEmpty(): boolean {
    return !this.amountOfOrders &&
      !this.amountOfProducts &&
      !this.amountOfItems &&
      !this.totalPrice;
  }

  public dataToJSON(incrementFunction?: Function, arrayUnionFunction?: Function): any {
    const json: any = {};

    if (this.amountOfOrders) {
      json.oA = incrementFunction ? incrementFunction(this.amountOfOrders) : this.amountOfOrders;
    }
    if (this.amountOfProducts) {
      json.pA = incrementFunction ? incrementFunction(this.amountOfProducts) : this.amountOfProducts;
    }
    if (this.amountOfItems) {
      json.iA = incrementFunction ? incrementFunction(this.amountOfItems) : this.amountOfItems;
    }
    if (this.totalPrice) {
      json.tP = incrementFunction ? incrementFunction(this.totalPrice) : this.totalPrice;
    }

    return json;
  }
}