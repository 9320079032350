import CustomerField from "./CustomerField";
import CustomerFieldProps from "./CustomerFieldProps";
import CustomerFieldType from "./CustomerFieldType";

interface DateCustomerFieldProps extends CustomerFieldProps {
  minDate?: Date;
  maxDate?: Date;
  exceptDays?: { [day: string]: boolean };
}

export default class DateCustomerField extends CustomerField {
  private _minDate: Date | null;
  private _maxDate: Date | null;
  private _exceptDays: { [day: string]: boolean };

  constructor(props: Omit<DateCustomerFieldProps, "type">) {
    super({ ...props, type: CustomerFieldType.DATE });

    const { minDate, maxDate, exceptDays } = props;

    this._minDate = minDate || null;
    this._maxDate = maxDate || null;
    this._exceptDays = exceptDays || {};
  }

  public get minDate() {
    return this._minDate;
  }

  public set minDate(minDate: Date | null) {
    this._minDate = minDate;
  }

  public get maxDate() {
    return this._maxDate;
  }

  public set maxDate(maxDate: Date | null) {
    this._maxDate = maxDate;
  }

  public get exceptDays() {
    return this._exceptDays;
  }

  public set exceptDays(exceptDays: { [day: string]: boolean }) {
    Object.keys(exceptDays).forEach((day) => {
      if (exceptDays[day] !== true) {
        delete exceptDays[day];
      }
    });

    if (Object.keys(exceptDays).length === 7) {
      throw new Error("error.select-at-least-one-day");
    }

    this._exceptDays = exceptDays;
  }

  public clone(): DateCustomerField {
    return new DateCustomerField({
      id: this.id,
      name: this.localisedName.toJSON(),
      description: this.localisedDescription.toJSON(),
      isRequired: this.isRequired,
      minDate: this.minDate || undefined,
      maxDate: this.maxDate || undefined,
      exceptDays: this.exceptDays
    });
  }

  public toJSON(): any {
    return {
      ...super.toJSON(),
      minDate: this.minDate,
      maxDate: this.maxDate,
      exceptDays: this.exceptDays
    };
  }
}
