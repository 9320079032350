import React, { PureComponent } from "react";
import classNames from "classnames";

import { ListSubheader, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { cloudStorage } from "../../../storage";

const styles = (theme) => ({
  category: {
    display: "flex",
    flexDirection: "column",
    //position: "relative",
    //top: 64,
    background: "transparent", // theme.palette.background.default,
    //backdropFilter: "saturate(180%) blur(20px)",
    lineHeight: "32px",
    minHeight: 40
  },
  text: {
    display: "block",
    justifyContent: "start",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: 2,
    fontSize: 16,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    color: theme.palette.primary.main
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 60,
    paddingBottom: 10
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%"
  }
});

class MenuCategory extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { showImage } = this.props;

    if (showImage) {
      this.refreshCategoryImageUrl();
    }
  }

  refreshCategoryImageUrl = async () => {
    const { bar, category } = this.props;

    if (category.hasImage) {
      try {
        const imageUrl = await cloudStorage.getBarMenuCategoryImageUrl(
          bar.id,
          category
        );

        this.setState({ imageUrl });
      } catch (error) {
        console.warn(error);

        this.setState({ imageUrl: null });
      }
    } else {
      this.setState({ imageUrl: null });
    }
  };

  render() {
    const { classes, category } = this.props;
    const { imageUrl } = this.state;

    return (
      <ListSubheader className={classes.category}>
        <Typography
          className={classNames(classes.text, "notranslate")}
          variant="overline"
        >
          {category.name}
        </Typography>
        {imageUrl && (
          <div className={classes.imageContainer}>
            <img className={classes.image} src={imageUrl} />
          </div>
        )}
      </ListSubheader>
    );
  }
}

export default withStyles(styles)(MenuCategory);
