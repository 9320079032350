import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { scannerService } from "tap-io/client/services";

import withAuthorization from "../auth/withAuthorization";
import ContentDrawer from "../common/ContentDrawer";

const styles = (theme) => ({
  content: {
    padding: theme.spacing()
  },
  smallSpacing: {
    height: 10
  },
  largeSpacing: {
    height: 20
  }
});

class EnterOrderDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    return {
      isDisabled: false,
      confirmationCode: "",
      orders: null
    };
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen } = this.props;
    const prevIsOpen = prevProps.isOpen;

    if (isOpen && !prevIsOpen) {
      this.setState(this.initialState());
    }
  }

  getCleanConfirmationCode = () => {
    const { confirmationCode } = this.state;

    return confirmationCode ? confirmationCode.trim().toLowerCase() : "";
  };

  handleConfirmationCodeChange = (event) => {
    this.setState({ confirmationCode: event.target.value });
  };

  handleSearchOrders = async () => {
    const { t, auth, scanner, getPinHash } = this.props;

    if (auth && auth.user && scanner) {
      try {
        this.setState({ isDisabled: true });

        const pinHash = await getPinHash();
        const confirmationCode = this.getCleanConfirmationCode();

        const orders = await scannerService.findOrders(
          config.functions.api,
          auth.user,
          scanner,
          pinHash,
          confirmationCode
        );

        this.setState({ isDisabled: false, orders });
      } catch (error) {
        console.warn(error);

        toast.error(
          `${t("label.something-went-wrong")} (${
            error ? error.message : t("error.unknown-error")
          })`
        );

        this.setState({ isDisabled: false, orders: [] });
      }
    } else {
      this.setState({ orders: null });
    }
  };

  handleEnter = (orderId) => (event) => {
    const { onEnter } = this.props;

    onEnter(orderId);
  };

  render() {
    const { classes, t, isOpen, onClose } = this.props;
    const { isDisabled, confirmationCode, orders } = this.state;

    return (
      <ContentDrawer
        isOpen={isOpen}
        onClose={onClose}
        title={t("scanner.enter-order-manually")}
        description={t("scanner.enter-order-confirmation-code-below")}
      >
        <div className={classes.content}>
          <TextField
            fullWidth
            variant="standard"
            disabled={isDisabled}
            value={confirmationCode}
            placeholder={t("scanner.order-confirmation-code")}
            onChange={this.handleConfirmationCodeChange}
          />
          <div className={classes.smallSpacing} />
          <Button
            fullWidth
            disabled={isDisabled || !this.getCleanConfirmationCode()}
            onClick={this.handleSearchOrders}
          >
            {t("scanner.search-orders")}
          </Button>
          {orders && (
            <div>
              <div className={classes.largeSpacing} />
              <List>
                <ListSubheader>{t("scanner.found-orders")}</ListSubheader>
                {orders.length > 0 ? (
                  orders.map((order) => (
                    <ListItem
                      key={order.id}
                      button
                      onClick={this.handleEnter(order.id)}
                    >
                      <ListItemText
                        primary={order.name}
                        secondary={order.confirmationCode}
                      />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary={t("scanner.no-orders-found")} />
                  </ListItem>
                )}
              </List>
            </div>
          )}
        </div>
      </ContentDrawer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withAuthorization()(withTranslation("common")(EnterOrderDrawer))
);
