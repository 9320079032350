import { utilsHelper } from ".";
import Integration from "../models/integration/Integration";
import DeliverectIntegration from "../models/integration/DeliverectIntegration";
import PartnerIntegration from "../models/integration/PartnerIntegration";
import AbiIntegration from "../models/integration/AbiIntegration";
import IntegrationName from "../models/integration/IntegrationName";

export default class IntegrationHelper {
  isDeliverectIntegration(integration: Integration): boolean {
    return integration instanceof DeliverectIntegration;
  }

  isPartnerIntegration(integration: Integration): boolean {
    return integration instanceof PartnerIntegration;
  }

  isAbiIntegration(integration: Integration): boolean {
    return integration instanceof AbiIntegration;
  }

  findAbiIntegration(integrations: Integration[]): AbiIntegration | null {
    return (
      utilsHelper.findInArrayById(integrations, IntegrationName.ABI) || null
    );
  }

  public generateStarnetPinResetUrl(
    baseResetUrl: string,
    barLocator: string,
    orderId?: string,
    cardNumber?: string
  ) {
    const queryParams: any = {};

    if (orderId) {
      queryParams.order = orderId;
    }
    if (cardNumber) {
      queryParams.card = cardNumber;
    }

    const query = utilsHelper.encodeQueryParams(queryParams);

    return `${baseResetUrl}/${barLocator}/request${
      query.length > 0 ? `?${query}` : ""
    }`;
  }
}
