import { serviceHelper } from "../../helpers";

class BaseSequenceNumberGenerator {
  private _current: number;
  private _increment: number = 1; // Must be positive int
  private _min: number = 1;
  private _max: number = 100;

  constructor(props: any) {
    this._current =
      props && props.current
        ? serviceHelper.parseNumber(props.current, this.min, this.max)
        : this.min - this.increment;
  }

  public get current(): number {
    return this._current;
  }

  public set current(current: number) {
    this._current = current;
  }

  public get increment(): number {
    return this._increment;
  }

  public get min(): number {
    return this._min;
  }

  public get max(): number {
    return this._max;
  }

  public next(): number {
    const next = this.current + this.increment;

    this.current = next < this.min || next > this.max ? this.min : next;

    return this.current;
  }

  public toJSON(): any {
    return {
      current: this.current
    };
  }
}

export default BaseSequenceNumberGenerator;
