import BarPropsModel from "../model/BarPropsModel";

class Data extends BarPropsModel {
  constructor(barId: string, name: string) {
    super(barId, name);
  }

  public get name(): string {
    if (!this.id) {
      throw new Error("error.name-is-not-defined");
    }

    return this.id;
  }
}

export default Data;