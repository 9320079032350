import { utilsHelper } from "../../helpers";

export default class LayoutPath {
  public static readonly DIVIDER: string = "/";

  private readonly _elements: string[];

  constructor(elements?: string[]) {
    this._elements = elements || [];
  }

  public get elements() {
    return this._elements;
  }

  public get length() {
    return this.elements.length;
  }

  get(index: number): string | null {
    return index >= 0 && index < this.elements.length
      ? this.elements[index]
      : null;
  }

  beginsWith(element: string): boolean {
    return this.elements.indexOf(element) === 0;
  }

  prepend(path: LayoutPath) {
    this.elements.unshift(...path.elements);
  }

  append(path: LayoutPath) {
    this.elements.push(...path.elements);
  }

  slice(begin?: number, end?: number): LayoutPath {
    return new LayoutPath(this.elements.slice(begin, end));
  }

  equals(layoutPath: LayoutPath): boolean {
    return utilsHelper.areArraysEqualShallow(
      this.elements,
      layoutPath.elements
    );
  }

  toString(): string {
    return this.elements.join(LayoutPath.DIVIDER);
  }
}
