import { depositParser } from "../parsers";
import { utilsHelper } from "../helpers";
import Deposit from "../models/deposit/Deposit";

export default class DepositService {
  protected readonly fbStore;

  constructor(fbStore: any) {
    this.fbStore = fbStore;
  }

  protected getDepositsRef(barId: string) {
    return this.fbStore.collection("bars").doc(barId).collection("deposits");
  }

  protected getDepositRef(barId: string, depositId: string) {
    return this.getDepositsRef(barId).doc(depositId);
  }

  public async getDepositById(
    barId: string,
    depositId: string
  ): Promise<Deposit> {
    if (!depositId) {
      throw new Error("error.deposit-id-is-not-defined");
    }

    const doc = await this.getDepositRef(barId, depositId).get();

    return depositParser.parseDocToDeposit(barId, doc);
  }

  public async getActiveDeposits(barId: string): Promise<Deposit[]> {
    const docs = await this.getDepositsRef(barId)
      .where("isActive", "==", true)
      .get();

    const deposits: Array<Deposit> = [];
    docs.forEach((doc) =>
      deposits.push(depositParser.parseDocToDeposit(barId, doc))
    );
    return deposits;
  }

  public async updateDeposit(deposit: Deposit) {
    if (!deposit.id) {
      throw new Error("error.id-is-not-defined");
    }

    const updatedProps = deposit.updatedPropsToJSON();
    if (!utilsHelper.isObjectEmpty(updatedProps)) {
      const ref = this.getDepositRef(deposit.barId, deposit.id);

      return ref.update(deposit.updatedPropsToJSON());
    }
  }

  public removeDeposit(deposit: Deposit) {
    if (!deposit.id) {
      throw new Error("error.id-is-not-defined");
    }

    return this.getDepositRef(deposit.barId, deposit.id).delete();
  }
}
