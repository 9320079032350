import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  Typography
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "calc(100vh - 200px)"
  },
  content: {
    maxWidth: 400
  },
  center: {
    justifyContent: "center"
  }
});

class SensitiveInformation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleUnlockApp = () => {
    const { unlockApp } = this.props;

    unlockApp()
      .then(() => {})
      .catch((error) => {
        console.warn(error);
      });
  };

  handleCancel = () => {
    this.setState({ redirectToOrders: true });
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.container}>
        <List className={classes.content}>
          <ListItem>
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <Typography variant="h6">
              {t("auth.view-sensitive-information")}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              {t("auth.enter-scanner-pin-to-view-sensitive-information")}
            </Typography>
          </ListItem>
          <ListItem className={classes.center}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleUnlockApp}
            >
              {t("auth.unlock")}
            </Button>
          </ListItem>
          <ListItem className={classes.center}>
            <Button onClick={this.handleCancel}>{t("label.cancel")}</Button>
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(SensitiveInformation)
);
