import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  priorityLabel: {
    display: "inline-block",
    padding: "2px 8px",
    borderRadius: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  }
});

class OrderFulfilmentLabel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, base, order } = this.props;

    return order.isHighPriority ? (
      <div className={classes.priorityLabel}>
        <span>{t(`service.priority-high`)}</span>
      </div>
    ) : null;
  }
}

export default withStyles(styles)(
  withTranslation("common")(OrderFulfilmentLabel)
);
