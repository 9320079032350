import { deliveryHelper, serviceHelper } from "../helpers";
import { orderParser } from "../parsers";

import Order from "../models/order/Order";
import OrderStatus from "../models/order/OrderStatus";
import Bar from "../models/bar/Bar";
import Base from "../models/base/Base";
import Locale from "../models/locale/Locale";
import Scanner from "../models/scanner/Scanner";

export default class OrderService {
  private readonly fbStore;

  public readonly SEARCH_BY_CONFIRMATION_CODE_MIN_QUERY_LENGTH = 4;

  constructor(fbStore) {
    this.fbStore = fbStore;
  }

  private checkIfMenuIdsArrayInQueryAreValid(menuIds: Array<string>) {
    if (!menuIds) {
      throw new Error("menuIds is not defined");
    }
    if (menuIds.length === 0) {
      throw new Error("error.menu-ids-empty");
    }
    if (menuIds.length > 10) {
      throw new Error("error.menu-ids-limit-exceeded");
    }
  }

  private getOrdersRef(barId: string, menuIds?: Array<string>) {
    let ref = this.fbStore.collection("bars").doc(barId).collection("orders");

    if (menuIds && menuIds.length > 0) {
      this.checkIfMenuIdsArrayInQueryAreValid(menuIds);
      ref = ref.where("menuIds", "array-contains-any", menuIds);
    }

    return ref;
  }

  protected getOrderRef(barId: string, orderId: string) {
    return this.getOrdersRef(barId).doc(orderId);
  }

  protected async setOrderStatus(
    barId: string,
    orderId: string,
    statusName: string,
    menuIds?: Array<string>,
    statusData?: any,
    updateFunction?: (order: Order) => any
  ) {
    const orderRef = this.getOrderRef(barId, orderId);

    if (statusName < OrderStatus.QUEUED) {
      throw new Error("error.status-name-is-less-than-status-queued");
    }

    const newStatus: any = { timestamp: serviceHelper.serverTimestamp() };
    if (statusData) {
      newStatus.data = statusData;
    }
    const newStatusForMenu = {};

    await serviceHelper.runTransaction((fTransaction) => {
      return fTransaction.get(orderRef).then((orderDoc) => {
        if (!orderDoc.exists) {
          throw new Error("error.order-does-not-exist");
        }
        const order = orderParser.parseDocToOrder(barId, orderDoc);

        /*if(order.hasBeenCancelled()) {
            throw new Error("error.order-has-been-cancelled");
          }*/

        // Check whether order has already been completed (for menuIds)
        // Once an order has been completed, it is not possible anymore to alter its state
        if (menuIds) {
          menuIds.forEach((menuId) => {
            if (order.getStatusForMenu(menuId) === OrderStatus.COMPLETE) {
              throw new Error("error.order-is-already-complete-for-menu-id");
            }
          });
        } else {
          if (order.isStatusComplete()) {
            throw new Error("error.order-is-already-complete");
          }
        }

        switch (statusName) {
          case OrderStatus.CANCELLED:
            if (!order.isStatusCreated()) {
              throw new Error(
                "error.status-name-is-cancelled-while-order-status-is-not-created"
              );
            }
            if (order.isPaymentProcessing()) {
              throw new Error(
                "error.status-name-is-cancelled-while-payment-is-processing"
              );
            }
            break;
        }

        const oldStatusName = order.getStatus();

        if (menuIds && menuIds.length > 0) {
          //newStatusForMenu = { ...oldStatusForMenu, [menuId]: statusName };
          if (order.menuIds) {
            order.menuIds.forEach((statusMenuId: string) => {
              const oldStatusForMenu = order.getStatusForMenu(statusMenuId);
              if (menuIds.indexOf(statusMenuId) >= 0) {
                if (oldStatusForMenu && statusName <= oldStatusForMenu) {
                  throw new Error(
                    "error.status-name-must-be-greater-than-old-status-for-menu"
                  ); // This error is being referred to in orders.js & tasks.js
                }

                newStatusForMenu[statusMenuId] = statusName;
              } else {
                newStatusForMenu[statusMenuId] = oldStatusForMenu
                  ? oldStatusForMenu
                  : oldStatusName;
              }
            });
          }

          const newStatusForMenuAsArray: Array<string> = [];
          for (let menuId in newStatusForMenu) {
            newStatusForMenuAsArray.push(newStatusForMenu[menuId]);
          }

          const lowestMenuStatus: string = newStatusForMenuAsArray.reduce(
            (statusA, statusB) => (statusA < statusB ? statusA : statusB)
          );

          if (oldStatusName && lowestMenuStatus < oldStatusName) {
            throw new Error(
              "error.lowest-menu-status-must-be-greater-than-or-equal-to-old-status-name"
            );
          }

          newStatus.name = lowestMenuStatus;
        } else {
          if (oldStatusName && statusName <= oldStatusName) {
            throw new Error(
              "error.status-name-must-be-greater-than-old-status-name"
            ); // This error is being referred to in orders.js & tasks.js
          }

          // Update status
          newStatus.name = statusName;

          // Update statusForMenu accordingly
          if (order.menuIds) {
            order.menuIds.forEach((menuId: string) => {
              newStatusForMenu[menuId] = statusName;
            });
          }
        }

        const toUpdate = updateFunction ? updateFunction(order) : {};
        toUpdate.status = newStatus;
        toUpdate.statusForMenu = newStatusForMenu;

        // Update statuses
        fTransaction.update(orderRef, toUpdate);
      });
    });
  }

  private sortOrders(orders: Array<Order>) {
    // FIFO: oldest first
    return orders.sort((orderA: Order, orderB: Order) =>
      orderA && orderA.sequenceTimestamp && orderB && orderB.sequenceTimestamp
        ? orderA.sequenceTimestamp.getTime() -
        orderB.sequenceTimestamp.getTime()
        : 0
    );
  }

  public async getById(barId: string, orderId: string): Promise<Order> {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }
    if (!orderId) {
      throw new Error("error.order-id-is-not-defined");
    }

    const doc = await this.getOrderRef(barId, orderId).get();
    if (!doc.exists) {
      throw new Error("error.order-does-not-exist");
    }

    return orderParser.parseDocToOrder(barId, doc);
  }

  public async getAllOrdersBetweenDates(
    barId: string,
    minDate: Date,
    maxDate: Date
  ): Promise<Array<Order>> {
    const ref = this.getOrdersRef(barId)
      .where("timestamp", ">=", minDate)
      .where("timestamp", "<=", maxDate)
      .orderBy("timestamp", "asc");

    const docs = await ref.get();

    const orders: Array<Order> = [];
    docs.forEach((doc) => {
      orders.push(orderParser.parseDocToOrder(barId, doc));
    });

    return orders;
  }

  public async getOpenOrders(barId: string): Promise<Order[]> {
    const ref = this.getOrdersRef(barId)
      .where("status.name", ">=", OrderStatus.QUEUED)
      .where("status.name", "<", OrderStatus.COMPLETE);
    const orders: Order[] = [];

    const docs = await ref.get();

    docs.forEach((doc) => {
      orders.push(orderParser.parseDocToOrder(barId, doc));
    });

    return this.sortOrders(orders);
  }

  public onById(bar: Bar, orderId: string, cb: Function) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }
    if (!orderId) {
      throw new Error("error.order-id-is-not-defined");
    }
    const barId = bar.id;

    return this.getOrderRef(barId, orderId).onSnapshot((doc) => {
      cb(orderParser.parseDocToOrder(barId, doc));
    });
  }

  onAllOrders(
    barId: string,
    cb: Function,
    maxCount: number,
    menuIds?: Array<string>
  ) {
    const ref = this.getOrdersRef(barId, menuIds)
      .orderBy("timestamp", "desc")
      .limit(maxCount);

    return ref.onSnapshot({ includeMetadataChanges: true }, (docs) => {
      //console.log(querySnapshot.metadata, querySnapshot.size);
      if (docs.metadata && docs.metadata.fromCache) {
        console.warn("querySnapshot fromCache; don't update allOrders");
      } else {
        const orders: Array<Order> = [];
        docs.forEach((doc) => {
          orders.push(orderParser.parseDocToOrder(barId, doc));
        });
        cb(orders);
      }
    });
  }

  onCrewOrders(
    barId: string,
    cb: Function,
    maxCount: number,
    menuIds?: Array<string>
  ) {
    const ref = this.getOrdersRef(barId, menuIds)
      .where("isCrew", "==", true)
      .orderBy("timestamp", "desc")
      .limit(maxCount);

    return ref.onSnapshot({ includeMetadataChanges: true }, (docs) => {
      //console.log(querySnapshot.metadata, querySnapshot.size);
      if (docs.metadata && docs.metadata.fromCache) {
        console.warn("querySnapshot fromCache; don't update allOrders");
      } else {
        const orders: Array<Order> = [];
        docs.forEach((doc) => {
          orders.push(orderParser.parseDocToOrder(barId, doc));
        });
        cb(orders);
      }
    });
  }

  onUnpaidOrders(
    barId: string,
    cb: Function,
    maxCount: number,
    menuIds?: Array<string>
  ) {
    const ref = this.getOrdersRef(barId, menuIds)
      .where("payment", "==", null)
      .orderBy("timestamp", "desc")
      .limit(maxCount);

    return ref.onSnapshot({ includeMetadataChanges: true }, (docs) => {
      //console.log(querySnapshot.metadata, querySnapshot.size);
      if (docs.metadata && docs.metadata.fromCache) {
        console.warn("querySnapshot fromCache; don't update allOrders");
      } else {
        const orders: Array<Order> = [];
        docs.forEach((doc) => {
          orders.push(orderParser.parseDocToOrder(barId, doc));
        });
        cb(orders);
      }
    });
  }

  onOpenOrders(barId: string, cb: Function, menuIds?: Array<string>) {
    let ref = this.getOrdersRef(barId);
    let orders: Array<Order> = [];

    if (menuIds && menuIds.length > 0) {
      const unsubscribeFunctions = menuIds.map((menuId) =>
        ref
          .where(`statusForMenu.${menuId}`, ">=", OrderStatus.QUEUED)
          .where(`statusForMenu.${menuId}`, "<", OrderStatus.COMPLETE)
          .onSnapshot((querySnapshot) => {
            orders = [
              ...orders.filter(
                (order) => !order.containsItemsFromMenuWithId(menuId)
              ), // Remove all orders with items from this menu
              ...querySnapshot.docs.map((doc) =>
                orderParser.parseDocToOrder(barId, doc)
              ) // Add all orders with items from this menu
            ];

            // Return copy of updated orders array and sort it
            cb(this.sortOrders([...orders]));
          })
      );

      return () => {
        unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
      };
    } else {
      return ref
        .where("status.name", ">=", OrderStatus.QUEUED)
        .where("status.name", "<", OrderStatus.COMPLETE)
        .onSnapshot((querySnapshot) => {
          orders = querySnapshot.docs.map((doc) =>
            orderParser.parseDocToOrder(barId, doc)
          );

          // Return copy of updated orders array and sort it
          cb(this.sortOrders([...orders]));
        });
    }
  }

  onAllOrdersBetweenDates(
    barId: string,
    minDate: Date,
    maxDate: Date,
    cb: Function
  ) {
    const ref = this.getOrdersRef(barId)
      .where("timestamp", ">=", minDate)
      .where("timestamp", "<=", maxDate)
      .orderBy("timestamp", "asc");

    return ref.onSnapshot((docs) => {
      const orders: Array<Order> = [];
      docs.forEach((doc) => {
        orders.push(orderParser.parseDocToOrder(barId, doc));
      });
      cb(orders);
    });
  }

  onPaidOrdersBetweenDates(
    barId: string,
    minDate: Date,
    maxDate: Date,
    cb: Function
  ) {
    const ref = this.getOrdersRef(barId)
      .where("timestamp", ">=", minDate)
      .where("timestamp", "<=", maxDate)
      .where("payment.isPaid", "==", true)
      .orderBy("timestamp", "asc");

    return ref.onSnapshot((docs) => {
      const orders: Array<Order> = [];
      docs.forEach((doc) => {
        orders.push(orderParser.parseDocToOrder(barId, doc));
      });
      cb(orders);
    });
  }

  onCompletedOrdersBetweenDates(
    barId: string,
    minDate: Date,
    maxDate: Date,
    cb: Function
  ) {
    const ref = this.getOrdersRef(barId)
      .where("timestamp", ">=", minDate)
      .where("timestamp", "<=", maxDate)
      .where("status.name", "==", OrderStatus.COMPLETE)
      .orderBy("timestamp", "asc");

    return ref.onSnapshot((docs) => {
      const orders: Array<Order> = [];
      docs.forEach((doc) => {
        orders.push(orderParser.parseDocToOrder(barId, doc));
      });
      cb(orders);
    });
  }

  async getLatestOrder(barId: string): Promise<Order | undefined> {
    const ref = this.getOrdersRef(barId).orderBy("timestamp", "desc").limit(1);

    const orderQuerySnapshot = await ref.get();

    if (orderQuerySnapshot.size === 1) {
      return orderParser.parseDocToOrder(barId, orderQuerySnapshot.docs[0]);
    }

    return undefined;
  }

  async searchOrdersByConfirmationCode(
    barId: string,
    query: string,
    menuIds?: Array<string>
  ): Promise<Array<Order>> {
    if (!barId) {
      throw new Error("barId is not defined");
    }
    if (query === undefined || query === null) {
      throw new Error("query is not defined");
    }
    if (query.length < this.SEARCH_BY_CONFIRMATION_CODE_MIN_QUERY_LENGTH) {
      return [];
    }

    const cleanQuery: string = query.trim().toLowerCase();

    const ref = this.getOrdersRef(barId, menuIds)
      .where("cleanConfirmationCode", ">=", cleanQuery)
      .where("cleanConfirmationCode", "<=", cleanQuery + "\uf8ff");

    const docs = await ref.get();

    const orders: Array<Order> = [];
    docs.forEach((doc) => {
      orders.push(orderParser.parseDocToOrder(barId, doc));
    });
    return orders;
  }

  async addOrder(
    userId: string,
    barId: string,
    name: string,
    items: Array<any>,
    service: any,
    delivery: any,
    zoneCode?: string,
    layoutPath?: string,
    fields?: any,
    note?: string,
    tip?: number,
    locale?: Locale,
    scanner?: Scanner,
    collectedDeposits?: { [depositId: string]: { amount: number } }
  ) {
    const order: any = {
      userId,
      name,
      items,
      service,
      delivery,
      timestamp: serviceHelper.serverTimestamp()
    };

    if (scanner) {
      order.scannerId = scanner.id;
    }

    if (zoneCode !== undefined) {
      order.zoneCode = zoneCode;
    }
    if (layoutPath !== undefined) {
      order.layoutPath = layoutPath;
    }
    if (fields && Object.keys(fields).length > 0) {
      order.fields = fields;
    }
    if (note && note !== "") {
      order.note = note;
    }
    if (tip) {
      order.tip = tip;
    }
    if (locale) {
      order.locale = locale;
    }
    if (collectedDeposits && Object.keys(collectedDeposits).length > 0) {
      order.deposits = {
        collected: collectedDeposits
      };
    }

    const orderRef = await this.getOrdersRef(barId).add(order);

    return orderRef.id;
  }

  onOrderReady(barId: string, orderId: string): Promise<Order> {
    return new Promise((resolve, reject) => {
      const unsubscribe = this.getOrderRef(barId, orderId).onSnapshot((doc) => {
        const order = orderParser.parseDocToOrder(barId, doc);

        if (order.isReady()) {
          unsubscribe();
          resolve(order);
        }
      });
    });
  }

  public setOrderStatusQueued(order: Order, base?: Base, statusData?: any) {
    if (!order.id) {
      throw new Error("error.order-id-is-not-defined");
    }

    return this.setOrderStatus(
      order.barId,
      order.id,
      OrderStatus.QUEUED,
      base ? base.menuIds : undefined,
      statusData
    );
  }

  public async setOrderStatusClaimed(
    order: Order,
    base?: Base,
    statusData?: any,
    getNextSequenceNumberFunction?: (
      barId: string,
      baseId: string
    ) => Promise<number>,
    preparationDurationInMinutes?: number
  ) {
    if (!order.id) {
      throw new Error("error.order-id-is-not-defined");
    }

    let sequenceNumber: number | null = null;
    if (
      order.isDeliveryMethodPickup &&
      order.isFulfilmentMethodAsSoonAsPossible &&
      base
    ) {
      if (!base.id) {
        throw new Error("error.base-id-is-not-defined");
      }
      if (!getNextSequenceNumberFunction) {
        throw new Error(
          "error.get-next-sequence-number-function-is-not-defined"
        );
      }

      sequenceNumber = await getNextSequenceNumberFunction(base.barId, base.id);
    }

    await this.setOrderStatus(
      order.barId,
      order.id,
      OrderStatus.CLAIMED,
      base ? base.menuIds : undefined,
      statusData,
      (order) => {
        const toUpdate: any = {};

        if (base && base.id) {
          if (sequenceNumber) {
            order.fulfilment.setSequenceNumber(base.id, sequenceNumber);
            toUpdate.fulfilment = order.fulfilment.toJSON();
          }

          if (preparationDurationInMinutes !== undefined) {
            order.delivery.setEstimatedPreparationDurationInMinutes(
              base.id,
              preparationDurationInMinutes
            );
            const delivery = order.delivery.toJSON();
            if (
              !delivery.color ||
              delivery.color === deliveryHelper.getDefaultDeliveryColorKey()
            ) {
              delete delivery.color;
            }
            if (!delivery.code) {
              delete delivery.code;
            }
            if (!delivery.contact) {
              delete delivery.contact;
            }
            toUpdate.delivery = delivery;
          }
        }

        return toUpdate;
      }
    );
  }

  public async increaseOrderPreparationDuration(
    barId: string,
    orderId: string,
    baseId: string,
    amountOfMinutesToAdd: number
  ) {
    const orderRef = this.getOrderRef(barId, orderId);

    await serviceHelper.runTransaction((fTransaction) => {
      return fTransaction.get(orderRef).then((orderDoc) => {
        if (!orderDoc.exists) {
          throw new Error("error.order-does-not-exist");
        }
        const order = orderParser.parseDocToOrder(barId, orderDoc);

        const estimatedPreparationDurationInMinutes =
          order.delivery.getEstimatedPreparationDurationInMinutes(baseId);

        if (estimatedPreparationDurationInMinutes === null) {
          throw new Error(
            "error.estimated-preparation-duration-in-minutes-is-null"
          );
        }
        order.delivery.setEstimatedPreparationDurationInMinutes(
          baseId,
          estimatedPreparationDurationInMinutes + amountOfMinutesToAdd
        );

        const delivery = order.delivery.toJSON();

        fTransaction.set(
          orderRef,
          { delivery: { bases: { [baseId]: delivery.bases[baseId] } } },
          { merge: true }
        );
      });
    });
  }

  public async setOrderReadyForPickup(
    barId: string,
    orderId: string,
    baseId: string
  ) {
    this.getOrderRef(barId, orderId).set(
      {
        delivery: {
          bases: {
            [baseId]: {
              preparationEstimatedToBeCompletedAt:
                serviceHelper.serverTimestamp()
            }
          }
        }
      },
      { merge: true }
    );
  }

  public setOrderStatusComplete(order: Order, base?: Base, statusData?: any) {
    if (!order.id) {
      throw new Error("error.order-id-is-not-defined");
    }

    return this.setOrderStatus(
      order.barId,
      order.id,
      OrderStatus.COMPLETE,
      base ? base.menuIds : undefined,
      statusData
    );
  }

  public createDummyOrder(barId: string, id: string, data: any): Order {
    return new Order(barId, id, data);
  }
}
