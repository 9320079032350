import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItem, ListItemText } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({});

class OrderVouchers extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, order } = this.props;

    if (order.getTotalAlreadyPaidWithVouchers()) {
      return (
        <ListItem>
          <ListItemText
            secondary={
              <strong>
                {t("payment.amount-x-paid-with-vouchers", {
                  totalAlreadyPaidWithVouchers: `${order.getFormattedTotalAlreadyPaidWithVouchers()} ${
                    order.currency
                  }`
                })}
              </strong>
            }
          />
        </ListItem>
      );
    }

    return null;
  }
}

export default withStyles(styles)(withTranslation("common")(OrderVouchers));
