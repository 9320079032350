import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Redirect } from "react-router-dom";

import { IconButton } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import withStyles from "@mui/styles/withStyles";

import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";
import { deviceStorage } from "tap-io/storage";

import * as routes from "../../constants/routes";
import {
  CACHE_KEY_BAR_LOCATOR,
  CACHE_KEY_PIN_HASH,
  CACHE_KEY_SCANNER_ID
} from "../../constants/cache";

const styles = (theme) => ({});

class SignOutButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmDialogOpen: false,
      redirectToRoot: false
    };
  }

  handleSignOut = () => {
    this.setState({ isConfirmDialogOpen: true });
  };

  handleConfirmSignOut = async () => {
    const { bar } = this.props;

    await deviceStorage.clearDeviceCacheItem(bar.id, CACHE_KEY_PIN_HASH);
    await deviceStorage.clearDeviceCacheItem("", CACHE_KEY_BAR_LOCATOR);
    await deviceStorage.clearDeviceCacheItem("", CACHE_KEY_SCANNER_ID);

    this.setState({ isConfirmDialogOpen: false, redirectToRoot: true });
  };

  handleCancelSignOut = () => {
    this.setState({ isConfirmDialogOpen: false });
  };

  render() {
    const { classes, t, color } = this.props;
    const { isConfirmDialogOpen, redirectToRoot } = this.state;

    if (redirectToRoot) {
      return <Redirect to={routes.SCAN_QR_CODE} />;
    }

    return (
      <>
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          title={t("auth.sign-out")}
          description={t("auth.are-you-sure-you-want-to-log-out")}
          confirmButtonLabel={t("auth.sign-out")}
          onConfirm={this.handleConfirmSignOut}
          onCancel={this.handleCancelSignOut}
        />
        <IconButton color={color} onClick={this.handleSignOut}>
          <ExitToAppIcon />
        </IconButton>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(SignOutButton));
