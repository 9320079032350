import LayoutNode from "./LayoutNode";
import LayoutNodeType from "./LayoutNodeType";
import LayoutNodeBases from "./LayoutNodeBases";
import { zoneHelper } from "../../helpers";

export default abstract class MultiLayoutNode extends LayoutNode {
  public static readonly COMPOSITE_TIE: string = "";

  constructor(
    type: LayoutNodeType,
    code: string,
    name: string,
    bases: LayoutNodeBases | null,
    children?: LayoutNode[]
  ) {
    super(type, code, name, bases, children);
  }

  public get code() {
    return this._code;
  }

  public set code(code: string) {
    const prevCode = this.code;
    this._code = zoneHelper.parseLayoutNodeCode(code);

    if (!this.areAllGeneratedCodesValid()) {
      this._code = prevCode;
      throw new Error("error.code-duplicate-found");
    }
  }

  protected areAllGeneratedCodesValid() {
    const locations = this.getLayoutLocations();

    for (let i = 0; i < locations.length; i++) {
      if (!this.isCodeValidForMe(locations[i].code)) {
        return false;
      }
    }

    return true;
  }

  protected getCompositeCode(annex: string): string {
    return `${this.code}${MultiLayoutNode.COMPOSITE_TIE}${annex}`;
  }

  protected getCompositeName(annex: string): string {
    return `${this.name} ${annex}`;
  }
}
