import { utilsHelper } from "./index";
import DeliveryMethods from "../models/delivery/DeliveryMethods";
import DeliveryMethod from "../models/delivery/DeliveryMethod";
import DeliveryColors from "../models/delivery/DeliveryColors";
import DeliveryColor from "../models/delivery/DeliveryColor";

export default class DeliveryHelper {
  private readonly _defaultDeliveryColor: any = {
    key: DeliveryColor.BLACK,
    value: "#000000"
  };
  private readonly _allDeliveryColors: any = {
    [DeliveryColor.GREEN]: "#289028",
    [DeliveryColor.RED]: "#f52d1f",
    [DeliveryColor.YELLOW]: "#ffeb3b",
    [DeliveryColor.BLUE]: "#2277a1",
    [DeliveryColor.ORANGE]: "#fc7b22",
    [DeliveryColor.BROWN]: "#6f533e",
    [DeliveryColor.PURPLE]: "#a05ce0",
    [DeliveryColor.PINK]: "#e84678",
    [DeliveryColor.GREY]: "#47473e"
  };

  createDeliveryMethods(
    isServeActive?: boolean,
    isPickupActive?: boolean
  ): DeliveryMethods {
    return new DeliveryMethods({
      [DeliveryMethod.SERVE]: { isActive: isServeActive === true },
      [DeliveryMethod.PICKUP]: { isActive: isPickupActive === true }
    });
  }

  createDeliveryMethodsFromArray(
    deliveryMethods: DeliveryMethod[]
  ): DeliveryMethods {
    return new DeliveryMethods({
      [DeliveryMethod.SERVE]: {
        isActive:
          deliveryMethods && deliveryMethods.indexOf(DeliveryMethod.SERVE) >= 0
      },
      [DeliveryMethod.PICKUP]: {
        isActive:
          deliveryMethods && deliveryMethods.indexOf(DeliveryMethod.PICKUP) >= 0
      }
    });
  }

  mergeDeliveryMethods(
    deliveryMethodsA: DeliveryMethods,
    deliveryMethodsB: DeliveryMethods
  ): DeliveryMethods {
    return this.createDeliveryMethods(
      deliveryMethodsA.isServeActive || deliveryMethodsB.isServeActive,
      deliveryMethodsA.isPickupActive || deliveryMethodsB.isPickupActive
    );
  }

  createDeliveryColors(deliveryColors: Array<string>): DeliveryColors {
    const data: any = {};
    deliveryColors.forEach(
      (deliveryColor) => (data[deliveryColor] = { isActive: true })
    );
    return new DeliveryColors(data);
  }

  public getRandomDeliveryColorKey(): string {
    return utilsHelper.getRandomElementFromArray(
      Object.keys(this._allDeliveryColors)
    );
  }

  public getDefaultDeliveryColorKey(): string {
    return this._defaultDeliveryColor.key;
  }

  public doesDeliveryColorKeyExist(key: string): boolean {
    return this._allDeliveryColors[key] !== undefined;
  }

  public getAllDeliveryColors(): Array<any> {
    return Object.keys(this._allDeliveryColors).map((key) => {
      return {
        key,
        value: this._allDeliveryColors[key]
      };
    });
  }

  public getDeliveryColorValue(key: string): string {
    return this._allDeliveryColors[key]
      ? this._allDeliveryColors[key]
      : this._defaultDeliveryColor.value;
  }
}
