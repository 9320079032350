import Scanner from "../models/scanner/Scanner";
import ScannerLogEntry from "../models/scanner/ScannerLogEntry";

export default class ScannerParser {
  public parseDocToScanner(barId: string, doc): Scanner {
    if (!doc.exists) {
      throw new Error("error.scanner-does-not-exist");
    }

    const data = doc.data();

    return new Scanner(
      barId,
      doc.id,
      {
        ...data,
        timestamp: data.timestamp ? data.timestamp.toDate() : undefined
      }
    );
  }

  public parseDocToScannerLogEntry(doc): ScannerLogEntry {
    const data = doc.data();

    return new ScannerLogEntry(
      doc.id,
      {
        ...data,
        timestamp: data.timestamp.toDate()
      }
    );
  }

  public parseDataToScannerLogEntry(data: any): ScannerLogEntry {
    return new ScannerLogEntry(data.id, data);
  }
}