enum StatsDataKey {
  ALL = "a",
  PER_ITEM = "pI",
  PER_PRODUCT = "pP",
  PER_PAYMENT_METHOD = "pPM",
  PER_DELIVERY_METHOD = "pDM",
  PER_MENU = "pM",
  FOR_MENU = "fM",
  PER_ZONE = "pZ",
  PER_TABLE = "pT"
}

export default StatsDataKey;