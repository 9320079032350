import LayoutNode from "./LayoutNode";
import LayoutNodeType from "./LayoutNodeType";
import LayoutNodeSequence from "./LayoutNodeSequence";
import MultiLayoutNode from "./MultiLayoutNode";
import LayoutLocation from "./LayoutLocation";
import LayoutNodeBases from "./LayoutNodeBases";

export default class SequenceLayoutNode extends MultiLayoutNode {
  private _sequence: LayoutNodeSequence;

  constructor(
    code: string,
    name: string,
    sequence: LayoutNodeSequence,
    bases: LayoutNodeBases | null,
    children?: LayoutNode[]
  ) {
    super(LayoutNodeType.SEQUENCE, code, name, bases, children);
    this._sequence = sequence;
  }

  public get sequence() {
    return this._sequence;
  }

  public set sequence(sequence: LayoutNodeSequence) {
    const prevSequence = this.sequence;
    this._sequence = sequence;

    if (!this.areAllGeneratedCodesValid()) {
      this._sequence = prevSequence;
      throw new Error("error.code-duplicate-found");
    }
  }

  public toJSON() {
    return {
      ...super.toJSON(),
      sequence: this.sequence.toJSON()
    };
  }

  public getLayoutLocations(
    parentLayoutLocation?: LayoutLocation
  ): LayoutLocation[] {
    return this.sequence.map(
      (element: string) =>
        new LayoutLocation(
          this.getCompositeCode(element),
          this.getCompositeName(element),
          parentLayoutLocation
        )
    );
  }

  public toString() {
    return `${this.name} ${this.sequence.toString()}`;
  }

  public clone(): SequenceLayoutNode {
    return new SequenceLayoutNode(
      this.code,
      this.name,
      this.sequence.clone(),
      this.customBases ? this.customBases.clone() : null,
      this.children ? this.children.map((child) => child.clone()) : undefined
    );
  }
}
