import AdService from "../../services/AdService";
import Ad from "../../models/ad/Ad";
import { authHelper, serviceHelper } from "../../helpers";

export default class ClientAdService extends AdService {
  constructor() {
    super();
  }

  public async fetchAds(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    barId: string,
    count: number
  ): Promise<Array<Ad>> {
    const link = `${apiFunctionsEndpoint}/public/ads`;
    const params = { barId, count };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    const rawData = await serviceHelper.fetchJSON(url);

    const data = serviceHelper.parseArray(rawData);

    const ads: Array<Ad> = [];

    data.forEach((entry: any) => {
      const props: any = {};

      if (entry) {
        // TO FIX
        if (entry.media && entry.media.length > 0) {
          const media = entry.media[0];
          props.landscapeImageUrl = media.url;
          props.portraitImageUrl = media.url;
          props.stripImageUrl = media.url;
        }
        props.backgroundColor = entry.backgroundColor;
      }

      ads.push(this.parseDataToAd(entry.name, props));
    });

    return ads;
  }

  private parseDataToAd(id: string, data: any): Ad {
    return new Ad(id, data);
  }
}
