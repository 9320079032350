import React, { PureComponent } from "react";
import classNames from "classnames";

import { Link } from "react-router-dom";

import { withTranslation } from "react-i18next";

import { AppBar, IconButton, Button, Toolbar, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import withStyles from "@mui/styles/withStyles";

import { IOSWrapper } from "tap-io/client/native/IOSWrapper";

import SignOutButton from "../auth/SignOutButton";
import { getScanOrderUrl } from "../../constants/routes";

const styles = (theme) => ({
  appBar: {},
  toolBar: {
    justifyContent: "center",
    minHeight: 56
  },
  barName: {
    flexGrow: 1,
    textAlign: "left"
  },
  baseButton: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: `${theme.palette.primary.contrastText} !important`
  }
});

class BarHeader extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getScanOrderLink = () => {
    const { barLocator, scanner } = this.props;

    return barLocator && scanner
      ? getScanOrderUrl(barLocator, scanner.id)
      : null;
  };

  render() {
    const { classes, t, bar, base } = this.props;

    const scanOrderLink = this.getScanOrderLink();

    return (
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          "App-header-ios": IOSWrapper.get()
        })}
      >
        <Toolbar className={classes.toolBar}>
          {scanOrderLink && (
            <Link to={scanOrderLink}>
              <IconButton
                disabled
                color="inherit"
                className={classes.baseButton}
              >
                <HomeIcon />
              </IconButton>
            </Link>
          )}
          <Typography variant="h6" noWrap className={classes.barName}>
            {bar ? bar.name : t("label.bar")}
          </Typography>
          {bar && bar.isUsingBases() && base && (
            <Button
              disabled
              color="inherit"
              className={classes.baseButton}
              startIcon={<CallSplitIcon />}
            >
              {base.name}
            </Button>
          )}
          <SignOutButton color="inherit" bar={bar} />
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(BarHeader));
