import { serviceHelper } from "../helpers";
import Stats from "../models/mgmt/Stats";

export default class MgmtService {
  private readonly fbStore;

  constructor(fbStore) {
    this.fbStore = fbStore;
  }

  protected getStatsRef() {
    return this.fbStore.collection("stats");
  }

  protected getStatsRefForMonth(year: string, month: number) {
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const beginDate = `${year}-${formattedMonth}-01`;
    const endDate = `${year}-${formattedMonth}-31`;

    return this.getStatsRef()
      .orderBy(serviceHelper.documentId())
      .startAt(beginDate)
      .endAt(endDate);
  }

  public onStatsPerDay(year: string, month: number, cb: Function) {
    return this.getStatsRefForMonth(year, month).onSnapshot((docs) => {
      const stats: { [date: string]: Stats } = {};

      docs.forEach((doc) => {
        stats[doc.id] = new Stats(doc.data());
      });

      cb(stats);
    });
  }
}
