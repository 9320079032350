import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import withStyles from "@mui/styles/withStyles";

import { depositHelper, feeHelper, utilsHelper } from "tap-io/helpers";
import { orderService } from "tap-io/client/services";
import ShowAndGoServiceOption from "tap-io/models/service/ShowAndGoServiceOption";

import SelectOrderItems from "./SelectOrderItems";
import OrderSubmittingDialog from "./OrderSubmittingDialog";
import withAuthorization from "../auth/withAuthorization";
import PayOrderButton from "./PayOrderButton";
import { getPayOrderUrl } from "../../constants/routes";
import DepositList from "../deposit/DepositList";

const styles = (theme) => ({
  content: {},
  preventContentScroll: {
    position: "fixed",
    visibility: "hidden"
  },
  noMenuElements: {
    padding: "100px 0",
    fontWeight: "bold"
  },
  bottomActions: {
    position: "sticky",
    bottom: 0,
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(0, 0.5),
    pointerEvents: "none"
  },
  bottomAction: {
    position: "relative",
    width: "100%",
    maxWidth: 608,
    marginBottom: theme.spacing(0.5),
    borderRadius: 4,
    pointerEvents: "auto",
    backgroundColor: "rgba(235, 235, 235, 0.95)",
    "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))": {
      backdropFilter: "saturate(180%) blur(20px)",
      backgroundColor: "rgba(235, 235, 235, 0.75)",
    },
    "&:after": {
      position: "absolute",
      bottom: -4,
      left: 0,
      width: "100%",
      height: theme.spacing(0.5),
      content: "''",
      backgroundColor: theme.palette.background.default
    }
  }
});

class CreateOrder extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    return {
      isOrderSubmittingDialogOpen: false,
      orderId: null,
      orderItems: {},
      orderTip: 0,
      orderFees: [],
      orderItemsAmount: 0,
      orderItemsTotal: 0,
      orderTotal: 0,
      orderDistributedDeposits: {},
      orderCollectedDeposits: {},
      orderDepositsAmount: 0,
      orderDepositsTotal: 0,
      orderName: "",
      orderNote: "",
      orderServiceOption: new ShowAndGoServiceOption(),
      orderDeliveryContact: null
    };
  };

  componentDidMount() {
    const { bar, orderName } = this.props;

    this.handleSetOrderName(orderName);

    this.checkFees();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { t, bar, orderName, menuItems, menuDeposits } = this.props;
    const { orderItems, orderCollectedDeposits } = this.state;

    const prevOrderName = prevProps.orderName;

    if (orderName !== prevOrderName) {
      this.handleSetOrderName(orderName);
    }

    // Clear order items if they are not available anymore
    let orderItemsChanged = false;
    if (Object.keys(orderItems).length > 0) {
      if (menuItems) {
        const updatedOrderItems = {};

        menuItems.forEach((menuItem) => {
          if (menuItem.isAvailable && orderItems[menuItem.id]) {
            updatedOrderItems[menuItem.id] = {
              ...orderItems[menuItem.id],
              menuItem
            };
          }
        });

        if (
          Object.keys(updatedOrderItems).length !==
          Object.keys(orderItems).length
        ) {
          this.handleOrderItemsChange(updatedOrderItems);
          orderItemsChanged = true;
        }
      } else {
        if (Object.keys(orderItems).length > 0) {
          this.handleClearOrderItems();
          orderItemsChanged = true;
        }
      }
    }
    if (orderItemsChanged) {
      toast.info(
        t(
          "order.order-has-been-adjusted-because-availability-of-some-items-has-changed"
        )
      );
    }

    const menuDepositIds = menuDeposits ? menuDeposits.map(menuDeposit => menuDeposit.id) : [];
    const prevMenuDepositIds = prevProps.menuDeposits ? prevProps.menuDeposits.map(menuDeposit => menuDeposit.id) : [];
    if (!utilsHelper.areArraysEqualShallow(menuDepositIds, prevMenuDepositIds)) {
      this.updateOrderItems(orderItems).then(() => {
        const updatedOrderCollectedDeposits = {};

        (menuDeposits || []).forEach(menuDeposit => {
          if (orderCollectedDeposits[menuDeposit.id]) {
            updatedOrderCollectedDeposits[menuDeposit.id] = {
              ...orderCollectedDeposits[menuDeposit.id]
            };
          }
        });

        if (Object.keys(orderCollectedDeposits).length !== Object.keys(updatedOrderCollectedDeposits).length) {
          this.handleCollectedDepositsChange(updatedOrderCollectedDeposits);
        }
      });
    }
  }

  checkFees = () => {
    const { activeFees } = this.props;
    const {
      orderServiceOption,
      orderItemsTotal,
      orderTip,
      orderDistributedDeposits,
      orderCollectedDeposits
    } = this.state;

    const orderFees = activeFees
      ? activeFees.filter((fee) => {
        return (
          fee.calculateFee(
            orderItemsTotal,
            orderServiceOption?.name,
            undefined,
            undefined
          ) > 0
        );
      })
      : [];

    const orderTotal = this.calculateOrderTotal(
      orderItemsTotal,
      orderTip,
      orderFees,
      orderDistributedDeposits,
      orderCollectedDeposits
    );

    this.setState({ orderFees, orderTotal });
  };

  checkDeposits = (cb) => {
    const {
      orderDistributedDeposits,
      orderCollectedDeposits
    } = this.state;

    const orderDepositsAmount = depositHelper.calculateTotalDepositAmount(orderDistributedDeposits, orderCollectedDeposits);
    const orderDepositsTotal = depositHelper.calculateTotalDepositValue(orderDistributedDeposits, orderCollectedDeposits);

    this.setState({ orderDepositsAmount, orderDepositsTotal }, cb);
  }

  handleSetOrderName = (orderName) => {
    this.updateOrderName(orderName);
  };

  handleClearOrderItems = () => {
    this.setState({
      orderItems: {},
      orderTip: 0,
      orderItemsAmount: 0,
      orderItemsTotal: 0,
      orderTotal: 0
    });
  };

  handleVerifyOrder = async () => {
    const { scanner, t, auth } = this.props;

    try {
      const orderId = await this.addOrder();

      this.setState({ orderId, isOrderSubmittingDialogOpen: false });
    } catch (error) {
      console.error(error);
      toast.error(t("error.failed-to-create-order"));
    }
  };

  handleOrderSubmittingDialogClose = () => {
    this.setState({ isOrderSubmittingDialogOpen: false });
  };

  handleOrderItemsChange = (orderItems) => {
    this.updateOrderItems(orderItems);
  };

  handleOrderItemAmountChange = (id, amount) => {
    const orderItems = { ...this.state.orderItems };

    orderItems[id].amount = amount;

    this.updateOrderItems(orderItems);
  };

  handleOrderNameChange = (name) => {
    this.updateOrderName(name);
  };

  handleOrderNoteChange = (note) => {
    this.setState({ orderNote: note });
  };

  handleOrderDeliveryContactChange = (deliveryContact) => {
    this.setState({ orderDeliveryContact: deliveryContact }, this.checkFees);
  };

  updateOrderItems = async (orderItems) => {
    return new Promise((resolve, reject) => {
      const { orderTip, orderFees, orderCollectedDeposits } = this.state;

      let orderItemsAmount = 0,
        orderItemsTotal = 0;

      for (let id in orderItems) {
        const orderItem = orderItems[id];
        orderItemsAmount += orderItem.amount;
        orderItemsTotal += orderItem.menuItem.price * orderItem.amount;
      }

      const orderDistributedDeposits = this.getDistributedDeposits(orderItems);

      const orderTotal = this.calculateOrderTotal(
        orderItemsTotal,
        orderTip,
        orderFees,
        orderDistributedDeposits,
        orderCollectedDeposits
      );

      this.setState({
        orderItems,
        orderItemsAmount,
        orderItemsTotal,
        orderTotal,
        orderDistributedDeposits
      }, () => { this.checkDeposits(resolve); });
    });
  };

  handleCollectedDepositsChange = (orderCollectedDeposits) => {
    const { orderItemsTotal, orderTip, orderFees, orderDistributedDeposits } =
      this.state;

    const orderTotal = this.calculateOrderTotal(
      orderItemsTotal,
      orderTip,
      orderFees,
      orderDistributedDeposits,
      orderCollectedDeposits
    );

    this.setState({ orderCollectedDeposits, orderTotal }, this.checkDeposits);

  };

  updateOrderName = (orderName) => {
    this.setState({ orderName: orderName ? orderName : "" });
  };

  calculateOrderTotal = (
    orderItemsTotal,
    orderTip,
    orderFees,
    orderDistributedDeposits,
    orderCollectedDeposits
  ) => {
    const { orderServiceOption } = this.state;

    return utilsHelper.roundToTwoDecimals(
      orderItemsTotal +
      orderTip +
      feeHelper.calculateTotalFee(
        orderFees,
        orderItemsTotal,
        orderServiceOption?.name,
        undefined,
        undefined
      ) +
      depositHelper.calculateTotalDepositValue(
        orderDistributedDeposits,
        orderCollectedDeposits
      )
    );
  };

  addOrder = async () => {
    const { t, locale, bar, auth, scanner } = this.props;
    const {
      orderItems,
      orderCollectedDeposits,
      orderFields,
      orderServiceOption,
      orderDeliveryContact
    } = this.state;

    try {
      const orderName = this.state.orderName
        ? this.state.orderName.trim()
        : undefined;
      const orderNote = this.state.orderNote
        ? this.state.orderNote.trim()
        : undefined;
      let orderTip;

      if (!bar || !bar.id) {
        throw new Error("error.no-valid-bar-found");
      }
      if (!auth || !auth.user || !auth.user.uid) {
        throw new Error("error.no-valid-user-found");
      }
      if (!orderName) {
        throw new Error("error.name-not-entered");
      }
      if (!orderItems || orderItems.length === 0) {
        throw new Error("error.no-items-selected");
      }
      if (this.state.orderTip) {
        orderTip = parseFloat(this.state.orderTip);

        if (isNaN(orderTip) || orderTip < 0) {
          throw new Error("error.tip-invalid");
        }
      }

      let orderFieldsAsArray;
      if (orderFields) {
        orderFieldsAsArray = [];
        for (const id in orderFields) {
          const orderField = orderFields[id];

          if (!orderField.isValid) {
            throw new Error("error.name-entered-invalid");
          }

          orderFieldsAsArray.push({
            id,
            value: orderField.value
          });
        }
      }

      // Delivery
      const orderDelivery = {};
      orderDelivery.contact = orderDeliveryContact;

      const orderItemsAsArray = [];
      for (const id in orderItems) {
        const orderItem = orderItems[id];

        orderItemsAsArray.push({
          id,
          menuId: orderItem.menuItem.menuId,
          amount: orderItem.amount
        });
      }

      this.setState({ isOrderSubmittingDialogOpen: true });

      // Deposits
      const parsedOrderCollectedDeposits = {};
      if (orderCollectedDeposits) {
        for (const id in orderCollectedDeposits) {
          const orderReceivedDeposit = orderCollectedDeposits[id];

          parsedOrderCollectedDeposits[id] = { amount: orderReceivedDeposit.amount }
        }
      }

      // Create order
      const orderId = await orderService.addOrder(
        auth.user.uid,
        bar.id,
        orderName,
        orderItemsAsArray,
        { option: { name: orderServiceOption.name } },
        orderDelivery,
        undefined,
        null,
        orderFieldsAsArray,
        orderNote ? orderNote : undefined,
        orderTip,
        locale,
        scanner,
        parsedOrderCollectedDeposits
      );

      return orderId;
    } catch (error) {
      console.warn(error);

      toast.error(
        `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`
      );
    }
  };

  getPayOrderLink = () => {
    const { barLocator, scanner } = this.props;
    const { orderId } = this.state;

    return barLocator && scanner && orderId
      ? `${getPayOrderUrl(barLocator, scanner.id, orderId)}`
      : null;
  };

  getDistributedDeposits = (orderItems) => {
    const { menuDeposits } = this.props;

    const distributedDeposits = {};

    if (menuDeposits) {
      const depositsAsMap = {};
      menuDeposits.forEach(
        (deposit) => (depositsAsMap[deposit.id] = deposit)
      );

      if (orderItems) {
        for (let id in orderItems) {
          const item = orderItems[id];
          const depositIds = item?.menuItem?.depositIds || [];

          depositIds.forEach((depositId) => {
            const deposit = depositsAsMap[depositId];

            if (deposit) {
              if (!distributedDeposits[depositId]) {
                distributedDeposits[depositId] = {
                  deposit,
                  amount: 0
                };
              }

              distributedDeposits[depositId].amount += item.amount;
            }
          });
        }
      }
    }

    return distributedDeposits;
  };

  render() {
    const {
      classes,
      t,
      headerProps,
      bar,
      menuDeposits,
      menuElements,
      menuCategories
    } = this.props;
    const {
      isOrderSubmittingDialogOpen,
      orderItems,
      orderItemsAmount,
      orderItemsTotal,
      orderDistributedDeposits,
      orderCollectedDeposits,
      orderDepositsAmount,
      orderDepositsTotal,
      orderTotal
    } = this.state;

    const payOrderLink = this.getPayOrderLink();

    if (payOrderLink) {
      return <Redirect to={payOrderLink} />;
    }

    const isAbleToOrder = orderTotal >= 0;

    return (
      <div
        className={classNames(classes.content, {
          [classes.preventContentScroll]: false
        })}
      >
        <OrderSubmittingDialog
          isOpen={isOrderSubmittingDialogOpen}
          onClose={this.handleOrderSubmittingDialogClose}
        />
        {menuElements && menuElements.length > 0 ? (
          <div>
            <SelectOrderItems
              headerProps={headerProps}
              bar={bar}
              menuElements={
                menuElements
                  ? menuElements.filter((element) => !element.isHidden)
                  : null
              }
              menuCategories={
                menuCategories
                  ? menuCategories.filter((category) => !category.isHidden)
                  : null
              }
              orderItems={orderItems}
              onOrderItemsChange={this.handleOrderItemsChange}
            />
            <div className={classes.bottomActions}>
              {menuDeposits && menuDeposits.length > 0 && (
                <div className={classes.bottomAction}>
                  <DepositList
                    bar={bar}
                    deposits={menuDeposits}
                    depositRoundItems={orderDistributedDeposits}
                    depositItems={orderCollectedDeposits}
                    hasTooMuchDeposits={!isAbleToOrder}
                    onChange={this.handleCollectedDepositsChange}
                  />
                </div>
              )}
              <div className={classes.bottomAction}>
                <PayOrderButton
                  isDisabled={!isAbleToOrder}
                  bar={bar}
                  orderItemsAmount={orderItemsAmount}
                  orderItemsTotal={orderItemsTotal}
                  orderDepositsAmount={orderDepositsAmount}
                  orderDepositsTotal={orderDepositsTotal}
                  orderTotal={orderTotal}
                  onClick={this.handleVerifyOrder}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.noMenuElements}>
            {t("order.ordering-currently-not-available")}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(
  withAuthorization()(withTranslation("common")(CreateOrder))
);
