import CatalogueData from "../models/data/CatalogueData";
import Catalogue from "../models/catalogue/Catalogue";
import Product from "../models/catalogue/Product";
import VatRate from "../models/menu/VatRate";
import { utilsHelper } from ".";

export default class CatalogueHelper {
  constructor() {
  }

  public createProduct(name: string, vatRate?: VatRate) {
    return new Product(
      utilsHelper.generateUUID(),
      true,
      name,
      vatRate
    );
  }

  public createDefaultCatalogue(data: any): Catalogue {
    const products: Array<Product> = [];
    for (let id in data) {
      products.push(new Product(
        id,
        false,
        data[id].name,
        data[id].vatRate
      ));
    }

    return new Catalogue(products);
  }

  public createCatalogue(defaultCatalogue: Catalogue, catalogData: CatalogueData): Catalogue {
    return new Catalogue([...defaultCatalogue.products, ...catalogData.customProducts]);
  }

  public catalogueToCatalogueData(barId: string, catalogue: Catalogue): CatalogueData {
    const catalogueData = new CatalogueData(barId, {});
    catalogueData.customProducts = catalogue.customProducts;
    return catalogueData;
  }
}
