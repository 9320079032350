import FeeCondition from "./FeeCondition";
import FeeConditionType from "./FeeConditionType";
import LayoutPath from "../zone/LayoutPath";
import FeeConditionOption from "./FeeConditionOption";
import ServiceOptionName from "../service/ServiceOptionName";

type Props = {
  option: FeeConditionOption;
  zoneCode: string;
  layoutPaths: LayoutPath[];
};

export default class FeeZoneCondition extends FeeCondition {
  private _zoneCode: string;
  private _layoutPaths: LayoutPath[];

  constructor(props: Props) {
    const { option, zoneCode, layoutPaths } = props;
    super(FeeConditionType.ZONE, option);

    this._zoneCode = zoneCode;
    this._layoutPaths = layoutPaths;
  }

  public get zoneCode() {
    return this._zoneCode;
  }

  public set zoneCode(zoneCode: string) {
    this._zoneCode = zoneCode;
  }

  public get layoutPaths() {
    return this._layoutPaths;
  }

  public set layoutPaths(layoutPaths: LayoutPath[]) {
    this._layoutPaths = layoutPaths;
  }

  public hasLayoutPath(layoutPath: LayoutPath): boolean {
    for (let i = 0; i < this.layoutPaths.length; i++) {
      if (this.layoutPaths[i].equals(layoutPath)) {
        return true;
      }
    }

    return false;
  }

  public addLayoutPath(layoutPathToAdd: LayoutPath) {
    if (!this.hasLayoutPath(layoutPathToAdd)) {
      this.layoutPaths.push(layoutPathToAdd);
    }
  }

  public removeLayoutPath(layoutPathToRemove: LayoutPath) {
    this.layoutPaths = this.layoutPaths.filter(
      (layoutPath) => !layoutPath.equals(layoutPathToRemove)
    );
  }

  public isMet(
    serviceOptionName?: ServiceOptionName,
    zoneCode?: string,
    layoutPath?: LayoutPath
  ): boolean {
    if (zoneCode !== this.zoneCode) {
      return false;
    }

    if (layoutPath) {
      switch (this.option) {
        case FeeConditionOption.INCLUDE:
          return this.hasLayoutPath(layoutPath);
          break;
        case FeeConditionOption.EXCLUDE:
          return !this.hasLayoutPath(layoutPath);
          break;
        default:
          throw new Error("error.fee-condition-option-is-not-valid");
          break;
      }
    } else {
      return this.layoutPaths.length === 0;
    }
  }

  public clone(): FeeZoneCondition {
    return new FeeZoneCondition({
      option: this.option,
      zoneCode: this.zoneCode,
      layoutPaths: this.layoutPaths
    });
  }

  public toJSON(): any {
    return {
      ...super.toJSON(),
      zoneCode: this.zoneCode,
      layoutPaths: this.layoutPaths
    };
  }
}
