import { NativeWrapper } from "./NativeWrapper";

export class AndroidWrapper extends NativeWrapper<{
  getVersion(): string;
  reloadApp(): void;
  launchIntent(intent: string): void;
  launchSecureIntent(secureIntent: string, version: string): void;
}> {
  protected constructor() {
    super("Android");
  }

  getVersion(): string {
    return this.native.getVersion();
  }

  async reloadApp() {
    this.native.reloadApp();
  }

  async launchIntent(intent: string) {
    this.native.launchIntent(intent);
  }

  async launchSecureIntent(secureIntent: string) {
    this.native.launchSecureIntent(secureIntent, "1");
  }

  private static instance: AndroidWrapper | undefined = undefined;

  static get() {
    if ((window as any).Android !== undefined && !this.instance) {
      this.instance = new AndroidWrapper();
      console.log("Android interface init");
    }
    return this.instance;
  }
}
