import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import BarHeaderSpacing from "./BarHeaderSpacing";
import BarRoutes from "./BarRoutes";

const styles = (theme) => ({
  content: {
    width: "100%",
    maxWidth: 600,
    margin: "0 auto"
  }
});

class BarContent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      barLocator,
      isAppLocked,
      bar,
      scanner,
      base,
      activeFees,
      activeDeposits,
      menuDeposits,
      menuElements,
      menuCategories,
      menuItems,
      setScannerId,
      unlockApp,
      getPinHash,
      resetPinHash
    } = this.props;

    return (
      <main>
        <BarHeaderSpacing />
        <div className={classes.content}>
          <BarRoutes
            barLocator={barLocator}
            isAppLocked={isAppLocked}
            bar={bar}
            scanner={scanner}
            base={base}
            activeFees={activeFees}
            activeDeposits={activeDeposits}
            menuDeposits={menuDeposits}
            menuElements={menuElements}
            menuCategories={menuCategories}
            menuItems={menuItems}
            setScannerId={setScannerId}
            unlockApp={unlockApp}
            getPinHash={getPinHash}
            resetPinHash={resetPinHash}
          />
        </div>
      </main>
    );
  }
}

export default withStyles(styles)(BarContent);
