import TextFormatIcon from "@mui/icons-material/TextFormat";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CustomerFieldType from "../../models/bar/CustomerFieldType";

export const FIELD_TYPES = [
  CustomerFieldType.TEXT,
  CustomerFieldType.EMAIL,
  CustomerFieldType.DATE,
  CustomerFieldType.TOGGLE,
  CustomerFieldType.SELECT
];

export const FIELD_ICONS = {
  [CustomerFieldType.TEXT]: TextFormatIcon,
  [CustomerFieldType.EMAIL]: AlternateEmailIcon,
  [CustomerFieldType.DATE]: DateRangeIcon,
  [CustomerFieldType.TOGGLE]: DoneAllIcon,
  [CustomerFieldType.SELECT]: FormatListBulletedIcon
};

export const FIELD_LABELS = {
  [CustomerFieldType.TEXT]: "settings.field-text-label",
  [CustomerFieldType.EMAIL]: "settings.field-email-label",
  [CustomerFieldType.DATE]: "settings.field-date-label",
  [CustomerFieldType.TOGGLE]: "settings.field-toggle-label",
  [CustomerFieldType.SELECT]: "settings.field-select-label"
};

export const FIELD_DESCRIPTIONS = {
  [CustomerFieldType.TEXT]: "settings.field-text-description",
  [CustomerFieldType.EMAIL]: "",
  [CustomerFieldType.DATE]: "settings.field-date-description",
  [CustomerFieldType.TOGGLE]: "settings.field-toggle-description",
  [CustomerFieldType.SELECT]: "settings.field-select-description"
};

export const FIELD_DAYS = [
  { label: "settings.field-days-mon", index: 1 },
  { label: "settings.field-days-tue", index: 2 },
  { label: "settings.field-days-wed", index: 3 },
  { label: "settings.field-days-thu", index: 4 },
  { label: "settings.field-days-fri", index: 5 },
  { label: "settings.field-days-sat", index: 6 },
  { label: "settings.field-days-sun", index: 0 }
];
