import OrderDeposit from "./OrderDeposit";
import OrderDistributedDeposit from "./OrderDistributedDeposit";
import OrderCollectedDeposit from "./OrderCollectedDeposit";

class OrderDeposits {
    private readonly _distributed: OrderDistributedDeposit[] = [];
    private readonly _collected: OrderCollectedDeposit[] = [];

    constructor(data: any) {
        if (data) {
            if (data.distributed) {
                for (let depositId in data.distributed) {
                    this._distributed.push(new OrderDistributedDeposit(depositId, data.distributed[depositId]));
                }
            }
            if (data.collected) {
                for (let depositId in data.collected) {
                    this._collected.push(new OrderCollectedDeposit(depositId, data.collected[depositId]));
                }
            }
        }
    }

    public get distributed() {
        return this._distributed;
    }

    public get collected() {
        return this._collected;
    }

    public get hasDistributed() {
        return this.distributed.length > 0;
    }

    public get hasCollected() {
        return this.collected.length > 0;
    }

    private calculateAmount(deposits: OrderDeposit[]) {
        return deposits.reduce((acc: number, deposit: OrderDeposit) => {
            return acc + (deposit.amount || 0);
        }, 0);
    }

    public get amount(): number {
        return this.distributedAmount + this.collectedAmount;
    }

    public get distributedAmount(): number {
        return this.calculateAmount(this.distributed);
    }

    public get collectedAmount(): number {
        return this.calculateAmount(this.collected);
    }

    private calculateTotal(deposits: OrderDeposit[]) {
        return deposits.reduce((acc: number, deposit: OrderDeposit) => {
            return acc + (deposit.total || 0);
        }, 0);
    }

    public get total(): number {
        return this.distributedTotal - this.collectedTotal;
    }

    public get distributedTotal(): number {
        return this.calculateTotal(this.distributed);
    }

    public get collectedTotal(): number {
        return this.calculateTotal(this.collected);
    }

    public toJSON() {
        const distributed: { [depositId: string]: any } = {};
        this.distributed.forEach(distributedDeposit =>
            distributed[distributedDeposit.depositId] = distributedDeposit.toJSON()
        );

        const collected: { [depositId: string]: any } = {};
        this.collected.forEach(collectedDeposit =>
            collected[collectedDeposit.depositId] = collectedDeposit.toJSON()
        );

        return {
            distributed,
            collected
        };
    }
}

export default OrderDeposits;
