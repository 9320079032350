import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { ListItem, ListItemText, Typography } from "@mui/material";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import PanoramaIcon from "@mui/icons-material/Panorama";
import withStyles from "@mui/styles/withStyles";

import SelectAmount from "../common/SelectAmount";
import ShowImageDialog from "../common/ShowImageDialog";
import { cloudStorage } from "../../../storage";

const styles = (theme) => ({
  item: {
    width: "auto",
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.5, 2),
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper
  },
  compactItem: {
    margin: 0,
    padding: theme.spacing(0, 2)
  },
  name: {
    verticalAlign: "middle"
  },
  description: {},
  price: {
    color: "black"
  },
  accentuatedPrice: {
    fontWeight: "bold"
  },
  icon: {
    verticalAlign: "middle",
    marginTop: -2,
    marginLeft: 5,
    fontSize: 16
  },
  controls: {
    marginLeft: 10
  }
});

class MenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isShowImageDialogOpen: false,
      amount: 0
    };
  }

  componentDidMount() {
    const { amount } = this.props;

    if (amount !== undefined) {
      this.setState({ amount });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { amount } = this.props;

    if (amount !== undefined && amount !== this.state.amount) {
      this.setState({ amount });
    }
  }

  refreshItemImageUrl = async () => {
    const { bar, item } = this.props;

    try {
      const imageUrl = await cloudStorage.getBarMenuItemImageUrl(bar.id, item);

      this.setState({ imageUrl });
    } catch (error) {
      console.warn(error);

      this.setState({ imageUrl: null });
    }
  };

  handleShowImage = () => {
    const { imageUrl } = this.state;

    if (!imageUrl) {
      this.refreshItemImageUrl();
    }

    this.setState({ isShowImageDialogOpen: true });
  };

  handleShowImageDialogClose = () => {
    this.setState({ isShowImageDialogOpen: false });
  };

  handleAmountChange = (item) => (amount) => {
    const { onAmountChange } = this.props;

    this.setState({ amount });
    onAmountChange(item, amount);
  };

  render() {
    const {
      classes,
      t,
      isDisabled,
      bar,
      item,
      showImage,
      showDescription,
      showDeposits,
      isCompact
    } = this.props;
    const { isShowImageDialogOpen, amount, imageUrl } = this.state;

    return (
      <div>
        <ShowImageDialog
          isOpen={isShowImageDialogOpen}
          onClose={this.handleShowImageDialogClose}
          title={item.name}
          url={imageUrl}
        />
        <ListItem
          className={classNames(classes.item, {
            [classes.compactItem]: isCompact
          })}
        >
          <ListItemText
            primary={
              <span>
                <span className={classNames(classes.name, "notranslate")}>
                  {item.name}
                </span>
                {
                  showDeposits && item.hasDeposits && <LocalDrinkIcon className={classes.icon} />
                }
                {showImage && item.hasImage && (
                  <PanoramaIcon className={classes.icon} />
                )}
              </span>
            }
            secondary={
              <span>
                {showDescription && item.description && (
                  <>
                    <span className={classes.description}>
                      {item.description}
                    </span>
                    <br />
                  </>
                )}
                <span
                  className={classNames(classes.price, {
                    [classes.accentuatedPrice]: !!amount
                  })}
                >{`${item.getFormattedPrice()} ${bar.getOrderCurrency()}`}</span>
              </span>
            }
            onClick={
              showImage && item.hasImage ? this.handleShowImage : undefined
            }
          />
          <div className={classes.controls}>
            {item.isAvailable ? (
              <SelectAmount
                isDisabled={isDisabled}
                hideZero={true}
                amount={amount}
                onChange={this.handleAmountChange(item)}
              />
            ) : (
              <Typography variant="overline">
                {t("label.not-available")}
              </Typography>
            )}
          </div>
        </ListItem>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(MenuItem));
