enum RestrictionName {
  MAX_AMOUNT_OF_BASES = "maxAmountOfBases",
  MAX_AMOUNT_OF_ZONES = "maxAmountOfZones",
  MAX_LAYOUT_HEIGHT = "maxLayoutHeight",
  MAX_AMOUNT_OF_SCANNERS = "maxAmountOfScanners",
  STATS_DATA_UNIT = "statsDataUnit",
  STATS_DATA_KEYS = "statsDataKeys",
  STATS_MAX_RANGE_IN_DAYS = "statsMaxRangeInDays",
  IS_DOWNLOAD_STATS_ORDERS_DAY_REPORT_AVAILABLE = "isDownloadStatsOrdersDayReportAvailable",
  IS_DOWNLOAD_STATS_ORDERS_MONTH_REPORT_AVAILABLE = "isDownloadStatsOrdersMonthReportAvailable",
  IS_DOWNLOAD_STATS_ITEMS_REPORT_AVAILABLE = "isDownloadStatsItemsReportAvailable",
  ARE_VOUCHER_TEMPLATES_AVAILABLE = "areVoucherTemplatesAvailable",
  ARE_PERSONAL_VOUCHERS_AVAILABLE = "arePersonalVouchersAvailable",
  AVAILABLE_MODES_BY_NAME = "availableModesByName",
  AVAILABLE_FEE_CONDITION_TYPES = "availableFeeConditionTypes",
  AVAILABLE_SERVICE_OPTIONS_BY_NAME = "availableServiceOptionsByName"
}

export default RestrictionName;
