import { paymentHelper, utilsHelper } from "../../helpers";
import Base from "../base/Base";
import DeliveryMethod from "../delivery/DeliveryMethod";
import FulfilmentMethod from "../fulfilment/FulfilmentMethod";
import Locale from "../locale/Locale";
import Menu from "../menu/Menu";
import BarPropsModel from "../model/BarPropsModel";
import PriorityNumber from "../priority/PriorityNumber";
import LayoutPath from "../zone/LayoutPath";
import Item from "./Item";
import OrderContactAddress from "./OrderContactAddress";
import OrderDelivery from "./OrderDelivery";
import OrderDeposits from "./OrderDeposits";
import OrderFee from "./OrderFee";
import OrderFulfilment from "./OrderFulfilment";
import OrderPayment from "./OrderPayment";
import OrderPriority from "./OrderPriority";
import OrderService from "./OrderService";
import OrderStatus from "./OrderStatus";
import OrderSubOrders from "./OrderSubOrders";
import OrderVoucher from "./OrderVoucher";

class Order extends BarPropsModel {
  private readonly _name: string;
  private readonly _userId: string;
  private readonly _locale: Locale;
  private readonly _scannerId: string | null;
  private readonly _zoneCode: string | null;
  private readonly _layoutPath: LayoutPath | null;
  private readonly _status: any;
  private readonly _statusForMenu: { [menuId: string]: string } | undefined;
  private readonly _total: number;
  private readonly _subtotals: any;
  private readonly _tip: number;
  private readonly _currency: string;
  private readonly _note: string;
  private readonly _confirmationCode: string;
  private readonly _payment: OrderPayment | null;
  private readonly _vouchers: Array<OrderVoucher>;
  private readonly _fees: OrderFee[];
  private readonly _fields: Array<any>;
  private readonly _items: Array<Item>;
  private readonly _menuIds: Array<string>;
  private readonly _service: OrderService;
  private readonly _delivery: OrderDelivery;
  private readonly _fulfilment: OrderFulfilment;
  private readonly _priority: OrderPriority;
  private readonly _deposits: OrderDeposits;
  private readonly _contactAddresses: Array<OrderContactAddress>;
  private readonly _timestamp: Date;
  private readonly _subOrders: OrderSubOrders;

  constructor(barId: string, id: string, data: any) {
    super(barId, id);

    if (!data) {
      throw new Error("data is not defined");
    }
    /*if (!data.name) {
      throw new Error("data.name is not defined");
    }
    if (!data.status) {
      throw new Error("data.status is not defined");
    }
    if (!data.statusForMenu) {
      throw new Error("data.statusForMenu is not defined");
    }
    if (!data.timestamp) {
      throw new Error("data.timestamp is not defined");
    }*/

    this._userId = data.userId;
    this._locale = data.locale || Locale.EN_GB;
    this._scannerId = data.scannerId || data?.status?.data?.scannerId || null;
    this._name = data.name;
    this._zoneCode = data.zoneCode ? data.zoneCode : null;
    this._layoutPath = data.layoutPath
      ? new LayoutPath(data.layoutPath)
      : data.tableNumber
        ? new LayoutPath([data.tableNumber])
        : null;
    this._status = data.status;
    this._statusForMenu = data.statusForMenu;
    this._total = data.total;
    this._subtotals = data.subtotals;
    this._tip = data.tip;
    this._currency = paymentHelper.parsePaymentCurrency(data.currency);
    this._note = data.note;
    this._fields = data.fields;
    this._items = data.items
      ? data.items.map((item: any) => new Item(item.id, item))
      : [];
    this._menuIds = data.menuIds;
    this._timestamp = data.timestamp;
    this._confirmationCode = data.confirmationCode;
    this._payment = data.payment ? new OrderPayment(data.payment) : null;
    this._fees = data.fees
      ? data.fees.map((fee: any) => new OrderFee(fee))
      : [];
    this._vouchers = data.vouchers
      ? data.vouchers.map((voucher: any) => new OrderVoucher(voucher))
      : [];
    this._service = new OrderService(
      data.service,
      data.delivery,
      data.fulfilment,
      data.priority
    );
    this._delivery = new OrderDelivery(data.delivery);
    this._fulfilment = new OrderFulfilment(data.fulfilment);
    this._priority = new OrderPriority(data.priority);
    this._deposits = new OrderDeposits(data.deposits);
    this._contactAddresses = data.contactAddresses
      ? data.contactAddresses.map(
        (contactAddress: any) => new OrderContactAddress(contactAddress)
      )
      : [];
    this._subOrders = new OrderSubOrders(data.subOrders);
  }

  public get userId(): string {
    return this._userId;
  }

  public get locale(): Locale {
    return this._locale;
  }

  public get scannerId(): string | null {
    return this._scannerId;
  }

  public get name(): string {
    return this._name;
  }

  public get zoneCode(): string | null {
    return this._zoneCode;
  }

  public get layoutPath(): LayoutPath | null {
    return this._layoutPath;
  }

  public get fields(): {
    id: string;
    name: string;
    type: string;
    value: string;
    label?: string;
  }[] {
    return this._fields;
  }

  public get items(): Array<Item> {
    return this._items;
  }

  public get tip(): number {
    return this._tip || 0;
  }

  private get totalForItems(): number {
    return utilsHelper.roundToTwoDecimals(
      this.items.reduce((total: number, item: Item) => {
        return item.totalPrice === undefined ? total : total + item.totalPrice;
      }, 0)
    );
  }

  public get total(): number {
    return this._total === undefined
      ? this.totalForItems + this.tip + this.getTotalFee()
      : this._total;
  }

  public get currency(): string {
    return this._currency;
  }

  public get note(): string {
    return this._note;
  }

  public get menuIds(): Array<string> {
    return this._menuIds;
  }

  public get fees(): OrderFee[] {
    return this._fees;
  }

  public get confirmationCode(): string {
    return this._confirmationCode;
  }

  public get payment(): OrderPayment | null {
    return this._payment;
  }

  public get vouchers(): Array<OrderVoucher> {
    return this._vouchers;
  }

  public get service(): OrderService {
    return this._service;
  }

  public get delivery(): OrderDelivery {
    return this._delivery;
  }

  public get fulfilment(): OrderFulfilment {
    return this._fulfilment;
  }

  public get priority(): OrderPriority {
    return this._priority;
  }

  public get deposits(): OrderDeposits {
    return this._deposits;
  }

  public get subOrders(): OrderSubOrders {
    return this._subOrders;
  }

  public get hasSubOrders(): boolean {
    return this.subOrders.length > 0;
  }

  public get isDeliveryMethodServe(): boolean {
    return this.delivery.method === DeliveryMethod.SERVE;
  }

  public get isDeliveryMethodPickup(): boolean {
    return this.delivery.method === DeliveryMethod.PICKUP;
  }

  public getDeliveryColor(base: Base): string {
    return this.delivery.getColor(base);
  }

  public get isFulfilmentMethodAsSoonAsPossible(): boolean {
    return this.fulfilment.method === FulfilmentMethod.AS_SOON_AS_POSSIBLE;
  }

  public get isFulfilmentMethodOnDemand(): boolean {
    return this.fulfilment.method === FulfilmentMethod.ON_DEMAND;
  }

  public get isFulfilmentMethodAutomatic(): boolean {
    return this.fulfilment.method === FulfilmentMethod.AUTOMATIC;
  }

  public getEstimatedRemainingPreparationTime(
    calculateRemainingTime: (date: Date) => number,
    base?: Base
  ): number | null {
    // If no base is given, the preparation time is unknown (null)
    if (!base) {
      return null;
    }
    // If a base is given, it should have an id
    if (!base.id) {
      throw new Error("error.base-id-is-not-defined");
    }

    if (
      this.delivery.getEstimatedPreparationDurationInMinutes(base.id) === null
    ) {
      return null;
    }

    // If that's the case, wait for the preparationEstimatedToBeCompletedAt timestamp to be set
    const preparationEstimatedToBeCompletedAt =
      this.delivery.getPreparationEstimatedToBeCompletedAt(base.id);

    if (!preparationEstimatedToBeCompletedAt) {
      return null;
    }

    return calculateRemainingTime(preparationEstimatedToBeCompletedAt);
  }

  public getEstimatedPreparationInfo(
    calculateRemainingTime: (date: Date) => number,
    base?: Base
  ): {
    remainingTime: number,
    isWaitingForPickup: boolean,
    timeInMinutes: number,
    customerLate: boolean,
    customerTooLate: boolean
  } {
    const estimatedRemainingPreparationTime =
      this.getEstimatedRemainingPreparationTime(
        calculateRemainingTime,
        base
      );

    const remainingTime = estimatedRemainingPreparationTime || 0;
    const isWaitingForPickup = remainingTime <= 0;
    const timeInMinutes = Math.abs(remainingTime);

    const customerTooLate = isWaitingForPickup && timeInMinutes > 10
    const customerLate = isWaitingForPickup && timeInMinutes > 5;

    return {
      remainingTime,
      isWaitingForPickup,
      timeInMinutes,
      customerLate,
      customerTooLate
    };
  }

  public shouldPreparationBeComplete(
    calculateRemainingTime: (date: Date) => number,
    base?: Base
  ): boolean {
    // Order should be claimed (that's when preparation starts)
    if (this.hasBeenClaimed(base)) {
      // If a base is given, check other things as well
      if (base) {
        // A base should always have an id
        if (!base.id) {
          throw new Error("error.base-id-is-not-defined");
        }

        // If no sequenceNumber has been assigned yet, preparation isn't started yet
        /*const sequenceNumber = this.fulfilment.getSequenceNumber(base.id);
        if (sequenceNumber === null) {
          return false;
        }*/

        // Check if estimatedPreparationDurationInMinutes is set
        // If that's not the case, no preparation duration is in place so preparation should be complete
        if (
          this.delivery.getEstimatedPreparationDurationInMinutes(base.id) ===
          null
        ) {
          return true;
        }

        // If that's the case, wait for the preparationEstimatedToBeCompletedAt timestamp to be set
        const estimatedTimeRemaining =
          this.getEstimatedRemainingPreparationTime(
            calculateRemainingTime,
            base
          );

        // If estimatedTimeRemaining is not known yet, preparation is not ready
        if (estimatedTimeRemaining === null) {
          return false;
        }

        // Lastly, check if the remaining time is elapsed
        return estimatedTimeRemaining <= 0;
      }

      return true;
    }

    return false;
  }

  public get isHighPriority(): boolean {
    return this.priority.number === PriorityNumber.HIGH;
  }

  public get contactAddresses(): Array<OrderContactAddress> {
    return this._contactAddresses;
  }

  public get sequenceTimestamp(): Date {
    return this.payment ? this.payment.timestamp : this.timestamp;
  }

  public get timestamp(): Date {
    return this._timestamp;
  }

  public getSubtotal(base: Base): number | undefined {
    return this._subtotals
      ? utilsHelper.roundToTwoDecimals(
        Object.keys(this._subtotals).reduce((subtotal, menuId) => {
          return (
            subtotal +
            (base.menuIds.indexOf(menuId) >= 0 ? this._subtotals[menuId] : 0)
          );
        }, 0)
      )
      : undefined;
  }

  public getSubtotalByMenu(menu: Menu): number | undefined {
    if (!menu.id) {
      throw new Error("error.menu-id-is-not-defined");
    }

    return this._subtotals ? this._subtotals[menu.id] : undefined;
  }

  public getTotal(base?: Base) {
    return base ? this.getSubtotal(base) : this.total;
  }

  public getFormattedTotal(base?: Base) {
    const total = this.getTotal(base);

    if (total !== undefined) {
      return utilsHelper.formatToTwoDecimals(total);
    }

    return "";
  }

  public getItems(base?: Base): Array<Item> {
    if (this.items) {
      if (base) {
        return this.items.filter(
          (item) => base.menuIds.indexOf(item.menuId) >= 0
        );
      } else {
        return this.items;
      }
    }

    return [];
  }

  public getTotalAmount(base?: Base): number {
    return this.getItems(base).reduce((amount: number, item: Item) => {
      return amount + item.amount;
    }, 0);
  }

  public getTotalAlreadyPaidWithVouchers(): number {
    return this.vouchers
      ? utilsHelper.roundToTwoDecimals(
        this.vouchers.reduce((acc, voucher) => acc + voucher.amount, 0)
      )
      : 0;
  }

  public getTotalAlreadyPaid(): number {
    if (this.isPaid()) {
      return this.total;
    }

    return this.getTotalAlreadyPaidWithVouchers();
  }

  public getTotalToBePaid(): number {
    if (this.isPaid()) {
      return 0;
    }

    return utilsHelper.roundToTwoDecimals(
      this.total - this.getTotalAlreadyPaidWithVouchers()
    );
  }

  public getTotalFee(): number {
    return this.fees.reduce((total, orderFee) => {
      return total + orderFee.value;
    }, 0);
  }

  public getFormattedTotalAlreadyPaid() {
    return utilsHelper.formatToTwoDecimals(this.getTotalAlreadyPaid());
  }

  public getFormattedTotalToBePaid() {
    return utilsHelper.formatToTwoDecimals(this.getTotalToBePaid());
  }

  public getFormattedTotalAlreadyPaidWithVouchers() {
    return utilsHelper.formatToTwoDecimals(
      this.getTotalAlreadyPaidWithVouchers()
    );
  }

  public getFormattedTip() {
    if (this._tip !== undefined) {
      return utilsHelper.formatToTwoDecimals(this._tip);
    }

    return "";
  }

  public isFree(): boolean {
    return this._total === 0;
  }

  public isPaid(): boolean {
    return this.payment ? this.payment.isPaid : false;
  }

  public isPartiallyPaid(): boolean {
    return !this.isPaid() && this.getTotalAlreadyPaid() > 0;
  }

  public isPaymentProcessing() {
    if (!this.payment) {
      return false;
    }

    return this.payment.isPaymentProcessing();
  }

  public hasTip(): boolean {
    return this._tip && this._tip > 0 ? true : false;
  }

  public containsItemsForBase(base: Base): boolean {
    for (let i = 0; i < base.menuIds.length; i++) {
      if (this.containsItemsFromMenuWithId(base.menuIds[i])) {
        return true;
      }
    }

    return false;
  }

  public containsItemsFromMenuWithId(menuId: string): boolean {
    return this.menuIds && this.menuIds.indexOf(menuId) >= 0;
  }

  public containsItemsFromMultipleMenus(): boolean {
    return this.menuIds && this.menuIds.length > 1;
  }

  public containsItemsThatWillBeScanned(allBases: Array<Base>): boolean {
    // TO FIX
    return this.isFulfilmentMethodOnDemand;

    /*if (this.isDeliveryMethodServe) {
      return false;
    }

    const menuIdsUsingScanners: Array<string> = allBases.reduce(
      (menuIds: Array<string>, base: Base) => {
        if (base.fulfilmentMethods.hasOnDemandFulfilment) {
          base.menuIds.forEach((menuId: string) => {
            if (menuIds.indexOf(menuId) < 0) {
              menuIds.push(menuId);
            }
          });
        }

        return menuIds;
      },
      []
    );

    for (let i = 0; i < this.menuIds.length; i++) {
      if (menuIdsUsingScanners.indexOf(this.menuIds[i]) >= 0) {
        return true;
      }
    }

    return false;*/
  }

  public hasBeenCreated(base?: Base): boolean {
    const status = this.getStatus(base);

    return status ? status >= OrderStatus.CREATED : false;
  }

  public hasBeenQueued(base?: Base): boolean {
    const status = this.getStatus(base);

    return status
      ? status >= OrderStatus.QUEUED && status < OrderStatus.CANCELLED
      : false;
  }

  public hasBeenClaimed(base?: Base): boolean {
    const status = this.getStatus(base);

    return status
      ? status >= OrderStatus.CLAIMED && status < OrderStatus.CANCELLED
      : false;
  }

  public hasBeenCancelled(base?: Base): boolean {
    const status = this.getStatus(base);

    return status ? status >= OrderStatus.CANCELLED : false;
  }

  public canBePaid(base?: Base): boolean {
    const status = this.getStatus(base);

    if (status) {
      return (
        !this.isFree() &&
        !this.isPaid() &&
        !this.isPaymentProcessing() &&
        status < OrderStatus.COMPLETE
      );
    }

    return false;
  }

  public getStatus(base?: Base): string | undefined {
    return base
      ? this._statusForMenu
        ? Object.keys(this._statusForMenu).reduce(
          (status: string | undefined, menuId: string) => {
            if (this._statusForMenu && base.menuIds.indexOf(menuId) >= 0) {
              if (!status || this._statusForMenu[menuId] < status) {
                return this._statusForMenu[menuId];
              }
            }

            return status;
          },
          undefined
        )
        : undefined
      : this._status
        ? this._status.name
        : undefined;
  }

  public getStatusTimestamp(): Date | undefined {
    return this._status ? this._status.timestamp : undefined;
  }

  public getStatusData(): any {
    return this._status ? this._status.data : undefined;
  }

  public getOverallStatus(): any {
    return this._status;
  }

  public getStatusesForAllMenus(): { [menuId: string]: string } | undefined {
    return this._statusForMenu;
  }

  public getStatusForMenu(menuId: string): string | undefined {
    return this._statusForMenu ? this._statusForMenu[menuId] : undefined;
  }

  public isStatus(statusName: string, base?: Base): boolean {
    return this.getStatus(base) === statusName;
  }

  public isStatusCreated(base?: Base): boolean {
    return this.isStatus(OrderStatus.CREATED, base);
  }

  public isStatusQueued(base?: Base): boolean {
    return this.isStatus(OrderStatus.QUEUED, base);
  }

  public isStatusClaimed(base?: Base): boolean {
    return this.isStatus(OrderStatus.CLAIMED, base);
  }

  public isStatusComplete(base?: Base): boolean {
    return this.isStatus(OrderStatus.COMPLETE, base);
  }

  public isStatusCancelled(base?: Base): boolean {
    return this.isStatus(OrderStatus.CANCELLED, base);
  }

  public isStatusError(base?: Base): boolean {
    return this.isStatus(OrderStatus.ERROR, base);
  }

  public isReady(base?: Base): boolean {
    const status = this.getStatus(base);

    return status ? status >= OrderStatus.CREATED : false;
  }
}

export default Order;
