import React, { PropsWithChildren, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import {
    Theme, Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import Bar from "tap-io/models/bar/Bar";
import Order from "tap-io/models/order/Order";
import Base from "tap-io/models/base/Base";


const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0.5, 2),
        borderRadius: 4,
        backgroundColor: "black",
        color: "white"
    },
    title: {
        flexGrow: 1
    },
    sequenceNumber: {

    }
}));

type OrderPreparationInfoProps = {
    order: Order;
    base: Base;
};

function OrderPreparationInfo({ order, base }: PropsWithChildren<OrderPreparationInfoProps>) {
    const { t, i18n } = useTranslation("common");
    const classes = useStyles();

    const [sequenceNumber, setSequenceNumber] = useState<number | null>(null);

    useEffect(() => {
        setSequenceNumber(base?.id ? order.fulfilment.getSequenceNumber(base.id) : null);
    }, [order]);

    return (
        sequenceNumber === null ?
            null :
            <div className={classes.container}>
                <Typography className={classes.title}>
                    {t("order.sequence-number")}
                </Typography>
                <Typography className={classes.sequenceNumber} variant="h6">
                    {sequenceNumber}
                </Typography>
            </div>
    );
}

export default OrderPreparationInfo;
