import {
  useCallback,
  useEffect,
  useRef,
  useState,
  VideoHtmlElement
} from "react";

import { Html5Qrcode } from "html5-qrcode";

import { Theme, Box, CircularProgress } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { utilsHelper } from "../../../helpers";

const useQRScanner = () => {
  /*const [result, setResult] = useState("");

  const streamRef = useRef<MediaStream>();
  const activeRef = useRef<boolean>();*/

  let html5QrCode: Html5Qrcode | null;

  const start = useCallback(
    async (
      id: string,
      onReady?: () => void,
      onSuccess?: (result: string) => void,
      onError?: (error: any) => void
    ) => {
      try {
        /*if (!streamRef.current) {
          streamRef.current = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: { ideal: "environment" } },
            audio: false
          });
        }
        activeRef.current = true;

        video.srcObject = streamRef.current;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const barcodeDetector = new BarcodeDetector({ formats: ["qr_code"] });

        const capture = async () => {
          try {
            console.log("capture");
            const barcodes: any[] = await barcodeDetector.detect(video);
            if (barcodes.length <= 0) {
              return;
            }

            const result = barcodes[0].rawValue;

            setResult(result);

            if (onSuccess) {
              onSuccess(result);
            }

            //requestAnimationFrame(capture);
          } catch (error) {
            if (onError) {
              onError(error);
            } else {
              console.error(error);
            }
          }
        }

        await video.play();

        (async function renderLoop() {
          if (activeRef.current) {
            capture();
            requestAnimationFrame(renderLoop);
          }
        })();*/

        html5QrCode = new Html5Qrcode(id, {
          useBarCodeDetectorIfSupported: true
        });

        let prevData: string = "";

        await html5QrCode.start(
          { facingMode: "environment" },
          {
            fps: 10
            /*qrbox: {
            width: 250,
            height: 250
          }*/
          },
          (data: string) => {
            // Prevent onSuccess being called more than once when the scanned QR code remains the same
            if (data !== prevData) {
              if (onSuccess) {
                onSuccess(cleanData(data));
              }
            }

            prevData = data;
          }
        );

        if (onReady) {
          onReady();
        }
      } catch (error) {
        if (onError) {
          onError(error);
        } else {
          console.error(error);
        }
      }
    },
    []
  );

  const stop = useCallback(async () => {
    /*activeRef.current = false;
    streamRef.current?.getTracks().forEach((track) => track.stop());*/

    try {
      if (html5QrCode) {
        await html5QrCode.stop();
      }

      html5QrCode = null;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const cleanData = (data: string) => {
    const matches = data.match(/^(url:)?(?<data>.*)$/i);

    if (matches?.groups) {
      return matches.groups.data;
    }

    return data;
  };

  return [start, stop] as const;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    loading: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 300
    }
  })
);

const QrReader = (props: {
  onSuccess: (result: string) => void;
  onError: (error: any) => void;
  onReady?: () => void;
}) => {
  const classes = useStyles();

  const { onSuccess, onError, onReady } = props;
  const readerRef = useRef(null);

  const [isReady, setIsReady] = useState(false);

  const id = utilsHelper.generateUUID();

  const [start, stop] = useQRScanner();

  useEffect(() => {
    if (!readerRef.current) {
      return;
    }

    start(
      readerRef.current.id,
      () => {
        setIsReady(true);

        if (onReady) {
          onReady();
        }
      },
      onSuccess,
      onError
    );

    return stop;
  }, [start, stop, onSuccess, onError]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column" }}
      className={classes.container}
    >
      {!isReady && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      <div ref={readerRef} id={id} />
    </Box>
  );
};

export default QrReader;
