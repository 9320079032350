import DeliveryColor from "./DeliveryColor";
import { utilsHelper } from "../../helpers";

class DeliveryColors {
  private readonly _colors: Array<string> = [];

  constructor(data: any) {
    if (data) {
      for (let color in data) {
        if (data[color] && data[color].isActive === true) {
          this._colors.push(color);
        }
      }
    }
  }

  public get colors(): Array<string> {
    return this._colors;
  }

  public add(color: string) {
    if (this.colors.indexOf(color) < 0) {
      this.colors.push(color);
    }
  }

  public remove(color: string) {
    const index = this.colors.indexOf(color);

    if (index >= 0) {
      this.colors.splice(index, 1);
    }
  }

  public pickRandomColor(): string {
    return this.colors.length > 0 ?
      utilsHelper.getRandomElementFromArray(this.colors) :
      DeliveryColor.BLACK;
  }

  public toJSON() {
    const json: any = {};
    this.colors.forEach(color => json[color] = { isActive: true });
    return json;
  }
}

export default DeliveryColors;
