import React from "react";

import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  contentContainer: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.5),
    borderRadius: 4,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    color: "black"
  }
});

const ContentContainer = ({ classes, children, style }) => (
  <div className={classes.contentContainer} style={style}>
    {children}
  </div>
);

export default withStyles(styles)(ContentContainer);
