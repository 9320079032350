import Order from "../models/order/Order";
import OrderLog from "..//models/order/OrderLog";

export default class OrderParser {
  public parseDocToOrder(barId: string, doc): Order {
    const data = doc.data();

    if (data.payment) {
      data.payment = {
        ...data.payment,
        timestamp: data.payment.timestamp.toDate(),
        createdAt: data.payment.createdAt ? data.payment.createdAt.toDate() : null,
        paidAt: data.payment.paidAt ? data.payment.paidAt.toDate() : null
      };
    }

    if (data.status) {
      data.status = {
        ...data.status,
        timestamp: data.status.timestamp.toDate()
      };
    }

    if (data.fields) {
      data.fields = data.fields.map((field) => {
        // TO FIX
        if (
          field &&
          field.type === "date" &&
          field.value &&
          field.value.toDate
        ) {
          field.value = field.value.toDate();
        }

        return field;
      });
    }

    if (data.delivery && data.delivery.bases) {
      for (let baseId in data.delivery.bases) {
        if (data.delivery.bases[baseId].preparationStartedAt) {
          data.delivery.bases[baseId].preparationStartedAt =
            data.delivery.bases[baseId].preparationStartedAt.toDate();
        }
        if (data.delivery.bases[baseId].preparationEstimatedToBeCompletedAt) {
          data.delivery.bases[baseId].preparationEstimatedToBeCompletedAt =
            data.delivery.bases[
              baseId
            ].preparationEstimatedToBeCompletedAt.toDate();
        }
      }
    }

    return new Order(barId, doc.id, {
      ...data,
      timestamp: data.timestamp.toDate()
    });
  }

  public parseDocToOrderLog(barId: string, orderId: string, doc): OrderLog {
    const data = doc.data();

    return new OrderLog(barId, orderId, doc.id, {
      ...data,
      timestamp: data.timestamp.toDate()
    });
  }
}
