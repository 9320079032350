import PaymentProvider from "../payment/PaymentProvider";

export default class Stats {
  public creditsInCount: number = 0;
  public creditsOutCount: number = 0;
  public itemCount: number = 0;
  public orderCount: number = 0;
  public voucherCount: number = 0;
  public feeCount: number = 0;
  public paymentProviderCount: {
    [paymentProvider in PaymentProvider]?: number;
  } = {};

  constructor(data?: any) {
    if (data) {
      if (data.creditsInCount) {
        this.creditsInCount = data.creditsInCount;
      }
      if (data.creditsOutCount) {
        this.creditsOutCount = data.creditsOutCount;
      }
      if (data.itemCount) {
        this.itemCount = data.itemCount;
      }
      if (data.orderCount) {
        this.orderCount = data.orderCount;
      }
      if (data.voucherCount) {
        this.voucherCount = data.voucherCount;
      }
      if (data.feeCount) {
        this.feeCount = data.feeCount;
      }
      if (data.paymentProviderCount) {
        this.paymentProviderCount = data.paymentProviderCount;
      }
    }
  }

  public add(stats: Stats) {
    this.creditsInCount += stats.creditsInCount;
    this.creditsOutCount += stats.creditsOutCount;
    this.itemCount += stats.itemCount;
    this.orderCount += stats.orderCount;
    this.voucherCount += stats.voucherCount;
    this.feeCount += stats.feeCount;

    for (let paymentProvider in stats.paymentProviderCount) {
      if (this.paymentProviderCount[paymentProvider]) {
        this.paymentProviderCount[paymentProvider] +=
          stats.paymentProviderCount[paymentProvider];
      } else {
        this.paymentProviderCount[paymentProvider] =
          stats.paymentProviderCount[paymentProvider];
      }
    }
  }

  public toJSON(): any {
    return {
      creditsInCount: this.creditsInCount,
      creditsOutCount: this.creditsOutCount,
      itemCount: this.itemCount,
      orderCount: this.orderCount,
      voucherCount: this.voucherCount,
      feeCount: this.feeCount,
      paymentProviderCount: this.paymentProviderCount
    };
  }
}
