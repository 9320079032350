import FulfilmentMethod from "../fulfilment/FulfilmentMethod";
import DeliveryMethod from "../delivery/DeliveryMethod";
import ServiceOptionName from "./ServiceOptionName";
import PriorityNumber from "../priority/PriorityNumber";

class ServiceOption {
  private readonly _name: ServiceOptionName;
  private readonly _fulfilmentMethod: FulfilmentMethod;
  private readonly _deliveryMethod: DeliveryMethod;
  private readonly _priorityNumber: PriorityNumber;

  constructor(
    name: ServiceOptionName,
    fulfilmentMethod: FulfilmentMethod,
    deliveryMethod: DeliveryMethod,
    priorityNumber: PriorityNumber
  ) {
    this._name = name;
    this._fulfilmentMethod = fulfilmentMethod;
    this._deliveryMethod = deliveryMethod;
    this._priorityNumber = priorityNumber;
  }

  public get name() {
    return this._name;
  }

  public get fulfilmentMethod() {
    return this._fulfilmentMethod;
  }

  public get deliveryMethod() {
    return this._deliveryMethod;
  }

  public get priorityNumber() {
    return this._priorityNumber;
  }

  public toJSON(): any {
    return {
      name: this.name
    };
  }

  public clone(): ServiceOption {
    return new ServiceOption(
      this.name,
      this.fulfilmentMethod,
      this.deliveryMethod,
      this.priorityNumber
    );
  }
}

export default ServiceOption;
