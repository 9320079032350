import FeeConditionType from "./FeeConditionType";
import FeeConditionOption from "./FeeConditionOption";
import LayoutPath from "../zone/LayoutPath";
import ServiceOptionName from "../service/ServiceOptionName";

export default abstract class FeeCondition {
  private readonly _type: FeeConditionType;
  private _option: FeeConditionOption;

  constructor(type: FeeConditionType, option: FeeConditionOption) {
    this._type = type;
    this._option = option;
  }

  public get type() {
    return this._type;
  }

  public get option() {
    return this._option;
  }

  public set option(option: FeeConditionOption) {
    this._option = option;
  }

  public abstract isMet(
    serviceOptionName?: ServiceOptionName,
    zoneCode?: string,
    layoutPath?: LayoutPath
  ): boolean;

  public abstract clone(): FeeCondition;

  public toJSON(): any {
    return {
      type: this.type,
      option: this.option
    };
  }
}
