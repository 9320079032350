import OrderDeposit from "./OrderDeposit";

class OrderDistributedDeposit extends OrderDeposit {
    private readonly _itemIds: string[];

    constructor(depositId: string, props: any) {
        super(depositId, props);

        this._itemIds = props.itemIds;
    }

    public get itemIds(): string[] {
        return this._itemIds;
    }

    public toJSON() {
        return {
            ...super.toJSON(),
            itemIds: this.itemIds
        }
    }
}

export default OrderDistributedDeposit;
