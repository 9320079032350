import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  spinner: {
    display: "flex",
    justifyContent: "center"
  },
  media: {
    width: "calc(100vw - 100px)",
    maxWidth: "100%",
    height: 0,
    marginTop: 10,
    paddingTop: "56.25%", // 16:9
    backgroundSize: "contain"
  }
});

class ShowImageDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, isOpen, onClose, title, description, url } = this.props;

    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {description && <DialogContentText>{description}</DialogContentText>}
          {url ? (
            <CardMedia
              className={classes.media}
              image={url}
              title={t("label.image")}
            />
          ) : (
            <div className={classes.spinner}>
              <CircularProgress />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t("label.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(ShowImageDialog));
