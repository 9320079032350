import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItem, ListItemText, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  note: {
    borderRadius: 4,
    background: "red",
    color: "white"
  }
});

class OrderNote extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, order } = this.props;

    if (order.note) {
      return (
        <ListItem className={classes.note}>
          <ListItemText
            primary={
              <Typography variant="caption">
                {`${t("order.note-from-customer")}: ${order.note}`}
              </Typography>
            }
          />
        </ListItem>
      );
    }

    return null;
  }
}

export default withStyles(styles)(withTranslation("common")(OrderNote));
