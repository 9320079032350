import Element from "./Element";

class Category extends Element {
  constructor(menuId: string, id: string, data: any) {
    super(menuId, id, "category", data);
  }

  public toJSON(): any {
    return {
      ...super.toJSON()
    };
  }
}

export default Category;