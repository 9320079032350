export default class SoundHelper {
  constructor() {}

  playSound(soundAsAudio) {
    if (soundAsAudio) {
      try {
        soundAsAudio.pause();
        soundAsAudio.currentTime = 0;
        soundAsAudio.play();
      } catch (error) {
        console.warn("Unknown error:", error);
      }
    } else {
      console.warn("soundAsAudio is not defined");
    }
  }
}
