import CustomerField from "./CustomerField";
import CustomerFieldProps from "./CustomerFieldProps";
import CustomerFieldType from "./CustomerFieldType";

interface TextCustomerFieldProps extends CustomerFieldProps {
  type: CustomerFieldType.TEXT;
}

export default class TextCustomerField extends CustomerField {
  constructor(props: Omit<TextCustomerFieldProps, "type">) {
    super({ ...props, type: CustomerFieldType.TEXT });
  }

  public clone(): TextCustomerField {
    return new TextCustomerField({
      id: this.id,
      name: this.localisedName.toJSON(),
      description: this.localisedDescription.toJSON(),
      isRequired: this.isRequired
    });
  }
}
