import Payment from "../models/payment/Payment";
import { paymentParser } from "../parsers";

export default class PaymentService {
  private readonly fbStore;

  constructor(fbStore: any) {
    this.fbStore = fbStore;
  }

  private getPaymentsRef() {
    return this.fbStore.collection("payments");
  }

  protected getPaymentRef(paymentId: string) {
    return this.getPaymentsRef().doc(paymentId);
  }

  public async getPaymentById(paymentId: string): Promise<Payment> {
    if (!paymentId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const doc = await this.getPaymentRef(paymentId).get();
    return paymentParser.parseDocToPayment(doc);
  }
}
