import React, { PureComponent } from "react";

import { Helmet } from "react-helmet";

import withStyles from "@mui/styles/withStyles";

import BarHeader from "./BarHeader";
import BarContent from "./BarContent";
import BarFooter from "./BarFooter";

const styles = (theme) => ({});

class BarSetting extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      barLocator,
      isAppLocked,
      bar,
      scanner,
      base,
      activeFees,
      activeDeposits,
      menuDeposits,
      menuElements,
      menuCategories,
      menuItems,
      setScannerId,
      unlockApp,
      getPinHash,
      resetPinHash
    } = this.props;

    return bar ? (
      <div>
        <Helmet htmlAttributes={{ lang: bar.getAppLang() }}>
          {bar && bar.name && <title>{bar.name}</title>}
        </Helmet>
        <BarHeader
          barLocator={barLocator}
          bar={bar}
          base={base}
          scanner={scanner}
        />
        <BarContent
          barLocator={barLocator}
          isAppLocked={isAppLocked}
          bar={bar}
          scanner={scanner}
          base={base}
          activeFees={activeFees}
          activeDeposits={activeDeposits}
          menuDeposits={menuDeposits}
          menuElements={menuElements}
          menuCategories={menuCategories}
          menuItems={menuItems}
          setScannerId={setScannerId}
          unlockApp={unlockApp}
          getPinHash={getPinHash}
          resetPinHash={resetPinHash}
        />
        <BarFooter />
      </div>
    ) : null;
  }
}

export default withStyles(styles)(BarSetting);
