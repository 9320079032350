import { auth, emailAuthProvider } from "./firebase";

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);

// Sign In (Anonymously)
export const doSignInAnonymously = () => auth.signInAnonymously();

// Sign In (E-mail & Password)
export const doSignInWithEmailAndPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password);

// Sign out
export const doSignOut = () => auth.signOut();

// Password Reset
export const doPasswordReset = (email) => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) =>
  auth.currentUser.updatePassword(password);

// Recaptcha Verifier
export const RecaptchaVerifier = auth.RecaptchaVerifier;

// Create email provider creditial (email, password)
export const createEmailProviderCredential = emailAuthProvider.credential;
