import SubscriptionService from "../../services/SubscriptionService";
import Bar from "../../models/bar/Bar";
import { serviceHelper } from "../../helpers";

export default class ClientSubscriptionService extends SubscriptionService {
  constructor(fbStore) {
    super(fbStore);
  }

  public requestPlan(
    userId: string,
    bar: Bar,
    planName: string,
    onProgress?: Function
  ) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const data: any = {
      barId: bar.id,
      planName
    };

    return serviceHelper.createTask(
      userId,
      "subscription",
      "requestPlan",
      data,
      onProgress
    );
  }

  public updatePlan(
    userId: string,
    bar: Bar,
    planName: string,
    validUntil: Date,
    onProgress?: Function
  ) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const data: any = {
      barId: bar.id,
      planName,
      validUntil
    };

    return serviceHelper.createTask(
      userId,
      "subscription",
      "updatePlan",
      data,
      onProgress
    );
  }

  public requestPlanRenewal(userId: string, bar: Bar, onProgress?: Function) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const data: any = {
      barId: bar.id
    };

    return serviceHelper.createTask(
      userId,
      "subscription",
      "requestPlanRenewal",
      data,
      onProgress
    );
  }

  public downgradePlan(userId: string, bar: Bar, onProgress?: Function) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const data: any = {
      barId: bar.id
    };

    return serviceHelper.createTask(
      userId,
      "subscription",
      "downgradePlan",
      data,
      onProgress
    );
  }
}
