import OrderSubOrder from "./OrderSubOrder";

class OrderSubOrders {
  private readonly _subOrders: { [baseId: string]: OrderSubOrder } = {};

  constructor(data: any) {
    if (data) {
      for (let baseId in data) {
        this._subOrders[baseId] = new OrderSubOrder(baseId, data[baseId]);
      }
    }
  }

  private get subOrders() {
    return this._subOrders;
  }

  public getForBase(baseId: string): OrderSubOrder {
    return this.subOrders[baseId];
  }

  public get length(): number {
    return Object.keys(this.subOrders).length;
  }

  public forEach(cb: Function): void {
    let index = 0;
    for (let baseId in this.subOrders) {
      cb(baseId, this.subOrders[baseId], index++);
    }
  }

  public map(cb: Function): Array<any> {
    const arr: Array<any> = [];

    this.forEach((baseId: string, subOrder: OrderSubOrder, index: number) => {
      arr.push(cb(subOrder, index++));
    });

    return arr;
  }

  public getAll(): OrderSubOrder[] {
    return Object.values(this.subOrders);
  }

  public get areAllSent(): boolean {
    return this.getNotYetSent().length === 0;
  }

  public get areNoneSent(): boolean {
    return this.getAlreadySent().length === 0;
  }

  public getNotYetSent(): OrderSubOrder[] {
    const notYetSent: OrderSubOrder[] = [];

    for (let baseId in this.subOrders) {
      const subOrder = this.subOrders[baseId];

      if (!subOrder.isSent) {
        notYetSent.push(subOrder);
      }
    }

    return notYetSent;
  }

  public getAlreadySent(): OrderSubOrder[] {
    const notYetSent: OrderSubOrder[] = [];

    for (let baseId in this.subOrders) {
      const subOrder = this.subOrders[baseId];

      if (subOrder.isSent) {
        notYetSent.push(subOrder);
      }
    }

    return notYetSent;
  }

  public toJSON() {
    const json: { [baseId: string]: any } = {};

    for (let baseId in this.subOrders) {
      json[baseId] = this.subOrders[baseId].toJSON();
    }

    return json;
  }
}

export default OrderSubOrders;
