import BarModel from "../model/BarModel";
import SubscriptionStatus from "./SubscriptionStatus";
import Capabilities from "./Capabilities";
import Capability from "./Capability";
import PlanName from "./PlanName";

class Subscription extends BarModel {
  private readonly _planName: string;
  private readonly _status: string;
  private readonly _capabilities: Capabilities;
  private readonly _validUntil: Date | null;
  private readonly _hadIntakeMeeting: boolean;
  private readonly _timestamp: Date;

  constructor(barId: string, data: any) {
    super(barId);

    if (!data) {
      throw new Error("error.data-is-not-defined");
    }
    if (!data.status) {
      throw new Error("error.data-status-is-not-defined");
    }
    if (!data.planName) {
      throw new Error("error.data-name-is-not-defined");
    }

    switch (data.planName) {
      case PlanName.BASIC:
        this._planName = PlanName.BASIC;
        //this._capabilities = PlanCapabilities[PlanName.BASIC];
        break;
      case PlanName.PRO:
        this._planName = PlanName.PRO;
        //this._capabilities = PlanCapabilities[PlanName.PRO];
        break;
      case PlanName.FULL_SERVICE:
        this._planName = PlanName.FULL_SERVICE;
        //this._capabilities = PlanCapabilities[PlanName.FULL_SERVICE];
        break;
      default:
        throw new Error("error.subscription-name-is-invalid");
    }
    this._status = data.status;

    this._capabilities = new Capabilities();
    if (data.capabilities) {
      //this._capabilities.clearCapabilities();
      for (let name in data.capabilities) {
        this._capabilities.addCapability(
          new Capability(name, data.capabilities[name])
        );
      }
    }
    this._hadIntakeMeeting = data.hadIntakeMeeting === true;
    this._validUntil = data.validUntil ? data.validUntil : null;

    this._timestamp = data.timestamp;
  }

  public get planName(): string {
    return this._planName;
  }

  public get status(): string {
    return this._status;
  }

  public get capabilities(): Capabilities {
    return this._capabilities;
  }

  public isRequested(): boolean {
    return this.status === SubscriptionStatus.REQUESTED;
  }

  public isActive(): boolean {
    return this.status === SubscriptionStatus.ACTIVE;
  }

  public isExpired(): boolean {
    return this.status === SubscriptionStatus.EXPIRED;
  }

  public isRevoked(): boolean {
    return this.status === SubscriptionStatus.REVOKED;
  }

  public get hadIntakeMeeting(): boolean {
    return this._hadIntakeMeeting;
  }

  public get validUntil(): Date | null {
    return this._validUntil;
  }

  public get timestamp(): Date {
    return this._timestamp;
  }
}

export default Subscription;
