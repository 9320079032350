import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import withStyles from "@mui/styles/withStyles";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const styles = (theme) => ({});

class ConfirmDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleConfirm = () => {
    const { onConfirm } = this.props;

    onConfirm();
  };

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel();
  };

  render() {
    const {
      classes,
      t,
      isOpen,
      title,
      description,
      confirmButtonLabel,
      cancelButtonLabel
    } = this.props;

    return (
      <Dialog open={isOpen} onClose={this.handleCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.handleCancel}>
            {cancelButtonLabel ? cancelButtonLabel : t("label.cancel")}
          </Button>
          <Button color="primary" onClick={this.handleConfirm}>
            {confirmButtonLabel ? confirmButtonLabel : t("label.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(withTranslation("common")(ConfirmDialog))
);
