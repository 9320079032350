import Moment from "moment";

export default class SessionStorage {
  private readonly _sessionData: any = {};
  private readonly _observers: any = {};

  private get sessionData(): any {
    return this._sessionData;
  }

  private get observers(): any {
    return this._observers;
  }

  private getItem(key: string): any {
    try {
      return this.sessionData[key];
    } catch (error) {
      throw error;
    }
  }

  private onItem(key: string, cb: Function) {
    const id = `${new Date().getTime()}_${Math.random()}`;

    this.observers[id] = { key, cb };

    const unsubscribe = () => {
      delete this.observers[id];
    };

    cb(this.getItem(key));

    return unsubscribe;
  }


  private setItem(key: string, item: any) {
    try {
      this.sessionData[key] = item;

      this.notifyObservers();
    } catch (error) {
      throw error;
    }
  }

  private removeItem(key: string) {
    try {
      delete this.sessionData[key];

      this.notifyObservers();
    } catch (error) {
      throw error;
    }
  }

  private notifyObservers() {
    for (let id in this.observers) {
      const observer = this.observers[id];
      observer.cb(this.getItem(observer.key));
    }
  }

  private async getSessionForBar(barId: string) {
    return this.getItem(barId);
  }

  private async setSessionForBar(barId: string, session: any) {
    this.setItem(barId, session);
  };

  public async getSessionItem(barId: string, key: string) {
    const sessionForBar = await this.getSessionForBar(barId);

    if (sessionForBar && sessionForBar[key]) {
      if (sessionForBar[key].expiresAt && Moment().isAfter(sessionForBar[key].expiresAt)) {
        await this.clearDeviceCacheItem(barId, key);
        return undefined;
      }

      return sessionForBar[key].value;
    }
  }

  public async setSessionItem(barId: string, key: string, value: any, maxAgeInSeconds?: number) {
    const sessionForBar = (await this.getSessionForBar(barId)) || {};

    sessionForBar[key] = { value };
    if (maxAgeInSeconds !== undefined) {
      sessionForBar[key].expiresAt = Moment().add(maxAgeInSeconds, "seconds").toDate().toISOString();
    }

    await this.setSessionForBar(barId, sessionForBar);
  }

  public async clearDeviceCacheItem(barId: string, key: string) {
    const sessionForBar = (await this.getSessionForBar(barId)) || {};
    delete sessionForBar[key];
    await this.setSessionForBar(barId, sessionForBar);
  }
}