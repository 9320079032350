import ServiceOptions from "../service/ServiceOptions";
import ServiceOptionName from "../service/ServiceOptionName";

class LayoutNodeBase {
  private readonly _serviceOptions: ServiceOptions;

  constructor(data?: any) {
    this._serviceOptions = new ServiceOptions(data?.serviceOptions);
  }

  public get serviceOptions() {
    return this._serviceOptions;
  }

  public get hasAnyServiceOption(): boolean {
    return this.serviceOptions.length > 0;
  }

  public hasServiceOption(serviceOptionName: ServiceOptionName): boolean {
    return this.serviceOptions.has(serviceOptionName);
  }

  public toJSON() {
    return {
      serviceOptions: this.serviceOptions.toJSON()
    };
  }
}

export default LayoutNodeBase;
