import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import theme from "tap-io/client/theme";

const styles = (theme) => ({
  container: {
    padding: theme.spacing(1)
  },
  logo: {
    height: 60,
    margin: theme.spacing(2, 1)
  }
});

class BarFooter extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.container}>
        <img className={classes.logo} src={theme.assets.logo} />
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(BarFooter));

/*
<a href={config.links.promo} target="_blank">
  <img className={classes.logo} src={theme.assets.logo} />
</a>
*/
