import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { CircularProgress, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "calc(100vh - 250px)"
  },
  spacing: {
    height: 40
  }
});

class VerifyingPin extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.container}>
        <CircularProgress />
        <div className={classes.spacing} />
        <Typography variant="h6">{t("auth.verifying-pin")}</Typography>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(VerifyingPin)
);
