import Voucher from "../models/voucher/Voucher";
import Bar from "../models/bar/Bar";
import VoucherTemplate from "../models/voucher/VoucherTemplate";
import { voucherParser } from "../parsers";
import { serviceHelper } from "../helpers";
import VoucherPayment from "../models/voucher/VoucherPayment";

export default class VoucherService {
  private readonly fbStore;

  public readonly SEARCH_BY_ID_MIN_QUERY_LENGTH = 4;

  constructor(fbStore) {
    this.fbStore = fbStore;
  }

  protected getVouchersRef(barId: string) {
    return this.fbStore.collection("bars").doc(barId).collection("vouchers");
  }

  protected getVoucherRef(barId: string, voucherId: string) {
    return this.getVouchersRef(barId).doc(voucherId);
  }

  protected getVoucherLogRef(barId: string, voucherId: string) {
    return this.getVoucherRef(barId, voucherId).collection("log");
  }

  protected getVoucherTemplatesRef(barId: string) {
    return this.fbStore
      .collection("bars")
      .doc(barId)
      .collection("voucherTemplates");
  }

  protected getVoucherTemplateRef(barId: string, voucherTemplateId: string) {
    return this.getVoucherTemplatesRef(barId).doc(voucherTemplateId);
  }

  public onAllVouchers(bar: Bar, maxCount: number, cb: Function) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }
    const barId = bar.id;

    const ref = this.getVouchersRef(barId)
      .orderBy("timestamp", "desc")
      .limit(maxCount);

    return ref.onSnapshot((docs) => {
      const vouchers: Array<Voucher> = [];
      docs.forEach((doc) => {
        vouchers.push(voucherParser.parseDocToVoucher(barId, doc));
      });
      cb(vouchers);
    });
  }

  public async getVoucherById(
    barId: string,
    voucherId: string
  ): Promise<Voucher> {
    const doc = await this.getVoucherRef(barId, voucherId).get();

    if (!doc.exists) {
      throw new Error("voucher does not exist");
    }

    return voucherParser.parseDocToVoucher(barId, doc);
  }

  onVoucherLog(voucher: Voucher, cb: Function, maxCount: number) {
    if (!voucher.id) {
      throw new Error("error.voucher-id-is-not-defined");
    }

    return this.getVoucherLogRef(voucher.barId, voucher.id)
      .orderBy("timestamp", "desc")
      .limit(maxCount)
      .onSnapshot((docs) => {
        const voucherLog: Array<any> = [];

        docs.forEach((doc) => {
          voucherLog.push(
            voucherParser.parseDocToVoucherLogEntry(voucher, doc)
          );
        });

        cb(voucherLog);
      });
  }

  public async searchVouchers(
    barId: string,
    query: string
  ): Promise<Array<Voucher>> {
    const vouchersById = await this.searchVouchersById(barId, query);
    const vouchersByCustomerEmail = await this.searchVouchersByCustomerEmail(
      barId,
      query
    );

    const vouchersAsMap: any = {};

    vouchersByCustomerEmail.forEach((voucher) => {
      if (voucher.id) {
        vouchersAsMap[voucher.id] = voucher;
      }
    });
    vouchersById.forEach((voucher) => {
      if (voucher.id && !vouchersAsMap[voucher.id]) {
        vouchersAsMap[voucher.id] = voucher;
      }
    });

    const vouchers: Array<Voucher> = [];

    for (let id in vouchersAsMap) {
      vouchers.push(vouchersAsMap[id]);
    }

    return vouchers;
  }

  public async searchVouchersById(
    barId: string,
    query: string
  ): Promise<Array<Voucher>> {
    if (!barId) {
      throw new Error("barId is not defined");
    }
    if (query === undefined || query === null) {
      throw new Error("query is not defined");
    }
    if (query.length < this.SEARCH_BY_ID_MIN_QUERY_LENGTH) {
      return [];
    }

    const cleanQuery: string = query.trim();

    const ref = this.getVouchersRef(barId)
      .where(serviceHelper.documentId(), ">=", cleanQuery)
      .where(serviceHelper.documentId(), "<=", cleanQuery + "\uf8ff");

    const docs = await ref.get();

    const vouchers: Array<Voucher> = [];
    docs.forEach((doc) => {
      vouchers.push(voucherParser.parseDocToVoucher(barId, doc));
    });
    return vouchers;
  }

  public async searchVouchersByCustomerEmail(
    barId: string,
    query: string
  ): Promise<Array<Voucher>> {
    if (!barId) {
      throw new Error("barId is not defined");
    }
    if (query === undefined || query === null) {
      throw new Error("query is not defined");
    }
    if (query.length < this.SEARCH_BY_ID_MIN_QUERY_LENGTH) {
      return [];
    }

    const cleanQuery: string = query.trim();

    const ref = this.getVouchersRef(barId)
      .where("customer.email", ">=", cleanQuery)
      .where("customer.email", "<=", cleanQuery + "\uf8ff");

    const docs = await ref.get();

    const vouchers: Array<Voucher> = [];
    docs.forEach((doc) => {
      vouchers.push(voucherParser.parseDocToVoucher(barId, doc));
    });
    return vouchers;
  }

  public async getVoucherPin(voucher: Voucher) {
    if (!voucher.id) {
      throw new Error("error.voucher-id-is-not-defined");
    }

    const ref = this.getVoucherRef(voucher.barId, voucher.id)
      .collection("protected")
      .doc("verification");

    const doc = await ref.get();
    if (!doc.exists) {
      throw new Error("error.voucher-verification-does-not-exist");
    }

    const voucherVerification = doc.data();

    if (!voucherVerification || !voucherVerification.pin) {
      throw new Error("error.voucher-pin-is-not-defined");
    }

    return voucherVerification.pin;
  }

  public async updateVoucher(voucher: Voucher) {
    if (!voucher.barId) {
      throw new Error("error.bar-id-is-not-defined");
    }
    if (!voucher.id) {
      throw new Error("error.id-is-not-defined");
    }

    const ref = this.getVoucherRef(voucher.barId, voucher.id);
    await ref.update(voucher.updatedPropsToJSON());
  }

  public createVoucherPayment(data: any): VoucherPayment {
    return new VoucherPayment(data);
  }

  public onAllVoucherTemplates(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    return this.getVoucherTemplatesRef(barId).onSnapshot((docs) => {
      const voucherTemplates: Array<VoucherTemplate> = [];
      docs.forEach((doc) =>
        voucherTemplates.push(
          voucherParser.parseDocToVoucherTemplate(barId, doc)
        )
      );
      cb(voucherTemplates);
    });
  }

  public createVoucherTemplate(barId: string, data: any): VoucherTemplate {
    return new VoucherTemplate(barId, undefined, data);
  }

  public async addVoucherTemplate(
    voucherTemplate: VoucherTemplate
  ): Promise<VoucherTemplate> {
    if (voucherTemplate.id) {
      throw new Error("error.id-already-defined");
    }

    const ref = await this.getVoucherTemplatesRef(voucherTemplate.barId).add({
      ...voucherTemplate.allPropsToJSON(),
      timestamp: serviceHelper.serverTimestamp()
    });
    voucherTemplate.id = ref.id;
    return voucherTemplate;
  }

  public removeVoucherTemplate(voucherTemplate: VoucherTemplate) {
    if (!voucherTemplate.id) {
      throw new Error("error.id-is-not-defined");
    }

    return this.getVoucherTemplateRef(
      voucherTemplate.barId,
      voucherTemplate.id
    ).delete();
  }

  public async updateVoucherTemplate(voucherTemplate: VoucherTemplate) {
    if (!voucherTemplate.id) {
      throw new Error("error.id-is-not-defined");
    }

    const ref = this.getVoucherTemplateRef(
      voucherTemplate.barId,
      voucherTemplate.id
    );

    return this.fbStore.runTransaction((fTransaction) => {
      return fTransaction.get(ref).then((voucherTemplateDoc) => {
        if (!voucherTemplateDoc.exists) {
          throw new Error("error.voucher-template-does-not-exist");
        }

        fTransaction.update(ref, voucherTemplate.updatedPropsToJSON());
      });
    });
  }
}
