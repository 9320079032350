import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItem, ListItemText } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  noTopBottomPadding: {
    paddingTop: 0,
    paddingBottom: 0
  },
  itemAmountAndName: {
    fontSize: 18
  },
  itemPrice: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  priceCalculation: {
    width: "100%"
  },
  calculatedPrice: {
    width: "100%",
    fontSize: 16,
    textAlign: "right"
  }
});

class OrderItems extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, base, order, color, hidePrice, isCompact } = this.props;

    return (
      <div>
        {order.getItems(base).map((item) => (
          <ListItem
            key={item.id}
            className={isCompact ? classes.noTopBottomPadding : null}
          >
            <ListItemText
              primary={
                <span className={classes.itemAmountAndName} style={{ color }}>
                  <strong>{item.amount}</strong>
                  {` x `}
                  <strong>{item.name}</strong>
                </span>
              }
              secondary={
                !hidePrice && (
                  <span className={classes.itemPrice} style={{ color }}>
                    <span className={classes.priceCalculation}>
                      {`${item.amount} x ${item.getFormattedPrice()} ${
                        order.currency
                      }`}
                    </span>
                    <span className={classes.calculatedPrice}>
                      {`${item.getFormattedTotalPrice()} ${order.currency}`}
                    </span>
                  </span>
                )
              }
            />
          </ListItem>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(OrderItems));
