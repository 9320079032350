import React, { PureComponent } from "react";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "60px 10px"
  },
  icon: {
    fontSize: 60
  },
  title: {
    margin: "20px 0"
  },
  description: {
    margin: "10px 0"
  }
});

class ScannerMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, Icon, title, description } = this.props;

    return (
      <div className={classes.content}>
        <Icon className={classes.icon} />
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.description}>{description}</Typography>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ScannerMessage);
