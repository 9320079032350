import React, { PureComponent } from "react";

import withAuthorization from "./withAuthorization";
import VerifyingPin from "./VerifyingPin";
import SensitiveInformation from "./SensitiveInformation";
import ScannerDoesNotExist from "./ScannerDoesNotExist";

const withPinAuthentication = (authCondition) => (Component) => {
  class WithPinAuthentication extends PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        isVerifyingPin: false
      };
    }

    componentDidMount() {
      const { match, scanner, setScannerId } = this.props;

      if (!scanner && match && match.params && match.params.scannerId) {
        // Make sure params are properly decoded
        setScannerId(decodeURIComponent(match.params.scannerId));
      }
    }

    componentDidUpdate(prevProps, prevState) {
      const { auth, scanner, isAppLocked, unlockApp } = this.props;

      const isReady = auth && auth.user && scanner;

      if (isReady && isAppLocked && !this.alreadyAskedToUnlockApp) {
        this.alreadyAskedToUnlockApp = true;
        this.setState({ isVerifyingPin: true });

        unlockApp(auth.user)
          .then(() => {
            this.setState({ isVerifyingPin: false });
          })
          .catch((error) => {
            console.warn(error);
            this.setState({ isVerifyingPin: false });
          });
      }
    }

    render() {
      const { isAppLocked, scanner, unlockApp } = this.props;
      const { isVerifyingPin } = this.state;

      return (
        <div>
          {isVerifyingPin ? (
            <VerifyingPin />
          ) : scanner ? (
            isAppLocked ? (
              <SensitiveInformation unlockApp={unlockApp} />
            ) : (
              <Component {...this.props} />
            )
          ) : (
            <ScannerDoesNotExist />
          )}
        </div>
      );
    }
  }

  return withAuthorization()(WithPinAuthentication);
};

export default withPinAuthentication;
