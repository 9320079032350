import { NativeWrapper } from "./NativeWrapper";

const RELOAD_APP_HANDLER_METHOD_NAME = "reloadAppHandler";
const QR_CODE_SCANNER_HANDLER_METHOD_NAME = "QRCodeScannerHandler";
const SHOW_QR_CODE_SCANNER_HANDLER_METHOD_NAME = `show${QR_CODE_SCANNER_HANDLER_METHOD_NAME}`;
const HIDE_QR_CODE_SCANNER_HANDLER_METHOD_NAME = `hide${QR_CODE_SCANNER_HANDLER_METHOD_NAME}`;

export class IOSWrapper extends NativeWrapper<{
  reloadApp(): void;
  getVersion(): string;
  onQRCodeScanned(): () => {};
  showQRCodeScanner(): void;
  hideQRCodeScanner(): void;
}> {
  protected constructor() {
    super("iOS");
  }

  protected isAvailable(name: string): boolean {
    return !!(window as any).webkit.messageHandlers[name];
  }

  protected call(name: string, params: Record<string, any> = []) {
    return this.nativePromise((promiseId) => {
      (window as any).webkit.messageHandlers[name].postMessage({
        ...params,
        promiseId
      });
    });
  }

  protected subscribe(
    name: string,
    params: Record<string, any> = [],
    cb: (data: string) => void
  ): Promise<() => {}> {
    return this.nativeSubscription(
      async (callbackId) => {
        await this.call(`start${name}`, { ...params, callbackId });
      },
      async (callbackId) => {
        await this.call(`stop${name}`, { callbackId });
      },
      cb
    );
  }

  public getVersion(): string {
    return (window as any)?.nativeWrapperVersion || "";
  }

  public reloadApp() {
    this.call(RELOAD_APP_HANDLER_METHOD_NAME);
  }

  public onQRCodeScanned(cb: (data: string) => void) {
    return this.subscribe(QR_CODE_SCANNER_HANDLER_METHOD_NAME, undefined, cb);
  }

  public showQRCodeScanner() {
    return this.call(SHOW_QR_CODE_SCANNER_HANDLER_METHOD_NAME);
  }

  public hideQRCodeScanner() {
    return this.call(HIDE_QR_CODE_SCANNER_HANDLER_METHOD_NAME);
  }

  private static instance: IOSWrapper | undefined = undefined;

  static get() {
    if (
      (window as any).webkit?.messageHandlers?.nativeWrapperAvailableHandler !==
      undefined &&
      !this.instance
    ) {
      this.instance = new IOSWrapper();
      console.log("iOS interface init");
    }
    return this.instance;
  }
}
