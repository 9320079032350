import FulfilmentMethod from "./FulfilmentMethod";

class FulfilmentMethods {
  private readonly _isAsSoonAsPossibleActive: boolean;
  private readonly _isOnDemandActive: boolean;
  private readonly _isAutomaticActive: boolean;

  constructor(data: any) {
    this._isAsSoonAsPossibleActive = !!(
      data &&
      data[FulfilmentMethod.AS_SOON_AS_POSSIBLE] &&
      data[FulfilmentMethod.AS_SOON_AS_POSSIBLE].isActive === true
    );
    this._isOnDemandActive = !!(
      data &&
      data[FulfilmentMethod.ON_DEMAND] &&
      data[FulfilmentMethod.ON_DEMAND].isActive === true
    );
    this._isAutomaticActive = !!(
      data &&
      data[FulfilmentMethod.AUTOMATIC] &&
      data[FulfilmentMethod.AUTOMATIC].isActive === true
    );
  }

  public isFulfilmentMethodActive(fulfilmentMethod: FulfilmentMethod): boolean {
    switch (fulfilmentMethod) {
      case FulfilmentMethod.AS_SOON_AS_POSSIBLE:
        return this.isAsSoonAsPossibleActive;
        break;
      case FulfilmentMethod.ON_DEMAND:
        return this.isOnDemandActive;
        break;
      case FulfilmentMethod.AUTOMATIC:
        return this.isAutomaticActive;
        break;
      default:
        throw new Error("error.fulfilment-method-is-not-valid");
        break;
    }
  }

  public get isAsSoonAsPossibleActive(): boolean {
    return this._isAsSoonAsPossibleActive;
  }

  public get isOnDemandActive(): boolean {
    return this._isOnDemandActive;
  }

  public get isAutomaticActive(): boolean {
    return this._isAutomaticActive;
  }

  public get isAnyFulfilmentMethodActive(): boolean {
    return this.isAsSoonAsPossibleActive || this.isOnDemandActive || this.isAutomaticActive;
  }

  public get activeFulfilmentMethods(): FulfilmentMethod[] {
    const activeFulfilmentMethods: FulfilmentMethod[] = [];

    if (this.isAsSoonAsPossibleActive) {
      activeFulfilmentMethods.push(FulfilmentMethod.AS_SOON_AS_POSSIBLE);
    }
    if (this.isOnDemandActive) {
      activeFulfilmentMethods.push(FulfilmentMethod.ON_DEMAND);
    }
    if (this.isAutomaticActive) {
      activeFulfilmentMethods.push(FulfilmentMethod.AUTOMATIC);
    }

    return activeFulfilmentMethods;
  }

  public isEqualTo(fulfilmentMethods: FulfilmentMethods) {
    return (
      this.isAsSoonAsPossibleActive ===
      fulfilmentMethods.isAsSoonAsPossibleActive &&
      this.isOnDemandActive === fulfilmentMethods.isOnDemandActive &&
      this.isAutomaticActive === fulfilmentMethods.isAutomaticActive
    );
  }

  public toJSON() {
    return {
      [FulfilmentMethod.AS_SOON_AS_POSSIBLE]: {
        isActive: this.isAsSoonAsPossibleActive
      },
      [FulfilmentMethod.ON_DEMAND]: {
        isActive: this.isOnDemandActive
      },
      [FulfilmentMethod.AUTOMATIC]: {
        isActive: this.isAutomaticActive
      }
    };
  }

  public clone(): FulfilmentMethods {
    return new FulfilmentMethods(this.toJSON());
  }
}

export default FulfilmentMethods;
