import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  fulfilmentLabel: {
    display: "inline-block",
    padding: "2px 8px",
    borderRadius: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    backgroundColor: "black",
    color: "white"
  }
});

class OrderFulfilmentLabel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, base, order } = this.props;

    if (order.isFulfilmentMethodAsSoonAsPossible) {
      return null;
    }

    return (
      <div className={classes.fulfilmentLabel}>
        <span>{t(`fulfilment.${order.fulfilment.method}`)}</span>
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(OrderFulfilmentLabel)
);
