import Integration from "../models/integration/Integration";
import { integrationParser } from "../parsers";
import AbiIntegration from "../models/integration/AbiIntegration";
import IntegrationName from "../models/integration/IntegrationName";

export default class IntegrationService {
  protected readonly fbStore;

  constructor(fbStore) {
    this.fbStore = fbStore;
  }

  protected getIntegrationsRef(barId: string) {
    let integrationsRef = this.fbStore
      .collection("bars")
      .doc(barId)
      .collection("integrations");

    return integrationsRef;
  }

  protected getIntegrationRef(barId: string, integrationName: string) {
    return this.getIntegrationsRef(barId).doc(integrationName);
  }

  public async getIntegration(
    barId: string,
    integrationName: string
  ): Promise<Integration> {
    const doc = await this.getIntegrationRef(barId, integrationName).get();

    return integrationParser.parseDocToIntegration(barId, doc);
  }

  public async getIntegrations(barId: string): Promise<Array<Integration>> {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const docs = await this.getIntegrationsRef(barId).get();

    return docs.map((doc) =>
      integrationParser.parseDocToIntegration(barId, doc)
    );
  }

  public async getActiveIntegrations(
    barId: string
  ): Promise<Array<Integration>> {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const docs = await this.getIntegrationsRef(barId)
      .where("isActive", "==", true)
      .get();

    return docs.map((doc) =>
      integrationParser.parseDocToIntegration(barId, doc)
    );
  }

  public onAllIntegrations(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.getIntegrationsRef(barId).onSnapshot(
      (docs) => {
        const integrations: Array<Integration> = [];
        docs.forEach((doc) =>
          integrations.push(integrationParser.parseDocToIntegration(barId, doc))
        );
        cb(integrations);
      },
      (error) => {
        console.log("error", error);
        cb([]);
      }
    );
  }

  public createAbiIntegration(barId: string, data: any): AbiIntegration {
    return new AbiIntegration(barId, data);
  }

  public async setIntegration(integration: Integration) {
    if (!integration.id) {
      throw new Error("error.integration-id-is-not-defined");
    }

    await this.getIntegrationRef(integration.barId, integration.name).set(
      integration.allPropsToJSON()
    );
  }

  public async updateIntegration(integration: Integration) {
    if (!integration.id) {
      throw new Error("error.integration-id-is-not-defined");
    }

    await this.getIntegrationRef(integration.barId, integration.name).update(
      integration.updatedPropsToJSON()
    );
  }

  public async removeIntegration(integration: Integration) {
    if (!integration.id) {
      throw new Error("error.integration-id-is-not-defined");
    }

    await this.getIntegrationRef(integration.barId, integration.name).delete();
  }

  public onAbiIntegrationSynced(barId: string): Promise<AbiIntegration> {
    return new Promise((resolve, reject) => {
      const unsubscribe = this.getIntegrationRef(
        barId,
        IntegrationName.ABI
      ).onSnapshot((doc) => {
        const abiIntegration = integrationParser.parseDocToAbiIntegration(
          barId,
          doc
        );

        if (!abiIntegration?.canEventsBeFetched) {
          unsubscribe();
          reject(new Error("error.abi-integration-events-can-not-be-fetched"));
        } else if (!abiIntegration?.currentEvent?.id) {
          unsubscribe();
          reject(
            new Error("error.abi-integration-current-event-id-is-not-defined")
          );
        } else if (abiIntegration?.currentEvent?.syncedAt) {
          unsubscribe();
          resolve(abiIntegration);
        }
      });
    });
  }
}
