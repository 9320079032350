import boDevConfig from "./boDev";
import boStagConfig from "./boStag";
import boProdConfig from "./boProd";
import abiStagConfig from "./abiStag";
import abiProdConfig from "./abiProd";

let config = boDevConfig;

switch (process.env.REACT_APP_ENV) {
  case "boProd":
    config = boProdConfig;
    break;
  case "boStag":
    config = boStagConfig;
    break;
  case "boDev":
    config = boDevConfig;
    break;
  case "abiProd":
    config = abiProdConfig;
    break;
  case "abiStag":
    config = abiStagConfig;
    break;
}

switch (process.env.REACT_APP_NAME) {
  case "tap-io-app":
    config.analytics.trackingIds =
      config.analytics &&
      config.analytics.trackingIds &&
      config.analytics.trackingIds.app
        ? config.analytics.trackingIds.app
        : [];
    config.cookies.manager.domainId =
      config.cookies &&
      config.cookies.manager &&
      config.cookies.manager.domainIds &&
      config.cookies.manager.domainIds.app
        ? config.cookies.manager.domainIds.app
        : null;
    break;
  case "tap-io-order":
    config.analytics.trackingIds =
      config.analytics &&
      config.analytics.trackingIds &&
      config.analytics.trackingIds.order
        ? config.analytics.trackingIds.order
        : [];
    config.cookies.manager.domainId =
      config.cookies &&
      config.cookies.manager &&
      config.cookies.manager.domainIds &&
      config.cookies.manager.domainIds.order
        ? config.cookies.manager.domainIds.order
        : null;
    break;
  case "tap-io-scan":
    config.analytics.trackingIds =
      config.analytics &&
      config.analytics.trackingIds &&
      config.analytics.trackingIds.scan
        ? config.analytics.trackingIds.scan
        : [];
    config.cookies.manager.domainId =
      config.cookies &&
      config.cookies.manager &&
      config.cookies.manager.domainIds &&
      config.cookies.manager.domainIds.scan
        ? config.cookies.manager.domainIds.scan
        : null;
    break;
  default:
    config.analytics.trackingIds = [];
    config.cookiebot.domainGroupId = null;
    break;
}

export default config;
