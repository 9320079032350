enum DeliveryColor {
  BLACK = "black",
  GREEN = "green",
  RED = "red",
  YELLOW = "yellow",
  BLUE = "blue",
  ORANGE = "orange",
  BROWN = "brown",
  PURPLE = "purple",
  PINK = "pink",
  GREY = "grey"
}

export default DeliveryColor;
