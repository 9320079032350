import { utilsHelper } from "../../helpers";
import Element from "./Element";
import VatRate from "./VatRate";

class Item extends Element {
  private _isAvailable: boolean;
  private _description: string;
  private _price: number;
  private _productId: string | null;
  private _vatRate: VatRate | null;
  private _depositIds: string[];

  constructor(menuId: string, id: string, data: any) {
    super(menuId, id, "item", data);

    this._isAvailable = data.isAvailable;
    this._description = data.description || "";
    this._price = data.price;

    this._productId = data.productId ? data.productId : null;
    this._vatRate = data.vatRate || null;
    this._depositIds = data.depositIds || [];
  }

  public get description(): string {
    return this._description;
  }

  public get productId(): string | null {
    return this._productId;
  }

  public get vatRate(): VatRate | null {
    return this._vatRate;
  }

  public get isAvailable(): boolean {
    return this._isAvailable;
  }

  public get price() {
    return this._price;
  }

  public get depositIds() {
    return this._depositIds;
  }

  public getFormattedPrice() {
    if (this.price !== undefined) {
      return utilsHelper.formatToTwoDecimals(this.price);
    }

    return "";
  }

  public set description(description: string) {
    this._description = description;
  }

  public set productId(productId: string | null) {
    this._productId = productId ? productId : null;
  }

  public set vatRate(vatRate: VatRate | null) {
    this._vatRate = vatRate ? vatRate : null;
  }

  public set isAvailable(isAvailable: boolean) {
    this._isAvailable = isAvailable;
  }

  public set price(price: number) {
    if (isNaN(price)) {
      throw new Error("error.price-is-invalid");
    }
    if (price < 0) {
      throw new Error("error.price-must-be-equal-to-or-greater-than-zero");
    }

    this._price = price;
  }

  public set depositIds(depositIds: string[]) {
    this._depositIds = depositIds;
  }

  public get hasDeposits(): boolean {
    return this.depositIds.length > 0;
  }

  public toJSON(): any {
    return {
      ...super.toJSON(),
      isAvailable: this.isAvailable,
      description: this.description,
      price: this.price,
      productId: this.productId,
      vatRate: this.vatRate,
      depositIds: this.depositIds
    };
  }
}

export default Item;
