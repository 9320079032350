import BarPropsModel from "../model/BarPropsModel";
import { serviceHelper } from "../../helpers";

type Props = {
  isActive: boolean;
  name: string;
  purchaseValue: number;
  tradeInValue: number;
};

export default class Deposit extends BarPropsModel {
  private _isActive: boolean;
  private _name: string;
  private _purchaseValue: number;
  private _tradeInValue: number;

  constructor(barId: string, id: string | undefined, props: Props) {
    super(barId, id);

    const { isActive, name, purchaseValue, tradeInValue } = props;

    this._isActive = isActive;
    this._name = serviceHelper.parseString(name);
    this._purchaseValue = serviceHelper.parseNumber(purchaseValue);
    this._tradeInValue = serviceHelper.parseNumber(tradeInValue);

    this.setAllProps(["isActive", "name", "purchaseValue", "tradeInValue"]);
  }

  get isActive() {
    return this._isActive;
  }

  set isActive(isActive) {
    this._isActive = isActive;
    this.setPropUpdated("isActive");
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
    this.setPropUpdated("name");
  }

  get purchaseValue() {
    return this._purchaseValue;
  }

  get tradeInValue() {
    return this._tradeInValue;
  }

  set purchaseValue(purchaseValue) {
    this._purchaseValue = purchaseValue;
    this.setPropUpdated("purchaseValue");
  }

  set tradeInValue(tradeInValue) {
    this._tradeInValue = tradeInValue;
    this.setPropUpdated("tradeInValue");
  }
}
