import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import withStyles from "@mui/styles/withStyles";

import Order from "./Order";
import ConfirmDeferredPaymentButton from "./ConfirmDeferredPaymentButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  dialog: {
    minWidth: 300
  },
  buttons: {
    flexDirection: "column",
    "&>:not(:first-child)": {
      marginTop: 8,
      marginLeft: 0
    }
  }
};

class OrderDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false
    };
  }

  handleConfirmOrderDeferredPaymentUpdate = (
    isOrderDeferredPaymentConfirmed
  ) => {
    const { onClose } = this.props;

    if (isOrderDeferredPaymentConfirmed) {
      onClose();
    }
  };

  handleClose = (event) => {
    const { onClose } = this.props;

    event.stopPropagation();

    onClose();
  };

  render() {
    const {
      classes,
      t,
      auth,
      isOpen,
      showFullScreen,
      bar,
      base,
      order,
      onPrintReceipt
    } = this.props;
    const { isDisabled } = this.state;

    return (
      <div>
        <Dialog
          onClose={this.handleClose}
          classes={{ paper: classes.dialog }}
          open={isOpen}
          fullScreen={showFullScreen}
          TransitionComponent={Transition}
        >
          <DialogContent>
            {order && (
              <Order
                bar={bar}
                base={base}
                order={order}
                showOrderContent={true}
              />
            )}
          </DialogContent>
          <DialogActions className={classes.buttons}>
            {onPrintReceipt && (
              <Button
                disabled={isDisabled}
                onClick={onPrintReceipt}
                color="primary"
                variant="contained"
                fullWidth
                startIcon={<PrintIcon />}
              >
                {t("printer.print-receipt")}
              </Button>
            )}
            <ConfirmDeferredPaymentButton
              auth={auth}
              bar={bar}
              order={order}
              onUpdate={this.handleConfirmOrderDeferredPaymentUpdate}
              fullWidth={true}
              size="large"
            />
            <Button
              disabled={isDisabled}
              onClick={this.handleClose}
              variant="contained"
              fullWidth
            >
              {t("label.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(OrderDialog));
