import React, { PureComponent } from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import Moment from "moment";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "typeface-roboto";

import { CssBaseline } from "@mui/material";

import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider
} from "@mui/material/styles";

import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { Helmet } from "react-helmet";

import { ToastContainer } from "react-toastify";

import * as routes from "./constants/routes";

import appTheme from "tap-io/client/theme";

import withAnalytics from "tap-io/client/components/hoc/withAnalytics";
import withBugsnag from "tap-io/client/components/hoc/withBugsnag";
import withTranslations from "tap-io/client/components/hoc/withTranslations";
import { IOSWrapper } from "tap-io/client/native/IOSWrapper";

import withAuthentication from "./components/auth/withAuthentication";
import { authService } from "tap-io/client/services";
import CheckConnection from "tap-io/client/components/utils/CheckConnection";

import BarWrapper from "./components/bar/BarWrapper";
import ScanQRCodePage from "./pages/ScanQRCodePage";

const DEFAULT_COLOR_PRIMARY = "#34b9c8";
const DEFAULT_COLOR_SECONDARY = "#faeb52";

class App extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.state = {
      theme: this.createTheme(DEFAULT_COLOR_PRIMARY, DEFAULT_COLOR_SECONDARY),
      locale: "nl_BE"
    };
  }

  componentDidMount() {
    authService.onAuthStateChanged((user) => {
      this.setState({ user });

      if (user === null) {
        authService
          .signInAnonymously()
          .then(() => {})
          .catch((error) => {
            console.warn(error);
          });
      }
    });
  }

  createTheme = (primaryColor, secondaryColor) => {
    return createTheme(appTheme.options(primaryColor, secondaryColor));
  };

  setThemeColors = (primaryColor, secondaryColor) => {
    this.setState({
      theme: this.createTheme(primaryColor, secondaryColor)
    });
  };

  setLocale = (locale) => {
    const { changeLanguage } = this.props;

    Moment.locale(locale);
    changeLanguage(locale);
    this.setState({ locale });
  };

  render() {
    const { theme, locale } = this.state;

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment} locale={locale}>
            <div className="App">
              {appTheme.faviconFolder && (
                <Helmet>
                  <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${appTheme.faviconFolder}/apple-touch-icon.png`}
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href={`${appTheme.faviconFolder}/favicon-32x32.png`}
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={`${appTheme.faviconFolder}/favicon-16x16.png`}
                  />
                  <link rel="manifest" href="/favicon-abi/site.webmanifest" />
                  <link
                    rel="mask-icon"
                    href={`${appTheme.faviconFolder}/safari-pinned-tab.svg`}
                    color="#000000"
                  />
                  <link
                    rel="shortcut icon"
                    href={`${appTheme.faviconFolder}/favicon.ico`}
                  />
                  <meta name="msapplication-TileColor" content="#ffffff" />
                  <meta
                    name="msapplication-config"
                    content={`${appTheme.faviconFolder}/browserconfig.xml`}
                  />
                  <meta name="theme-color" content="#ffffff" />
                </Helmet>
              )}
              <CssBaseline />
              <ToastContainer
                className={
                  IOSWrapper.get() ? "App-toast-container-ios" : undefined
                }
              />
              <CheckConnection />
              <Switch>
                <Route
                  exact
                  path={routes.SCAN_QR_CODE}
                  render={(params) => (
                    <ScanQRCodePage {...params} {...this.props} />
                  )}
                />
                <Route
                  path={routes.SCAN_ROOT}
                  render={(params) => (
                    <BarWrapper
                      setThemeColors={this.setThemeColors}
                      setLocale={this.setLocale}
                    />
                  )}
                />
              </Switch>
            </div>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const AppWithoutRouter = withRouter(
  withTranslations(withBugsnag(withAnalytics(withAuthentication(App))))
);

const AppWithRouter = () => (
  <BrowserRouter>
    <AppWithoutRouter />
  </BrowserRouter>
);

export default AppWithRouter;
