import boTheme from "./boTheme";
import abiTheme from "./abiTheme";

let theme = boTheme;

switch (process.env.REACT_APP_THEME) {
  case "bo":
    theme = boTheme;
    break;
  case "abi":
    theme = abiTheme;
    break;
}

export default theme;
