import PriorityNumber from "../priority/PriorityNumber";

class OrderPriority {
  private readonly _number: PriorityNumber;

  constructor(props: any) {
    this._number = this.parseNumber(props ? props.number : null);
  }

  private parseNumber(number: number): PriorityNumber {
    switch (number) {
      case PriorityNumber.HIGH:
        return PriorityNumber.HIGH;
      // Fallback for orders without priorityNumber
      //case PriorityNumber.MEDIUM:
      //  return PriorityNumber.MEDIUM;
      default:
        return PriorityNumber.MEDIUM;
    }
  }

  public get number(): PriorityNumber {
    return this._number;
  }

  public toJSON(): any {
    return {
      number: this.number
    };
  }
}

export default OrderPriority;
