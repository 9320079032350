import BarPropsModel from "../model/BarPropsModel";
import { serviceHelper } from "../../helpers";
import DeliveryColors from "../delivery/DeliveryColors";
import ServiceOptions from "../service/ServiceOptions";
import PickupServiceOption from "../service/PickupServiceOption";
import DeliveryServiceOption from "../service/DeliveryServiceOption";
import ShowAndGoServiceOption from "../service/ShowAndGoServiceOption";
import BaseSequenceNumberGenerator from "./BaseSequenceNumberGenerator";
import BasePreparationOptions from "./BasePreparationOptions";
import DeliveryMethod from "../delivery/DeliveryMethod";

class Base extends BarPropsModel {
  private _name: string;
  private _menuIds: Array<string>;
  private _isActive: boolean;
  private _deliveryColors: DeliveryColors;
  private _serviceOptions: ServiceOptions;
  private _preparationOptions: BasePreparationOptions;
  private readonly _sequenceNumberGenerator: BaseSequenceNumberGenerator;
  private readonly _externalId: string | null;
  private readonly _timestamp: Date | undefined;

  constructor(barId: string, id?: string, props?: any) {
    super(barId, id);

    if (!props) {
      throw new Error("props is not defined");
    }

    this._name = serviceHelper.parseString(props.name);
    this._menuIds = this.parseMenuIds(props.menuIds);
    this._isActive =
      props.isActive === undefined
        ? true
        : serviceHelper.parseBoolean(props.isActive);
    this._deliveryColors = new DeliveryColors(props.deliveryColors);

    // Legacy useScanners
    if (props.serviceOptions) {
      this._serviceOptions = new ServiceOptions(props.serviceOptions);
    } else {
      const serviceOptionsAsArray = [
        new PickupServiceOption().toJSON(),
        new DeliveryServiceOption().toJSON()
      ];

      if (props.useScanners === true) {
        serviceOptionsAsArray.push(new ShowAndGoServiceOption().toJSON());
      }

      this._serviceOptions = new ServiceOptions(serviceOptionsAsArray);
    }

    this._externalId = props.externalId || null;

    this._sequenceNumberGenerator = new BaseSequenceNumberGenerator(
      props.sequenceNumberGenerator
    );

    this._preparationOptions = new BasePreparationOptions(
      props.preparationOptions
    );

    if (props.timestamp) {
      this._timestamp = serviceHelper.parseTimestamp(props.timestamp);
    }

    super.setAllProps([
      "name",
      "isActive",
      "menuIds",
      "deliveryColors",
      "serviceOptions",
      "externalId",
      //"sequenceNumberGenerator",
      "preparationOptions",
      "timestamp"
    ]);
  }

  private parseMenuIds(menuIds: Array<string>): Array<string> {
    if (!menuIds) {
      throw new Error("error.menuIds-is-not-defined");
    }
    /*if (menuIds.length !== 1) {
      throw new Error("error.menuIds-length-not-equal-to-one");
    }*/

    return menuIds;
  }

  public get name(): string {
    return this._name;
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public get menuIds() {
    return this._menuIds;
  }

  public set menuIds(menuIds) {
    this._menuIds = menuIds;
  }

  public get canUseScanner(): boolean {
    return this.serviceOptions.deliveryMethods.isDeliveryMethodActive(DeliveryMethod.PICKUP);
  }

  public get deliveryColors(): DeliveryColors {
    return this._deliveryColors;
  }

  public get serviceOptions(): ServiceOptions {
    return this._serviceOptions;
  }

  public get externalId(): string | null {
    return this._externalId;
  }

  public get isEditable(): boolean {
    return !this.externalId;
  }

  public get sequenceNumberGenerator(): BaseSequenceNumberGenerator {
    return this._sequenceNumberGenerator;
  }

  public get preparationOptions(): BasePreparationOptions {
    return this._preparationOptions;
  }

  public get timestamp(): Date | undefined {
    return this._timestamp;
  }

  public set name(name: string) {
    this._name = serviceHelper.parseString(name);
    super.setPropUpdated("name");
  }

  public set isActive(isActive: boolean) {
    this._isActive = serviceHelper.parseBoolean(isActive);
    super.setPropUpdated("isActive");
  }

  public set deliveryColors(deliveryColors: DeliveryColors) {
    this._deliveryColors = deliveryColors;
    super.setPropUpdated("deliveryColors");
  }

  public set serviceOptions(serviceOptions: ServiceOptions) {
    this._serviceOptions = serviceOptions;
    super.setPropUpdated("serviceOptions");
  }

  public set preparationOptions(preparationOptions: BasePreparationOptions) {
    this._preparationOptions = preparationOptions;
    super.setPropUpdated("preparationOptions");
  }

  public hasMenuId(menuId: string): boolean {
    return this._menuIds.indexOf(menuId) >= 0;
  }

  public addMenuId(menuIdToAdd: string) {
    if (!this.hasMenuId(menuIdToAdd)) {
      this._menuIds.push(menuIdToAdd);
      super.setPropUpdated("menuIds");
    }
  }

  public removeMenuId(menuIdToRemove: string) {
    if (this.hasMenuId(menuIdToRemove)) {
      this._menuIds = this._menuIds.filter(
        (menuId) => menuId !== menuIdToRemove
      );
      super.setPropUpdated("menuIds");
    }
  }
}

export default Base;
