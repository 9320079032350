class VoucherLogEntry {
  private readonly _id: string;
  private readonly _userId: string;
  private readonly _timestamp: Date;
  private readonly _orderId: string | undefined;

  protected readonly _previousVoucherValue: any;
  protected readonly _newVoucherValue: any;
  protected readonly _valuePaidWithVoucher: any;

  constructor(id: string, props: any) {
    this._id = id;

    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    this._userId = props.userId;
    this._previousVoucherValue = props.previousVoucherValue;
    this._newVoucherValue = props.newVoucherValue;
    this._valuePaidWithVoucher = props.valuePaidWithVoucher;
    this._timestamp = props.timestamp;

    if (props.data) {
      switch (props.data.type) {
        case "order":
          if (!props.data.orderId) {
            throw new Error("error.props-order-id-is-not-defined-while-props-type-is-order");
          }
          this._orderId = props.data.orderId;
          break;
        default:
          throw new Error("error.props-type-is-not-valid");
          break;
      }
    }
  }

  public get id(): string {
    return this._id;
  }

  public get userId(): string {
    return this._userId;
  }

  public get timestamp(): Date {
    return this._timestamp;
  }

  public get orderId(): string | undefined {
    return this._orderId;
  }

  public get previousVoucherValue(): any {
    throw new Error("error.not-implemented");
  }

  public get newVoucherValue(): any {
    throw new Error("error.not-implemented");
  }

  public get valuePaidWithVoucher(): any {
    throw new Error("error.not-implemented");
  }

  public get usedVoucherValue(): any {
    throw new Error("error.not-implemented");
  }

  public get voucherValueDifference(): any {
    throw new Error("error.not-implemented");
  }

  public previousVoucherValueToString(translator: Function): string {
    throw new Error("error.not-implemented");
  }

  public newVoucherValueToString(translator: Function): string {
    throw new Error("error.not-implemented");
  }

  public valuePaidWithVoucherToString(translator: Function): string {
    throw new Error("error.not-implemented");
  }

  public voucherValueDifferenceToString(translator: Function): string {
    throw new Error("error.not-implemented");
  }
}

export default VoucherLogEntry;