import ServiceOption from "./ServiceOption";
import ServiceOptionName from "./ServiceOptionName";
import FulfilmentMethod from "../fulfilment/FulfilmentMethod";
import DeliveryMethod from "../delivery/DeliveryMethod";
import PriorityNumber from "../priority/PriorityNumber";

export default class SelfServiceServiceOption extends ServiceOption {
  constructor() {
    super(
      ServiceOptionName.SELF_SERVICE,
      FulfilmentMethod.AUTOMATIC,
      DeliveryMethod.PICKUP,
      PriorityNumber.MEDIUM
    );
  }
}
