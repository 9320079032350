import Menu from "../models/menu/Menu";
import Element from "../models/menu/Element";
import Category from "../models/menu/Category";
import Item from "../models/menu/Item";

export default class MenuHelper {
  filterActiveMenus(menus: Array<Menu>): Array<Menu> {
    return menus.filter((menu) => menu.isActive);
  }

  isCategoryMenuElement(element: Element): boolean {
    return element instanceof Category;
  }

  isItemMenuElement(element: Element): boolean {
    return element instanceof Item;
  }
}
