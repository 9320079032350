import FulfilmentMethod from "../fulfilment/FulfilmentMethod";

class OrderFulfilment {
  private readonly _method: FulfilmentMethod;
  private readonly _bases: {
    [baseId: string]: {
      menuIds: string[];
      sequenceNumber: number | null;
    };
  };

  constructor(props: any) {
    this._method = this.parseMethod(props ? props.method : null);

    this._bases = {};
    if (props && props.bases) {
      for (let baseId in props.bases) {
        this._bases[baseId] = {
          menuIds: props.bases[baseId].menuIds || [],
          sequenceNumber: props.bases[baseId].sequenceNumber || null
        };
      }
    }
  }

  private parseMethod(method: string): FulfilmentMethod {
    switch (method) {
      case FulfilmentMethod.ON_DEMAND:
        return FulfilmentMethod.ON_DEMAND;
      case FulfilmentMethod.AUTOMATIC:
        return FulfilmentMethod.AUTOMATIC;
      // Fallback for orders without fulfilmentMethod
      //case FulfilmentMethod.AS_SOON_AS_POSSIBLE:
      //  return FulfilmentMethod.AS_SOON_AS_POSSIBLE;
      default:
        return FulfilmentMethod.AS_SOON_AS_POSSIBLE;
    }
  }

  public get method(): FulfilmentMethod {
    return this._method;
  }

  private get bases(): {
    [baseId: string]: {
      menuIds: string[] | null;
      sequenceNumber: number | null;
    };
  } {
    return this._bases;
  }

  public getBaseIds(menuId: string): string[] {
    const baseIds: string[] = [];

    for (let baseId in this.bases) {
      if (this.getMenuIds(baseId).indexOf(menuId) >= 0) {
        baseIds.push(baseId);
      }
    }

    return baseIds;
  }

  public getMenuIds(baseId: string): string[] {
    return this.bases[baseId]?.menuIds || [];
  }

  public hasSequenceNumber(baseId: string): boolean {
    return !!this.getSequenceNumber(baseId);
  }

  public getSequenceNumber(baseId: string): number | null {
    return this.bases[baseId] ? this.bases[baseId].sequenceNumber : null;
  }

  public setSequenceNumber(baseId: string, sequenceNumber: number) {
    if (this.bases[baseId]) {
      if (this.bases[baseId].sequenceNumber !== null) {
        throw new Error(
          "error.fulfilment-base-sequence-number-is-already-defined"
        );
      }

      this.bases[baseId].sequenceNumber = sequenceNumber;
    }
  }

  public toJSON(): any {
    return {
      method: this.method,
      bases: this.bases
    };
  }
}

export default OrderFulfilment;
