import React, { PropsWithChildren } from "react";

import { useTranslation } from "react-i18next";

import {
  ListItem,
  ListItemText,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import Bar from "tap-io/models/bar/Bar";
import Deposit from "tap-io/models/deposit/Deposit";
import SelectAmount from "tap-io/client/components/common/SelectAmount";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      width: "auto",
      margin: 0,
      padding: theme.spacing(0.5, 2)
    },
    name: {
      flexGrow: 0
    },
    controls: {
      display: "flex",
      flexGrow: 1,
      marginLeft: 10
    },
    spacing: {
      flexGrow: 1
    },
    primaryText: {
      fontSize: 18
    }
  })
);

type SelectDepositProps = {
  bar: Bar;
  deposit: Deposit;
  roundAmount?: number;
  amount: number;
  isDisabled: boolean;
  onAmountChange: (amount: number) => void;
};

function DepositItem({
  bar,
  deposit,
  roundAmount,
  amount,
  isDisabled,
  onAmountChange
}: PropsWithChildren<SelectDepositProps>) {
  const { t, i18n } = useTranslation("common");
  const classes = useStyles();

  return (
    <div>
      <ListItem className={classes.item}>
        <ListItemText
          className={classes.name}
          classes={{ primary: classes.primaryText }}
          primary={deposit.name}
        />
        <div className={classes.controls}>
          <div className={classes.spacing} />
          {deposit.isActive ? (
            <SelectAmount
              isDisabled={isDisabled}
              hideZero={true}
              amount={amount}
              roundAmount={roundAmount}
              onChange={onAmountChange}
            />
          ) : (
            <Typography variant="overline">
              {t("label.not-available")}
            </Typography>
          )}
        </div>
      </ListItem>
    </div>
  );
}

export default DepositItem;
