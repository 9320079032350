import React, { PureComponent } from "react";
import classNames from "classnames";

import { Toolbar } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { IOSWrapper } from "tap-io/client/native/IOSWrapper";

const styles = (theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    flexShrink: 0
  },
  toolBar: {
    padding: 0
  }
});

class BarHeaderSpacing extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, children } = this.props;

    return (
      <div
        className={classNames(classes.header, {
          "App-header-ios": IOSWrapper.get()
        })}
      >
        <Toolbar className={classes.toolBar}>{children}</Toolbar>
      </div>
    );
  }
}

export default withStyles(styles)(BarHeaderSpacing);
