import BarPropsModel from "../model/BarPropsModel";
import Element from "./Element";
import Category from "./Category";
import Item from "./Item";
import { menuHelper, serviceHelper } from "../../helpers";

class Menu extends BarPropsModel {
  private _name: string;
  private _isActive: boolean;
  private _elements: Array<Element>;
  private _externalId: string | null;
  private readonly _timestamp: Date | undefined;

  constructor(barId: string, id?: string, props?: any) {
    super(barId, id);

    if (!props) {
      throw new Error("props is not defined");
    }

    this._name = props.name ? props.name : "";
    this._isActive =
      props.isActive === undefined
        ? true
        : serviceHelper.parseBoolean(props.isActive);
    this._elements =
      props.elements === undefined ? [] : this.parseElements(props.elements);
    this._externalId = props.externalId ? props.externalId : null;

    if (props.timestamp) {
      this._timestamp = serviceHelper.parseTimestamp(props.timestamp);
    }

    super.setAllProps([
      "name",
      "isActive",
      "elements",
      "baseId",
      "externalId",
      "timestamp"
    ]);
  }

  private parseElements(elements: Array<Element>): Array<Element> {
    if (!elements) {
      throw new Error("error.elements-is-not-defined");
    }

    return elements;
  }

  public get name(): string {
    return this._name;
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public get elements(): Array<Element> {
    return this._elements;
  }

  public get categories(): Array<Category> {
    return <Array<Category>>(
      this.elements.filter((element: Element) =>
        menuHelper.isCategoryMenuElement(element)
      )
    );
  }

  public get items(): Array<Item> {
    return <Array<Item>>(
      this.elements.filter((element: Element) =>
        menuHelper.isItemMenuElement(element)
      )
    );
  }

  public get externalId(): string | null {
    return this._externalId;
  }

  public get isEditable(): boolean {
    return !this.externalId;
  }

  public get timestamp(): Date | undefined {
    return this._timestamp;
  }

  public set name(name: string) {
    this._name = serviceHelper.parseString(name);
    super.setPropUpdated("name");
  }

  public set isActive(isActive: boolean) {
    this._isActive = serviceHelper.parseBoolean(isActive);
    super.setPropUpdated("isActive");
  }

  public set elements(elements: Array<Element>) {
    this._elements = this.parseElements(elements);
    super.setPropUpdated("elements");
  }

  public set externalId(externalId: string | null) {
    this._externalId = externalId ? externalId : null;
    super.setPropUpdated("externalId");
  }
}

export default Menu;
