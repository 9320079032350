import DeliveryMethod from "../models/delivery/DeliveryMethod";
import DeliveryMethods from "../models/delivery/DeliveryMethods";
import FulfilmentMethod from "../models/fulfilment/FulfilmentMethod";
import DeliveryServiceOption from "../models/service/DeliveryServiceOption";
import ExpressDeliveryServiceOption from "../models/service/ExpressDeliveryServiceOption";
import ExpressPickupServiceOption from "../models/service/ExpressPickupServiceOption";
import PickupServiceOption from "../models/service/PickupServiceOption";
import ServiceOption from "../models/service/ServiceOption";
import ServiceOptionName from "../models/service/ServiceOptionName";
import ServiceOptionProps from "../models/service/ServiceOptionProps";
import ServiceOptions from "../models/service/ServiceOptions";
import ShowAndGoServiceOption from "../models/service/ShowAndGoServiceOption";
import PriorityNumber from "../models/priority/PriorityNumber";
import SelfServiceServiceOption from "../models/service/SelfServiceServiceOption";
import Base from "../models/base/Base";
import Bar from "../models/bar/Bar";
import { serviceOptionHelper } from "./index";

export default class ServiceOptionHelper {
  public parseDataToServiceOption(data: ServiceOptionProps): ServiceOption {
    const { name } = data;

    return this.createServiceOption(name);
  }

  public createDefaultServiceOption(
    deliveryMethod: DeliveryMethod,
    fulfilmentMethod: FulfilmentMethod = FulfilmentMethod.AS_SOON_AS_POSSIBLE,
    priorityNumber: PriorityNumber = PriorityNumber.MEDIUM
  ): ServiceOption {
    switch (deliveryMethod) {
      case DeliveryMethod.SERVE:
        switch (fulfilmentMethod) {
          case FulfilmentMethod.AS_SOON_AS_POSSIBLE:
            switch (priorityNumber) {
              case PriorityNumber.MEDIUM:
                return this.createServiceOption(ServiceOptionName.DELIVERY);
              case PriorityNumber.HIGH:
                return this.createServiceOption(
                  ServiceOptionName.EXPRESS_DELIVERY
                );
            }
        }
        break;
      case DeliveryMethod.PICKUP:
        switch (fulfilmentMethod) {
          case FulfilmentMethod.AS_SOON_AS_POSSIBLE:
            switch (priorityNumber) {
              case PriorityNumber.MEDIUM:
                return this.createServiceOption(ServiceOptionName.PICKUP);
              case PriorityNumber.HIGH:
                return this.createServiceOption(
                  ServiceOptionName.EXPRESS_PICKUP
                );
            }
          case FulfilmentMethod.ON_DEMAND:
            return this.createServiceOption(ServiceOptionName.SHOW_AND_GO);
          case FulfilmentMethod.AUTOMATIC:
            return this.createServiceOption(ServiceOptionName.SELF_SERVICE);
        }
    }

    throw new Error("error.delivery-or-fulfilment-method-not-valid");
  }

  public createServiceOption(
    serviceOptionName: ServiceOptionName
  ): ServiceOption {
    switch (serviceOptionName) {
      case ServiceOptionName.EXPRESS_PICKUP:
        return new ExpressPickupServiceOption();
      case ServiceOptionName.EXPRESS_DELIVERY:
        return new ExpressDeliveryServiceOption();
      case ServiceOptionName.PICKUP:
        return new PickupServiceOption();
      case ServiceOptionName.DELIVERY:
        return new DeliveryServiceOption();
      case ServiceOptionName.SHOW_AND_GO:
        return new ShowAndGoServiceOption();
      case ServiceOptionName.SELF_SERVICE:
        return new SelfServiceServiceOption();
      default:
        throw new Error("error.service-option-name-is-not-valid");
    }
  }

  public createServiceOptions(
    serviceOptions: ServiceOption[] = []
  ): ServiceOptions {
    return new ServiceOptions(
      serviceOptions.map((serviceOption) => serviceOption.toJSON())
    );
  }

  public createServiceOptionsByName(
    serviceOptionNames: ServiceOptionName[]
  ): ServiceOptions {
    return this.createServiceOptions(
      serviceOptionNames.map((serviceOptionName) =>
        this.createServiceOption(serviceOptionName)
      )
    );
  }

  public createAllServiceOptions(): ServiceOptions {
    return this.createServiceOptions([
      this.createServiceOption(ServiceOptionName.EXPRESS_PICKUP),
      this.createServiceOption(ServiceOptionName.EXPRESS_DELIVERY),
      this.createServiceOption(ServiceOptionName.PICKUP),
      this.createServiceOption(ServiceOptionName.DELIVERY),
      this.createServiceOption(ServiceOptionName.SHOW_AND_GO),
      this.createServiceOption(ServiceOptionName.SELF_SERVICE)
    ]);
  }

  public createDefaultServiceOptions(
    deliveryMethods?: DeliveryMethods
  ): ServiceOptions {
    const serviceOptionsAsArray: ServiceOption[] = [];

    if (!deliveryMethods || deliveryMethods.isServeActive) {
      serviceOptionsAsArray.push(
        this.createServiceOption(ServiceOptionName.DELIVERY)
      );
    }
    if (!deliveryMethods || deliveryMethods.isPickupActive) {
      serviceOptionsAsArray.push(
        this.createServiceOption(ServiceOptionName.PICKUP)
      );
    }

    return this.createServiceOptions(serviceOptionsAsArray);
  }

  public createInvertedServiceOptions(
    serviceOptions: ServiceOptions
  ): ServiceOptions {
    const invertedServiceOptions = this.createAllServiceOptions();

    serviceOptions.forEach((serviceOption) => {
      invertedServiceOptions.remove(serviceOption);
    });

    return invertedServiceOptions;
  }

  public getServiceOptionLabel(
    serviceOption: ServiceOption,
    translator: (key: string) => string
  ): string {
    return translator(`service.${serviceOption.name}`);
  }

  public getServiceOptionsForDeliveryMethod(
    deliveryMethod: DeliveryMethod
  ): ServiceOptions {
    return this.createAllServiceOptions().filter(
      (serviceOption) => serviceOption.deliveryMethod === deliveryMethod
    );
  }

  public getAvailableServiceOptions = (
    bar: Bar,
    bases: Base[],
    nodeServiceOptions?: ServiceOptions
  ) => {
    if (bar.isUsingBases()) {
      const availableServiceOptions =
        serviceOptionHelper.createServiceOptions();

      if (bases) {
        bases.forEach((base) => {
          base.serviceOptions.forEach((serviceOption: ServiceOption) => {
            if (
              nodeServiceOptions
                ? nodeServiceOptions.has(serviceOption.name)
                : bar.deliveryMethods.isDeliveryMethodActive(
                    serviceOption.deliveryMethod
                  )
            ) {
              availableServiceOptions.add(serviceOption);
            }
          });
        });
      }

      return availableServiceOptions;
    } else {
      return this.createDefaultServiceOptions(bar.deliveryMethods);
    }
  };
}
