import ServiceOption from "./ServiceOption";
import ServiceOptionName from "./ServiceOptionName";
import FulfilmentMethod from "../fulfilment/FulfilmentMethod";
import DeliveryMethod from "../delivery/DeliveryMethod";
import PriorityNumber from "../priority/PriorityNumber";

export default class ExpressDeliveryServiceOption extends ServiceOption {
  constructor() {
    super(
      ServiceOptionName.EXPRESS_DELIVERY,
      FulfilmentMethod.AS_SOON_AS_POSSIBLE,
      DeliveryMethod.SERVE,
      PriorityNumber.HIGH
    );
  }
}
