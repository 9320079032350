import React, { PureComponent } from "react";

import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { Typography, IconButton } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import withStyles from "@mui/styles/withStyles";

import OrderFieldsDialog from "./OrderFieldsDialog";

const styles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "center"
  }
});

class Order extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOrderFieldsDialogOpen: false
    };
  }

  handleShowCustomerFields = (event) => {
    event.stopPropagation();

    this.setState({ isOrderFieldsDialogOpen: true });
  };

  handleShowCustomerFieldsDialogClose = () => {
    this.setState({
      isOrderFieldsDialogOpen: false
    });
  };

  render() {
    const { classes, t, order, className, variant, showInfoButton, isDisabled } = this.props;
    const { isOrderFieldsDialogOpen } = this.state;

    return (
      <>
        <OrderFieldsDialog
          isOpen={isOrderFieldsDialogOpen}
          orderFields={order.fields}
          onClose={this.handleShowCustomerFieldsDialogClose}
        />
        <span className={classNames(classes.container, className)}>
          <Typography variant={variant}>{order.name}</Typography>
          {showInfoButton && order.fields && (
            <IconButton
              edge="end"
              disabled={isDisabled}
              onClick={this.handleShowCustomerFields}
              size="large"
            >
              <PersonIcon />
            </IconButton>
          )}
        </span>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(Order));
