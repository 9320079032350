import Moment from "moment";

import { serviceHelper } from "../../helpers";
import BarPropsModel from "../model/BarPropsModel";
import Order from "../order/Order";
import VoucherCustomer from "./VoucherCustomer";
import VoucherPayment from "./VoucherPayment";

class Voucher extends BarPropsModel {
  private readonly _type: string;
  private readonly _validUntil: Date;
  protected readonly _originalValue: any;
  private readonly _mustBeRedeemedInOneGo: boolean;
  private _isActive: boolean;
  protected _remainingValue: any;
  private _label: string | null;
  private readonly _customer: VoucherCustomer | null;
  private _payment: VoucherPayment | null;
  private readonly _templateId: string | undefined;

  private readonly _timestamp: Date | undefined;
  private _pin: string | null;

  constructor(barId: string, type: string, id?: string, props?: any) {
    super(barId, id);

    if (!type) {
      throw new Error("error.type-is-not-defined");
    }
    if (!props) {
      throw new Error("error.props-is-not-defined");
    }
    if (props.originalValue === undefined) {
      throw new Error("error.props-original-value-is-not-defined");
    }
    if (props.remainingValue === undefined) {
      throw new Error("error.props-remaining-value-is-not-defined");
    }

    this._type = type;
    this._isActive = props.isActive === true;
    this._validUntil = serviceHelper.parseDate(props.validUntil);
    this._originalValue = props.originalValue;
    this._remainingValue = props.remainingValue;
    this._mustBeRedeemedInOneGo = props.mustBeRedeemedInOneGo === true;
    this._label = props.label ? props.label : null;
    this._customer = props.customer
      ? new VoucherCustomer(props.customer)
      : null;
    this._payment = props.payment ? new VoucherPayment(props.payment) : null;

    if (props.templateId) {
      this._templateId = props.templateId;
    }

    if (props.timestamp) {
      this._timestamp = serviceHelper.parseTimestamp(props.timestamp);
    }

    this._pin = props.pin ? props.pin : null;

    this.setAllProps([]);
  }

  protected setAllProps(props: Array<string>) {
    super.setAllProps([
      "type",
      "isActive",
      "validUntil",
      "mustBeRedeemedInOneGo",
      "originalValue",
      "remainingValue",
      "label",
      "customer",
      "payment",
      "templateId",
      "timestamp",
      ...props
    ]);
  }

  protected get type(): string {
    return this._type;
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public set isActive(isActive: boolean) {
    this._isActive = isActive;
    super.setPropUpdated("isActive");
  }

  public get validUntil(): Date {
    return this._validUntil;
  }

  public get label(): string | null {
    return this._label;
  }

  public set label(label: string | null) {
    this._label = label;
  }

  public get customer(): VoucherCustomer | null {
    return this._customer;
  }

  public get payment(): VoucherPayment | null {
    return this._payment;
  }

  public set payment(payment: VoucherPayment | null) {
    this._payment = payment;
    super.setPropUpdated("payment");
  }

  public get templateId(): string | undefined {
    return this._templateId;
  }

  public get timestamp(): Date | undefined {
    return this._timestamp;
  }

  public get originalValue(): any {
    throw new Error("error.not-implemented");
  }

  public get remainingValue(): any {
    throw new Error("error.not-implemented");
  }

  public get usedValue(): any {
    throw new Error("error.not-implemented");
  }

  public get isExpired(): boolean {
    return Moment().isAfter(this.validUntil);
  }

  public get hasRemainingValue(): boolean {
    throw new Error("error.not-implemented");
  }

  public get mustBeRedeemedInOneGo(): boolean {
    return this._mustBeRedeemedInOneGo;
  }

  public get isValid(): boolean {
    return this.hasRemainingValue && !this.isExpired && this.isActive;
  }

  public get canBeEdited(): boolean {
    return this.hasRemainingValue && !this.isExpired;
  }

  public get pin(): string | null {
    return this._pin;
  }

  public set pin(pin: string | null) {
    this._pin = pin;
  }

  public canBeRedeemedForOrder(order: Order): boolean {
    throw new Error("error.not-implemented");
  }

  public redeemForOrder(order: Order) {
    throw new Error("error.not-implemented");
  }

  public refund(value: any): any {
    throw new Error("error.not-implemented");
  }

  public originalValueToString(translator: any): string {
    throw new Error("error.not-implemented");
  }

  public remainingValueToString(translator: any): string {
    throw new Error("error.not-implemented");
  }

  public usedValueToString(translator: any): string {
    throw new Error("error.not-implemented");
  }

  public serializeRemainingValue(): any {
    throw new Error("error.not-implemented");
  }

  public clone(): Voucher {
    throw new Error("error.not-implemented");
  }
}

export default Voucher;
