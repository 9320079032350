class BarModel {
  private readonly _barId: string;

  protected constructor(barId: string) {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    this._barId = barId;
  }

  public get barId(): string {
    return this._barId;
  }
}

export default BarModel;
