import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import MenuCategorySelector from "tap-io/client/components/menu/MenuCategorySelector";
import MenuItemPicker from "tap-io/client/components/menu/MenuItemPicker";

const styles = (theme) => ({
  categorySelector: {
    top: 64,
    zIndex: 2,
    position: "sticky",
    margin: "0 auto",
    padding: theme.spacing(0.5, 0.5, 0, 0.5),
    //backgroundColor: "white",
    transition: "top 0.5s ease",
    "&:before": {
      position: "absolute",
      top: 0,
      left: "50%",
      transform: "translate(-50%, 0)",
      width: "100%",
      maxWidth: 600,
      height: 4,
      //borderRadius: 4,
      content: "''",
      backgroundColor: "rgba(235, 235, 235, 0.95)",
      "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))": {
        backdropFilter: "saturate(180%) blur(20px)",
        backgroundColor: "rgba(235, 235, 235, 0.75)",
      }
    }
  },
  menuItemPicker: {
    position: "relative",
    zIndex: 0,
    width: `calc(100% - ${theme.spacing()})`,
    maxWidth: 600,
    margin: "0 auto"
  }
});

class SelectOrderItems extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillUnmount() {
    this.willBeUnmounted = true;
  }

  handleSelectedCategory = (category) => {
    if (!this.willBeUnmounted) {
      this.handleScrollToCategoryInPicker(category);
    }
  };

  handleScrolledToCategory = (category) => {
    if (!this.willBeUnmounted) {
      this.handleSelectCategoryInSelector(category);
    }
  };

  handlePickedMenuItemsChange = (pickedMenuItems) => {
    const { onOrderItemsChange } = this.props;

    const orderItems = {};
    for (let id in pickedMenuItems) {
      orderItems.push({
        id,
        menuItem: pickedMenuItems[id].menuItem,
        amount: pickedMenuItems[id].amount
      });
    }

    onOrderItemsChange(orderItems);
  };

  render() {
    const {
      t,
      theme,
      classes,
      headerProps,
      bar,
      menuElements,
      menuCategories,
      orderItems,
      onOrderItemsChange
    } = this.props;

    return (
      <div>
        {menuCategories && menuCategories.length > 0 && (
          <div
            className={classes.categorySelector}
            style={{ top: headerProps.height }}
          >
            <MenuCategorySelector
              categories={menuCategories}
              onSelect={this.handleSelectedCategory}
              selectCategory={(selectCategory) =>
                (this.handleSelectCategoryInSelector = selectCategory)
              }
            />
          </div>
        )}
        <div className={classes.menuItemPicker}>
          <MenuItemPicker
            headerProps={headerProps}
            bar={bar}
            elements={menuElements}
            onScroll={this.handleScrolledToCategory}
            scrollToCategory={(scrollToCategory) =>
              (this.handleScrollToCategoryInPicker = scrollToCategory)
            }
            pickedMenuItems={orderItems}
            showImage={false}
            showDescription={true}
            showDeposits={true}
            onChange={onOrderItemsChange}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withTranslation("common")(SelectOrderItems));
