import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import HelpIcon from "@mui/icons-material/Help";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import BlockIcon from "@mui/icons-material/Block";
import ReceiptIcon from "@mui/icons-material/Receipt";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { scannerService } from "tap-io/client/services";
import OrderItems from "tap-io/client/components/order/OrderItems";
import OrderPayment from "tap-io/client/components/order/OrderPayment";
import OrderStatus from "tap-io/models/order/OrderStatus";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";
import ServiceOptionName from "tap-io/models/service/ServiceOptionName";

import ContentDrawer from "../common/ContentDrawer";
import ContentContainer from "../common/ContentContainer";
import withAuthorization from "../auth/withAuthorization";
import WarningMessage from "../common/WarningMessage";
import OrderStatusContent from "./OrderStatusContent";
import OrderStatusActions from "./OrderStatusActions";
import OrderLoading from "./OrderLoading";
import PaymentMessage from "../common/PaymentMessage";
import OrderPreparationInfo from "./OrderPreparationInfo";

const styles = (theme) => ({
  noBackground: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.background.contrastText
  },
  successBackground: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  },
  warningBackground: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  errorBackground: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  content: {
    padding: theme.spacing(0, 1, 1, 1)
  },
  spacing: {
    display: "block",
    height: 10
  },
  drawerIcon: {
    fontSize: 60
  }
});

class OrderWithActionsDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (order, base) => {
    const { scanner } = this.props;

    const wasOrderAlreadyClaimedByAScanner =
      order &&
      order.isStatusClaimed(base) &&
      order.service.is(ServiceOptionName.SHOW_AND_GO)
    const wasOrderAlreadyClaimedByThisScanner =
      wasOrderAlreadyClaimedByAScanner && scanner && order?.getStatusData()?.scannerId === scanner.id;
    const wasOrderAlreadyScanned = wasOrderAlreadyClaimedByAScanner && !wasOrderAlreadyClaimedByThisScanner;

    return {
      isReady: false,
      isDisabled: false,
      isConfirmDialogOpen: false,
      wasOrderAlreadyScanned
    };
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { t, isOpen, base, order } = this.props;
    const prevIsOpen = prevProps.isOpen;
    const prevOrder = prevProps.order;

    const isReady = isOpen && order;
    const wasReady = prevIsOpen && prevOrder;

    if (isReady) {
      const isQueued = order.isStatusQueued(base);
      const wasQueued = prevProps.order
        ? prevProps.order.isStatusQueued(base)
        : false;

      if (!wasReady || (isQueued && !wasQueued)) {
        this.setState(this.initialState(order, base), () => {
          if (order) {
            if (!order.canBePaid() && order.isStatusQueued(base)) {
              this.claimOrder()
                .then(() => {
                  this.setState({
                    isReady: true
                  });
                })
                .catch((error) => {
                  toast.error(
                    `${t("label.something-went-wrong")} (${t(
                      error ? error.message : "label.unknown-error"
                    )})`
                  );
                });
            } else {
              this.setState({ isReady: true });
            }
          }
        });
      }
    }
  }

  claimOrder = async () => {
    const { t, auth, scanner, order, getPinHash } = this.props;

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    this.setState({ isDisabled: true });
    try {
      const pinHash = await getPinHash();
      await scannerService.claimOrder(
        config.functions.api,
        auth.user,
        scanner,
        order,
        pinHash
      );

      this.setState({ isDisabled: false });

      //toast.info(t("scanner.order-scanned"));
    } catch (error) {
      console.warn(error);

      toast.error(
        `${t("label.something-went-wrong")} (${t(
          error ? error.message : "label.unknown-error"
        )})`
      );

      this.setState({ isDisabled: false });
    }
  };

  handleClaimOrder = async (event) => {
    await this.claimOrder();
  };

  completeOrder = async () => {
    const { t, auth, scanner, order, getPinHash } = this.props;

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    this.setState({ isDisabled: true });
    try {
      const pinHash = await getPinHash();

      await scannerService.completeOrder(
        config.functions.api,
        auth.user,
        scanner,
        order,
        pinHash
      );

      //this.setState({ isDisabled: false });

      //toast.info(t("scanner.order-complete"));
    } catch (error) {
      console.warn(error);

      toast.error(
        `${t("label.something-went-wrong")} (${t(
          error ? error.message : "label.unknown-error"
        )})`
      );

      this.setState({ isDisabled: false });
    }
  };

  handleCompleteOrder = (event) => {
    const { onCloseAndContinue } = this.props;

    this.completeOrder(); // Don't wait for async

    onCloseAndContinue();
  };

  handleRefreshPayment = () => {
    this.setState({ now: new Date().getTime() });
  };

  getDrawerIconAndClass = () => {
    const { classes, base, order } = this.props;
    const { wasOrderAlreadyScanned } = this.state;

    if (order) {
      const orderStatus = order.getStatus(base);

      if (orderStatus === OrderStatus.CANCELLED) {
        return {
          icon: <BlockIcon className={classes.drawerIcon} />,
          class: classes.noBackground
        };
      } else if (order.isPaid() || order.isFree()) {
        return wasOrderAlreadyScanned
          ? {
            icon: <WarningIcon className={classes.drawerIcon} />,
            class: classes.warningBackground
          }
          : orderStatus === OrderStatus.QUEUED ||
            orderStatus === OrderStatus.CLAIMED
            ? {
              icon: <CheckCircleIcon className={classes.drawerIcon} />,
              class: classes.successBackground
            }
            : orderStatus === OrderStatus.COMPLETE
              ? {
                icon: <ReceiptIcon className={classes.drawerIcon} />,
                class: classes.warningBackground
              }
              : {
                icon: <ErrorIcon className={classes.drawerIcon} />,
                class: classes.errorBackground
              };
      } else {
        if (order.isPaymentProcessing()) {
          return {
            icon: <WarningIcon className={classes.drawerIcon} />,
            class: classes.warningBackground
          };
        } else {
          return {
            icon: <ErrorIcon className={classes.drawerIcon} />,
            class: classes.errorBackground
          };
        }
      }
    }

    return { icon: <HelpIcon className={classes.drawerIcon} />, class: null };
  };

  handleCloseOrder = () => {
    this.setState({ isConfirmDialogOpen: true });
  };

  handleConfirmCloseOrder = () => {
    const { onClose } = this.props;

    this.setState({ isConfirmDialogOpen: false }, onClose);
  };

  handleCancelCloseOrder = () => {
    this.setState({ isConfirmDialogOpen: false });
  };

  render() {
    const {
      theme,
      classes,
      t,
      auth,
      isOpen,
      onClose,
      barLocator,
      bar,
      scanner,
      base,
      order
    } = this.props;
    const {
      isReady,
      isDisabled,
      isConfirmDialogOpen,
      wasOrderAlreadyScanned,
      now
    } = this.state;

    const drawerIconAndClass = this.getDrawerIconAndClass();

    const enableCloseOrder =
      !order ||
      (order.containsItemsForBase(base) &&
        (order.isStatusCreated(base) || order.isStatusQueued(base)));

    return (
      <>
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          title={t("scanner.close-order")}
          description={t("scanner.are-you-sure-you-want-to-close-this-order")}
          confirmButtonLabel={t("scanner.close-order")}
          onConfirm={this.handleConfirmCloseOrder}
          onCancel={this.handleCancelCloseOrder}
        />
        <ContentDrawer
          disableClose={!enableCloseOrder}
          isOpen={isOpen}
          onClose={this.handleCloseOrder}
          title={drawerIconAndClass.icon}
          className={drawerIconAndClass.class}
          centerTitle={true}
        >
          <div className={classes.content}>
            {order ? (
              <>
                {isReady ? (
                  <>
                    {order.containsItemsForBase(base) ? (
                      <ContentContainer>
                        <OrderPreparationInfo
                          order={order}
                          base={base}
                        />
                        <OrderItems
                          hidePrice
                          isCompact
                          order={order}
                          base={base}
                        />
                      </ContentContainer>
                    ) : (
                      <ContentContainer
                        style={{ padding: theme.spacing(1.5, 2.5) }}
                      >
                        <WarningMessage
                          label={t(
                            "scanner.order-does-not-contain-items-for-this-base"
                          )}
                        />
                      </ContentContainer>
                    )}
                    <ContentContainer
                      style={{ padding: theme.spacing(1.5, 2.5) }}
                    >
                      <OrderStatusContent
                        t={t}
                        base={base}
                        order={order}
                        wasOrderAlreadyScanned={wasOrderAlreadyScanned}
                      />
                    </ContentContainer>
                    <ContentContainer
                      style={{ padding: theme.spacing(0.5, 2.5) }}
                    >
                      <PaymentMessage
                        label={
                          <OrderPayment
                            disableGutters
                            disableColor
                            now={now} // Refresh order payment status
                            bar={bar}
                            order={order}
                            size="large"
                            noPaymentDetailsAvailableText={t(
                              "order.no-payment-details-available"
                            )}
                          />
                        }
                      />
                      {order.isPaymentProcessing() && (
                        <Button
                          fullWidth
                          size="small"
                          color="primary"
                          startIcon={<RefreshIcon />}
                          onClick={this.handleRefreshPayment}
                        >
                          {t("payment.refresh-payment")}
                        </Button>
                      )}
                    </ContentContainer>
                    <div className={classes.spacing} />
                    <OrderStatusActions
                      now={now} // Refresh order payment status
                      t={t}
                      barLocator={barLocator}
                      auth={auth}
                      bar={bar}
                      scanner={scanner}
                      base={base}
                      order={order}
                      isDisabled={isDisabled}
                      onClaimOrder={this.handleClaimOrder}
                      onCompleteOrder={this.handleCompleteOrder}
                      onClose={onClose}
                    />
                  </>
                ) : (
                  <OrderLoading label={t("scanner.claiming-order")} />
                )}
              </>
            ) : (
              <OrderLoading label={t("scanner.loading-order")} />
            )}
          </div>
        </ContentDrawer>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withAuthorization()(withTranslation("common")(OrderWithActionsDrawer))
);
