import { serviceHelper } from "../../helpers";

class OrderVoucher {
  private readonly _id: string;
  private readonly _amount: number;

  constructor(props: any) {
    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    this._id = serviceHelper.parseString(props.id);
    this._amount = serviceHelper.parseNumber(props.amount);
  }

  public get id(): string {
    return this._id;
  }

  public get amount(): number {
    return this._amount;
  }

  public toJSON(): any {
    return {
      id: this.id,
      amount: this.amount
    };
  }
}

export default OrderVoucher;