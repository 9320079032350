import Menu from "../models/menu/Menu";
import Element from "../models/menu/Element";
import Category from "../models/menu/Category";
import Item from "../models/menu/Item";
import { serviceHelper } from "../helpers";
import { menuParser } from "../parsers";

export default class MenuService {
  protected readonly fbStore;

  constructor(fbStore) {
    this.fbStore = fbStore;

    this.addSetMenuIsActiveByIdInBatch =
      this.addSetMenuIsActiveByIdInBatch.bind(this);
  }

  protected getMenusRef(barId: string) {
    return this.fbStore.collection("bars").doc(barId).collection("menus");
  }

  private checkIfMenuIdsArrayInQueryAreValid(menuIds: Array<string>) {
    if (!menuIds) {
      throw new Error("menuIds is not defined");
    }
    if (menuIds.length === 0) {
      throw new Error("error.menu-ids-empty");
    }
    if (menuIds.length > serviceHelper.IN_QUERY_ARRAY_MAX_SIZE) {
      throw new Error("error.menu-ids-limit-exceeded");
    }
  }

  protected getMenusWithIdsRef(barId: string, menuIds: Array<string>) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    this.checkIfMenuIdsArrayInQueryAreValid(menuIds);

    return this.getMenusRef(barId).where(
      serviceHelper.documentId(),
      "in",
      menuIds
    );
  }

  protected getMenusExceptForIdsRef(barId: string, menuIds: Array<string>) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    this.checkIfMenuIdsArrayInQueryAreValid(menuIds);

    return this.getMenusRef(barId).where(
      serviceHelper.documentId(),
      "not-in",
      menuIds
    );
  }

  protected getMenuRef(barId: string, menuId: string) {
    return this.getMenusRef(barId).doc(menuId);
  }

  private async getAndUpdateMenuElements(menu: Menu, elementsUpdateFunction) {
    if (!menu.id) {
      throw new Error("error.menu-id-is-not-defined");
    }
    const menuId: string = menu.id;
    const menuRef = this.getMenuRef(menu.barId, menuId);

    await this.fbStore.runTransaction((fTransaction) => {
      return fTransaction.get(menuRef).then((menuDoc) => {
        const menuData = menuDoc.data();

        const currentElements: Array<Element> =
          menuData && menuData.elements
            ? menuData.elements
                .map((element: any) => {
                  if (element) {
                    switch (element.type) {
                      case "category":
                        return new Category(menuId, element.id, element);
                      case "item":
                        return new Item(menuId, element.id, element);
                    }
                  }

                  return undefined;
                })
                .filter((element: Element) => element !== undefined)
            : [];
        const updatedElements: Array<Element> =
          elementsUpdateFunction(currentElements);
        const elementsAsJson = updatedElements.map((element) =>
          element.toJSON()
        );

        if (menuData) {
          fTransaction.update(menuRef, { elements: elementsAsJson });
        } else {
          fTransaction.set(menuRef, { elements: elementsAsJson });
        }
      });
    });
  }

  private updateMenuElements(menu: Menu, updatedElements: Array<Element>) {
    return this.getAndUpdateMenuElements(menu, (elements: Array<Element>) =>
      elements.map((element: Element) => {
        const foundElements = updatedElements.filter(
          (updatedElement) => updatedElement.id === element.id
        );

        if (foundElements && foundElements.length === 1) {
          return foundElements[0];
        }

        return element;
      })
    );
  }

  private updateMenuElement(menu: Menu, updatedElement: Element) {
    return this.getAndUpdateMenuElements(menu, (elements: Array<Element>) =>
      elements.map((element: Element) => {
        if (updatedElement.id === element.id) {
          return updatedElement;
        }

        return element;
      })
    );
  }

  public async getAllMenus(barId: string): Promise<Array<Menu>> {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    const docs = await this.getMenusRef(barId).get();

    const menus: Array<Menu> = [];
    docs.forEach((doc) => menus.push(menuParser.parseDocToMenu(barId, doc)));
    return menus;
  }

  public onAllMenus(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    return this.getMenusRef(barId).onSnapshot((docs) => {
      const menus: Array<Menu> = [];
      docs.forEach((doc) => menus.push(menuParser.parseDocToMenu(barId, doc)));
      cb(menus);
    });
  }

  public onAllElementsFromActiveMenus(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    return this.getMenusRef(barId)
      .where("isActive", "==", true)
      .onSnapshot((docs) => {
        const menusData: Array<any> = [];

        docs.forEach((doc) => {
          const data = doc.data();

          if (data) {
            menusData.push({ id: doc.id, ...data });
          }
        });

        // Sort elements by their menu name
        // TO FIX: do this more efficiently (e.g. QuickSort, cfr. Stats)
        menusData.sort((menuDataA: any, menuDataB: any) =>
          menuDataA.name > menuDataB.name
            ? 1
            : menuDataA.name < menuDataB.name
            ? -1
            : 0
        );

        const elements: Array<Element> = [];
        menusData.forEach((menuData) => {
          if (menuData.elements) {
            elements.push(
              ...menuParser.parseElements(menuData.id, menuData.elements)
            );
          }
        });

        cb(elements);
      });
  }

  public onAllElementsFromActiveMenusWithIds(
    barId: string,
    menuIds: Array<string>,
    cb: Function
  ) {
    return serviceHelper.onByIds(
      (menuIds: string[]) =>
        this.getMenusWithIdsRef(barId, menuIds).where("isActive", "==", true),
      (doc) => menuParser.parseDocToMenu(barId, doc),
      menuIds,
      (menus) => {
        const elements: Array<Element> = [];

        menus.forEach((menu) => elements.push(...menu.elements));

        // Sort elements in the same way menuIds were sorted
        cb(
          elements.sort(
            (elementA: Element, elementB: Element) =>
              menuIds.indexOf(elementA.menuId) -
              menuIds.indexOf(elementB.menuId)
          )
        );
      }
    );
  }

  public async getById(barId: string, menuId: string): Promise<Menu> {
    if (!barId) {
      throw new Error("barId is not defined");
    }
    if (!menuId) {
      throw new Error("menuId is not defined");
    }

    const doc = await this.getMenuRef(barId, menuId).get();
    return menuParser.parseDocToMenu(barId, doc);
  }

  public onById(barId: string, menuId: string, cb: Function) {
    if (!barId) {
      throw new Error("barId is not defined");
    }
    if (!menuId) {
      throw new Error("menuId is not defined");
    }

    return this.getMenuRef(barId, menuId).onSnapshot((doc) => {
      cb(menuParser.parseDocToMenu(barId, doc));
    });
  }

  protected setMenuIsActive(menu: Menu, isActive: boolean) {
    if (!menu.id) {
      throw new Error("error.menu-id-is-not-defined");
    }

    return this.getMenuRef(menu.barId, menu.id).update({ isActive });
  }

  public toggleMenuIsActive(menu: Menu) {
    return this.setMenuIsActive(menu, !menu.isActive);
  }

  public addSetMenuIsActiveByIdInBatch(
    fBatch: any,
    barId: string,
    menuId: string,
    isActive: boolean
  ) {
    return fBatch.update(this.getMenuRef(barId, menuId), { isActive });
  }

  public createMenu(barId: string, data: any): Menu {
    return new Menu(barId, undefined, data);
  }

  public async addMenu(menu: Menu): Promise<Menu> {
    if (menu.id) {
      throw new Error("error.id-already-defined");
    }

    const ref = await this.getMenusRef(menu.barId).add({
      ...menu.allPropsToJSON(),
      timestamp: serviceHelper.serverTimestamp()
    });
    menu.id = ref.id;
    return menu;
  }

  public removeMenu(menu: Menu) {
    if (!menu.id) {
      throw new Error("error.id-is-not-defined");
    }

    return this.getMenuRef(menu.barId, menu.id).delete();
  }

  public async updateMenu(menu: Menu) {
    if (!menu.id) {
      throw new Error("error.id-is-not-defined");
    }

    await this.getMenuRef(menu.barId, menu.id).update(
      menu.updatedPropsToJSON()
    );
  }

  public addMenuElements(menu: Menu, elementsToAdd: Array<Element>) {
    return this.getAndUpdateMenuElements(menu, (elements) => [
      ...elements,
      ...elementsToAdd
    ]);
  }

  public async removeMenuElement(menu: Menu, elementToRemove: Element) {
    return this.getAndUpdateMenuElements(menu, (elements) =>
      elements.filter((element) => element.id !== elementToRemove.id)
    );
  }

  public createMenuCategory(menuId: string, data: any): Category {
    return new Category(menuId, serviceHelper.generateId(), data);
  }

  public createMenuItem(menuId: string, data: any): Item {
    return new Item(menuId, serviceHelper.generateId(), data);
  }

  public addMenuCategory(menu: Menu, category: Category) {
    return this.addMenuElements(menu, [category]);
  }

  public addMenuItem(menu: Menu, item: Item) {
    return this.addMenuElements(menu, [item]);
  }

  public async updateMenuCategory(menu: Menu, category: Category) {
    return this.updateMenuElement(menu, category);
  }

  public async updateMenuCategories(menu: Menu, categories: Array<Category>) {
    return this.updateMenuElements(menu, categories);
  }

  public async updateMenuItem(menu: Menu, item: Item) {
    return this.updateMenuElement(menu, item);
  }

  public async updateMenuItems(menu: Menu, items: Array<Item>) {
    return this.updateMenuElements(menu, items);
  }

  public async toggleMenuItemAvailability(menu: Menu, item: Item) {
    if (!menu.id) {
      throw new Error("error.menu-id-is-not-defined");
    }

    return this.getAndUpdateMenuElements(menu, (elements: Array<Element>) =>
      elements.map((element: Element) => {
        if (item.id === element.id && element instanceof Item) {
          element.isAvailable = !element.isAvailable;
        }

        return element;
      })
    );
  }
}
