import Base from "../models/base/Base";

export default class BaseParser {
  public parseDocToBase(barId: string, doc): Base {
    if (!doc.exists) {
      throw new Error("error.base-does-not-exist");
    }

    const data = doc.data();

    return new Base(
      barId,
      doc.id,
      {
        ...data,
        timestamp: data.timestamp ? data.timestamp.toDate() : undefined
      }
    );
  }
}