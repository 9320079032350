import LayoutNode from "./LayoutNode";
import LayoutNodeType from "./LayoutNodeType";
import LayoutLocation from "./LayoutLocation";
import LayoutNodeBases from "./LayoutNodeBases";

export default class ZoneLayoutNode extends LayoutNode {
  constructor(
    code: string,
    name: string,
    bases: LayoutNodeBases | null,
    children?: LayoutNode[]
  ) {
    super(LayoutNodeType.ZONE, code, name, bases, children);
  }

  public get code() {
    return this._code;
  }

  public set code(code: string) {
    throw new Error("error.not-allowed");
  }

  public getLayoutLocations(
    parentLayoutLocation?: LayoutLocation
  ): LayoutLocation[] {
    return [];
  }

  public toString(): string {
    return this.name;
  }

  public clone(): ZoneLayoutNode {
    return new ZoneLayoutNode(
      this.code,
      this.name,
      this.customBases ? this.customBases.clone() : null,
      this.children ? this.children.map((child) => child.clone()) : undefined
    );
  }
}
