export const SCAN_QR_CODE = "/";
export const BAR_ROOT = "/:barLocator";
export const WAITER_ORDER = `${BAR_ROOT}/waiter/:waiterCode`;
export const SCAN_ROOT = `${BAR_ROOT}/:scannerId`;
export const SCAN_ORDER = `${SCAN_ROOT}/orders`;
export const CREATE_ORDER = `${SCAN_ROOT}/order`;
export const PAY_ORDER = `${SCAN_ROOT}/pay/:orderId`;

export const getScanOrderUrl = (barLocator, scannerId) => {
  return SCAN_ORDER.replace(":barLocator", barLocator).replace(
    ":scannerId",
    scannerId
  );
};

export const getWaiterOrderUrl = (barLocator, waiterCode) => {
  return WAITER_ORDER.replace(":barLocator", barLocator).replace(
    ":waiterCode",
    waiterCode
  );
};

export const getCreateOrderUrl = (barLocator, scannerId) => {
  return CREATE_ORDER.replace(":barLocator", barLocator).replace(
    ":scannerId",
    scannerId
  );
};

export const getPayOrderUrl = (barLocator, scannerId, orderId) => {
  return PAY_ORDER.replace(":barLocator", barLocator)
    .replace(":scannerId", scannerId)
    .replace(":orderId", orderId);
};
