import { serviceHelper, utilsHelper } from "../../helpers";

class BasePreparationOption {
  private readonly _duration: number;

  constructor(props: any) {
    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    this._duration = serviceHelper.parseNumber(props.duration, 1);
  }

  public get duration() {
    return this._duration;
  }

  public toJSON(): any {
    return {
      duration: this.duration
    };
  }

  public clone(): BasePreparationOption {
    return new BasePreparationOption(this.toJSON());
  }

  public equals(basePreparationOption: BasePreparationOption): boolean {
    return utilsHelper.areObjectsEqualShallow(
      this.toJSON(),
      basePreparationOption.toJSON()
    );
  }
}

export default BasePreparationOption;
