import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({});

class Version extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, nativeWrapperVersion } = this.props;

    return (
      <Typography variant="caption" className={classes.version}>
        {`${t("label.version")} ${process.env.REACT_APP_VERSION}`}
        {nativeWrapperVersion && <span> ({nativeWrapperVersion})</span>}
      </Typography>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(Version)
);
