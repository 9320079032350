import LayoutNode from "./LayoutNode";
import LayoutNodeType from "./LayoutNodeType";
import MultiLayoutNode from "./MultiLayoutNode";
import LayoutLocation from "./LayoutLocation";
import LayoutNodeBases from "./LayoutNodeBases";

export default class SeriesLayoutNode extends MultiLayoutNode {
  private _series: string[];

  constructor(
    code: string,
    name: string,
    series: string[],
    bases: LayoutNodeBases | null,
    children?: LayoutNode[]
  ) {
    super(LayoutNodeType.SERIES, code, name, bases, children);
    this._series = series;
  }

  public get series() {
    return this._series;
  }

  public set series(series: string[]) {
    const prevSeries = this.series;
    this._series = series;

    if (!this.areAllGeneratedCodesValid()) {
      this._series = prevSeries;
      throw new Error("error.code-duplicate-found");
    }
  }

  public toJSON() {
    return {
      ...super.toJSON(),
      series: this.series
    };
  }

  public getLayoutLocations(
    parentLayoutLocation?: LayoutLocation
  ): LayoutLocation[] {
    return this.series.map(
      (element) =>
        new LayoutLocation(
          this.getCompositeCode(element),
          this.getCompositeName(element),
          parentLayoutLocation
        )
    );
  }

  public toString() {
    return `${this.name} (${this.series.length})`;
  }

  public clone(): SeriesLayoutNode {
    return new SeriesLayoutNode(
      this.code,
      this.name,
      [...this.series],
      this.customBases ? this.customBases.clone() : null,
      this.children ? this.children.map((child) => child.clone()) : undefined
    );
  }
}
