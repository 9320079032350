import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";
import EuroIcon from "@mui/icons-material/Euro";
import withStyles from "@mui/styles/withStyles";

import ConfirmOrderDeferredPaymentDialog from "../payment/ConfirmOrderDeferredPaymentDialog";

const styles = (theme) => ({});

class ConfirmDeferredPaymentButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmOrderDeferredPaymentDialogOpen: false
    };
  }

  handleConfirmDeferredPayment = (event) => {
    event.stopPropagation();

    this.setState({ isConfirmOrderDeferredPaymentDialogOpen: true });
  };

  handleConfirmOrderDeferredPaymentDialogClose = (
    isOrderDeferredPaymentConfirmed
  ) => {
    const { onUpdate } = this.props;

    this.setState(
      {
        isConfirmOrderDeferredPaymentDialogOpen: false
      },
      () => {
        if (onUpdate) {
          onUpdate(isOrderDeferredPaymentConfirmed);
        }
      }
    );
  };

  render() {
    const { classes, t, auth, bar, order, isDisabled, fullWidth, size, className } =
      this.props;
    const { isConfirmOrderDeferredPaymentDialogOpen } = this.state;

    if (auth && bar.isAllowingDeferredPayments() && order && !order.isPaid()) {
      return (
        <>
          <ConfirmOrderDeferredPaymentDialog
            isOpen={isConfirmOrderDeferredPaymentDialogOpen}
            onClose={this.handleConfirmOrderDeferredPaymentDialogClose}
            auth={auth}
            bar={bar}
            order={order}
          />
          <Button
            className={className}
            disabled={isDisabled}
            variant="contained"
            size={size}
            color="primary"
            fullWidth={fullWidth}
            onClick={this.handleConfirmDeferredPayment}
            startIcon={<EuroIcon />}
          >
            {t("payment.pay-cash")}
          </Button>
        </>
      );
    }

    return null;
  }
}

export default withStyles(styles)(
  withTranslation("common")(ConfirmDeferredPaymentButton)
);
