interface SelectCustomerFieldOptionProps {
  id: string;
  label: string;
}

export default class SelectCustomerFieldOption {
  private _id: string;
  private _label: string;

  constructor(props: SelectCustomerFieldOptionProps) {
    const { id, label } = props;

    this._id = id;
    this._label = label;
  }

  public get id() {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get label() {
    return this._label;
  }

  public set label(label: string) {
    this._label = label;
  }

  public clone(): SelectCustomerFieldOption {
    return new SelectCustomerFieldOption({ id: this.id, label: this.label });
  }

  public toJSON(): any {
    return {
      id: this.id,
      label: this.label
    };
  }
}
