import LayoutNode from "../models/zone/LayoutNode";
import ZoneLayoutNode from "../models/zone/ZoneLayoutNode";
import LayoutPath from "../models/zone/LayoutPath";
import MultiLayoutNode from "../models/zone/MultiLayoutNode";

export default class ZoneHelper {
  public static readonly LEGACY_TABLE_LAYOUT_NODE_NAME: string = "Tafel";
  public static readonly LEGACY_TABLE_LAYOUT_NODE_CODE: string = "tafel";

  constructor() {}

  public isZoneLayoutNode(layoutNode: LayoutNode): boolean {
    return layoutNode instanceof ZoneLayoutNode;
  }

  public parseZoneCode(zoneCode: string): string | undefined {
    if (zoneCode) {
      return zoneCode.trim().toUpperCase();
    }

    return undefined;
  }

  public parseLayoutNodeCode(layoutNodeCode: string) {
    return layoutNodeCode
      .toLowerCase()
      .replace(/[^a-z0-9]/g, "")
      .substr(0, 10);
  }

  public parseElementsToLayoutPath(elements: string[]): LayoutPath {
    return new LayoutPath(elements);
  }

  public parseLayoutPath(layoutPath: string): LayoutPath {
    return new LayoutPath(
      layoutPath.trim().toLowerCase().split(LayoutPath.DIVIDER)
    );
  }

  public parseZoneCodeAndLayoutPath(zoneCodeAndLayoutPath: string): {
    zoneCode: string | undefined;
    layoutPath: LayoutPath | undefined;
  } {
    const parsedZoneCodeAndLayoutPath: {
      zoneCode: string | undefined;
      layoutPath: LayoutPath | undefined;
    } = {
      zoneCode: undefined,
      layoutPath: undefined
    };

    if (zoneCodeAndLayoutPath) {
      if (zoneCodeAndLayoutPath.indexOf("/") < 0) {
        return this.parseLegacyZoneCodeAndTableNumber(zoneCodeAndLayoutPath);
      }

      let matches = zoneCodeAndLayoutPath.match(
        new RegExp(`([A-Za-z]+)\\s*(${LayoutPath.DIVIDER}.+)?`)
      );

      if (matches && matches.length === 3) {
        parsedZoneCodeAndLayoutPath.zoneCode = this.parseZoneCode(matches[1]);
        if (matches[2]) {
          parsedZoneCodeAndLayoutPath.layoutPath = this.parseLayoutPath(
            matches[2].substr(1)
          );
        }
      }
    }

    return parsedZoneCodeAndLayoutPath;
  }

  private parseLegacyZoneCodeAndTableNumber(zoneCodeAndTableNumber: string): {
    zoneCode: string | undefined;
    layoutPath: LayoutPath | undefined;
  } {
    const parsedZoneCodeAndLayoutPath: {
      zoneCode: string | undefined;
      layoutPath: LayoutPath | undefined;
    } = {
      zoneCode: undefined,
      layoutPath: undefined
    };

    if (zoneCodeAndTableNumber) {
      const zoneCodeBeforeTableNumberRegEx = /([A-Za-z]+)\s*(\d+)/;
      const zoneCodeAfterTableNumberRegEx = /(\d+)\s*([A-Za-z]+)/;
      const onlyZoneCodeRegEx = /([A-Za-z]+)/;

      let matches = zoneCodeAndTableNumber.match(
        zoneCodeBeforeTableNumberRegEx
      );

      if (matches && matches.length === 3) {
        parsedZoneCodeAndLayoutPath.zoneCode = this.parseZoneCode(matches[1]);
        parsedZoneCodeAndLayoutPath.layoutPath = this.parseLayoutPath(
          `${ZoneHelper.LEGACY_TABLE_LAYOUT_NODE_CODE}${MultiLayoutNode.COMPOSITE_TIE}${matches[2]}`
        );
      } else {
        matches = zoneCodeAndTableNumber.match(zoneCodeAfterTableNumberRegEx);

        if (matches && matches.length === 3) {
          parsedZoneCodeAndLayoutPath.zoneCode = this.parseZoneCode(matches[2]);
          parsedZoneCodeAndLayoutPath.layoutPath = this.parseLayoutPath(
            `${ZoneHelper.LEGACY_TABLE_LAYOUT_NODE_CODE}${MultiLayoutNode.COMPOSITE_TIE}${matches[1]}`
          );
        } else {
          matches = zoneCodeAndTableNumber.match(onlyZoneCodeRegEx);

          if (matches && matches.length === 2) {
            parsedZoneCodeAndLayoutPath.zoneCode = this.parseZoneCode(
              matches[1]
            );
          }
        }
      }
    }

    return parsedZoneCodeAndLayoutPath;
  }

  private generateCode(length: number): string {
    const characters: string = "abcdefghijklmnopqrstuvwxyz";

    let code = "";
    for (let i = 0; i < length; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return code;
  }

  public generateLayoutNodeCode(exceptForCodes: string[]): string {
    let code = this.generateCode(3);

    while (exceptForCodes.indexOf(code) >= 0) {
      code = this.generateCode(3);
    }

    return code;
  }

  public removeBaseIdFromLayout(layoutNode: LayoutNode, baseId: string) {
    layoutNode.bases.clear(baseId);
    layoutNode.children.forEach((child) =>
      this.removeBaseIdFromLayout(child, baseId)
    );
    return layoutNode;
  }

  //DEPRECATED
  // public generateTableNumbers(tableCount: number, startAtTableNumber?: number): Array<number> {
  //   startAtTableNumber = (startAtTableNumber === undefined ? 1 : startAtTableNumber);

  //   const tableNumbers: Array<number> = [];
  //   for (let i: number = startAtTableNumber; i < tableCount + startAtTableNumber; i++) {
  //     tableNumbers.push(i);
  //   }

  //   return tableNumbers;
  // }

  //DEPRECATED
  // public parseZoneCode(zoneCode: string): string | undefined {
  //   if (zoneCode) {
  //     return zoneCode.trim().toUpperCase();
  //   }

  //   return undefined;
  // }

  //DEPRECATED
  // public parseTableNumber(tableNumber: string): number | undefined {
  //   if (tableNumber !== undefined && tableNumber !== null) {
  //     const parsedTableNumber = parseInt(tableNumber);
  //     if (!isNaN(parsedTableNumber)) {
  //       return parsedTableNumber;
  //     }
  //   }

  //   return undefined;
  // }

  // public parseZoneCodeAndTableNumber(zoneCodeAndTableNumber: string): {
  //   zoneCode: string | undefined;
  //   tableNumber: number | undefined;
  // } {
  //   const parsedZoneCodeAndTableNumber: {
  //     zoneCode: string | undefined;
  //     tableNumber: number | undefined;
  //   } = {
  //     zoneCode: undefined,
  //     tableNumber: undefined
  //   };

  //   if (zoneCodeAndTableNumber) {
  //     const zoneCodeBeforeTableNumberRegEx = /([A-Za-z]+)\s*(\d+)/;
  //     const zoneCodeAfterTableNumberRegEx = /(\d+)\s*([A-Za-z]+)/;
  //     const onlyZoneCodeRegEx = /([A-Za-z]+)/;

  //     let matches = zoneCodeAndTableNumber.match(
  //       zoneCodeBeforeTableNumberRegEx
  //     );

  //     if (matches && matches.length === 3) {
  //       parsedZoneCodeAndTableNumber.zoneCode = this.parseZoneCode(matches[1]);
  //       parsedZoneCodeAndTableNumber.tableNumber = this.parseTableNumber(
  //         matches[2]
  //       );
  //     } else {
  //       matches = zoneCodeAndTableNumber.match(zoneCodeAfterTableNumberRegEx);

  //       if (matches && matches.length === 3) {
  //         parsedZoneCodeAndTableNumber.zoneCode = this.parseZoneCode(
  //           matches[2]
  //         );
  //         parsedZoneCodeAndTableNumber.tableNumber = this.parseTableNumber(
  //           matches[1]
  //         );
  //       } else {
  //         matches = zoneCodeAndTableNumber.match(onlyZoneCodeRegEx);

  //         if (matches && matches.length === 2) {
  //           parsedZoneCodeAndTableNumber.zoneCode = this.parseZoneCode(
  //             matches[1]
  //           );
  //         }
  //       }
  //     }
  //   }

  //   return parsedZoneCodeAndTableNumber;
  // }
}
