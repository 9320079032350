import { utilsHelper, voucherHelper } from "../../helpers";
import { voucherParser } from "../../parsers";
import Voucher from "./Voucher";
import VoucherType from "./VoucherType";
import ProductVoucherValue from "./ProductVoucherValue";
import Order from "../order/Order";
import Item from "../order/Item";

class ProductVoucher extends Voucher {
  constructor(barId: string, id?: string, props?: any) {
    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    super(barId, VoucherType.PRODUCT, id, {
      ...props,
      originalValue: voucherParser.parseProductVoucherValue(
        props.originalValue
      ),
      remainingValue: voucherParser.parseProductVoucherValue(
        props.remainingValue
      )
    });

    super.setAllProps([]);
  }

  private removeEntryFromValue(
    value: Array<ProductVoucherValue>,
    productId: string,
    discountPercentage?: number
  ): Array<ProductVoucherValue> {
    return value.filter(
      (entry) =>
        entry &&
        entry.getProductId() !== productId &&
        (discountPercentage === undefined ||
          entry.discountPercentage !== discountPercentage)
    );
  }

  private redeemForItem(item: Item): number {
    if (item.amount && item.price && item.productId) {
      const entry = voucherHelper.findProductVoucherValue(
        this.remainingValue,
        item.productId
      );
      if (entry) {
        const amountOfItemsToRedeemWithVoucher = Math.min(
          entry.amount,
          item.amount
        );
        entry.amount -= amountOfItemsToRedeemWithVoucher;
        if (entry.amount === 0) {
          this._remainingValue = this.removeEntryFromValue(
            this.remainingValue,
            item.productId
          );
          super.setPropUpdated("remainingValue");
        }

        // itemPricePaidWithVoucher
        return amountOfItemsToRedeemWithVoucher * item.price;
      }
    }

    return 0;
  }

  public get originalValue(): Array<ProductVoucherValue> {
    return this._originalValue;
  }

  public get remainingValue(): Array<ProductVoucherValue> {
    return this._remainingValue;
  }

  public get usedValue(): Array<ProductVoucherValue> {
    const usedValue: Array<ProductVoucherValue> = [];

    this.originalValue.forEach((originalEntry) => {
      const remainingEntry = voucherHelper.findProductVoucherValue(
        this.remainingValue,
        originalEntry.getProductId(),
        originalEntry.discountPercentage
      );

      if (remainingEntry) {
        // Something has been used
        if (remainingEntry.amount < originalEntry.amount) {
          usedValue.push(
            new ProductVoucherValue(
              originalEntry.productIds,
              originalEntry.discountPercentage,
              originalEntry.amount - remainingEntry.amount
            )
          );
        }
      } else {
        // Nothing remaining -> All used
        usedValue.push(
          new ProductVoucherValue(
            originalEntry.productIds,
            originalEntry.discountPercentage,
            originalEntry.amount
          )
        );
      }
    });

    return usedValue;
  }

  public get hasRemainingValue(): boolean {
    for (let i = 0; i < this.originalValue.length; i++) {
      const originalEntry = this.originalValue[i];
      const remainingEntry = voucherHelper.findProductVoucherValue(
        this.remainingValue,
        originalEntry.getProductId(),
        originalEntry.discountPercentage
      );

      if (remainingEntry && remainingEntry.amount > 0) {
        return true;
      }
    }

    return false;
  }

  public canBeRedeemedForOrder(order: Order): boolean {
    if (!this.isValid) {
      return false;
    }
    if (!order) {
      return false;
    }
    //order.items.filter()

    return true;
  }

  public redeemForOrder(order: Order): number {
    return utilsHelper.roundToTwoDecimals(
      order.items.reduce(
        (acc: number, item: Item) => acc + this.redeemForItem(item),
        0
      )
    );
  }

  public refund(value: Array<ProductVoucherValue>): Array<ProductVoucherValue> {
    const newValue: Array<ProductVoucherValue> = [];

    this.originalValue.forEach((originalValueEntry: ProductVoucherValue) => {
      const remainingValueEntry = voucherHelper.findProductVoucherValue(
        this.remainingValue,
        originalValueEntry.getProductId(),
        originalValueEntry.discountPercentage
      );
      const valueEntry = voucherHelper.findProductVoucherValue(
        value,
        originalValueEntry.getProductId(),
        originalValueEntry.discountPercentage
      );

      const remainingAmount =
        (remainingValueEntry ? remainingValueEntry.amount : 0) +
        (valueEntry ? valueEntry.amount : 0);

      if (remainingAmount > originalValueEntry.amount) {
        throw new Error("error.new-value-is-greater-than-original-value");
      }

      if (remainingAmount > 0) {
        newValue.push(
          new ProductVoucherValue(
            originalValueEntry.productIds,
            originalValueEntry.discountPercentage,
            remainingAmount
          )
        );
      }
    });

    this._remainingValue = newValue;
    super.setPropUpdated("remainingValue");

    return newValue;
  }

  private calculateProductAmount(
    productVoucherValues: ProductVoucherValue[]
  ): number {
    return productVoucherValues.reduce(
      (productCount: number, productVoucherValue: ProductVoucherValue) => {
        return productCount + productVoucherValue.amount;
      },
      0
    );
  }

  private productsToString(
    translator: Function,
    productVoucherValues: Array<ProductVoucherValue>
  ): string {
    let isAtLeastOneProductNameKnown = false;

    const productsAsString = productVoucherValues
      .reduce(
        (
          productsAsString: string[],
          productVoucherValue: ProductVoucherValue
        ) => [
          ...productsAsString,
          productVoucherValue.productIds
            .map((productId) => {
              const productName = translator(productId);

              if (productName !== productId) {
                isAtLeastOneProductNameKnown = true;
              }

              return `${productVoucherValue.amount} x ${
                productName === productId
                  ? `(${translator("label.unknown")})`
                  : productName
              }`;
            })
            .join(", ")
        ],
        []
      )
      .join(", ");

    return isAtLeastOneProductNameKnown ? productsAsString : "";
  }

  public originalValueToString(translator: Function): string {
    const totalProductCount = this.calculateProductAmount(this.originalValue);
    const productsAsString = this.productsToString(
      translator,
      this.originalValue
    );

    return `${totalProductCount} ${translator(
      totalProductCount === 1 ? "voucher.product" : "voucher.products"
    )}${productsAsString ? ` (${productsAsString})` : ""}`;
  }

  public remainingValueToString(translator: Function): string {
    const totalProductCount = this.calculateProductAmount(this.remainingValue);
    const productsAsString = this.productsToString(
      translator,
      this.remainingValue
    );

    return `${totalProductCount} ${translator(
      totalProductCount === 1 ? "voucher.product" : "voucher.products"
    )}${productsAsString ? ` (${productsAsString})` : ""}`;
  }

  public usedValueToString(translator: Function): string {
    const totalProductCount = this.calculateProductAmount(this.usedValue);
    const productsAsString = this.productsToString(translator, this.usedValue);

    return `${totalProductCount} ${translator(
      totalProductCount === 1 ? "voucher.product" : "voucher.products"
    )}${productsAsString ? ` (${productsAsString})` : ""}`;
  }

  public serializeRemainingValue(): Array<any> {
    return this.remainingValue.map((entry: ProductVoucherValue) =>
      entry.toJSON()
    );
  }

  public clone(): ProductVoucher {
    return new ProductVoucher(this.barId, this.id, super.allPropsToJSON());
  }
}

export default ProductVoucher;
