import Stats from "../models/stats/Stats";

export default class StatsParser {
  public parseDocToStats(barId: string, doc): Stats {
    const data = doc.data();

    return new Stats(
      barId,
      doc.id,
      {
        ...data,
        t: data.t ? data.t.toDate() : null
      }
    );
  }
}
