import Moment from "moment-timezone";

import StatsData from "../models/stats/StatsData";
import StatsDataFrame from "../models/stats/StatsDataFrame";
import StatsDataCollection from "../models/stats/StatsDataCollection";
import StatsDataType from "../models/stats/StatsDataType";
import StatsDataTime from "../models/stats/StatsDataTime";
import StatsVersion from "../models/stats/StatsVersion";

type Tree<U> = {
  nodeUnit?: U;
  treeUnit?: U;
  children?: {
    [path: string]: Tree<U>;
  };
};

/*type FlatTree<U> = {
  path: string;
  unit: U;
  children: FlatTree<U>[];
};*/

type FlatTree<U> = U & {
  key: string;
  label: string;
  children?: U[];
};

export default class StatsHelper {
  constructor() {}

  public dataToStatsData(rawData: any): StatsData {
    if (!rawData) {
      throw new Error("error.raw-data-is-not-defined");
    }

    const { t, n, d } = rawData;
    switch (t) {
      case StatsDataType.COLLECTION:
        return new StatsDataCollection(d, n);
        break;
      case StatsDataType.FRAME:
        return new StatsDataFrame(d, n);
        break;
      default:
        throw new Error("error.data-type-is-invalid");
        break;
    }
  }

  public timestampToStatsDate(timestamp: Date): string {
    return Moment(timestamp).tz("Europe/Brussels").format("YYYY-MM-DD");
  }

  public timestampToStatsDataTime(timestamp: Date): StatsDataTime {
    return new StatsDataTime(
      Moment(timestamp).tz("Europe/Brussels").format("HHmm")
    );
  }

  public isStatsDataFrame(statsData: StatsData): boolean {
    return statsData instanceof StatsDataFrame;
  }

  public isStatsDataCollection(statsData: StatsData): boolean {
    return statsData instanceof StatsDataCollection;
  }

  public getDatesAsString(minDate: string, maxDate: string): Array<string> {
    if (minDate > maxDate) {
      throw new Error("error.min-date-should-come-before-max-date");
    }

    const parsedMinDate = Moment(minDate).startOf("day");
    const parsedMaxDate = Moment(maxDate).startOf("day").add(1, "hour");

    const dates: Array<string> = [];

    let date = parsedMinDate;
    while (date.isBefore(parsedMaxDate)) {
      dates.push(Moment(date).format("YYYY-MM-DD"));
      date.add(1, "day");
    }

    return dates;
  }

  public canStatsBeResetForDate(date: string): boolean {
    const todayDate = Moment().tz("Europe/Brussels").format("YYYY-MM-DD");
    return date < todayDate;
  }

  public hasStatsDataKey(
    statsDataKeys: Array<string>,
    statsDataKey: string
  ): boolean {
    return statsDataKeys.indexOf(statsDataKey) >= 0;
  }

  public getStatsFormat(
    statsDataUnit: string,
    statsDataKeys: Array<string>
  ): string {
    return `v:${StatsVersion.LATEST};u:${statsDataUnit};k:${statsDataKeys
      .sort()
      .join(",")}`;
  }

  public parseStatsFormat(
    statsFormat: string
  ): { statsDataUnit: string; statsDataKeys: Array<string> } | undefined {
    const matches = statsFormat.match(/v:(.+);u:(.+);k:(.+)/);

    if (matches && matches.length === 4) {
      //const statsVersion = matches[1];
      const statsDataUnit: string = matches[2];
      const statsDataKeys: string = matches[3];

      return {
        statsDataUnit,
        statsDataKeys: statsDataKeys.split(",")
      };
    }

    return undefined;
  }

  public generateFlatTree<U>(
    data: U[],
    getPath: (U) => string,
    getLabel: (U) => string,
    labelSeparator: string = ""
  ): FlatTree<U>[] {
    return (
      this.flattenTree(
        this.generateTree(data, getPath),
        getLabel,
        "",
        labelSeparator
      ) || []
    );
  }

  private generateTree<U>(data: U[], getPath: (U) => string) {
    const tree: Tree<U> = {};

    data.forEach((unit) => {
      const path = getPath(unit);
      const fullPath = path.match(/^(.+?)(\/\*)?$/);

      if (fullPath) {
        const cleanPath = fullPath[1];
        const isTreeUnit = !!fullPath[2];

        const pathParts = cleanPath.split("/");

        this.parseTreePath(tree, pathParts, unit, isTreeUnit);
      }
    });

    return tree;
  }

  private parseTreePath<U>(
    tree: Tree<U>,
    pathParts: string[],
    unit: U,
    isTreeUnit: boolean
  ) {
    if (pathParts.length > 0) {
      const pathPart = pathParts[0];

      if (!tree.children) {
        tree.children = {};
      }
      if (!tree.children[pathPart]) {
        tree.children[pathPart] = {
          treeUnit: unit
        };
      }

      this.parseTreePath(
        tree.children[pathPart],
        pathParts.slice(1),
        unit,
        isTreeUnit
      );
    } else {
      if (isTreeUnit) {
        tree.treeUnit = unit;
      } else {
        tree.nodeUnit = unit;
      }
    }
  }

  private flattenTree<U>(
    tree: Tree<U>,
    getLabel: (U) => string,
    labelPrefix: string = "",
    labelSeparator: string = ""
  ): FlatTree<U>[] | undefined {
    if (tree.children) {
      const flatTree: FlatTree<U>[] = [];

      for (let key in tree.children) {
        const child = tree.children[key];

        if (!child.treeUnit) {
          throw new Error("error.tree-unit-is-not-defined");
        }

        const children: FlatTree<U>[] = [];
        if (child.nodeUnit && child.nodeUnit !== child.treeUnit) {
          children.push({
            ...child.nodeUnit,
            key,
            label: this.getUnitLabel(
              child.nodeUnit,
              getLabel,
              labelPrefix,
              labelSeparator
            ),
            children: []
          });
        }

        const subTree = this.flattenTree(
          child,
          getLabel,
          getLabel(child.treeUnit),
          labelSeparator
        );
        if (subTree) {
          children.push(...subTree);
        }

        flatTree.push({
          ...child.treeUnit,
          key,
          label: this.getUnitLabel(
            child.treeUnit,
            getLabel,
            labelPrefix,
            labelSeparator
          ),
          children
        });
      }

      return flatTree;
    }

    return undefined;
  }

  private getUnitLabel<U>(
    unit: U,
    getLabel: (U) => string,
    labelPrefix: string = "",
    labelSeparator: string = ""
  ) {
    return getLabel(unit)
      .replace(new RegExp(`^${labelPrefix}${labelSeparator}`), "")
      .trim();
  }

  /*private flattenTree<U>(tree: Tree<U>): FlatTree<U>[] | undefined {
    if (tree.children) {
      const flatTree: FlatTree<U>[] = [];

      for (let path in tree.children) {
        const child = tree.children[path];

        if (!child.treeUnit) {
          throw new Error("error.tree-unit-is-not-defined");
        }

        const children: FlatTree<U>[] = [];
        if (child.nodeUnit && child.nodeUnit !== child.treeUnit) {
          children.push({
            path,
            unit: child.nodeUnit,
            children: []
          });
        }

        const subTree = this.flattenTree(child);
        if (subTree) {
          children.push(...subTree);
        }

        flatTree.push({
          path,
          unit: child.treeUnit,
          children
        });
      }

      return flatTree;
    }
  }*/
}
