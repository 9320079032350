import BalanceParser from "./BalanceParser";
import BarParser from "./BarParser";
import BaseParser from "./BaseParser";
import DepositParser from "./DepositParser";
import FeeParser from "./FeeParser";
import IntegrationParser from "./IntegrationParser";
import InvoiceParser from "./InvoiceParser";
import MenuParser from "./MenuParser";
import OrderParser from "./OrderParser";
import PaymentParser from "./PaymentParser";
import ScannerParser from "./ScannerParser";
import StatsParser from "./StatsParser";
import SubscriptionParser from "./SubscriptionParser";
import VoucherParser from "./VoucherParser";
import ZoneParser from "./ZoneParser";

export const barParser = new BarParser();
export const menuParser = new MenuParser();
export const orderParser = new OrderParser();
export const voucherParser = new VoucherParser();
export const balanceParser = new BalanceParser();
export const baseParser = new BaseParser();
export const zoneParser = new ZoneParser();
export const scannerParser = new ScannerParser();
export const subscriptionParser = new SubscriptionParser();
export const invoiceParser = new InvoiceParser();
export const integrationParser = new IntegrationParser();
export const statsParser = new StatsParser();
export const feeParser = new FeeParser();
export const depositParser = new DepositParser();
export const paymentParser = new PaymentParser();
