import Balance from "../models/balance/Balance";

import { balanceParser } from "../parsers";

export default class BalanceService {
  private readonly fbStore;

  constructor(fbStore) {
    this.fbStore = fbStore;
  }

  protected getBalancesRef() {
    return this.fbStore.collection("balances");
  }

  protected getBalanceRef(barId: string) {
    return this.getBalancesRef().doc(barId);
  }

  private getTransactionsRef(barId: string) {
    return this.fbStore.collection("bars").doc(barId).collection("transactions");
  }

  private getPricingRef() {
    return this.fbStore.collection("pricing");
  }

  async getBalanceById(barId: string): Promise<Balance> {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    const doc = await this.getBalanceRef(barId).get();

    return balanceParser.parseDocToBalance(barId, doc);
  }

  onBalanceById(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    return this.getBalanceRef(barId).onSnapshot((doc) => {
      cb(balanceParser.parseDocToBalance(barId, doc));
    });
  }

  onBalanceLogById(barId: string, maxCount: number, cb: Function) {
    return this.getBalanceRef(barId)
      .collection("log")
      .orderBy("timestamp", "desc")
      .limit(maxCount)
      .onSnapshot((docs) => {
        const balanceLog: Array<any> = [];

        docs.forEach((doc) => {
          balanceLog.push(balanceParser.parseDocToBalanceLogEntry(doc));
        });

        cb(balanceLog);
      });
  }

  onTransactionsById(barId: string, maxCount: number, cb: Function) {
    return this.getTransactionsRef(barId)
      .orderBy("timestamp", "desc")
      .onSnapshot((docs) => {
        const transactions: Array<any> = [];

        docs.forEach((doc) => {
          transactions.push(balanceParser.parseDocToTransaction(doc));
        });

        cb(transactions);
      });
  };

  onPricing(cb: Function) {
    this.getPricingRef().onSnapshot((docs) => {
      const pricing: Array<any> = [];

      docs.forEach((doc) => {
        const id: string = doc.id;
        const data: any = doc.data();

        pricing.push({
          id,
          ...data
        });
      });

      cb(pricing);
    });
  }
}
