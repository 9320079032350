import LayoutNode from "./LayoutNode";
import LayoutNodeType from "./LayoutNodeType";
import LayoutLocation from "./LayoutLocation";
import LayoutNodeBases from "./LayoutNodeBases";
import { zoneHelper } from "../../helpers";

export default class SingleLayoutNode extends LayoutNode {
  constructor(
    code: string,
    name: string,
    bases: LayoutNodeBases | null,
    children?: LayoutNode[]
  ) {
    super(LayoutNodeType.SINGLE, code, name, bases, children);
  }

  public get id() {
    return this.code;
  }

  public get code() {
    return this._code;
  }

  public set code(code: string) {
    const parsedCode = zoneHelper.parseLayoutNodeCode(code);
    if (!this.isCodeValidForMe(parsedCode)) {
      throw new Error("error.code-is-not-valid");
    }

    this._code = parsedCode;
  }

  public getLayoutLocations(
    parentLayoutLocation?: LayoutLocation
  ): LayoutLocation[] {
    return [new LayoutLocation(this.code, this.name, parentLayoutLocation)];
  }

  public toString() {
    return this.name;
  }

  public clone(): SingleLayoutNode {
    return new SingleLayoutNode(
      this.code,
      this.name,
      this.customBases ? this.customBases.clone() : null,
      this.children
        ? this.children.map((child: LayoutNode) => child.clone())
        : undefined
    );
  }
}
