import Payment from "../models/payment/Payment";
import PaymentLog from "../models/payment/PaymentLog";

export default class PaymentParser {
  public parseDocToPayment(doc) {
    const data = doc.data();

    return new Payment(doc.id, {
      ...data,
      timestamp: data.timestamp.toDate()
    });
  }

  public parseDocToPaymentLog(paymentId: string, doc): PaymentLog {
    const data = doc.data();

    return new PaymentLog(paymentId, doc.id, {
      ...data
    });
  }
}
