import { firebase } from "../../firebase";

import ClientAuthService from "./ClientAuthService";
import ClientAdService from "./ClientAdService";
import ClientCrewService from "./ClientCrewService";
import ClientBarService from "./ClientBarService";
import ClientDataService from "./ClientDataService";
import ClientIntegrationService from "./ClientIntegrationService";
import ClientSubscriptionService from "./ClientSubscriptionService";
import ClientMenuService from "./ClientMenuService";
import ClientBaseService from "./ClientBaseService";
import ClientZoneService from "./ClientZoneService";
import ClientScannerService from "./ClientScannerService";
import ClientBalanceService from "./ClientBalanceService";
import ClientOrderService from "./ClientOrderService";
import ClientInvoiceService from "./ClientInvoiceService";
import ClientPaymentService from "./ClientPaymentService";
import ClientVoucherService from "./ClientVoucherService";
import ClientMgmtService from "./ClientMgmtService";
import ClientStatsService from "./ClientStatsService";
import ClientFeeService from "./ClientFeeService";
import ClientDepositService from "./ClientDepositService";

const authService = new ClientAuthService(
  firebase.auth,
  firebase.emailAuthProvider
);
const adService = new ClientAdService();
const crewService = new ClientCrewService(firebase.store);
const barService = new ClientBarService(firebase.store);
const dataService = new ClientDataService(firebase.store);
const integrationService = new ClientIntegrationService(firebase.store);
const subscriptionService = new ClientSubscriptionService(firebase.store);
const menuService = new ClientMenuService(firebase.store);
const baseService = new ClientBaseService(firebase.store);
const zoneService = new ClientZoneService(firebase.store);
const scannerService = new ClientScannerService(firebase.store);
const balanceService = new ClientBalanceService(firebase.store);
const orderService = new ClientOrderService(firebase.store);
const invoiceService = new ClientInvoiceService(firebase.store);
const paymentService = new ClientPaymentService();
const voucherService = new ClientVoucherService(firebase.store);
const mgmtService = new ClientMgmtService(firebase.store);
const statsService = new ClientStatsService(firebase.store);
const feeService = new ClientFeeService(firebase.store);
const depositService = new ClientDepositService(firebase.store);

export {
  authService,
  crewService,
  barService,
  dataService,
  integrationService,
  subscriptionService,
  menuService,
  baseService,
  zoneService,
  scannerService,
  balanceService,
  orderService,
  invoiceService,
  paymentService,
  voucherService,
  mgmtService,
  adService,
  statsService,
  feeService,
  depositService
};
