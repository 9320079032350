import ServiceOption from "./ServiceOption";
import {
  deliveryHelper,
  fulfilmentHelper,
  serviceOptionHelper
} from "../../helpers";
import ServiceOptionName from "./ServiceOptionName";
import ServiceOptionProps from "./ServiceOptionProps";
import DeliveryMethods from "../delivery/DeliveryMethods";
import DeliveryMethod from "../delivery/DeliveryMethod";
import FulfilmentMethod from "../fulfilment/FulfilmentMethod";
import FulfilmentMethods from "../fulfilment/FulfilmentMethods";

class ServiceOptions {
  private _serviceOptions: ServiceOption[];

  constructor(data: ServiceOptionProps[] = []) {
    this._serviceOptions = data.map((item) =>
      serviceOptionHelper.parseDataToServiceOption(item)
    );
  }

  private get serviceOptions() {
    return this._serviceOptions;
  }

  private set serviceOptions(serviceOptions) {
    this._serviceOptions = serviceOptions;
  }

  public get all() {
    return this.serviceOptions;
  }

  public get length() {
    return this.serviceOptions.length;
  }

  public getDeliveryMethods(
    fulfilmentMethod?: FulfilmentMethod
  ): DeliveryMethods {
    const deliveryMethodsAsArray: DeliveryMethod[] = [];

    this.serviceOptions.forEach((serviceOption) => {
      if (
        (!fulfilmentMethod ||
          serviceOption.fulfilmentMethod === fulfilmentMethod) &&
        deliveryMethodsAsArray.indexOf(serviceOption.deliveryMethod) < 0
      ) {
        deliveryMethodsAsArray.push(serviceOption.deliveryMethod);
      }
    });

    return deliveryHelper.createDeliveryMethodsFromArray(
      deliveryMethodsAsArray
    );
  }

  public get deliveryMethods(): DeliveryMethods {
    return this.getDeliveryMethods();
  }

  public get fulfilmentMethods(): FulfilmentMethods {
    const fulfilmentMethodsAsArray: FulfilmentMethod[] = [];

    this.serviceOptions.forEach((serviceOption) => {
      if (
        fulfilmentMethodsAsArray.indexOf(serviceOption.fulfilmentMethod) < 0
      ) {
        fulfilmentMethodsAsArray.push(serviceOption.fulfilmentMethod);
      }
    });

    return fulfilmentHelper.createFulfilmentMethodsFromArray(
      fulfilmentMethodsAsArray
    );
  }

  public forEach(
    cb: (value: ServiceOption, index: number, array: ServiceOption[]) => void
  ) {
    return this.all.forEach(cb);
  }

  public map<T>(
    cb: (value: ServiceOption, index: number, array: ServiceOption[]) => T
  ): T[] {
    return this.all.map(cb);
  }

  public find(serviceOptionName: string): ServiceOption | null {
    for (let i = 0; i < this.serviceOptions.length; i++) {
      if (this.serviceOptions[i].name === serviceOptionName) {
        return this.serviceOptions[i];
      }
    }

    return null;
  }

  public filter(
    cb: (value: ServiceOption, index: number, array: ServiceOption[]) => boolean
  ) {
    this.serviceOptions = this.serviceOptions.filter(cb);
    return this;
  }

  public has(serviceOptionName: ServiceOptionName): boolean {
    return this.find(serviceOptionName) !== null;
  }

  public add(serviceOptionToAdd: ServiceOption) {
    if (!this.has(serviceOptionToAdd.name)) {
      this.serviceOptions.push(serviceOptionToAdd);
    }
  }

  public remove(serviceOptionToRemove: ServiceOption) {
    this.filter(
      (serviceOption) => serviceOption.name !== serviceOptionToRemove.name
    );
  }

  public toJSON() {
    return this.serviceOptions.map((serviceOption) => serviceOption.toJSON());
  }

  public clone(): ServiceOptions {
    return new ServiceOptions(this.toJSON());
  }

  public merge(serviceOptions: ServiceOptions) {
    serviceOptions.all.forEach((serviceOption) => this.add(serviceOption));
  }
}

export default ServiceOptions;
