import Fee from "../models/fee/Fee";
import LayoutPath from "../models/zone/LayoutPath";
import ServiceOptionName from "../models/service/ServiceOptionName";

export default class FeeHelper {
  constructor() {}

  calculateTotalFee(
    fees: Fee[],
    orderTotal: number,
    serviceOptionName: ServiceOptionName,
    zoneCode?: string,
    layoutPath?: LayoutPath
  ): number {
    return fees.reduce((totalFee: number, fee: Fee) => {
      return (
        totalFee +
        fee.calculateFee(orderTotal, serviceOptionName, zoneCode, layoutPath)
      );
    }, 0);
  }
}
