import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Redirect } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import withStyles from "@mui/styles/withStyles";

import ContentPage from "tap-io/client/components/common/ContentPage";

import * as routes from "../constants/routes";

const styles = (theme) => ({
  titleWithIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    color: theme.palette.error.main
  },
  titleIcon: {
    marginRight: 5
  },
  spacing: {
    height: 40
  }
});

class LocatorErrorPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleRedirectToScanQRCodePage = () => {
    this.setState({ doRedirect: true });
  };

  render() {
    const { classes, t, error } = this.props;
    const { doRedirect } = this.state;

    if (doRedirect) {
      return <Redirect to={routes.SCAN_QR_CODE} />;
    }

    return (
      <ContentPage>
        <Typography variant="h6" className={classes.titleWithIcon}>
          <ErrorIcon className={classes.titleIcon} />
          {t("label.error")}
        </Typography>
        <div className={classes.spacing} />
        <Typography>
          {t(error ? error.message : "error.unknown-error")}
        </Typography>
        <div className={classes.spacing} />
        <div>
          <Button
            color="primary"
            variant="contained"
            size="large"
            disableElevation={true}
            onClick={this.handleRedirectToScanQRCodePage}
          >
            {t("label.scan-qr-code")}
          </Button>
        </div>
      </ContentPage>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(LocatorErrorPage));
