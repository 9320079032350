import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import ContentDrawer from "../common/ContentDrawer";
import ScannerLog from "./ScannerLog";

const styles = (theme) => ({});

class ScannerLogDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen } = this.props;
    const prevIsOpen = prevProps.isOpen;

    if (isOpen && !prevIsOpen) {
    }
  }

  render() {
    const {
      classes,
      t,
      isOpen,
      onClose,
      scanner,
      getPinHash,
      onLogEntryClick
    } = this.props;

    return (
      <ContentDrawer
        isOpen={isOpen}
        onClose={onClose}
        title={t("scanner.previously-scanned-orders")}
        description={t("scanner.previously-scanned-orders-below")}
      >
        <ScannerLog
          scanner={scanner}
          getPinHash={getPinHash}
          onLogEntryClick={onLogEntryClick}
        />
      </ContentDrawer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(ScannerLogDrawer)
);
