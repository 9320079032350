import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import { toast } from "react-toastify";

import { withTranslation } from "react-i18next";

import { serviceHelper } from "../../../helpers";

const styles = (theme) => ({});

class CheckConnection extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { t } = this.props;

    serviceHelper.onConnected((isConnected) => {
      if (this.isConnectedTimeout) {
        clearTimeout(this.isConnectedTimeout);
        this.isConnectedTimeout = undefined;
      }
      this.setState({ isConnected });

      if (isConnected) {
        if (this.isDisconnectedToastId) {
          toast.dismiss(this.isDisconnectedToastId);
          this.isDisconnectedToastId = undefined;
        }
      } else {
        this.isConnectedTimeout = setTimeout(() => {
          if (!this.state.isConnected && !this.isDisconnectedToastId) {
            this.isDisconnectedToastId = toast(
              t("error.connection-with-server-lost-check-internet-connection"),
              {
                type: toast.TYPE.ERROR,
                autoClose: false,
                closeOnClick: false,
                draggable: false,
                closeButton: false
              }
            );
          }
        }, 15000);
      }
    });
  }

  render() {
    return null;
  }
}

export default withStyles(styles)(withTranslation("common")(CheckConnection));
