import { serviceHelper } from "../../helpers";
import Data from "./Data";
import DataName from "./DataName";

class BusinessData extends Data {
  private _name: string = "";
  private _vatNumber: string = "";
  private _address: {
    streetName: string
    houseNumber: string,
    boxName: string,
    zipCode: string,
    cityName: string
  } = {
    streetName: "",
    houseNumber: "",
    boxName: "",
    zipCode: "",
    cityName: ""
  };
  private _contact: {
    firstName: string
    lastName: string,
    emailAddress: string
  } = {
    firstName: "",
    lastName: "",
    emailAddress: ""
  };

  constructor(barId: string, props: any) {
    super(barId, DataName.BUSINESS);

    if (props) {
      if (props.name) {
        this.name = props.name;
      }
      if (props.vatNumber) {
        this.vatNumber = props.vatNumber;
      }
      if (props.address) {
        if (props.address.streetName) {
          this.addressStreetName = props.address.streetName;
        }
        if (props.address.houseNumber) {
          this.addressHouseNumber = props.address.houseNumber;
        }
        if (props.address.boxName) {
          this.addressBoxName = props.address.boxName;
        }
        if (props.address.zipCode) {
          this.addressZipCode = props.address.zipCode;
        }
        if (props.address.cityName) {
          this.addressCityName = props.address.cityName;
        }
      }
      if (props.contact) {
        if (props.contact.firstName) {
          this.contactFirstName = props.contact.firstName;
        }
        if (props.contact.lastName) {
          this.contactLastName = props.contact.lastName;
        }
        if (props.contact.emailAddress) {
          this.contactEmailAddress = props.contact.emailAddress;
        }
      }
    }

    super.setAllProps(["name", "vatNumber", "address", "contact"]);
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = serviceHelper.parseString(name);
    super.setPropUpdated("name");
  }

  public get vatNumber(): string {
    return this._vatNumber;
  }

  public set vatNumber(vatNumber: string) {
    this._vatNumber = serviceHelper.parseString(vatNumber);
    super.setPropUpdated("vatNumber");
  }

  protected get address(): any {
    return this._address;
  }

  public get addressStreetName(): string {
    return this._address.streetName;
  }

  public set addressStreetName(streetName: string) {
    this._address.streetName = serviceHelper.parseString(streetName);
    super.setPropUpdated("address");
  }

  public get addressHouseNumber(): string {
    return this._address.houseNumber;
  }

  public set addressHouseNumber(houseNumber: string) {
    this._address.houseNumber = serviceHelper.parseString(houseNumber);
    super.setPropUpdated("address");
  }

  public get addressBoxName(): string {
    return this._address.boxName;
  }

  public set addressBoxName(boxName: string) {
    this._address.boxName = boxName;
    super.setPropUpdated("address");
  }

  public get addressZipCode(): string {
    return this._address.zipCode;
  }

  public set addressZipCode(zipCode: string) {
    this._address.zipCode = serviceHelper.parseString(zipCode);
    super.setPropUpdated("address");
  }

  public get addressCityName(): string {
    return this._address.cityName;
  }

  public set addressCityName(cityName: string) {
    this._address.cityName = serviceHelper.parseString(cityName);
    super.setPropUpdated("address");
  }

  protected get contact(): any {
    return this._contact;
  }

  public get contactFirstName(): string {
    return this._contact.firstName;
  }

  public set contactFirstName(firstName: string) {
    this._contact.firstName = serviceHelper.parseString(firstName);
    super.setPropUpdated("contact");
  }

  public get contactLastName(): string {
    return this._contact.lastName;
  }

  public set contactLastName(lastName: string) {
    this._contact.lastName = serviceHelper.parseString(lastName);
    super.setPropUpdated("contact");
  }

  public get contactEmailAddress(): string {
    return this._contact.emailAddress;
  }

  public set contactEmailAddress(emailAddress: string) {
    this._contact.emailAddress = serviceHelper.parseString(emailAddress);
    super.setPropUpdated("contact");
  }
}

export default BusinessData;