class PropsModel {
  private _allProps: Array<string> = [];
  private readonly _updatedProps: Array<string> = [];

  private _id: string | undefined;

  protected constructor(id?: string) {
    if (id) {
      this._id = id;
    }
  }

  public get id(): string | undefined {
    return this._id;
  }

  public set id(id: string | undefined) {
    if (this._id) {
      throw new Error("id is already set");
    }
    if (!id) {
      throw new Error("id is not defined");
    }

    this._id = id;
  }

  /*protected parseProp(prop: string, value: any) {
    throw new Error("error.prop-unknown");
  }

  protected initProp(prop: string, value: any, defaultValue?: any) {
    this[`_${prop}`] = value === undefined && defaultValue !== undefined ?
      defaultValue :
      this.parseProp(prop, value);
  }

  protected getProp(prop: string) {
    return this[`_${prop}`];
  }

  protected setProp(prop: string, value) {
    this[`_${prop}`] = this.parseProp(prop, value);

    this.setPropUpdated(prop);
  }*/

  private getValueAsJSON(value: any) {
    if (value !== undefined) {
      // Date also has an toJSON function and this returns the date in an ISO string format
      // For Firestore, we want the Date format (not a string)
      if (value && value.toJSON && !(value instanceof Date)) {
        return value.toJSON();
      } else {
        return value;
      }
    }
  }

  private propsToJSON(props: Array<string>) {
    const json: { [key: string]: any } = {};

    props.forEach(prop => {
      const value: any = this[prop];

      if (value instanceof Array) {
        json[prop] = value.map(item => this.getValueAsJSON(item));
      } else if (value !== undefined) {
        json[prop] = this.getValueAsJSON(value);
      }
    });

    return json;
  }

  protected setAllProps(props: Array<string>) {
    this._allProps = props;
  }

  protected setPropUpdated(prop: string) {
    if (this._updatedProps.indexOf(prop) < 0) {
      this._updatedProps.push(prop);
    }
  }

  public updatedPropsToJSON() {
    return this.propsToJSON(this._updatedProps);
  }

  public allPropsToJSON(): any {
    return this.propsToJSON(this._allProps);
  }
}

export default PropsModel;
