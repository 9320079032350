import Bar from "../models/bar/Bar";
import Item from "../models/menu/Item";
import Category from "../models/menu/Category";

export default class CloudStorage {
  private readonly fbStorage;

  constructor(fbStorage) {
    this.fbStorage = fbStorage;
  }

  private getBarRef(barId: string) {
    return this.fbStorage.ref("bars").child(barId);
  }

  private getLowResFilenameItem(fileName: string) {
    return `lowres_${fileName}`;
  }

  private getBarLogoImageRef(barId: string, fileName: string) {
    return this.getBarAssetsRef(barId).child(fileName);
  }

  private getBarMenuCategoryImageRef(
    barId: string,
    menuId: string,
    fileName: string
  ) {
    return this.getBarMenuCategoryImagesRef(barId, menuId).child(fileName);
  }

  private getBarMenuItemImageRef(
    barId: string,
    menuId: string,
    fileName: string
  ) {
    return this.getBarMenuItemImagesRef(barId, menuId).child(fileName);
  }

  private async getImageUrl(fileName: string, getRefFunction, ...getRefArgs) {
    try {
      // Always try to get lowRes first
      const lowResUrl = await getRefFunction
        .bind(this)(...getRefArgs, this.getLowResFilenameItem(fileName))
        .getDownloadURL();
      return lowResUrl;
    } catch (error) {
      console.warn(error);
      const originalUrl = await getRefFunction(
        ...getRefArgs,
        fileName
      ).getDownloadURL();
      return originalUrl;
    }
  }

  public getBarAssetsRef(barId: string) {
    return this.getBarRef(barId).child("assets");
  }

  public getBarMenuCategoryImagesRef(barId: string, menuId: string) {
    return this.getBarRef(barId)
      .child("menus")
      .child(menuId)
      .child("categories");
  }

  public getBarMenuItemImagesRef(barId: string, menuId: string) {
    return this.getBarRef(barId).child("menus").child(menuId).child("items");
  }

  public getBarLogoImageUrl(bar: Bar) {
    const logoFilename = bar.getLogoFileName();
    return this.getImageUrl(
      logoFilename,
      this.getBarLogoImageRef.bind(this),
      bar.id
    );
  }

  public getBarCoverImageUrl(bar: Bar) {
    const coverFilename = bar.getCoverFileName();
    return this.getImageUrl(
      coverFilename,
      this.getBarLogoImageRef.bind(this),
      bar.id
    );
  }

  public getBarMenuCategoryImageUrl(barId: string, menuCategory: Category) {
    if (!menuCategory.imageFilename) {
      throw new Error("error.menu-category-image-filename-is-not-defined");
    }

    return this.getImageUrl(
      menuCategory.imageFilename,
      this.getBarMenuCategoryImageRef.bind(this),
      barId,
      menuCategory.menuId
    );
  }

  public getBarMenuItemImageUrl(barId: string, menuItem: Item) {
    if (!menuItem.imageFilename) {
      throw new Error("error.menu-item-image-filename-is-not-defined");
    }

    return this.getImageUrl(
      menuItem.imageFilename,
      this.getBarMenuItemImageRef.bind(this),
      barId,
      menuItem.menuId
    );
  }
}
