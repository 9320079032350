import boLogo from "../assets/bo-logo.png";
import boBackground from "../assets/bo-background.jpg";
import SocialButtons from "../components/promo/SocialButtons";

const DEFAULT_BACKGROUND = "#fafafa";

const options = (primaryColor, secondaryColor) => {
  return {
    shadows: Array(25).fill("none"),
    palette: {
      background: {
        default: DEFAULT_BACKGROUND
      },
      primary: {
        main: primaryColor
      },
      secondary: {
        main: secondaryColor
      },
      tertiary: {
        main: primaryColor
      }
    }
  };
};

const assets = {
  logo: boLogo,
  qrCodeLogo: null,
  headerLogoSmall: null,
  headerLogoLarge: null,
  background: boBackground
};

const socials = SocialButtons;

const faviconFolder = "/favicon-bo";

export default {
  editableColors: ["primary", "secondary"],
  faviconFolder,
  options,
  assets,
  socials
};
