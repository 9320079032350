import { utilsHelper } from "../../helpers";

export default class StatsDataTime {
  private readonly _hours: string;
  private readonly _minutes: string;

  constructor(time: string | number) {
    switch (typeof time) {
      case "string":
        const matches = time.match(/^(\d\d)(\d\d)$/);
        if (!matches || matches.length !== 3) {
          throw new Error("error.time-format-is-invalid");
        }

        this._hours = matches[1];
        this._minutes = matches[2];
        break;
      case "number":
        const hours: number = Math.floor(time / 60);
        const minutes: number = time % 60;

        this._hours = utilsHelper.addLeadingPadding(`${hours}`, 2, "0");
        this._minutes = utilsHelper.addLeadingPadding(`${minutes}`, 2, "0");
        break;
      default:
        throw new Error("error.time-type-is-not-valid");
        break;
    }
  }

  public get hours(): string {
    return this._hours;
  }

  public get minutes(): string {
    return this._minutes;
  }

  public get preciseTime(): string {
    return `${this.hours}${this.minutes}`;
  }

  public get roundedToHourTime(): string {
    return `${this.hours}00`;
  }

  public get roundedToQuarterTime(): string {
    const minutesAsNumber = parseInt(this.minutes);

    const minutes =
      minutesAsNumber < 15 ?
        "00" :
        minutesAsNumber < 30 ?
          "15" :
          minutesAsNumber < 45 ?
            "30" :
            "45";

    return `${this.hours}${minutes}`;
  }

  public get timeInMinutes(): number {
    const hours = parseInt(this.hours);
    const minutes = parseInt(this.minutes);

    if (isNaN(hours)) {
      throw new Error("error.hours-is-nan");
    }
    if (isNaN(minutes)) {
      throw new Error("error.minutes-is-nan");
    }

    return hours * 60 + minutes;
  }
}