import DeliveryMethod from "./DeliveryMethod";

class DeliveryMethods {
  private readonly _isServeActive: boolean;
  private readonly _isPickupActive: boolean;

  constructor(data: any) {
    this._isServeActive = !!(
      data &&
      data[DeliveryMethod.SERVE] &&
      data[DeliveryMethod.SERVE].isActive === true
    );
    this._isPickupActive = !!(
      data &&
      data[DeliveryMethod.PICKUP] &&
      data[DeliveryMethod.PICKUP].isActive === true
    );
  }

  public isDeliveryMethodActive(deliveryMethod: DeliveryMethod): boolean {
    switch (deliveryMethod) {
      case DeliveryMethod.SERVE:
        return this.isServeActive;
        break;
      case DeliveryMethod.PICKUP:
        return this.isPickupActive;
        break;
      default:
        throw new Error("error.delivery-method-is-not-valid");
        break;
    }
  }

  public get isServeActive(): boolean {
    return this._isServeActive;
  }

  public get isPickupActive(): boolean {
    return this._isPickupActive;
  }

  public get isAnyDeliveryMethodActive(): boolean {
    return this.isServeActive || this.isPickupActive;
  }

  public get activeDeliveryMethods(): DeliveryMethod[] {
    const activeDeliveryMethods: DeliveryMethod[] = [];

    if (this.isServeActive) {
      activeDeliveryMethods.push(DeliveryMethod.SERVE);
    }
    if (this.isPickupActive) {
      activeDeliveryMethods.push(DeliveryMethod.PICKUP);
    }

    return activeDeliveryMethods;
  }

  public isEqualTo(deliveryMethods: DeliveryMethods) {
    return (
      this.isServeActive === deliveryMethods.isServeActive &&
      this.isPickupActive === deliveryMethods.isPickupActive
    );
  }

  public toJSON() {
    return {
      [DeliveryMethod.SERVE]: {
        isActive: this.isServeActive
      },
      [DeliveryMethod.PICKUP]: {
        isActive: this.isPickupActive
      }
    };
  }

  public clone(): DeliveryMethods {
    return new DeliveryMethods(this.toJSON());
  }
}

export default DeliveryMethods;
