import { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";
import QrReader from "tap-io/client/components/common/QrReader";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { orderHelper } from "tap-io/helpers";

import ContentDrawer from "../common/ContentDrawer";

const styles = (theme) => ({
  blurredQrReader: {
    filter: "blur(10px)"
  },
  isPausedContainer: {
    position: "relative",
    width: "100%",
    paddingTop: "100%"
  },
  isPausedContent: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.default
  },
  spacing: {
    height: 40
  }
});

const PAUSED_TIMEOUT = 15000;

class ScanOrderDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
      isPaused: false
    };
  }

  componentDidMount() {
    // isReady can't be TRUE yet
    // this.refreshSetPausedTimeout();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen } = this.props;
    const prevIsOpen = prevProps.isOpen;
    const { isReady } = this.state;
    const wasReady = prevState.isReady;

    /*if (isOpen && !prevIsOpen) {
      //this.checkNativeScanner();
    }*/

    if (isOpen) {
      if (isReady && (!wasReady || !prevIsOpen)) {
        this.refreshSetPausedTimeout();
      }
    } else {
      if (prevIsOpen) {
        this.clearSetPausedTimeout();
      }
    }
  }

  componentWillUnmount() {
    this.clearSetPausedTimeout();
  }

  handleIsPausedClick = () => {
    this.refreshSetPausedTimeout();
  };

  refreshSetPausedTimeout = () => {
    this.clearSetPausedTimeout();

    this.setPausedTimeout = setTimeout(
      () => this.setState({ isPaused: true }),
      PAUSED_TIMEOUT
    );
  };

  clearSetPausedTimeout = () => {
    if (this.setPausedTimeout) {
      clearTimeout(this.setPausedTimeout);
      this.setPausedTimeout = undefined;
    }

    this.setState({ isPaused: false });
  };

  /*checkNativeScanner = async () => {
    const wrapper = IOSWrapper.get();

    if (wrapper) {
      this.unsubscribeScanner = await wrapper.onQRCodeScanned(async (data) => {
        this.unsubscribeScanner();

        await wrapper.hideQRCodeScanner();

        this.handleScan(data);
      });
      await wrapper.showQRCodeScanner();
    }
  };*/

  handleReady = () => {
    this.setState({ isReady: true });
  };

  handleSuccess = (data) => {
    const { t, onScan } = this.props;

    if (data) {
      try {
        const { barLocator, orderId } = orderHelper.parseOrderUrl(
          config.hosting.orderDomain,
          data
        );

        onScan(barLocator, orderId);
      } catch (error) {
        console.warn(error);

        toast.error(
          `${t("label.something-went-wrong")} (${t(
            error ? error.message : "error.unknown-error"
          )})`
        );
      }
    }
  };

  handleError = (error) => {
    const { t } = this.props;

    toast.error(
      `${t("label.something-went-wrong")} (${
        error ? error.toString() : t("error.unknown-error")
      })`
    );
  };

  render() {
    const { classes, t, isOpen, onClose } = this.props;
    const { isPaused } = this.state;

    return (
      <ContentDrawer
        isOpen={isOpen}
        onClose={onClose}
        title={t("scanner.scan-order")}
        description={t("scanner.scan-order-qr-code-below")}
      >
        {isPaused ? (
          <div
            className={classes.isPausedContainer}
            onClick={this.handleIsPausedClick}
          >
            <div className={classes.isPausedContent}>
              <Typography variant="h6">{t("scanner.camera-paused")}</Typography>
              <div className={classes.spacing} />
              <Typography variant="body">
                {t("scanner.tap-here-to-resume-camera")}
              </Typography>
            </div>
          </div>
        ) : (
          <QrReader
            onReady={this.handleReady}
            onSuccess={this.handleSuccess}
            onError={this.handleError}
            //delay={300}
            //style={{ width: "100%" }}
          />
        )}
      </ContentDrawer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(ScanOrderDrawer)
);
