import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import ReactMoment from "react-moment";

import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import AdjustIcon from "@mui/icons-material/Adjust";
import RestoreIcon from "@mui/icons-material/Restore";
import withStyles from "@mui/styles/withStyles";

import { barService, voucherService } from "../../services";
import config from "../../env";
import { orderHelper } from "../../../helpers";

const VOUCHER_LOG_INITIAL_BATCH_SIZE = 5;
const VOUCHER_LOG_ADDITIONAL_BATCH_SIZE = 5;

const styles = (theme) => ({
  spinner: {
    justifyContent: "center"
  },
  entryLabel: {
    display: "block",
    fontWeight: "bold"
  },
  entryValue: {
    display: "block"
  },
  plus: {
    color: "green"
  },
  minus: {
    color: "red"
  },
  zero: {
    color: "grey"
  },
  disabledColor: {
    color: theme.palette.action.disabled
  }
});

class VoucherLog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      barUrl: null,
      voucherLogMaxCount: VOUCHER_LOG_INITIAL_BATCH_SIZE
    };
  }

  componentDidMount() {
    this.refreshVoucherLog();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { voucher } = this.props;
    const { voucherLogMaxCount } = this.state;

    const voucherId = voucher ? voucher.id : undefined;
    const prevVoucherId = prevProps.voucher ? prevProps.voucher.id : undefined;

    const prevVoucherLogMaxCount = prevState.voucherLogMaxCount;

    if (
      voucherId !== prevVoucherId ||
      voucherLogMaxCount !== prevVoucherLogMaxCount
    ) {
      this.refreshVoucherLog();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeVoucherLog) {
      this.unsubscribeVoucherLog();
      this.unsubscribeVoucherLog = undefined;
    }
  }

  fetchBarUrlIfNeeded = async () => {
    const { bar } = this.props;
    const { barUrl } = this.state;

    if (!barUrl) {
      const url = await barService.getBarUrl(bar, config.hosting.orderDomain);
      this.setState({ barUrl: url });
    }
  };

  refreshVoucherLog = () => {
    const { voucher } = this.props;
    const { voucherLogMaxCount } = this.state;

    if (this.unsubscribeVoucherLog) {
      this.unsubscribeVoucherLog();
      this.unsubscribeVoucherLog = undefined;
    }

    if (voucher) {
      this.setState({ isLoading: true });

      this.unsubscribeVoucherLog = voucherService.onVoucherLog(
        voucher,
        async (voucherLog) => {
          this.setState({ isLoading: false, voucherLog });

          if (voucherLog && voucherLog.length > 0) {
            await this.fetchBarUrlIfNeeded();
          }
        },
        voucherLogMaxCount
      );
    } else {
      this.setState({ isLoading: false, voucherLog: [] });
    }
  };

  handleFetchMoreVoucherLogs = () => {
    const { voucherLogMaxCount } = this.state;

    this.setState({
      voucherLogMaxCount: voucherLogMaxCount + VOUCHER_LOG_ADDITIONAL_BATCH_SIZE
    });
  };

  handleEntryClick = (entry) => async (event) => {
    const { barUrl } = this.state;

    if (this.hasEntryOrderId(entry)) {
      if (barUrl) {
        return window.open(
          orderHelper.getOrderUrl(barUrl, entry.orderId),
          "_blank"
        );
      } else {
        toast.error("error.bar-url-is-not-defined");
      }
    }
  };

  getEntryLabel = (entry) => {
    const { t } = this.props;

    if (this.hasEntryOrderId(entry)) {
      return t(
        entry.voucherValueDifference > 0 ? "label.refund" : "label.order"
      );
    }
  };

  getEntryValue = (entry) => {
    const { t } = this.props;

    return `${t("voucher.new-value")}: ${entry.newVoucherValueToString(
      t
    )} (${entry.voucherValueDifferenceToString(t)})`;
  };

  hasEntryOrderId = (entry) => {
    return !!entry.orderId;
  };

  render() {
    const { classes, t } = this.props;
    const { isLoading, voucherLog, voucherLogMaxCount } = this.state;

    return (
      <div>
        <List>
          {isLoading ? (
            <ListItem className={classes.spinner}>
              <CircularProgress />
            </ListItem>
          ) : (
            <div>
              {voucherLog && voucherLog.length > 0 ? (
                voucherLog.map((entry) => (
                  <ListItem
                    key={entry.id}
                    onClick={this.handleEntryClick(entry)}
                    button={this.hasEntryOrderId(entry)}
                  >
                    <ListItemIcon>
                      {this.hasEntryOrderId(entry) ? (
                        <LaunchIcon />
                      ) : (
                        <AdjustIcon className={classes.disabledColor} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <span>
                          <span className={classes.entryLabel}>
                            {this.getEntryLabel(entry)}
                          </span>
                          <span
                            className={classNames(
                              classes.entryValue,
                              entry.voucherValueDifference > 0
                                ? classes.plus
                                : classes.minus
                            )}
                          >
                            {this.getEntryValue(entry)}
                          </span>
                        </span>
                      }
                      secondary={
                        <ReactMoment format="DD/MM/YYYY, HH:mm:ss">
                          {entry.timestamp}
                        </ReactMoment>
                      }
                    />
                  </ListItem>
                ))
              ) : (
                <ListItem>{t("voucher.no-orders-found")}</ListItem>
              )}
            </div>
          )}
          {voucherLog && voucherLogMaxCount <= voucherLog.length && (
            <ListItemButton
              disabled={isLoading}
              onClick={this.handleFetchMoreVoucherLogs}
            >
              <ListItemIcon>
                <RestoreIcon />
              </ListItemIcon>
              <ListItemText primary={t("label.load-more-items")} />
            </ListItemButton>
          )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(VoucherLog));
