import { authHelper, serviceHelper } from "../../helpers";
import IntegrationService from "../../services/IntegrationService";

export default class ClientIntegrationService extends IntegrationService {
  constructor(fbStore) {
    super(fbStore);
  }

  public async getAbiEvents(
    integrationApiFunctionsEndpoint: string,
    authenticatedUser: any,
    barId: string
  ) {
    const link = `${integrationApiFunctionsEndpoint}/abi/app/events`;
    const params = { barId };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    const rawData = await serviceHelper.fetchJSON(url);

    return rawData;
  }

  public async verifyAbi(
    integrationApiFunctionsEndpoint: string,
    authenticatedUser: any,
    barId: string,
    username: string,
    password: string,
    pocId: string
  ) {
    const link = `${integrationApiFunctionsEndpoint}/abi/app/verify`;
    const params = { barId, username, password, pocId };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    const rawData = await serviceHelper.fetchJSON(url, { method: "POST" });

    return rawData;
  }

  public async syncAbi(
    integrationApiFunctionsEndpoint: string,
    authenticatedUser: any,
    barId: string
  ) {
    const link = `${integrationApiFunctionsEndpoint}/abi/app/sync`;
    const params = { barId };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    const rawData = await serviceHelper.fetchJSON(url, { method: "POST" });

    return rawData;
  }

  public async getStarnetCardInfo(
    integrationApiFunctionsEndpoint: string,
    authenticatedUser: any,
    barId: string,
    cardNumber: string
  ) {
    const link = `${integrationApiFunctionsEndpoint}/abi/card/info`;
    const params = { barId, cardNumber };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    const rawData = await serviceHelper.fetchJSON(url);

    return rawData;
  }

  public async payOrderWithStarnet(
    integrationApiFunctionsEndpoint: string,
    authenticatedUser: any,
    barId: string,
    orderId: string,
    cardNumber: string,
    cardPin?: string,
    emailAddress?: string
  ) {
    const link = `${integrationApiFunctionsEndpoint}/abi/order/pay`;
    const data: any = { barId, orderId, cardNumber };

    if (cardPin) {
      data.cardPin = cardPin;
    } else if (emailAddress) {
      data.emailAddress = emailAddress;
    } else {
      throw new Error("error.card-pin-and-email-address-not-defined");
    }

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link
    );

    const rawData = await serviceHelper.fetchJSON(url, {
      method: "POST",
      body: JSON.stringify(data)
    });

    return rawData;
  }

  public async syncOrder(
    integrationApiFunctionsEndpoint: string,
    authenticatedUser: any,
    barId: string,
    orderId: string,
    cardNumber?: string,
    cardPin?: string
  ) {
    const link = `${integrationApiFunctionsEndpoint}/abi/order/sync`;
    const data: any = { barId, orderId };
    if (cardNumber && cardPin) {
      data.cardNumber = cardNumber;
      data.cardPin = cardPin;
    }

    // TO FIX: put barId in query OR body
    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      { barId }
    );

    const rawData = await serviceHelper.fetchJSON(url, {
      method: "POST",
      body: JSON.stringify(data)
    });

    return rawData;
  }
}
