import ServiceOption from "./ServiceOption";
import ServiceOptionName from "./ServiceOptionName";
import FulfilmentMethod from "../fulfilment/FulfilmentMethod";
import DeliveryMethod from "../delivery/DeliveryMethod";
import PriorityNumber from "../priority/PriorityNumber";

export default class PickupServiceOption extends ServiceOption {
  constructor() {
    super(
      ServiceOptionName.PICKUP,
      FulfilmentMethod.AS_SOON_AS_POSSIBLE,
      DeliveryMethod.PICKUP,
      PriorityNumber.MEDIUM
    );
  }
}
