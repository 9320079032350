enum PaymentMethod {
  VOUCHER = "voucher",
  DEPOSIT = "deposit",
  MOBILE = "mobile",
  CARD_CREDIT = "card-credit",
  CARD_DEBIT = "card-debit",
  CASH = "cash",
  INVOICE = "invoice",
  INTERNAL_EXPENSES = "internal-expenses",
  NONE = "none"
}

export default PaymentMethod;
