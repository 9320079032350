enum CapabilityName {
  BASES = "bases",
  ZONES = "zones",
  SCANNERS = "scanners",
  STATS = "stats",
  VOUCHERS = "vouchers",
  MODES = "modes",
  FEES = "fees",
  DEPOSITS = "deposits",
  SERVICES = "services"
}

export default CapabilityName;
