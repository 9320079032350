export default class InvoicePeriod {
  public year: number = 0;
  public month: number = 0;

  constructor(data?: any) {
    if (data) {
      if (data.year) {
        this.year = data.year;
      }
      if (data.month) {
        this.month = data.month;
      }
    }
  }

  public toJSON(): any {
    return {
      year: this.year,
      month: this.month
    };
  }
}
