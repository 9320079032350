import { serviceHelper, serviceOptionHelper } from "../../helpers";
import BarPropsModel from "../model/BarPropsModel";
import DeliveryMethods from "../delivery/DeliveryMethods";
import WholeNumbersLayoutNodeSequence from "./WholeNumbersLayoutNodeSequence";
import { zoneParser } from "../../parsers";
import ZoneLayoutNode from "./ZoneLayoutNode";
import SeriesLayoutNode from "./SeriesLayoutNode";
import SequenceLayoutNode from "./SequenceLayoutNode";
import ZoneHelper from "../../helpers/ZoneHelper";
import LayoutNodeBases from "./LayoutNodeBases";
import ServiceOptions from "../service/ServiceOptions";
import ServiceOptionName from "../service/ServiceOptionName";

class Zone extends BarPropsModel {
  private _isActive: boolean;
  private _layout: ZoneLayoutNode;
  private readonly _timestamp: Date | undefined;

  constructor(barId: string, id?: string, props?: any) {
    super(barId, id);

    if (!props) {
      throw new Error("props is not defined");
    }

    this._isActive =
      props.isActive === undefined
        ? false
        : serviceHelper.parseBoolean(props.isActive);

    // Legacy tableNumbers/tableCount
    if (props.layout) {
      this._layout = zoneParser.parseLayout(props.layout);
    } else {
      const name = serviceHelper.parseString(props.name);

      const deliveryMethods = new DeliveryMethods(props.deliveryMethods);

      const baseIds = this.parseBaseIds(props.baseIds || []);
      const basesData: any = {};
      baseIds.forEach((baseId) => {
        basesData[baseId] = {
          serviceOptions:
            serviceOptionHelper.createDefaultServiceOptions(deliveryMethods)
        };
      });
      const bases = new LayoutNodeBases(basesData);

      if (props.tableNumbers && props.tableNumbers.length > 0) {
        // Transforms old tableNumbers to new layout
        this._layout = new ZoneLayoutNode(
          this.code,
          name,
          props.isTableNumberRequiredToOrder ? null : bases,
          [
            new SeriesLayoutNode(
              ZoneHelper.LEGACY_TABLE_LAYOUT_NODE_CODE,
              ZoneHelper.LEGACY_TABLE_LAYOUT_NODE_NAME,
              props.tableNumbers.map((tableNumber: number) => `${tableNumber}`),
              props.isTableNumberRequiredToOrder ? bases : null
            )
          ]
        );
      } else if (props.tableCount) {
        // Transforms old tableCount to new layout
        this._layout = new ZoneLayoutNode(
          this.code,
          name,
          props.isTableNumberRequiredToOrder ? null : bases,
          [
            new SequenceLayoutNode(
              ZoneHelper.LEGACY_TABLE_LAYOUT_NODE_CODE,
              ZoneHelper.LEGACY_TABLE_LAYOUT_NODE_NAME,
              new WholeNumbersLayoutNodeSequence(1, props.tableCount, 1),
              props.isTableNumberRequiredToOrder ? bases : null
            )
          ]
        );
      } else {
        // Transforms old table-less zone to new layout
        this._layout = new ZoneLayoutNode(this.code, name, bases);
      }
    }

    if (props.timestamp) {
      this._timestamp = serviceHelper.parseTimestamp(props.timestamp);
    }

    // Include baseIds (index for e.g. onBaseDeleted() Firebase Function)
    // Include serviceOptionNames (index for e.g. onZoneCreatedUpdated() Firebase Function)
    super.setAllProps([
      "isActive",
      "layout",
      "baseIds",
      "serviceOptionNames",
      "timestamp"
    ]);
  }

  private parseBaseIds(baseIds: Array<string>): Array<string> {
    return baseIds ? baseIds : [];
  }

  public get code(): string {
    return this.id ? this.id : "";
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public get name(): string {
    return this.layout.name;
  }

  public get serviceOptions(): ServiceOptions {
    return this.layout.allServiceOptions;
  }

  public get baseIds(): string[] {
    return this.layout.allBaseIds;
  }

  public get serviceOptionNames(): ServiceOptionName[] {
    return this.serviceOptions.map((serviceOption) => serviceOption.name);
  }

  public get timestamp(): Date | undefined {
    return this._timestamp;
  }

  public get layout(): ZoneLayoutNode {
    return this._layout;
  }

  public set isActive(isActive: boolean) {
    this._isActive = serviceHelper.parseBoolean(isActive);
    super.setPropUpdated("isActive");
  }

  public set layout(layout: ZoneLayoutNode) {
    this._layout = layout;
    super.setPropUpdated("layout");
    super.setPropUpdated("baseIds");
    super.setPropUpdated("serviceOptionNames");
  }
}

export default Zone;
