import OrderService from "../../services/OrderService";
import Order from "../../models/order/Order";
import { serviceHelper } from "../../helpers";

export default class ClientOrderService extends OrderService {
  constructor(fbStore) {
    super(fbStore);
  }

  public cancelOrder(
    userId: string,
    order: Order,
    data: any = {},
    onProgress?: Function
  ) {
    return serviceHelper.createTask(
      userId,
      "order",
      "cancel",
      {
        ...data,
        barId: order.barId,
        orderId: order.id
      },
      onProgress
    );
  }
}
