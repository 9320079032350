export default {
  firebase: {
    apiKey: "AIzaSyCZbkZ-syEdC5sD_wwMU-CF3VYeNZJX6zM",
    authDomain: "tap-io.firebaseapp.com",
    databaseURL: "https://tap-io.firebaseio.com",
    projectId: "tap-io",
    storageBucket: "tap-io.appspot.com",
    messagingSenderId: "478533438452"
  },
  gtm: {
    id: null
  },
  analytics: {
    trackingIds: {
      app: ["G-181WH1CHBV"],
      order: ["G-DE6TDC9VR0"],
      scan: ["G-W6SW82B4LJ"]
    }
  },
  functions: {
    api: "https://europe-west1-tap-io.cloudfunctions.net/api",
    statsApi: "https://europe-west1-tap-io.cloudfunctions.net/Stats-API",
    promoApi: "https://europe-west1-tap-io.cloudfunctions.net/promo",
    integrationsApi:
      "https://europe-west1-tap-io.cloudfunctions.net/integrations",
    vivaWalletWebhook:
      "https://europe-west1-tap-io.cloudfunctions.net/vivawallet/webhook",
    vivaWalletCheckout:
      "https://europe-west1-tap-io.cloudfunctions.net/vivawallet/checkout"
  },
  stripe: {
    pubApiKey: "pk_live_WuCarCTo8fVBYBLcI5CzNIw000KRBd5WLL"
  },
  hosting: {
    domain: "app.watdrinkje.be",
    appDomain: "app.watdrinkje.be",
    orderDomain: "bestel.watdrinkje.be",
    scanDomain: "scan.watdrinkje.be"
  },
  bugsnag: {
    apiKey: "7245d8363e70e06ea8ea5d027f6ffcf1",
    releaseStage: "production"
  },
  cookies: {
    manager: {
      type: "cookiebot",
      domainIds: {
        app: null,
        order: "5aac2af7-d8cf-4166-be95-a905c0b7b7af",
        scan: null
      }
    }
  },
  links: {
    promo: "https://watdrinkje.be",
    termsOfUse: "https://watdrinkje.be/gebruikersvoorwaarden",
    privacyPolicy: "https://watdrinkje.be/privacy-policy",
    starnetPinReset: "https://guard-abi.web.app"
  },
  defaults: {
    name: "bo"
  },
  product: {
    name: "Wat Drink Je?",
    tagline: null
  },
  support: {
    email: null
  },
  paymentProviders: {
    mollie: true,
    payconiq: true,
    vivawallet: true,
    multisafepay: true
  },
  integrations: {},
  native: {
    appStoreUrl: "https://apps.apple.com/be/app/wat-drink-je/id1490103401"
  }
};
