import CrewService from "../../services/CrewService";
import { authHelper, serviceHelper } from "../../helpers";

export default class ClientCrewService extends CrewService {
  constructor(fbStore) {
    super(fbStore);
  }

  public async grantUserAccessToBar(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    barId: string
  ): Promise<any> {
    const link = `${apiFunctionsEndpoint}/mgmt/bars/${barId}/grant`;

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link
    );

    const data = await serviceHelper.fetchJSON(url);

    return data;
  }

  public async revokeUserAccessToBar(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    barId: string
  ): Promise<any> {
    const link = `${apiFunctionsEndpoint}/mgmt/bars/${barId}/revoke`;

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link
    );

    const data = await serviceHelper.fetchJSON(url);

    return data;
  }
}
