class Capability {
  private readonly _name;
  private readonly _isAvailable: boolean = false;
  private readonly _restrictions: any = {};

  constructor(name: string, data: any) {
    this._name = name;

    if (data) {
      if (data.isAvailable) {
        this._isAvailable = data.isAvailable;
      }
      if (data.restrictions) {
        this._restrictions = data.restrictions;
      }
    }
  }

  private get restrictions(): any {
    return this._restrictions;
  }

  public get name(): string | undefined {
    return this._name;
  }

  public get isAvailable(): boolean {
    return this._isAvailable;
  }

  public getRestriction(name: string): any {
    return this.restrictions[name];
  }

  public toJSON(): any {
    return {
      isAvailable: this.isAvailable,
      restrictions: this.restrictions
    };
  }
}

export default Capability;