import StatsDataFrame from "./StatsDataFrame";
import StatsDataCollection from "./StatsDataCollection";
import StatsDataName from "./StatsDataName";
import StatsDataKey from "./StatsDataKey";
import { statsHelper } from "../../helpers";

export default class OrdersStatsDataCollection extends StatsDataCollection {
  constructor(data?: any) {
    super(undefined, StatsDataName.ORDER);

    super.set(StatsDataKey.ALL, data && data[StatsDataKey.ALL] ?
      statsHelper.dataToStatsData(data[StatsDataKey.ALL]) :
      new StatsDataFrame());
    super.set(StatsDataKey.PER_ITEM, data && data[StatsDataKey.PER_ITEM] ?
      statsHelper.dataToStatsData(data[StatsDataKey.PER_ITEM]) :
      new StatsDataCollection());
    super.set(StatsDataKey.PER_PAYMENT_METHOD, data && data[StatsDataKey.PER_PAYMENT_METHOD] ?
      statsHelper.dataToStatsData(data[StatsDataKey.PER_PAYMENT_METHOD]) :
      new StatsDataCollection());
    super.set(StatsDataKey.PER_PRODUCT, data && data[StatsDataKey.PER_PRODUCT] ?
      statsHelper.dataToStatsData(data[StatsDataKey.PER_PRODUCT]) :
      new StatsDataCollection());
    super.set(StatsDataKey.PER_DELIVERY_METHOD, data && data[StatsDataKey.PER_DELIVERY_METHOD] ?
      statsHelper.dataToStatsData(data[StatsDataKey.PER_DELIVERY_METHOD]) :
      new StatsDataCollection());
    super.set(StatsDataKey.PER_MENU, data && data[StatsDataKey.PER_MENU] ?
      statsHelper.dataToStatsData(data[StatsDataKey.PER_MENU]) :
      new StatsDataCollection());
    super.set(StatsDataKey.FOR_MENU, data && data[StatsDataKey.FOR_MENU] ?
      statsHelper.dataToStatsData(data[StatsDataKey.FOR_MENU]) :
      new StatsDataCollection());
    super.set(StatsDataKey.PER_ZONE, data && data[StatsDataKey.PER_ZONE] ?
      statsHelper.dataToStatsData(data[StatsDataKey.PER_ZONE]) :
      new StatsDataCollection());
    super.set(StatsDataKey.PER_TABLE, data && data[StatsDataKey.PER_TABLE] ?
      statsHelper.dataToStatsData(data[StatsDataKey.PER_TABLE]) :
      new StatsDataCollection());
  }

  public get all(): StatsDataFrame {
    return super.getStatsDataFrame(StatsDataKey.ALL);
  }

  public set all(all: StatsDataFrame) {
    super.set(StatsDataKey.ALL, all);
  }

  public get perItem(): StatsDataCollection {
    return super.getStatsDataCollection(StatsDataKey.PER_ITEM);
  }

  public set perItem(perItem: StatsDataCollection) {
    super.set(StatsDataKey.PER_ITEM, perItem);
  }

  public get perProduct(): StatsDataCollection {
    return super.getStatsDataCollection(StatsDataKey.PER_PRODUCT);
  }

  public set perProduct(perProduct: StatsDataCollection) {
    super.set(StatsDataKey.PER_PRODUCT, perProduct);
  }

  public get perPaymentMethod(): StatsDataCollection {
    return super.getStatsDataCollection(StatsDataKey.PER_PAYMENT_METHOD);
  }

  public set perPaymentMethod(perPaymentMethod: StatsDataCollection) {
    super.set(StatsDataKey.PER_PAYMENT_METHOD, perPaymentMethod);
  }

  public get perDeliveryMethod(): StatsDataCollection {
    return super.getStatsDataCollection(StatsDataKey.PER_DELIVERY_METHOD);
  }

  public set perDeliveryMethod(perDeliveryMethod: StatsDataCollection) {
    super.set(StatsDataKey.PER_DELIVERY_METHOD, perDeliveryMethod);
  }

  public get perMenu(): StatsDataCollection {
    return super.getStatsDataCollection(StatsDataKey.PER_MENU);
  }

  public set perMenu(perMenu: StatsDataCollection) {
    super.set(StatsDataKey.PER_MENU, perMenu);
  }

  public get forMenu(): StatsDataCollection {
    return super.getStatsDataCollection(StatsDataKey.FOR_MENU);
  }

  public set forMenu(forMenu: StatsDataCollection) {
    super.set(StatsDataKey.FOR_MENU, forMenu);
  }

  public get perZone(): StatsDataCollection {
    return super.getStatsDataCollection(StatsDataKey.PER_ZONE);
  }

  public set perZone(perZone: StatsDataCollection) {
    super.set(StatsDataKey.PER_ZONE, perZone);
  }

  public get perTable(): StatsDataCollection {
    return super.getStatsDataCollection(StatsDataKey.PER_TABLE);
  }

  public set perTable(perTable: StatsDataCollection) {
    super.set(StatsDataKey.PER_TABLE, perTable);
  }
}