import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import FacebookButton from "./FacebookButton";
import InstagramButton from "./InstagramButton";
import LinkedInButton from "./LinkedInButton";

const styles = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "center"
  },
  large: {
    flexDirection: "column"
  },
  socialText: {
    padding: "0 20px",
    textAlign: "left"
  },
  alignCenter: {
    textAlign: "center"
  },
  socialLinks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10
  },
  socialLink: {
    margin: "0 10px"
  },
  socialHyperlink: {
    textDecoration: "none",
    color: theme.palette.primary.main
  }
});

class SocialButtons extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, size } = this.props;

    const iconSize = size === "small" ? 20 : 40;

    return (
      <div
        className={classNames(
          classes.container,
          size === "small" ? undefined : classes.large
        )}
      >
        <Typography
          variant="caption"
          className={classNames(
            classes.socialText,
            size === "small" ? undefined : classes.alignCenter
          )}
        >
          {t("promo.tap-io-on-your-event")} {t("label.go-to")}{" "}
          <a
            className={classes.socialHyperlink}
            href="https://watdrinkje.be"
            target="_blank"
          >
            watdrinkje.be
          </a>{" "}
          {t("promo.or-take-a-look-at-socials")}.
        </Typography>
        <div className={classes.socialLinks}>
          <FacebookButton
            url="https://www.facebook.com/watdrinkje"
            size={iconSize}
            margin={5}
          />
          <InstagramButton
            url="https://www.instagram.com/watdrinkje"
            size={iconSize}
            margin={5}
          />
          <LinkedInButton
            url="https://www.linkedin.com/company/watdrinkje"
            size={iconSize}
            margin={5}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(SocialButtons));
