import BarModel from "../model/BarModel";

class Balance extends BarModel {
  private readonly _creditCount: number;
  private readonly _doNotChargeCredits: boolean;
  private readonly _allowCreditDebt: boolean;

  constructor(barId: string, data: any) {
    super(barId);

    if (!data) {
      throw new Error("error.data-is-not-defined");
    }

    this._creditCount = data.creditCount;
    this._doNotChargeCredits = data.doNotChargeCredits === true;
    this._allowCreditDebt = data.allowCreditDebt === true;
  }

  public get creditCount(): number {
    return this._creditCount;
  }

  public get doNotChargeCredits(): boolean {
    return this._doNotChargeCredits;
  }

  public get allowCreditDebt(): boolean {
    return this._allowCreditDebt;
  }

  public hasCredits() {
    return this.creditCount > 0;
  }

  public isLowOnCredits() {
    return this.creditCount <= 50;
  }

  public isSufficient(creditCount: number = 1) {
    return (
      this.doNotChargeCredits ||
      this.allowCreditDebt ||
      this.creditCount >= creditCount
    );
  }
}

export default Balance;
