import Moment from "moment";
import { parseOneAddress } from "email-addresses";
import hmacmd5 from "crypto-js/hmac-md5";
import { TIMEOUT as PAYMENT_TIMEOUT } from "../constants/payment";
import * as statuses from "../constants/statuses";

export const findInArrayByPropVal = (arr, prop, val) => {
  const findByPropVal = (element) => {
    return element && element[prop] === val;
  };

  if (arr) {
    return arr.find(findByPropVal);
  }
};

export const findInArrayById = (arr, id) => {
  return findInArrayByPropVal(arr, "id", id);
};

export const filterArrayWithPropBetween = (
  arr,
  prop,
  begin,
  end,
  includeBegin,
  includeEnd
) => {
  if (arr) {
    return arr.filter((element) => {
      return (
        element &&
        element[prop] &&
        (includeBegin ? element[prop] >= begin : element[prop] > begin) &&
        (includeEnd ? element[prop] <= end : element[prop] < end)
      );
    });
  }
};

export const removeArrayDuplicates = (arr) =>
  arr.filter((d, i) => arr.indexOf(d) === i);

export const compareObjects = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    }
  };
};

export const isiOS = () => {
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
};

export const jsDateToDateString = (jsDate) => {
  return Moment(jsDate).format("YYYY-MM-DD"); //jsDate.toISOString().substr(0, 10);
};

export const jsDateToDateTimeString = (jsDate) => {
  return (
    jsDate.toISOString().substr(0, 19).replace(/-/g, "").replace(/:/g, "") + "Z"
  );
};

export const dateStringToJSDate = (dateString) => {
  return new Date(`${dateString}T00:00:00Z`);
};

export const isValidDate = (jsDate) => {
  if (Object.prototype.toString.call(jsDate) === "[object Date]") {
    // it is a date
    if (isNaN(jsDate.getTime())) {
      // d.valueOf() could also work
      // date is not valid
      return false;
    } else {
      // date is valid
      return true;
    }
  } else {
    // not a date
    return false;
  }
};

export const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const formatToXDecimals = (number, decimals) => {
  return `${number.toLocaleString("nl-BE", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  })}`;
};

export const formatToTwoDecimals = (number) => {
  return formatToXDecimals(number, 2);
};

export const roundToTwoDecimals = (number) => {
  return parseFloat(Math.round(number * 100) / 100).toFixed(2);
};

export const resetScrollPosition = (scrollElement) => {
  return (scrollElement ? scrollElement : window).scrollTo(0, 0);
};

export const generateAuthorizedLink = (authUser, barId, link, params) => {
  return new Promise((resolve, reject) => {
    if (authUser) {
      authUser
        .getIdToken()
        .then((token) => {
          if (token) {
            let url = `${link}?token=${token}&barId=${barId}`;

            if (params) {
              for (const key in params) {
                url += `&${encodeURIComponent(key)}=${encodeURIComponent(
                  params[key]
                )}`;
              }
            }

            resolve(url);
          } else {
            reject("Geen geldig token gevonden voor deze gebruiker");
          }
        })
        .catch((error) => {
          console.warn(error);
          reject("Fout bij het ophalen van het token voor deze gebruiker");
        });
    } else {
      reject("Geen gemachtigde gebruiker gevonden");
    }
  });
};

export const fetchJSON = (url) => {
  return new Promise((resolve, reject) => {
    return fetch(url)
      .then((response) => response.json())
      .then(resolve)
      .catch(reject);
  });
};

export const fetchBase64Data = (url) => {
  return new Promise((resolve, reject) => {
    return fetch(url)
      .then((response) => response.text())
      .then(resolve)
      .catch(reject);
  });
};

export const parseEmail = (email) => {
  if (email) {
    const parsedEmail = parseOneAddress({ input: email, rejectTLD: true });
    if (parsedEmail && parsedEmail.address) {
      return parsedEmail.address;
    }
  }
};

export const parseBase64Data = (data) => {
  const parsedData = data.split(",");

  if (parsedData.length === 2) {
    return {
      header: parsedData[0],
      content: parsedData[1]
    };
  }
};

export const getObjectValues = (obj) => {
  return Object.keys(obj).map((key) => {
    return obj[key];
  });
};

export const generateUUID = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

export const isValidPayconiqConf = (conf) => {
  return conf && conf.merchantId && conf.apiKey;
};

export const isValidMollieConf = (conf) => {
  return conf && conf.profileId && conf.apiKey;
};

export const isValidStarnetConf = (conf) => {
  return conf && conf.host && conf.apiKey;
};

export const getFirstDateExceptDays = (exceptDays) => {
  if (exceptDays) {
    for (let i = 0; i < 7; i++) {
      const date = Moment().add(i, "days");
      if (!exceptDays[date.day()]) {
        return date.toDate();
      }
    }

    return undefined;
  }

  return new Date();
};

export const isPaymentProcessing = (payment) => {
  if (payment && payment.isPaid) {
    return false;
  }

  const paymentSecondsAgo =
    payment && payment.timestamp
      ? Moment().diff(payment.timestamp.toDate(), "seconds")
      : 99999; // If a payment has been initialized, enable a grace period in which payments are not allowed

  return paymentSecondsAgo < PAYMENT_TIMEOUT;
};

export const getCurrentBaseMenuId = (base) => {
  return base &&
    base.current &&
    base.current.menuIds &&
    base.current.menuIds.length > 0
    ? base.current.menuIds[0]
    : undefined;
};

const isOrderStatus = (base, order, statusName) => {
  const currentBaseMenuId = getCurrentBaseMenuId(base);
  if (currentBaseMenuId) {
    return (
      order &&
      order.statusForMenu &&
      order.statusForMenu[currentBaseMenuId] &&
      order.statusForMenu[currentBaseMenuId] === statusName
    );
  } else {
    return order && order.status && order.status.name === statusName;
  }

  /*const { claimedOrderIds } = this.state;
  return claimedOrderIds.indexOf(id) >= 0;*/
};
getMenuCategories;

export const getMenuCategories = (menu) => {
  return menu && menu.elements
    ? menu.elements.filter((element) => element && element.type === "category")
    : [];
};

export const getMenuItems = (menu) => {
  return menu && menu.elements
    ? menu.elements.filter((element) => element && element.type === "item")
    : [];
};

export const getZoneMenuIds = (zone, bases) => {
  const menuIds = [];

  if (zone && zone.baseIds && bases) {
    zone.baseIds.forEach((baseId) => {
      const foundBases = bases.filter((base) => base.id === baseId);
      if (foundBases.length === 1) {
        foundBases[0].menuIds.forEach((menuId) => {
          if (menuIds.indexOf(menuId) < 0) {
            menuIds.push(menuId);
          }
        });
      }
    });
  }

  return menuIds;
};

export const isOrderStatusError = (base, order) => {
  return isOrderStatus(base, order, statuses.ERROR);
};

export const isOrderStatusCreated = (base, order) => {
  return isOrderStatus(base, order, statuses.CREATED);
};

export const isOrderStatusComplete = (base, order) => {
  return isOrderStatus(base, order, statuses.COMPLETE);
};

export const isOrderStatusQueued = (base, order) => {
  return isOrderStatus(base, order, statuses.QUEUED);
};

export const isOrderStatusClaimed = (base, order) => {
  return isOrderStatus(base, order, statuses.CLAIMED);
};

export const getAvailableOnlinePaymentProviders = (bar) => {
  if (bar && bar.onlinePayments && bar.onlinePayments.paymentProviders) {
    return Object.keys(bar.onlinePayments.paymentProviders).filter(
      (provider) =>
        bar.onlinePayments.paymentProviders[provider] &&
        bar.onlinePayments.paymentProviders[provider].isActive === true
    );
  }

  return [];
};

export const isPaymentProviderAvailable = (bar, paymentProvider) => {
  if (bar && bar.onlinePayments && bar.onlinePayments.paymentProviders) {
    return (
      bar.onlinePayments.paymentProviders[paymentProvider] &&
      bar.onlinePayments.paymentProviders[paymentProvider].isActive === true
    );
  }

  return false;
};

export const areThereOnlinePaymentProvidersAvailable = (bar) => {
  if (bar && bar.onlinePayments && bar.onlinePayments.paymentProviders) {
    for (let provider in bar.onlinePayments.paymentProviders) {
      if (
        bar.onlinePayments.paymentProviders[provider] &&
        bar.onlinePayments.paymentProviders[provider].isActive === true
      ) {
        return true;
      }
    }
  }

  return false;
};

export const hashPin = (barId, pin) => {
  return pin ? hmacmd5(pin, barId).toString() : "";
};

export const getBarLocale = (bar, type, forMomentJS) => {
  const locale =
    bar && bar.locales && bar.locales[type] ? bar.locales[type] : "nl_BE";

  if (forMomentJS) {
    const localeForMomentJS = locale.replace("_", "-").toLowerCase();

    return localeForMomentJS === "fr-be" ? "fr" : localeForMomentJS;
  } else {
    return locale;
  }
};

export const parseZoneCode = (zoneCode) => {
  if (zoneCode) {
    return zoneCode.trim().toUpperCase();
  }
};

export const generateZoneCode = (startAtCode) => {
  const nextChar = (c) => (c ? String.fromCharCode(c.charCodeAt(0) + 1) : "A");
  const nextCol = (s) =>
    s
      .toUpperCase()
      .replace(/([^Z]?)(Z*)$/, (_, a, z) => nextChar(a) + z.replace(/Z/g, "A"));

  return nextCol(startAtCode ? parseZoneCode(startAtCode) : "");
};

export const parseTableNumber = (tableNumber) => {
  if (tableNumber !== undefined && tableNumber !== null) {
    const parsedTableNumber = parseInt(tableNumber);
    if (!isNaN(parsedTableNumber)) {
      return parsedTableNumber;
    }
  }
};

export const extractZoneCodeAndTableNumberFromName = (name) => {
  if (name) {
    const zoneCodeBeforeTableNumberRegEx = /([A-Za-z]+)\s*(\d+)/;
    const zoneCodeAfterTableNumberRegEx = /(\d+)\s*([A-Za-z]+)/;
    const onlyZoneCodeRegEx = /([A-Za-z]+)/;

    let matches = name.match(zoneCodeBeforeTableNumberRegEx);

    if (matches && matches.length === 3) {
      return {
        zoneCode: parseZoneCode(matches[1]),
        tableNumber: parseTableNumber(matches[2])
      };
    } else {
      matches = name.match(zoneCodeAfterTableNumberRegEx);

      if (matches && matches.length === 3) {
        return {
          zoneCode: parseZoneCode(matches[2]),
          tableNumber: parseTableNumber(matches[1])
        };
      } else {
        matches = name.match(onlyZoneCodeRegEx);

        if (matches && matches.length === 2) {
          return {
            zoneCode: parseZoneCode(matches[1]),
            tableNumber: null
          };
        }
      }
    }
  }
};

export const getCurrentZoneCodes = (base) => {
  return base && base.zoneCodes ? base.zoneCodes.sort() : undefined;
};

export const createListViewportWatcher = () => {
  const itemsInViewport = {};

  const getIndexes = () => {
    return Object.keys(itemsInViewport).sort(
      (a, b) => parseInt(a) - parseInt(b)
    );
  };

  const getFirstIndexInViewport = () => {
    const index = getIndexes().shift();
    return index === undefined ? undefined : parseInt(index);
  };

  const getLastIndexInViewport = () => {
    const index = getIndexes().pop();
    return index === undefined ? undefined : parseInt(index);
  };

  const getFirstItemInViewport = () => {
    const index = getFirstIndexInViewport();
    return index === undefined ? undefined : itemsInViewport[`${index}`];
  };

  const getLastItemInViewport = () => {
    const index = getLastIndexInViewport();
    return index === undefined ? undefined : itemsInViewport[`${index}`];
  };

  const getItemsInViewport = () => {
    const indexes = getIndexes();

    return indexes.map((index) => itemsInViewport[`${index}`]);
  };

  const handleItemEnteringViewport = (index, item, items) => {
    itemsInViewport[index] = item;
  };

  const handleItemLeavingViewport = (index, item) => {
    delete itemsInViewport[index];
  };

  return {
    getItemsInViewport,
    getFirstIndexInViewport,
    getLastIndexInViewport,
    getFirstItemInViewport,
    getLastItemInViewport,
    handleItemEnteringViewport,
    handleItemLeavingViewport
  };
};

export const encodeVoucherCode = (voucherId, voucherPin) => {
  return voucherId && voucherPin ? `${voucherId}-${voucherPin}` : undefined;
};

export const decodeVoucherCode = (voucherCode) => {
  if (voucherCode) {
    const matches = voucherCode.match(/(.+)\-(\d+)/);
    if (matches && matches.length === 3) {
      return {
        id: matches[1],
        pin: matches[2]
      };
    }
  }
};
