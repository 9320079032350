import { voucherParser } from "../../parsers";
import { serviceHelper, utilsHelper, voucherHelper } from "../../helpers";
import VoucherLogEntry from "./VoucherLogEntry";
import ProductVoucherValue from "./ProductVoucherValue";

class ProductVoucherLogEntry extends VoucherLogEntry {
  constructor(id: string, props: any) {
    super(
      id,
      {
        ...props,
        previousVoucherValue: voucherParser.parseProductVoucherValue(props.previousVoucherValue),
        newVoucherValue: voucherParser.parseProductVoucherValue(props.newVoucherValue),
        valuePaidWithVoucher: serviceHelper.parseNumber(props.valuePaidWithVoucher)
      }
    );
  }

  public get previousVoucherValue(): Array<ProductVoucherValue> {
    return this._previousVoucherValue;
  }

  public get newVoucherValue(): Array<ProductVoucherValue> {
    return this._newVoucherValue;
  }

  public get valuePaidWithVoucher(): number {
    return this._valuePaidWithVoucher;
  }

  private calculateVoucherValueDifference(voucherValueA: Array<ProductVoucherValue>, voucherValueB: Array<ProductVoucherValue>): Array<ProductVoucherValue> {
    const differenceVoucherValue: Array<ProductVoucherValue> = [];

    voucherValueA.forEach(voucherValueAEntry => {
      const voucherValueBEntry = voucherHelper.findProductVoucherValue(
        voucherValueB,
        voucherValueAEntry.getProductId(),
        voucherValueAEntry.discountPercentage
      );

      const usedAmount = voucherValueAEntry.amount - (voucherValueBEntry ? voucherValueBEntry.amount : 0);

      if (usedAmount > 0) {
        differenceVoucherValue.push(new ProductVoucherValue(
          voucherValueAEntry.productIds,
          voucherValueAEntry.discountPercentage,
          usedAmount
        ));
      }
    });

    return differenceVoucherValue;
  }

  public get usedVoucherValue(): Array<ProductVoucherValue> {
    return this.valuePaidWithVoucher > 0 ?
      this.calculateVoucherValueDifference(this.previousVoucherValue, this.newVoucherValue) :
      this.calculateVoucherValueDifference(this.newVoucherValue, this.previousVoucherValue);
  }

  public get voucherValueDifference(): number {
    const difference = this.usedVoucherValue.reduce((acc: number, value: ProductVoucherValue) => acc + value.amount, 0);
    return this.valuePaidWithVoucher > 0 ?
      -difference :
      difference;
  }

  public previousVoucherValueToString(translator: Function): string {
    return `${this.previousVoucherValue.length} ${translator(this.previousVoucherValue.length === 1 ? "voucher.product" : "voucher.products")}`;
  }

  public newVoucherValueToString(translator: Function): string {
    return `${this.newVoucherValue.length} ${translator(this.newVoucherValue.length === 1 ? "voucher.product" : "voucher.products")}`;
  }

  public valuePaidWithVoucherToString(translator: Function): string {
    const amountOfProductsPaidWithVoucher = -this.voucherValueDifference;

    return `${amountOfProductsPaidWithVoucher} ${translator(amountOfProductsPaidWithVoucher === 1 ? "voucher.product" : "voucher.products")} • ${utilsHelper.formatToTwoDecimals(this.valuePaidWithVoucher)} ${translator("label.euro")}`;
  }

  public voucherValueDifferenceToString(translator: Function): string {
    const voucherValueDifference = this.voucherValueDifference;
    const absoluteVoucherValueDifference = Math.abs(voucherValueDifference);
    const sign = voucherValueDifference < 0 ? "-" : "+";

    // TO FIX: currency
    return `${sign}${absoluteVoucherValueDifference} ${translator(absoluteVoucherValueDifference === 1 ? "voucher.product" : "voucher.products")} • ${sign}${utilsHelper.formatToTwoDecimals(Math.abs(this.valuePaidWithVoucher))} ${translator("label.euro")}`;
  }
}

export default ProductVoucherLogEntry;