enum OrderStatus {
  CREATED = "005-created",
  QUEUED = "010-queued",
  //OPEN = "020-open",
  CLAIMED = "030-claimed",
  COMPLETE = "100-complete",
  CANCELLED = "800-cancelled",
  ERROR = "901-error"
};

export default OrderStatus;