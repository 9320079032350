enum ServiceOptionName {
  EXPRESS_PICKUP = "express-pickup",
  EXPRESS_DELIVERY = "express-delivery",
  PICKUP = "pickup",
  DELIVERY = "delivery",
  SHOW_AND_GO = "show-and-go",
  SELF_SERVICE = "self-service"
}

export default ServiceOptionName;
