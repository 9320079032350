import Bugsnag from "@bugsnag/js";

import Bar from "../../models/bar/Bar";

const BUGSNAG_BAR_METADATA_KEY = "bar";

export default class BugsnagHelper {
  constructor() {}

  public setBar(bar: Bar) {
    this.clearBar();

    Bugsnag.addMetadata(BUGSNAG_BAR_METADATA_KEY, {
      id: bar.id,
      name: bar.name
    });
  }

  public clearBar() {
    Bugsnag.clearMetadata(BUGSNAG_BAR_METADATA_KEY);
  }
}
