import React, { PureComponent } from "react";
import queryString from "query-string";

const withPageQuery = (Component) => {
  class WithPageQuery extends PureComponent {
    constructor(props) {
      super(props);

      this.state = this.initialState();
    }

    initialState = () => {
      const newState = { pageQuery: null, queryParams: null };

      const pageQuery = this.getPageQuery();

      if (pageQuery) {
        newState.pageQuery = pageQuery;

        const queryParams = this.getQueryParams(pageQuery);

        if (queryParams) {
          newState.queryParams = queryParams;
        }
      }

      return newState;
    };

    getPageQuery = () => {
      const { location } = this.props;

      let pageQuery;

      if (location) {
        // React Router bug: when linked from previous page,
        // search is undefined and added to pathname
        //console.log(location)

        if (location.search) {
          pageQuery = location.search;
        } else if (location.pathname) {
          const indexOfSearch = location.pathname.indexOf("?");
          if (indexOfSearch >= 0) {
            pageQuery = location.pathname.substr(indexOfSearch);
          }
        }
      }

      return pageQuery;
    };

    getQueryParams = (query) => {
      const queryParams = {};

      if (query) {
        const params = queryString.parse(query);

        for (let key in params) {
          queryParams[key] = decodeURIComponent(params[key]);
        }
      }

      return queryParams;
    };

    render() {
      const { pageQuery, queryParams } = this.state;

      return (
        <Component
          pageQuery={pageQuery}
          queryParams={queryParams}
          {...this.props}
        />
      );
    }
  }

  return WithPageQuery;
};

export default withPageQuery;
