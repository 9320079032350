import Product from "./Product";

class Catalogue {
  private _products: Array<Product> = [];

  constructor(products: Array<Product>) {
    this._products = products;
  }

  public get products(): Array<Product> {
    return this._products;
  }

  public get defaultProducts(): Array<Product> {
    return this._products.filter(product => !product.isCustom);
  }

  public get customProducts(): Array<Product> {
    return this._products.filter(product => product.isCustom);
  }

  public set products(products: Array<Product>) {
    this._products = products;
  }

  public getProduct(productId: string): Product {
    return this.getProducts([productId])[0];
  }

  public getProducts(productIds: Array<string>): Array<Product> {
    const foundProducts = this.products.filter(product => productIds.indexOf(product.id) >= 0);

    if (foundProducts.length !== productIds.length) {
      throw new Error("error.not-all-products-were-found");
    }
    return foundProducts;
  }

  public findProductById(id: string): Product | null {
    const foundProducts: Array<Product> = this.products.filter((product: Product) =>
      product.id === id
    );

    return foundProducts.length > 0 ?
      foundProducts[0] :
      null;
  }

  public findProductByName(name: string): Product | null {
    const cleanName = name.trim().toLowerCase();

    const foundProducts: Array<Product> = this.products.filter((product: Product) =>
      product.name.trim().toLowerCase() === cleanName
    );

    return foundProducts.length > 0 ?
      foundProducts[0] :
      null;
  }

  public doesProductExist(productId: string): boolean {
    return this.findProductById(productId) !== null;
  }

  public addProduct(product: Product) {
    return this.addProducts([product]);
  }

  public addProducts(products: Array<Product>) {
    const productsAsMap: any = {};
    this.products.forEach(product => productsAsMap[product.id] = product);

    products.forEach(product => {
      productsAsMap[product.id] = product;
    });

    this.products = Object.keys(productsAsMap).map(productId => productsAsMap[productId]);
  }
}

export default Catalogue;