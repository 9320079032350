class Ad {
  private readonly _id: string;
  private readonly _landscapeImageUrl: string;
  private readonly _portraitImageUrl: string;
  private readonly _stripImageUrl: string;
  private readonly _backgroundColor: string;

  constructor(id: string, data: any) {
    if (!id) {
      throw new Error("error.id-is-not-defined");
    }
    if (!data) {
      throw new Error("error.data-is-not-defined");
    }

    this._id = id;
    this._landscapeImageUrl = data.landscapeImageUrl;
    this._portraitImageUrl = data.portraitImageUrl;
    this._stripImageUrl = data.stripImageUrl;
    this._backgroundColor = data.backgroundColor;
  }

  public get id(): string {
    return this._id;
  }

  public get landscapeImageUrl(): string {
    return this._landscapeImageUrl;
  }

  public get portraitImageUrl(): string {
    return this._portraitImageUrl;
  }

  public get stripImageUrl(): string {
    return this._stripImageUrl;
  }

  public get backgroundColor(): string {
    return this._backgroundColor;
  }
}

export default Ad;
