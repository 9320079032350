import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItem, ListItemText } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  itemTip: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  tipLabel: {
    width: "100%"
  },
  tipTotal: {
    width: "100%",
    fontSize: 16,
    textAlign: "right"
  }
});

class OrderTip extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, order } = this.props;

    if (order.hasTip()) {
      return (
        <ListItem>
          <ListItemText
            primary={
              <span className={classes.itemTip}>
                <span className={classes.tipLabel}>{t("order.tip")}</span>
                <span className={classes.tipTotal}>
                  {`${order.getFormattedTip()} ${order.currency}`}
                </span>
              </span>
            }
          />
        </ListItem>
      );
    }

    return null;
  }
}

export default withStyles(styles)(withTranslation("common")(OrderTip));
