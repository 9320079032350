import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { CircularProgress, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 200
  },
  text: {
    marginTop: 20
  }
});

class LoadingScanner extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.content}>
        <CircularProgress />
        <Typography className={classes.text}>
          {t("scanner.loading-scanner")}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(LoadingScanner)
);
