import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import withStyles from "@mui/styles/withStyles";

import { scannerService } from "tap-io/client/services";
import config from "tap-io/client/env";
import EnterScannerPinDialog from "tap-io/client/components/scanner/EnterScannerPinDialog";
import { scannerHelper } from "tap-io/helpers";

import withAuthorization from "./withAuthorization";
import { PIN_LENGTH } from "tap-io/constants/scanner";

const styles = {};

class VerifyPinDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false
    };
  }

  handlePinDialogConfirm = async (pin) => {
    const { t, auth, bar, scanner, onSuccess } = this.props;

    if (auth && auth.user) {
      this.setState({ isDisabled: true });

      try {
        const pinHash = scannerHelper.hashPin(bar.id, scanner.id, pin);

        const isVerified = await scannerService.verifyScannerPin(
          config.functions.api,
          auth.user,
          scanner,
          pinHash
        );

        if (!isVerified) {
          throw new Error("error.pin-incorrect");
        }

        this.setState({ isDisabled: false });

        onSuccess(pinHash);
      } catch (error) {
        console.warn(error);

        toast.error(t("error.pin-incorrect"));

        this.setState({ isDisabled: false });
      }
    } else {
      toast.error(t("error.no-authorized-user-found"));
    }
  };

  render() {
    const { classes, t, isOpen, bar, onCancel } = this.props;
    const { isDisabled } = this.state;

    return (
      <div>
        <EnterScannerPinDialog
          isOpen={isOpen}
          isDisabled={isDisabled}
          onCancel={onCancel}
          onConfirm={this.handlePinDialogConfirm}
          pinLength={PIN_LENGTH}
          bar={bar}
        />
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(VerifyPinDialog))
);
