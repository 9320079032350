import CustomerFieldType from "./CustomerFieldType";
import CustomerFieldProps from "./CustomerFieldProps";
import Translations from "../locale/Translations";
import LocalisedText from "../locale/LocalisedText";
import Locale from "../locale/Locale";

const DEFAULT_LOCALE = Locale.NL_BE;

export default abstract class CustomerField {
  private _type: CustomerFieldType;
  private _id: string;
  private _name: LocalisedText;
  private _description: LocalisedText;
  private _isRequired: boolean;

  constructor(props: CustomerFieldProps) {
    const { type, id, name, description, isRequired } = props;

    this._type = type;
    this._id = id;
    this._name = this.parsePropToLocalisedText(name);
    this._description = this.parsePropToLocalisedText(description);
    this._isRequired = isRequired;
  }

  private parsePropToLocalisedText(
    prop: any,
    locale: Locale = DEFAULT_LOCALE
  ): LocalisedText {
    const translations: Partial<Translations> = {};

    if (prop) {
      Object.keys(Locale).forEach((key) => {
        const locale = Locale[key];

        if (typeof prop === "string") {
          translations[locale] = prop;
        } else if (typeof prop === "object") {
          translations[locale] = prop[locale] || "";
        }
      });
    }

    return new LocalisedText(translations, locale);
  }

  public set locale(locale: Locale) {
    this._name.locale = locale;
    this._description.locale = locale;
  }

  public get type() {
    return this._type;
  }

  public set type(type: CustomerFieldType) {
    this._type = type;
  }

  public get id() {
    return this._id;
  }

  public set id(id: string) {
    this._id = id;
  }

  public get localisedName() {
    return this._name;
  }

  public set localisedName(name: LocalisedText) {
    this._name = name;
  }

  public get name() {
    return this._name.text;
  }

  public set name(name: string) {
    this._name.text = name;
  }

  public get localisedDescription() {
    return this._description;
  }

  public set localisedDescription(description: LocalisedText) {
    this._description = description;
  }

  public get description() {
    return this._description.text;
  }

  public set description(description: string) {
    this._description.text = description;
  }

  public get isRequired() {
    return this._isRequired;
  }

  public set isRequired(isRequired: boolean) {
    this._isRequired = isRequired;
  }

  public abstract clone(): CustomerField;

  public toJSON(): any {
    return {
      type: this.type,
      id: this.id,
      name: this.name,
      description: this.description,
      isRequired: this.isRequired
    };
  }
}
