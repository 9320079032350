import Moment from "moment";

const DEVICE_SETTINGS_KEY_PREFIX = "deviceSettings";
const DEVICE_CACHE_KEY_PREFIX = "deviceCache";
const DEVICE_KEY_PREFIXES = [
  DEVICE_SETTINGS_KEY_PREFIX,
  DEVICE_CACHE_KEY_PREFIX
];

export default class DeviceStorage {
  private readonly _observers: any = {};
  private _isDisabled: boolean = false;

  private get observers(): any {
    return this._observers;
  }

  public get isDisabled(): boolean {
    return this._isDisabled;
  }

  public set isDisabled(isDisabled: boolean) {
    if (isDisabled) {
      this.clearStorage();
    }
    this._isDisabled = isDisabled;
  }

  private clearStorage() {
    if (this.storage) {
      for (let i = 0; i < this.storage.length; i++) {
        const key = this.storage.key(i);

        for (let j = 0; j < DEVICE_KEY_PREFIXES.length; j++) {
          if (key.indexOf(DEVICE_KEY_PREFIXES[j]) === 0) {
            this.storage.removeItem(key); // this.removeItem(key)? -- notifyObservers
            break;
          }
        }
      }
    }
  }

  private get storage(): any {
    return window.localStorage;
  }

  private get isAvailable(): boolean {
    return !!this.storage && !this.isDisabled;
  }

  private getItem(key: string): any {
    try {
      if (this.isAvailable) {
        const lsItem = this.storage.getItem(key);

        if (lsItem) {
          const item = JSON.parse(lsItem);

          return item;
        }
      }
    } catch (error) {
      throw error;
    }
  }

  private onItem(key: string, cb: Function) {
    const id = `${new Date().getTime()}_${Math.random()}`;

    this.observers[id] = { key, cb };

    const unsubscribe = () => {
      delete this.observers[id];
    };

    cb(this.getItem(key));

    return unsubscribe;
  }

  private setItem(key: string, item: any) {
    try {
      if (this.isAvailable) {
        const lsItem = JSON.stringify(item);

        this.storage.setItem(key, lsItem);
      }

      this.notifyObservers();
    } catch (error) {
      throw error;
    }
  }

  private removeItem(key: string) {
    try {
      if (this.isAvailable) {
        this.storage.removeItem(key);
      }

      this.notifyObservers();
    } catch (error) {
      throw error;
    }
  }

  private notifyObservers() {
    for (let id in this.observers) {
      const observer = this.observers[id];
      observer.cb(this.getItem(observer.key));
    }
  }

  private getBarKey(barId: string, keyPrefix: string) {
    return `${keyPrefix}_${barId}`;
  }

  private getDeviceSettingsKey(barId: string) {
    return this.getBarKey(barId, DEVICE_SETTINGS_KEY_PREFIX);
  }

  private getDeviceCacheKey(barId: string) {
    return this.getBarKey(barId, DEVICE_CACHE_KEY_PREFIX);
  }

  private async getDeviceCache(barId: string) {
    const barKey = this.getDeviceCacheKey(barId);
    return this.getItem(barKey);
  }

  private async setDeviceCache(barId: string, cache: any) {
    const barKey = this.getDeviceCacheKey(barId);
    this.setItem(barKey, cache);
  }

  public async getDeviceSettings(barId: string) {
    const barKey = this.getDeviceSettingsKey(barId);
    return this.getItem(barKey);
  }

  public onDeviceSettings(barId: string, cb: Function) {
    const barKey = this.getDeviceSettingsKey(barId);
    return this.onItem(barKey, cb);
  }

  public async getDeviceSetting(barId: string, key: string) {
    const barSettings = await this.getDeviceSettings(barId);
    if (barSettings) {
      return barSettings[key];
    }
  }

  public async setDeviceSettings(barId: string, settings: any) {
    const barKey = this.getDeviceSettingsKey(barId);
    this.setItem(barKey, settings);
  }

  public async updateDeviceSettings(barId: string, settings: any) {
    const currentSettings = await this.getDeviceSettings(barId);
    await this.setDeviceSettings(barId, { ...currentSettings, ...settings });
  }

  public async setDeviceSetting(barId: string, key: string, value: any) {
    const barSettings = (await this.getDeviceSettings(barId)) || {};
    barSettings[key] = value;
    await this.setDeviceSettings(barId, barSettings);
  }

  public async clearDeviceSetting(barId: string, key: string) {
    const barSettings = (await this.getDeviceSettings(barId)) || {};
    delete barSettings[key];
    await this.setDeviceSettings(barId, barSettings);
  }

  public async getDeviceCacheItem(barId: string, key: string) {
    // TO FIX: temporarily return barCache[key] or barCache[key].value
    // --> support 'old' version
    const barCache = await this.getDeviceCache(barId);
    if (barCache && barCache[key]) {
      if (
        barCache[key].expiresAt &&
        Moment().isAfter(barCache[key].expiresAt)
      ) {
        await this.clearDeviceCacheItem(barId, key);
        return undefined;
      }

      return barCache[key].value ? barCache[key].value : barCache[key];
    }
  }

  public async setDeviceCacheItem(
    barId: string,
    key: string,
    value: any,
    maxAgeInSeconds?: number
  ) {
    const barCache = (await this.getDeviceCache(barId)) || {};

    barCache[key] = { value };
    if (maxAgeInSeconds !== undefined) {
      barCache[key].expiresAt = Moment()
        .add(maxAgeInSeconds, "seconds")
        .toDate()
        .toISOString();
    }

    await this.setDeviceCache(barId, barCache);
  }

  public async clearDeviceCacheItem(barId: string, key: string) {
    const barCache = (await this.getDeviceCache(barId)) || {};
    delete barCache[key];
    await this.setDeviceCache(barId, barCache);
  }
}
