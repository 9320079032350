import BarPropsModel from "../model/BarPropsModel";
import { serviceHelper } from "../../helpers";

class Scanner extends BarPropsModel {
  private _name: string;
  private _baseId: string;
  private _isActive: boolean;
  private _allowCreatingOrders: boolean;
  private _allowCashPayments: boolean;

  private readonly _timestamp: Date | undefined;
  private _pin: string | null;
  private _lastClaimedOrderId: string | null;

  constructor(barId: string, id?: string, props?: any) {
    super(barId, id);

    if (!props) {
      throw new Error("props is not defined");
    }

    this._name = serviceHelper.parseString(props.name);
    this._baseId = serviceHelper.parseString(props.baseId);
    this._isActive = serviceHelper.parseBoolean(props.isActive);
    this._allowCreatingOrders = props.allowCreatingOrders === true;
    this._allowCashPayments = props.allowCashPayments === true;
    this._lastClaimedOrderId = props.lastClaimedOrderId || null;

    if (props.timestamp) {
      this._timestamp = serviceHelper.parseTimestamp(props.timestamp);
    }

    this._pin = props.pin ? props.pin : null;

    super.setAllProps([
      "name",
      "baseId",
      "isActive",
      "allowCreatingOrders",
      "allowCashPayments",
      "lastClaimedOrderId",
      "timestamp"
    ]);
  }

  public get name(): string {
    return this._name;
  }

  public get baseId(): string {
    return this._baseId;
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public get allowCreatingOrders(): boolean {
    return this._allowCreatingOrders;
  }

  public get allowCashPayments(): boolean {
    return this._allowCashPayments;
  }

  public get lastClaimedOrderId(): string | null {
    return this._lastClaimedOrderId;
  }

  public get timestamp(): Date | undefined {
    return this._timestamp;
  }

  public get pin(): string | null {
    return this._pin;
  }

  public set name(name: string) {
    this._name = serviceHelper.parseString(name);
    super.setPropUpdated("name");
  }

  public set baseId(baseId: string) {
    this._baseId = serviceHelper.parseString(baseId);
    super.setPropUpdated("baseId");
  }

  public set isActive(isActive: boolean) {
    this._isActive = serviceHelper.parseBoolean(isActive);
    super.setPropUpdated("isActive");
  }

  public set allowCreatingOrders(allowCreatingOrders: boolean) {
    this._allowCreatingOrders = serviceHelper.parseBoolean(allowCreatingOrders);
    super.setPropUpdated("allowCreatingOrders");
  }

  public set allowCashPayments(allowCashPayments: boolean) {
    this._allowCashPayments = serviceHelper.parseBoolean(allowCashPayments);
    super.setPropUpdated("allowCashPayments");
  }

  public set lastClaimedOrderId(lastClaimedOrderId: string | null) {
    this._lastClaimedOrderId = lastClaimedOrderId || null;
    super.setPropUpdated("lastClaimedOrderId");
  }

  public set pin(pin: string | null) {
    this._pin = pin;
  }
}

export default Scanner;
