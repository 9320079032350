import React from "react";

import ErrorIcon from "@mui/icons-material/Error";

import Message from "./Message";

const WarningMessage = ({ label }) => (
  <Message icon={<ErrorIcon />} label={label} />
);

export default WarningMessage;
