import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  button: {
    backgroundImage: "url('/img/background.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "contain"
  }
});

class SocialButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, icon, url, size, margin } = this.props;

    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={url}
        className={classes.button}
        style={{
          width: size,
          height: size,
          margin: margin,
          backgroundImage: `url('${icon}')`
        }}
      />
    );
  }
}

export default withStyles(styles)(SocialButton);
