import PartnerIntegration from "../models/integration/PartnerIntegration";
import DeliverectIntegration from "../models/integration/DeliverectIntegration";
import IntegrationName from "../models/integration/IntegrationName";
import AbiIntegration from "../models/integration/AbiIntegration";

export default class IntegrationParser {
  public parseDocToIntegration(barId: string, snap) {
    const name = snap.data()?.name;
    if (!name) {
      throw new Error("error.integration-name-is-not-defined");
    }

    switch (name) {
      case IntegrationName.DELIVERECT:
        return this.parseDocToDeliverectIntegration(barId, snap);
      case IntegrationName.PARTNER:
        return this.parseDocToPartnerIntegration(barId, snap);
      case IntegrationName.ABI:
        return this.parseDocToAbiIntegration(barId, snap);
      default:
        throw new Error("error.unknown-integration-name");
    }
  }

  public parseDocToPartnerIntegration(barId, snap): PartnerIntegration {
    if (!snap.exists) {
      throw new Error("error.integration-does-not-exist");
    }

    const data = snap.data();
    if (!data) {
      throw new Error("error.data-does-not-exist");
    }

    return new PartnerIntegration(barId, {
      name: data.name,
      label: data.label,
      key: data.key,
      externalKey: data.externalKey,
      isActive: data.isActive,
      status: data.status
    });
  }

  public parseDocToDeliverectIntegration(barId, snap): DeliverectIntegration {
    if (!snap.exists) {
      throw new Error("error.integration-does-not-exist");
    }

    const data = snap.data();
    if (!data) {
      throw new Error("error.data-does-not-exist");
    }

    return new DeliverectIntegration(barId, {
      baseId: data.baseId,
      name: data.name,
      label: data.label,
      key: data.key,
      externalKey: data.externalKey,
      isActive: data.isActive,
      status: data.status
    });
  }

  public parseDocToAbiIntegration(barId, snap): AbiIntegration {
    if (!snap.exists) {
      throw new Error("error.integration-does-not-exist");
    }

    const data = snap.data();
    if (!data) {
      throw new Error("error.data-does-not-exist");
    }

    return new AbiIntegration(barId, {
      name: data.name,
      isActive: data.isActive,
      credentials: data.credentials,
      pos: data.pos,
      currentEvent: data.currentEvent
    });
  }
}
