import React, { PureComponent } from "react";
import classNames from "classnames";

import handleViewport from "react-in-viewport";

import animateScrollTo from "animated-scroll-to";

import { List } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { menuHelper, utilsHelper } from "../../../helpers";
import { createListViewportWatcher } from "../../../utils";
import MenuCategory from "./MenuCategory";
import MenuItem from "./MenuItem";

const MenuCategoryInViewport = handleViewport(
  (props) => {
    const { forwardedRef, bar, category, showImage } = props;
    return (
      <MenuCategory
        ref={forwardedRef}
        bar={bar}
        category={category}
        showImage={showImage}
      />
    );
  },
  {
    rootMargin: "-80px 0px 0px 0px"
  }
);

const styles = (theme) => ({});

class MenuItemPicker extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      pickedMenuItems: {}
    };

    this.categoryViewportWatcher = createListViewportWatcher();
  }

  componentDidMount() {
    const { scrollToCategory } = this.props;

    scrollToCategory((category) => {
      this.setLostFocus();
      this.scrollToCategory(category);
    });

    this.refreshPickedMenuItems();

    this.setHasFocus();
  }

  componentDidUpdate(prevProps, prevState) {
    this.refreshPickedMenuItems();
  }

  refreshPickedMenuItems = () => {
    const { pickedMenuItems } = this.props;

    if (
      pickedMenuItems !== undefined &&
      pickedMenuItems !== null &&
      !utilsHelper.areObjectsEqualShallow(
        pickedMenuItems,
        this.state.pickedMenuItems
      )
    ) {
      this.setState({ pickedMenuItems });
    }
  };

  scrollToCategory = (category) => {
    const { headerProps } = this.props;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      const scrollToCategoryElement = (offset) => {
        animateScrollTo(
          document.querySelector(
            `.elementsList [data-category-id="${category.id}"]`
          ),
          { verticalOffset: offset }
          //{ elementToScroll: document.querySelector("body") }
        );
      };

      // Scroll to category element
      scrollToCategoryElement(-headerProps.height);

      // The value of headerProps possibly changes when scrolling
      // Check if this has changed within a certain timeout and update scroll pos if needed
      setTimeout(() => {
        if (headerProps.height !== this.props.headerProps.height) {
          scrollToCategoryElement(-this.props.headerProps.height);
        }
      }, 200);
    }, 10);
  };

  handleCategoryClick = (category) => (event) => {
    this.scrollToCategory(category);
  };

  handleCategoryEnteringViewport = (category, index) => () => {
    const { elements, onScroll } = this.props;

    const firstCategoryIndexInViewport =
      this.categoryViewportWatcher.getFirstIndexInViewport();

    this.categoryViewportWatcher.handleItemEnteringViewport(index, category);

    if (
      this.hasFocus &&
      (firstCategoryIndexInViewport === undefined ||
        index < firstCategoryIndexInViewport)
    ) {
      onScroll(category);
    }
  };

  handleCategoryLeavingViewport = (category, index) => () => {
    const { onScroll } = this.props;

    const firstCategoryIndexInViewport =
      this.categoryViewportWatcher.getFirstIndexInViewport();

    this.categoryViewportWatcher.handleItemLeavingViewport(index, category);

    if (this.hasFocus && index === firstCategoryIndexInViewport) {
      onScroll(category);
    }
  };

  setHasFocus = () => {
    this.hasFocus = true;
  };

  setLostFocus = () => {
    this.hasFocus = false;
  };

  handleItemAmountChange = (menuItem, amount) => {
    const { onChange } = this.props;

    const pickedMenuItems = { ...this.state.pickedMenuItems };

    if (amount) {
      pickedMenuItems[menuItem.id] = { menuItem, amount };
    } else {
      delete pickedMenuItems[menuItem.id];
    }

    this.setState({ pickedMenuItems });

    if (onChange) {
      onChange(pickedMenuItems);
    }
  };

  render() {
    const { classes, bar, elements, showImage, showDescription, showDeposits } = this.props;
    const { pickedMenuItems } = this.state;

    return (
      <List
        className={classNames("elementsList", classes.list)}
        onMouseMove={this.setHasFocus}
        onTouchStart={this.setHasFocus}
      >
        {elements &&
          elements.map((element, index) =>
            menuHelper.isCategoryMenuElement(element) ? (
              <div
                key={element.id}
                data-category-id={element.id}
                onClick={this.handleCategoryClick(element)}
              >
                <MenuCategoryInViewport
                  onEnterViewport={this.handleCategoryEnteringViewport(
                    element,
                    index
                  )}
                  onLeaveViewport={this.handleCategoryLeavingViewport(
                    element,
                    index
                  )}
                  bar={bar}
                  category={element}
                  showImage={showImage}
                />
              </div>
            ) : menuHelper.isItemMenuElement(element) ? (
              <MenuItem
                key={element.id}
                bar={bar}
                item={element}
                amount={
                  pickedMenuItems[element.id]
                    ? pickedMenuItems[element.id].amount
                    : 0
                }
                showImage={showImage}
                showDescription={showDescription}
                showDeposits={showDeposits}
                onAmountChange={this.handleItemAmountChange}
              />
            ) : undefined
          )}
      </List>
    );
  }
}

export default withStyles(styles)(MenuItemPicker);
