import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, Typography } from "@mui/material";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import withStyles from "@mui/styles/withStyles";

import OrderDialog from "./OrderDialog";

const styles = (theme) => ({});

class ShowCompleteOrderButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOrderDialogOpen: false
    };
  }

  handleShowOrder = (event) => {
    event.stopPropagation();

    this.setState({ isOrderDialogOpen: true });
  };

  handleOrderDialogClose = (event) => {
    this.setState({ isOrderDialogOpen: false });
  };

  render() {
    const { classes, t, bar, order, size, variant, isDisabled } = this.props;
    const { isOrderDialogOpen } = this.state;

    if (order.containsItemsFromMultipleMenus()) {
      return (
        <>
          <OrderDialog
            isOpen={isOrderDialogOpen}
            onClose={this.handleOrderDialogClose}
            bar={bar}
            order={order}
          />
          <Button
            size={size}
            variant={variant}
            disabled={isDisabled}
            startIcon={<ClearAllIcon />}
            onClick={this.handleShowOrder}
          >
            {t("order.show-full-order")}
          </Button>
        </>
      );
    }

    return null;
  }
}

export default withStyles(styles)(
  withTranslation("common")(ShowCompleteOrderButton)
);
