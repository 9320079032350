class PaymentLog {
  private readonly _paymentId: string;
  private readonly _id: string;
  private readonly _reference: string;
  private readonly _value: number;
  private readonly _timestamp: Date | null;

  constructor(paymentId: string, id: string, data: any) {
    //Starnet || ccv || Vivawallet | Payconiq | Mollie

    this._paymentId = paymentId;
    this._id = id;

    if (!data) {
      throw new Error("error.data-is-not-defined");
    }

    this._reference =
      data.orderCode || data.paymentId || data.id || data.reference;
    if ("organisation" in data) {
      //Starnet payment is in eurocents not in euro
      this._value = data.amount / 100;
    } else if ("debtor" in data) {
      //Payconiq payment is in eurocents not in euro
      this._value = data.amount / 100;
    } else {
      this._value = Number(data.amount?.value || data.amount);
    }
    this._timestamp = data.createdAt ? new Date(data.createdAt) : null;
  }

  public get paymentId() {
    return this._paymentId;
  }

  public get id() {
    return this._id;
  }

  public get reference() {
    return this._reference;
  }

  public get value() {
    return this._value;
  }

  public get timestamp() {
    return this._timestamp;
  }
}

export default PaymentLog;
