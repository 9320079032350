import FeeCondition from "./FeeCondition";
import FeeConditionType from "./FeeConditionType";
import FeeConditionOption from "./FeeConditionOption";
import LayoutPath from "../zone/LayoutPath";
import ServiceOptionName from "../service/ServiceOptionName";

type Props = {
  serviceOptionNames: ServiceOptionName[];
};

export default class FeeServiceOptionCondition extends FeeCondition {
  private _serviceOptionNames: ServiceOptionName[];

  constructor(props: Props) {
    const { serviceOptionNames } = props;
    super(FeeConditionType.SERVICE_OPTION, FeeConditionOption.INCLUDE);

    this._serviceOptionNames = serviceOptionNames;
  }

  public get serviceOptionNames() {
    return this._serviceOptionNames;
  }

  public set serviceOptionNames(serviceOptionNames: ServiceOptionName[]) {
    this._serviceOptionNames = serviceOptionNames;
  }

  public hasServiceOption(serviceOptionName: ServiceOptionName) {
    return this.serviceOptionNames.indexOf(serviceOptionName) >= 0;
  }

  public addServiceOption(serviceOptionNameToAdd: ServiceOptionName) {
    if (!this.hasServiceOption(serviceOptionNameToAdd)) {
      this.serviceOptionNames.push(serviceOptionNameToAdd);
    }
  }

  public removeServiceOption(serviceOptionNameToRemove: ServiceOptionName) {
    this.serviceOptionNames = this.serviceOptionNames.filter(
      (serviceOptionName) => serviceOptionName !== serviceOptionNameToRemove
    );
  }

  public isMet(
    serviceOptionName?: ServiceOptionName,
    zoneCode?: string,
    layoutPath?: LayoutPath
  ): boolean {
    if (serviceOptionName) {
      switch (this.option) {
        case FeeConditionOption.INCLUDE:
          return this.hasServiceOption(serviceOptionName);
          break;
        case FeeConditionOption.EXCLUDE:
          return !this.hasServiceOption(serviceOptionName);
          break;
        default:
          throw new Error("error.fee-condition-option-is-not-valid");
          break;
      }
    } else {
      return this.serviceOptionNames.length === 0;
    }
  }

  public clone(): FeeServiceOptionCondition {
    return new FeeServiceOptionCondition({
      serviceOptionNames: this.serviceOptionNames
    });
  }

  public toJSON(): any {
    return {
      ...super.toJSON(),
      serviceOptionNames: this.serviceOptionNames
    };
  }
}
