import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import ReactMoment from "react-moment";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import VoucherLog from "./VoucherLog";

const styles = (theme) => ({
  container: {
    textAlign: "center"
  },
  voucher: {
    display: "inline-block",
    padding: 10,
    borderRadius: 10,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: 2,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main
  },
  smallSpacing: {
    height: 10
  },
  largeSpacing: {
    height: 40
  },
  label: {
    marginBottom: theme.spacing(),
    fontWeight: "bold"
  }
});

class Voucher extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, voucher } = this.props;

    return (
      <div>
        {voucher && (
          <div className={classes.container}>
            <div
              className={classes.voucher}
              style={{
                borderStyle: voucher.isValid ? "solid" : "dotted"
              }}
            >
              {voucher.label && (
                <Typography variant="h6" className={classes.label}>
                  {voucher.label}
                </Typography>
              )}
              <Typography className={classes.code}>
                {t("voucher.code")}
                {": "}
                <strong>{voucher.id}</strong>
              </Typography>
              <div className={classes.smallSpacing} />
              <Typography>
                {t("voucher.original-value")}
                {": "}
                <strong>{voucher.originalValueToString(t)}</strong>
              </Typography>
              {voucher.isExpired ? (
                <div>
                  <div className={classes.smallSpacing} />
                  <Typography variant="caption">
                    {`${t("voucher.voucher-is-expired")} `}
                  </Typography>
                </div>
              ) : (
                <div>
                  <Typography className={classes.remainingValue}>
                    {t("voucher.remaining-value")}
                    {": "}
                    <strong>{voucher.remainingValueToString(t)}</strong>
                  </Typography>
                  <div className={classes.smallSpacing} />
                  <Typography variant="caption">
                    {`${t("voucher.voucher-valid-until")} `}
                    <ReactMoment format="DD/MM/YYYY, HH:mm">
                      {voucher.validUntil}
                    </ReactMoment>
                  </Typography>
                </div>
              )}
            </div>
            <div className={classes.largeSpacing} />
            <Typography variant="subtitle2">{t("voucher.logs")}</Typography>
            <VoucherLog bar={bar} voucher={voucher} />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(Voucher));
