import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import ContentPage from "tap-io/client/components/common/ContentPage";
import ScanQRCodeDialog from "tap-io/client/components/common/ScanQRCodeDialog";
import config from "tap-io/client/env";
import { deviceStorage } from "tap-io/storage";

import { getScanOrderUrl, getWaiterOrderUrl } from "../constants/routes";
import {
  CACHE_KEY_BAR_LOCATOR,
  CACHE_KEY_SCANNER_ID,
  CACHE_KEY_WAITER_CODE
} from "../constants/cache";

const styles = (theme) => ({});

class ScanQRCodePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isScanQRCodeDialogOpen: false,
      manuallyEnteredLocator: ""
    };
  }

  componentDidMount() {
    this.checkCache();
  }

  checkCache = async () => {
    const barLocator = await deviceStorage.getDeviceCacheItem(
      "",
      CACHE_KEY_BAR_LOCATOR
    );
    const scannerId = await deviceStorage.getDeviceCacheItem(
      "",
      CACHE_KEY_SCANNER_ID
    );
    const waiterCode = await deviceStorage.getDeviceCacheItem(
      "",
      CACHE_KEY_WAITER_CODE
    );

    if (barLocator) {
      this.setState({
        barLocator,
        scannerId: scannerId || null,
        waiterCode: scannerId ? null : waiterCode || null
      });
    }
  };

  handleScanQRCode = () => {
    this.setState({ isScanQRCodeDialogOpen: true });
  };

  getScanRoute = (data) => {
    const regExp = new RegExp(
      `^https:\/\/${config.hosting.scanDomain.replace(/\./g, "\\.")}\/(.*)$`
    );
    const matches = data.match(regExp);

    if (matches && matches.length === 2) {
      return matches[1];
    }

    return null;
  };

  getWaiterRoute = (data) => {
    const regExp = new RegExp(
      `^https:\/\/${config.hosting.orderDomain.replace(
        /\./g,
        "\\."
      )}\/(.*)\\?waiterCode=(.*)$`
    );
    const matches = data.match(regExp);

    if (matches && matches.length === 3) {
      const barLocator = matches[1];
      const waiterCode = matches[2];

      return getWaiterOrderUrl(barLocator, waiterCode);
    }

    return null;
  };

  handleQRCodeScanned = async (data) => {
    const { t } = this.props;

    try {
      const scanRoute = this.getScanRoute(data);

      if (scanRoute) {
        this.setState({
          scanRoute
        });
      } else {
        const waiterRoute = this.getWaiterRoute(data);

        if (waiterRoute) {
          this.setState({
            waiterRoute
          });
        } else {
          throw new Error("error.invalid-qr-code");
        }
      }
    } catch (error) {
      console.warn(error);

      this.setState({
        isScanQRCodeDialogOpen: false
      });

      toast.error(
        `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`
      );
    }
  };

  handleScanQRCodeDialogClose = () => {
    this.setState({ isScanQRCodeDialogOpen: false });
  };

  getScanOrderUrl = () => {
    const { barLocator, scannerId } = this.state;

    return barLocator && scannerId
      ? getScanOrderUrl(barLocator, scannerId)
      : null;
  };

  getWaiterOrderUrl = () => {
    const { barLocator, waiterCode } = this.state;

    return barLocator && waiterCode
      ? getWaiterOrderUrl(barLocator, waiterCode)
      : null;
  };

  render() {
    const { classes, t } = this.props;
    const { isScanQRCodeDialogOpen, scanRoute, waiterRoute } = this.state;

    const scanOrderUrl = this.getScanOrderUrl();
    if (scanOrderUrl) {
      return <Redirect to={scanOrderUrl} />;
    }

    const waiterOrderUrl = this.getWaiterOrderUrl();
    if (waiterOrderUrl) {
      return <Redirect to={waiterOrderUrl} />;
    }

    return (
      <>
        <ScanQRCodeDialog
          isOpen={isScanQRCodeDialogOpen}
          onScan={this.handleQRCodeScanned}
          onClose={this.handleScanQRCodeDialogClose}
        />
        <ContentPage>
          {scanRoute ? (
            <>
              <Typography>{t("label.redirecting-to-scan-page")}</Typography>
              <Redirect to={scanRoute} />
            </>
          ) : waiterRoute ? (
            <>
              <Typography>{t("label.redirecting-to-waiter-page")}</Typography>
              <Redirect to={waiterRoute} />
            </>
          ) : (
            <div>
              <Button
                color="primary"
                variant="contained"
                size="large"
                disableElevation={true}
                onClick={this.handleScanQRCode}
              >
                {t("label.scan-qr-code")}
              </Button>
            </div>
          )}
        </ContentPage>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(ScanQRCodePage));
