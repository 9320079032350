import React from "react";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import common_nl_BE from "../../translations/nl_BE/common.json";
import common_fr_BE from "../../translations/fr_BE/common.json";
import common_en_GB from "../../translations/en_GB/common.json";
import common_de_DE from "../../translations/de_DE/common.json";
import common_es_ES from "../../translations/es_ES/common.json";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "nl_BE", // language to use
  resources: {
    nl_BE: {
      common: common_nl_BE
    },
    fr_BE: {
      common: common_fr_BE
    },
    en_GB: {
      common: common_en_GB
    },
    de_DE: {
      common: common_de_DE
    },
    es_ES: {
      common: common_es_ES
    }
  }
});

const withTranslations = (Component) => {
  class WithTranslations extends React.Component {
    componentDidMount() {}

    changeLanguage = (language) => {
      return i18next.changeLanguage(language);
    };

    render() {
      return (
        <I18nextProvider i18n={i18next}>
          <Component
            language={i18next.language}
            changeLanguage={this.changeLanguage}
            {...this.props}
          />
        </I18nextProvider>
      );
    }
  }

  return WithTranslations;
};

export default withTranslations;
