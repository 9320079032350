import Locale from "./Locale";
import Translations from "./Translations";

export default class LocalisedText {
  private _translations: Partial<Translations>;
  private _locale: Locale;

  constructor(translations: Partial<Translations>, locale: Locale) {
    this._translations = translations;
    this._locale = locale;
  }

  public get translations() {
    return this._translations;
  }

  public get locale() {
    return this._locale;
  }

  public set locale(locale: Locale) {
    this._locale = locale;
  }

  public get text(): string {
    return this.getTranslatedText(this.locale);
  }

  public set text(text: string) {
    this.setTranslatedText(this.locale, text);
  }

  public getTranslatedText(locale: Locale): string {
    return this.translations[locale] || "";
  }

  public setTranslatedText(locale: Locale, text: string) {
    this.translations[locale] = text;
  }

  public toJSON(): any {
    const json: { [locale: string]: string } = {};

    for (let locale in this.translations) {
      json[locale] = this.translations[locale];
    }

    return json;
  }
}
