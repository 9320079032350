import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";

import Moment from "moment";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { FIELD_ICONS } from "../../constants/fields";
import CustomerFieldType from "../../../models/bar/CustomerFieldType";

const styles = (theme) => ({});

class OrderFieldsDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getFieldIcon = (fieldType, color) => {
    const FieldIcon = FIELD_ICONS[fieldType]
      ? FIELD_ICONS[fieldType]
      : HelpOutlineIcon;
    return <FieldIcon color={color} />;
  };

  getFieldContent = (field) => {
    const { t } = this.props;

    if (field) {
      if (field.value !== undefined) {
        switch (field.type) {
          case CustomerFieldType.DATE:
            return Moment(field.value).format("DD/MM/YYYY");
            break;
          case CustomerFieldType.TOGGLE:
            return field.value === true ? t("label.yes") : t("label.no");
            break;
          case CustomerFieldType.SELECT:
            if (field.label !== "") {
              return field.label;
            }
            break;
          default:
            if (field.value !== "") {
              return field.value;
            }
        }
      }

      return `(${t("label.empty")})`;
    }

    return `(${t("label.unknown")})`;
  };

  handleClose = (event) => {
    const { onClose } = this.props;

    // Prevent Dialog 'clickthrough'
    event.stopPropagation();

    onClose();
  };

  handleStopPropagation = (event) => {
    event.stopPropagation();
  };

  render() {
    const { classes, t, isOpen, orderFields } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={this.handleClose}
        onClick={this.handleStopPropagation}
      >
        <DialogTitle>{t("order.extra-data")}</DialogTitle>
        <DialogContent>
          <List>
            {orderFields && orderFields.length > 0 ? (
              orderFields.map((field) => (
                <ListItem key={field.id}>
                  <ListItemIcon>{this.getFieldIcon(field.type)}</ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="overline">{field.name}</Typography>
                    }
                    secondary={
                      <Typography variant="body1">
                        {this.getFieldContent(field)}
                      </Typography>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <Typography>{t("order.no-fields-found")}</Typography>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">
            {t("label.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(OrderFieldsDialog));
