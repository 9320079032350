import { serviceHelper } from "../../helpers";
import VatRate from "../menu/VatRate";

class Product {
  private readonly _id: string;
  private readonly _isCustom: boolean;
  private _name: string;
  private _vatRate: VatRate | null;

  constructor(id: string, isCustom: boolean, name: string, vatRate?: VatRate) {
    this._id = id;
    this._name = name;
    this._isCustom = isCustom;
    this._vatRate = vatRate === undefined ? null : vatRate;
  }

  public get id(): string {
    return this._id;
  }

  public get isCustom(): boolean {
    return this._isCustom;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = serviceHelper.parseString(name);
  }

  public get vatRate(): VatRate | null {
    return this._vatRate;
  }

  public set vatRate(vatRate: VatRate | null) {
    this._vatRate = vatRate === null ? null : vatRate;
  }

  toJSON(): any {
    return {
      id: this.id,
      name: this.name,
      isCustom: this.isCustom,
      vatRate: this.vatRate
    };
  }
}

export default Product;
