import hmacmd5 from "crypto-js/hmac-md5";

export default class ScannerHelper {
  hashPin(barId: string, scannerId: string, pin: string): string {
    return pin ? hmacmd5(pin, `${barId}/${scannerId}`).toString() : "";
  }

  getScannerUrl(barUrl: string, scannerId: string): string {
    return `${barUrl}/${scannerId}/orders`;
  }

  parseScannerUrl(
    scannerDomain: string,
    scannerUrl: string
  ): { barLocator: string; scannerId: string } {
    const regExp = new RegExp(
      `^https:\/\/${scannerDomain.replace(/\./g, "\\.")}\/(.*)\/(.*?)\/(.+)?$`
    );
    const matches = scannerUrl.match(regExp);

    if (!matches || matches.length !== 4) {
      throw new Error("error.qr-code-is-invalid");
    }

    const barLocator = matches[1];
    const scannerId = matches[2];

    return {
      barLocator,
      scannerId
    };
  }
}
