enum PaymentProvider {
  BO = "bo",
  DEFERRED = "deferred", // TO FIX: deprecated (now a BO)
  VOUCHER = "voucher", // TO FIX: deprecated (now BO with PaymentMethod VOUCHER)
  MOLLIE = "mollie",
  CCV = "ccv",
  PAYCONIQ = "payconiq",
  STARNET = "starnet",
  VIVAWALLET = "vivawallet",
  MULTISAFEPAY = "multisafepay"
}

export default PaymentProvider;
