import Capability from "./Capability";
import CapabilityName from "./CapabilityName";
import RestrictionName from "./RestrictionName";
import { statsHelper } from "../../helpers";
import FeeConditionType from "../fee/FeeConditionType";
import ServiceOptionName from "../service/ServiceOptionName";

class Capabilities {
  private _capabilities: any = {};

  constructor(capabilities?: Array<Capability>) {
    if (capabilities) {
      capabilities.forEach((capability) => this.addCapability(capability));
    }
  }

  private get capabilities(): any {
    return this._capabilities;
  }

  private isCapabilityAvailable(capabilityName: string) {
    return (
      this.capabilities[capabilityName] &&
      this.capabilities[capabilityName].isAvailable
    );
  }

  private getCapabilityRestriction(
    capabilityName: string,
    restrictionName: string
  ) {
    return (
      this.capabilities[capabilityName] &&
      this.capabilities[capabilityName].getRestriction(restrictionName)
    );
  }

  public clearCapabilities() {
    this._capabilities = {};
  }

  public setCapability(capability: Capability) {
    if (!capability.name) {
      throw new Error("error.capability-name-is-not-defined");
    }

    this._capabilities[capability.name] = capability;
  }

  public addCapability(capability: Capability) {
    if (!capability.name) {
      throw new Error("error.capability-name-is-not-defined");
    }
    if (this._capabilities[capability.name]) {
      throw new Error("error.duplicate-capability");
    }

    return this.setCapability(capability);
  }

  public removeCapability(capability: Capability) {
    if (!capability.name) {
      throw new Error("error.capability-name-is-not-defined");
    }
    if (!this._capabilities[capability.name]) {
      throw new Error("error.capability-not-found");
    }

    delete this._capabilities[capability.name];
  }

  public areBasesAvailable(): boolean {
    return this.isCapabilityAvailable(CapabilityName.BASES);
  }

  public getMaxAmountOfBases(): number {
    return (
      this.areBasesAvailable() &&
      this.getCapabilityRestriction(
        CapabilityName.BASES,
        RestrictionName.MAX_AMOUNT_OF_BASES
      )
    );
  }

  public areZonesAvailable(): boolean {
    return this.isCapabilityAvailable(CapabilityName.ZONES);
  }

  public getMaxAmountOfZones(): number {
    return (
      this.areZonesAvailable() &&
      this.getCapabilityRestriction(
        CapabilityName.ZONES,
        RestrictionName.MAX_AMOUNT_OF_ZONES
      )
    );
  }

  public getMaxLayoutHeight(): number | undefined {
    return this.areZonesAvailable()
      ? this.getCapabilityRestriction(
          CapabilityName.ZONES,
          RestrictionName.MAX_LAYOUT_HEIGHT
        ) || undefined
      : 0;
  }

  public areScannersAvailable(): boolean {
    return this.isCapabilityAvailable(CapabilityName.SCANNERS);
  }

  public getMaxAmountOfScanners(): number {
    return (
      this.areScannersAvailable() &&
      this.getCapabilityRestriction(
        CapabilityName.SCANNERS,
        RestrictionName.MAX_AMOUNT_OF_SCANNERS
      )
    );
  }

  public areStatsAvailable(): boolean {
    return this.isCapabilityAvailable(CapabilityName.STATS);
  }

  public getStatsDataUnit(): string | undefined {
    return this.areStatsAvailable()
      ? this.getCapabilityRestriction(
          CapabilityName.STATS,
          RestrictionName.STATS_DATA_UNIT
        )
      : undefined;
  }

  public getStatsDataKeys(): Array<string> | undefined {
    return this.areStatsAvailable()
      ? this.getCapabilityRestriction(
          CapabilityName.STATS,
          RestrictionName.STATS_DATA_KEYS
        )
      : undefined;
  }

  public isStatsDataKeyAvailable(statsDataKey: string): boolean {
    return statsHelper.hasStatsDataKey(
      this.getStatsDataKeys() || [],
      statsDataKey
    );
  }

  public getStatsMaxRangeInDays(): number | undefined {
    return this.areStatsAvailable()
      ? this.getCapabilityRestriction(
          CapabilityName.STATS,
          RestrictionName.STATS_MAX_RANGE_IN_DAYS
        )
      : undefined;
  }

  public isDownloadStatsOrdersDayReportAvailable(): boolean {
    return (
      this.areStatsAvailable() &&
      this.getCapabilityRestriction(
        CapabilityName.STATS,
        RestrictionName.IS_DOWNLOAD_STATS_ORDERS_DAY_REPORT_AVAILABLE
      ) === true
    );
  }

  public isDownloadStatsOrdersMonthReportAvailable(): boolean {
    return (
      this.areStatsAvailable() &&
      this.getCapabilityRestriction(
        CapabilityName.STATS,
        RestrictionName.IS_DOWNLOAD_STATS_ORDERS_MONTH_REPORT_AVAILABLE
      ) === true
    );
  }

  public isDownloadStatsItemsReportAvailable(): boolean {
    return (
      this.areStatsAvailable() &&
      this.getCapabilityRestriction(
        CapabilityName.STATS,
        RestrictionName.IS_DOWNLOAD_STATS_ITEMS_REPORT_AVAILABLE
      ) === true
    );
  }

  public areVoucherTemplatesAvailable(): boolean {
    return (
      this.isCapabilityAvailable(CapabilityName.VOUCHERS) &&
      this.getCapabilityRestriction(
        CapabilityName.VOUCHERS,
        RestrictionName.ARE_VOUCHER_TEMPLATES_AVAILABLE
      )
    );
  }

  public arePersonalVouchersAvailable(): boolean {
    return (
      this.isCapabilityAvailable(CapabilityName.VOUCHERS) &&
      this.getCapabilityRestriction(
        CapabilityName.VOUCHERS,
        RestrictionName.ARE_PERSONAL_VOUCHERS_AVAILABLE
      )
    );
  }

  public isModeAvailable(modeName: string): boolean {
    if (this.isCapabilityAvailable(CapabilityName.MODES)) {
      const availableModesByName = this.getCapabilityRestriction(
        CapabilityName.MODES,
        RestrictionName.AVAILABLE_MODES_BY_NAME
      );

      return (
        availableModesByName && availableModesByName.indexOf(modeName) >= 0
      );
    }

    return false;
  }

  public areFeesAvailable(): boolean {
    return this.isCapabilityAvailable(CapabilityName.FEES);
  }

  public getAvailableFeeConditionTypes(): FeeConditionType[] {
    if (this.areFeesAvailable()) {
      const availableFeeConditionTypes = this.getCapabilityRestriction(
        CapabilityName.FEES,
        RestrictionName.AVAILABLE_FEE_CONDITION_TYPES
      );

      if (availableFeeConditionTypes) {
        return availableFeeConditionTypes;
      }
    }

    return [];
  }

  public areDepositsAvailable(): boolean {
    return this.isCapabilityAvailable(CapabilityName.DEPOSITS);
  }

  public areServicesAvailable(): boolean {
    return this.isCapabilityAvailable(CapabilityName.SERVICES);
  }

  public getAvailableServiceOptionsByName(): ServiceOptionName[] {
    if (this.areServicesAvailable()) {
      const availableServiceOptionsByName = this.getCapabilityRestriction(
        CapabilityName.SERVICES,
        RestrictionName.AVAILABLE_SERVICE_OPTIONS_BY_NAME
      );

      if (availableServiceOptionsByName) {
        return availableServiceOptionsByName;
      }
    }

    return [];
  }

  public toJSON(): any {
    const json: { [key: string]: any } = {};

    for (let name in this.capabilities) {
      json[name] = this.capabilities[name].toJSON();
    }

    return json;
  }
}

export default Capabilities;
