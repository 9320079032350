import LayoutNodeBase from "./LayoutNodeBase";
import { serviceOptionHelper } from "../../helpers";
import ServiceOptions from "../service/ServiceOptions";
import ServiceOptionName from "../service/ServiceOptionName";

class LayoutNodeBases {
  private _bases: { [baseId: string]: LayoutNodeBase } = {};

  constructor(data?: any) {
    if (data) {
      for (let baseId in data) {
        this._bases[baseId] = new LayoutNodeBase(data[baseId]);
      }
    }
  }

  private get bases() {
    return this._bases;
  }

  public get length(): number {
    return Object.keys(this.bases).length;
  }

  public get(baseId: string): LayoutNodeBase {
    return this._bases[baseId];
  }

  public has(baseId: string): boolean {
    return !!this.get(baseId);
  }

  public set(baseId: string) {
    this.bases[baseId] = new LayoutNodeBase();
  }

  public clear(baseId: string) {
    delete this.bases[baseId];
  }

  public getBaseIds(): string[] {
    return Object.keys(this.bases);
  }

  public get hasAnyServiceOption(): boolean {
    return this.serviceOptions.length > 0;
  }

  public get serviceOptions(): ServiceOptions {
    const serviceOptions = serviceOptionHelper.createServiceOptions();

    for (let baseId in this.bases) {
      serviceOptions.merge(this.bases[baseId].serviceOptions);
    }

    return serviceOptions;
  }

  public getBaseIdsForServiceOption(
    serviceOptionName: ServiceOptionName
  ): string[] {
    const baseIds: string[] = [];

    for (let baseId in this.bases) {
      if (this.bases[baseId].hasServiceOption(serviceOptionName)) {
        baseIds.push(baseId);
      }
    }

    return baseIds;
  }

  public hasBasesForServiceOption(
    serviceOptionName: ServiceOptionName
  ): boolean {
    return this.getBaseIdsForServiceOption(serviceOptionName).length > 0;
  }

  public getBaseIdsWithAtLeastOneServiceOption(): string[] {
    const baseIds: string[] = [];

    for (let baseId in this.bases) {
      if (this.bases[baseId].hasAnyServiceOption) {
        baseIds.push(baseId);
      }
    }

    return baseIds;
  }

  public toJSON() {
    const json: any = {};

    for (let baseId in this.bases) {
      json[baseId] = this.bases[baseId].toJSON();
    }

    return json;
  }

  public clone(): LayoutNodeBases {
    return new LayoutNodeBases(this.toJSON());
  }
}

export default LayoutNodeBases;
