import BarPropsModel from "../model/BarPropsModel";
import FeeType from "./FeeType";
import FeeCondition from "./FeeCondition";
import { serviceHelper, utilsHelper } from "../../helpers";
import LayoutPath from "../zone/LayoutPath";
import ServiceOptionName from "../service/ServiceOptionName";

type Props = {
  type: FeeType;
  conditions: FeeCondition[];
  isActive: boolean;
  name: string;
  value: number;
  externalId?: string;
};

export default class Fee extends BarPropsModel {
  private _type: FeeType;
  private _conditions: FeeCondition[];
  private _isActive: boolean;
  private _name: string;
  private _value: number;
  private readonly _externalId: string | null;

  constructor(barId: string, id: string | undefined, props: Props) {
    super(barId, id);

    const { type, conditions, isActive, name, value, externalId } = props;

    this._type = type;
    this._conditions = conditions;
    this._isActive = isActive;
    this._name = serviceHelper.parseString(name);
    this._value = serviceHelper.parseNumber(
      value,
      0.01,
      this.type === FeeType.VARIABLE ? 1 : undefined
    );
    this._externalId = externalId || null;

    this.setAllProps([
      "type",
      "conditions",
      "isActive",
      "name",
      "value",
      "externalId"
    ]);
  }

  get type() {
    return this._type;
  }

  set type(v) {
    this._type = v;
    this.setPropUpdated("type");
  }

  get conditions() {
    return this._conditions;
  }

  set conditions(conditions) {
    this._conditions = conditions;
    this.setPropUpdated("conditions");
  }

  get isActive() {
    return this._isActive;
  }

  set isActive(isActive) {
    this._isActive = isActive;
    this.setPropUpdated("isActive");
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
    this.setPropUpdated("name");
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.setPropUpdated("value");
  }

  get externalId(): string | null {
    return this._externalId;
  }

  public get isEditable(): boolean {
    return !this.externalId;
  }

  public get hasConditions(): boolean {
    return this.conditions.length > 0;
  }

  public willApply(
    serviceOptionName?: ServiceOptionName,
    zoneCode?: string,
    layoutPath?: LayoutPath
  ): boolean {
    if (this.isActive) {
      for (let i = 0; i < this.conditions.length; i++) {
        if (
          !this.conditions[i].isMet(serviceOptionName, zoneCode, layoutPath)
        ) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  public calculateFee(
    orderTotal: number,
    serviceOptionName?: ServiceOptionName,
    zoneCode?: string,
    layoutPath?: LayoutPath
  ): number {
    if (this.willApply(serviceOptionName, zoneCode, layoutPath)) {
      return this.type === FeeType.VARIABLE
        ? utilsHelper.roundToTwoDecimals(orderTotal * this.value)
        : this.value;
    }

    return 0;
  }
}
