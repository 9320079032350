import EmailCustomerField from "../models/bar/EmailCustomerField";
import ToggleCustomerField from "../models/bar/ToggleCustomerField";
import Locale from "../models/locale/Locale";

export const BOBarDefaults = {
  orderConfirmationMessage: "",
  customerFields: []
};

export const ABIBarDefaults = {
  orderConfirmationMessage: "We'd love to hear your thoughts! Go to https://survey.bees.be to participate in a survey.",
  customerFields: [
    new EmailCustomerField({
      id: "abi-email",
      name: {
        [Locale.NL_BE]: "E-mailadres",
        [Locale.FR_BE]: "Adresse e-mail",
        [Locale.EN_GB]: "E-mail address",
        [Locale.DE_DE]: "E-Mail-Addresse",
        [Locale.ES_ES]: "Dirección de correo electrónico"
      },
      description: {
        [Locale.NL_BE]: "",
        [Locale.FR_BE]: "",
        [Locale.EN_GB]: "",
        [Locale.DE_DE]: "",
        [Locale.ES_ES]: ""
      },
      isRequired: true,
      sendOrderConfirmationToThisAddress: true
    }),
    new ToggleCustomerField({
      id: "abi-promo",
      name: {
        [Locale.NL_BE]:
          "Schrijf je in voor nieuwsbrieven & promoties van BEES, Beyond Ordering en {{ barName }}",
        [Locale.FR_BE]:
          "Inscrivez-vous pour recevoir des newsletters et promotions de BEES, Beyond Ordering et {{ barName }}",
        [Locale.EN_GB]:
          "Sign up for newsletters & promotions from BEES, Beyond Ordering and {{ barName }}",
        [Locale.DE_DE]:
          "Melden Sie sich an um Newsletter & Werbeaktionen von BEES, Beyond Ordering und {{ barName }} zu erhalten",
        [Locale.ES_ES]:
          "Regístrate para recibir boletines y promociones de las marcas BEES, Beyond Ordering y {{ barName }}"
      },
      description: {
        [Locale.NL_BE]: "Je moet 18+ zijn en kan op elk moment uitschrijven.",
        [Locale.FR_BE]:
          "Vous devez avoir 18+ et pouvez désabonner à tout moment.",
        [Locale.EN_GB]: "You must be 18+ and can unsubscribe at any time.",
        [Locale.DE_DE]:
          "Sie müssen 18+ sein und können sich jederzeit abmelden.",
        [Locale.ES_ES]:
          "Debes ser 18+ y puedes darte de baja en cualquier momento."
      },
      isRequired: false
    })
  ]
};
