import React from "react";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  spacing: {
    display: "block",
    height: 10
  },
  message: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  icon: {},
  label: {
    flexGrow: 1,
    marginLeft: theme.spacing()
  }
});

const Message = ({ classes, icon, label, content }) => (
  <div className={classes.message}>
    {icon}
    <span className={classes.label}>{label}</span>
  </div>
);

export default withStyles(styles)(Message);
