class Transaction {
  private readonly _id: string;
  private readonly _creditCount: number;
  private readonly _totalPrice: number;
  private readonly _isPaid: boolean;
  private readonly _timestamp: Date;

  constructor(id: string, data: any) {
    this._id = id;

    if (!data) {
      throw new Error("data is not defined");
    }

    this._creditCount = data.creditCount;
    this._totalPrice = data.totalPrice;
    this._isPaid = data.isPaid;
    this._timestamp = data.timestamp;
  }

  public get id(): string {
    return this._id;
  }

  public get creditCount(): number {
    return this._creditCount;
  }

  public get totalPrice(): number {
    return this._totalPrice;
  }

  public get isPaid(): boolean {
    return this._isPaid;
  }

  public get timestamp(): Date {
    return this._timestamp;
  }
}

export default Transaction;