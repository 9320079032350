import VoucherService from "../../services/VoucherService";
import Bar from "../../models/bar/Bar";
import { serviceHelper } from "../../helpers";

export default class ClientVoucherService extends VoucherService {
  constructor(fbStore) {
    super(fbStore);
  }

  public generateVouchers(
    userId: string,
    bar: Bar,
    amountOfVouchers: number,
    voucherValidUntil: Date,
    voucherMustBeRedeemedInOneGo: boolean,
    voucherType: string,
    valuePerVoucher: any,
    voucherLabel?: string,
    templateId?: string,
    customerEmail?: string,
    paymentMethod?: string,
    onProgress?: Function
  ) {
    const taskData: any = {
      barId: bar.id,
      amountOfVouchers,
      voucherValidUntil,
      voucherMustBeRedeemedInOneGo,
      voucherType,
      valuePerVoucher
    };
    if (voucherLabel) {
      taskData.voucherLabel = voucherLabel;
    }
    if (templateId) {
      taskData.templateId = templateId;
    }
    if (customerEmail) {
      taskData.customerEmail = customerEmail;
    }
    if (paymentMethod) {
      taskData.paymentMethod = paymentMethod;
    }

    return serviceHelper.createTask(
      userId,
      "voucher",
      "generate",
      taskData,
      onProgress
    );
  }
}
