import BarlowSemiCondensedSemiBold from "../fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-SemiBold.ttf";
import WorkSansMedium from "../fonts/Work_Sans/WorkSans-Medium.ttf";
import WorkSansSemiBold from "../fonts/Work_Sans/WorkSans-SemiBold.ttf";
import BeesRegular from "../fonts/Bees/Bees-Regular.ttf";

import abiLogo from "../assets/abi-logo.png";
import abiLogoSquare from "../assets/abi-logo-square.png";
import abiLogoSquareWithBackground from "../assets/abi-logo-square.jpg";
import abiBackground from "../assets/abi-background.jpg";

const DEFAULT_BACKGROUND = "#fafafa";
const DEFAULT_COLOR_PRIMARY = "#000000";
const DEFAULT_COLOR_SECONDARY = "#ffff00";

const barlowSemiCondensedSemiBold = {
  fontFamily: "BarlowSemiCondensedSemiBold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
        local('BarlowSemiCondensed'),
        local('BarlowSemiCondensed-SemiBold'),
        url(${BarlowSemiCondensedSemiBold}) format('truetype')
      `
};

const workSansMedium = {
  fontFamily: "WorkSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
        local('WorkSans'),
        local('WorkSans-Medium'),
        url(${WorkSansMedium}) format('truetype')
      `
};

const workSansSemiBold = {
  fontFamily: "WorkSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `
        local('WorkSans'),
        local('WorkSans-SemiBold'),
        url(${WorkSansSemiBold}) format('truetype')
      `
};

const beesRegular = {
  fontFamily: "Bees",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `
        local('Bees'),
        local('Bees-Regular'),
        url(${BeesRegular}) format('truetype')
      `
};

const options = (primaryColor, secondaryColor) => {
  return {
    shadows: Array(25).fill("none"),
    palette: {
      background: {
        default: DEFAULT_BACKGROUND
      },
      primary: {
        main: DEFAULT_COLOR_PRIMARY,
        contrastText: DEFAULT_COLOR_SECONDARY
      },
      secondary: {
        main: DEFAULT_COLOR_SECONDARY
      },
      tertiary: {
        main: primaryColor
      },
      error: {
        main: "#ff6f71"
      },
      warning: {
        main: "#ffb042"
      },
      info: {
        main: "#9cfffd"
      },
      success: {
        main: "#77dd77" //"#f0ecfc"
      }
    },
    typography: {
      fontFamily: "WorkSans, Arial",
      h6: {
        fontFamily: "BarlowSemiCondensedSemiBold, Arial",
        fontSize: 24
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": barlowSemiCondensedSemiBold,
          fallbacks: [
            { "@font-face": workSansMedium },
            { "@font-face": workSansSemiBold },
            { "@font-face": beesRegular }
          ],
          ".Toastify__toast--info": {
            backgroundColor: `${DEFAULT_COLOR_SECONDARY} !important`,
            color: DEFAULT_COLOR_PRIMARY
          },
          ".Toastify__close-button--info": {
            color: DEFAULT_COLOR_PRIMARY
          },
          ".BoBarTitle": {
            fontFamily: "Bees"
          },
          ".BoCategory": {
            borderRadius: "8px !important"
          },
          ".BoCategory-default": {
            backgroundColor: `#f0ecfc !important`,
            color: `${DEFAULT_COLOR_PRIMARY} !important`
          },
          ".BoCategory-selected": {
            backgroundColor: `${DEFAULT_COLOR_PRIMARY} !important`,
            color: `${DEFAULT_COLOR_SECONDARY} !important`
          },
          ".MuiFab-secondary:hover": {
            backgroundColor: "white !important"
          }
        }
      },
      MuiSwitch: {
        defaultProps: {
          color: "primary"
        }
      },
      MuiRadio: {
        defaultProps: {
          color: "primary"
        }
      },
      MuiCheckbox: {
        defaultProps: {
          color: "primary"
        }
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.variant === "text" &&
              ownerState.color === "secondary" && {
                color: DEFAULT_COLOR_PRIMARY
              })
          })
        }
      },
      MuiFab: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.color === "secondary" && {
              backgroundColor: "white",
              "&:not(.Mui-disabled)": {
                border: `1px solid ${DEFAULT_COLOR_PRIMARY}`
              }
            })
          })
        }
      }
    }
  };
};

const socials = () => null;

const assets = {
  logo: abiLogo,
  qrCodeLogo: abiLogoSquareWithBackground,
  headerLogoSmall: abiLogoSquare,
  headerLogoLarge: abiLogo,
  background: abiBackground
};

const faviconFolder = "/favicon-abi";

export default {
  editableColors: ["primary"],
  faviconFolder,
  options,
  assets,
  socials
};
