import React, { PureComponent } from "react";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    padding: "10px 20px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  message: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    display: "flex"
  },
  text: {
    marginLeft: 10,
    marginTop: -2,
    textAlign: "left",
    fontWeight: "bold"
  },
  description: {
    marginTop: 5,
    fontSize: 10
  }
});

class PaymentMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, color, icon, text, description } = this.props;

    return (
      <div
        className={classes.content}
        style={{ backgroundColor: color, color: color ? "white" : undefined }}
      >
        <div className={classes.message}>
          <div className={classes.icon}>{icon}</div>
          <span className={classes.text}>{text}</span>
        </div>
        {description && (
          <Typography variant="caption" className={classes.description}>
            {description}
          </Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(PaymentMessage);
