import ServiceOption from "../service/ServiceOption";
import { serviceOptionHelper } from "../../helpers";
import ServiceOptionName from "../service/ServiceOptionName";

class OrderService {
  private readonly _option: ServiceOption;

  constructor(
    serviceProps: any,
    deliveryProps?: any,
    fulfilmentProps?: any,
    priorityProps?: any
  ) {
    this._option =
      serviceProps && serviceProps.option && serviceProps.option.name
        ? serviceOptionHelper.createServiceOption(serviceProps.option.name)
        : deliveryProps && deliveryProps.method
          ? serviceOptionHelper.createDefaultServiceOption(
            deliveryProps.method,
            fulfilmentProps?.method,
            priorityProps?.number
          )
          : serviceOptionHelper.createServiceOption(ServiceOptionName.DELIVERY);
  }

  public get option(): ServiceOption {
    return this._option;
  }

  public is(name: ServiceOptionName): boolean {
    return this.option.name === name;
  }

  public toJSON(): any {
    return {
      option: this.option.toJSON()
    };
  }
}

export default OrderService;
