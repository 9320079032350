import Bar from "../models/bar/Bar";
import Params from "../models/bar/Params";
import DeliveryColors from "../models/delivery/DeliveryColors";
import DeliveryMethods from "../models/delivery/DeliveryMethods";
import PaymentProvider from "../models/payment/PaymentProvider";

import {
  barHelper,
  paymentHelper,
  serviceHelper,
  utilsHelper
} from "../helpers";
import { barParser } from "../parsers";

export default class BarService {
  private readonly fbStore;

  constructor(fbStore: any) {
    this.fbStore = fbStore;
  }

  private getBarsRef() {
    return this.fbStore.collection("bars");
  }

  protected getBarRef(barId: string) {
    return this.getBarsRef().doc(barId);
  }

  private checkIfBarIdsArrayInQueryAreValid(barIds: string[]) {
    if (!barIds) {
      throw new Error("error.bar-ids-is-not-defined");
    }
    if (barIds.length === 0) {
      throw new Error("error.bar-ids-empty");
    }
    if (barIds.length > serviceHelper.IN_QUERY_ARRAY_MAX_SIZE) {
      throw new Error("error.bar-ids-limit-exceeded");
    }
  }

  protected getBarsWithIdsRef(barIds: string[]) {
    this.checkIfBarIdsArrayInQueryAreValid(barIds);

    return this.getBarsRef().where(serviceHelper.documentId(), "in", barIds);
  }

  protected getBarPaymentProviderConfRef(
    barId: string,
    paymentProvider: string
  ) {
    return this.getBarRef(barId)
      .collection("paymentProviders")
      .doc(paymentProvider);
  }

  async getBarSearch(barId: string) {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const searchDoc = await this.fbStore.collection("search").doc(barId).get();

    const searchData = searchDoc.data();

    if (searchData) {
      return searchData;
    } else {
      throw new Error("no locator found");
    }
  }

  protected async getAndUpdateBarParams(
    barId: string,
    paramsUpdateFunction: any
  ) {
    const barRef = this.getBarRef(barId);

    await this.fbStore.runTransaction((fTransaction) => {
      return fTransaction.get(barRef).then((barDoc) => {
        const currentParams = barDoc.data().params || {};
        const updatedParams = paramsUpdateFunction(currentParams);

        fTransaction.update(barRef, {
          params: updatedParams
        });
      });
    });
  }

  protected async setBarOnlinePaymentProviderIsActive(
    barId: string,
    providerName: string,
    isActive: boolean
  ) {
    return await this.setBarOnlinePaymentProvider(barId, providerName, {
      isActive
    });
  }

  protected async setBarOnlinePaymentProvider(
    barId: string,
    providerName: string,
    props: { [key: string]: any }
  ) {
    const barRef = this.getBarRef(barId);

    await this.fbStore.runTransaction((fTransaction) => {
      return fTransaction.get(barRef).then((barDoc) => {
        const onlinePayments = barDoc.data().onlinePayments || {};

        if (
          onlinePayments.paymentProviders === undefined ||
          onlinePayments.paymentProviders === null
        ) {
          onlinePayments.paymentProviders = {};
        }

        if (
          onlinePayments.paymentProviders[providerName] === undefined ||
          onlinePayments.paymentProviders[providerName] === null
        ) {
          onlinePayments.paymentProviders[providerName] = {};
        }

        for (let key in props) {
          onlinePayments.paymentProviders[providerName][key] = props[key];
        }

        fTransaction.update(barRef, {
          onlinePayments
        });
      });
    });
  }

  private async getBarPaymentProviderConf(bar: Bar, providerName: string) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const doc = await this.getBarPaymentProviderConfRef(
      bar.id,
      providerName
    ).get();

    return doc.data();
  }

  private onBarPaymentProviderConf(
    bar: Bar,
    providerName: string,
    cb: Function
  ) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.getBarPaymentProviderConfRef(bar.id, providerName).onSnapshot(
      (doc) => {
        cb(doc.data());
      }
    );
  }

  private async setBarPaymentProviderConf(
    bar: Bar,
    providerName: string,
    conf: any
  ) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    await this.getBarPaymentProviderConfRef(bar.id, providerName).set(conf);
  }

  public async getBarById(barId: string): Promise<Bar> {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const doc = await this.getBarRef(barId).get();
    return barParser.parseDocToBar(doc);
  }

  public onBarById(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.getBarRef(barId).onSnapshot((doc) => {
      cb(barParser.parseDocToBar(doc));
    });
  }

  public onBarsWithIds(barIds: string[], cb: Function) {
    return serviceHelper.onByIds(
      (barIdsForRef: string[]) => this.getBarsWithIdsRef(barIdsForRef),
      (doc) => barParser.parseDocToBar(doc),
      barIds,
      cb
    );
  }

  public async getBarByLocator(barLocator: string): Promise<Bar> {
    const barId = await this.searchBarIdByLocator(barLocator);
    return await this.getBarById(barId);
  }

  public onBarByLocator(barLocator: string, cb: Function) {
    return this.searchBarIdByLocator(barLocator)
      .then((barId) => {
        return this.onBarById(barId, cb);
      })
      .catch((error) => {
        throw error;
      });
  }

  public async searchBarIdByLocator(barLocator: string): Promise<string> {
    if (!barLocator) {
      throw new Error("barLocator is not defined");
    }

    const cleanLocator = barLocator.trim();

    const searchQuerySnapshot = await this.fbStore
      .collection("search")
      .where("locator", "==", cleanLocator)
      .get();

    const searchDoc =
      serviceHelper.getOnlyDocFromQuerySnapshot(searchQuerySnapshot);

    if (searchDoc && searchDoc.id) {
      return searchDoc.id;
    } else {
      throw new Error("no barId found");
    }
  }

  public async searchRedirectOriginByLocator(locator: string): Promise<string> {
    if (!locator) {
      throw new Error("error.locator-is-not-defined");
    }

    const cleanLocator = locator.trim();

    const searchQuerySnapshot = await this.fbStore
      .collection("redirects")
      .where("locator", "==", cleanLocator)
      .get();

    const searchDoc =
      serviceHelper.getOnlyDocFromQuerySnapshot(searchQuerySnapshot);
    const search = searchDoc.data();

    if (search && search.origin) {
      return search.origin;
    } else {
      throw new Error("error.no-redirect-origin-found");
    }
  }

  public async searchBarsById(query: string): Promise<Array<Bar>> {
    const cleanQuery = query.trim();

    const ref = this.getBarsRef()
      .where(serviceHelper.documentId(), ">=", cleanQuery)
      .where(serviceHelper.documentId(), "<=", cleanQuery + "\uf8ff");

    const docs = await ref.get();

    const bars: Array<Bar> = [];

    docs.forEach((doc) => {
      bars.push(barParser.parseDocToBar(doc));
    });

    return bars;
  }

  public async searchBarsByName(query: string): Promise<Array<Bar>> {
    const cleanQuery = query.trim().toLowerCase();

    const ref = this.getBarsRef()
      .where("cleanName", ">=", cleanQuery)
      .where("cleanName", "<=", cleanQuery + "\uf8ff");

    const docs = await ref.get();

    const bars: Array<Bar> = [];

    docs.forEach((doc) => {
      bars.push(barParser.parseDocToBar(doc));
    });

    return bars;
  }

  public async getLatestBars(count: number): Promise<Array<Bar>> {
    if (isNaN(count)) {
      throw new Error("error.count-is-nan");
    }
    if (count <= 0) {
      throw new Error("error.count-is-less-than-or-equal-to-zero");
    }

    const ref = this.getBarsRef().orderBy("timestamp", "desc").limit(count);

    const docs = await ref.get();

    const bars: Array<Bar> = [];

    docs.forEach((doc) => {
      bars.push(barParser.parseDocToBar(doc));
    });

    return bars;
  }

  public async getBarUrlById(barId: string, domain: string): Promise<any> {
    const barSearch = await this.getBarSearch(barId);

    if (barSearch.locator) {
      return `https://${domain}/${barSearch.locator}`;
    } else {
      throw new Error("error.locator-is-not-defined");
    }
  }

  public async getBarUrl(bar: Bar, domain: string): Promise<any> {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.getBarUrlById(bar.id, domain);
  }

  public async getBarZoneUrl(
    bar: Bar,
    domain: string,
    zoneCode: string
  ): Promise<any> {
    const barUrl = await this.getBarUrl(bar, domain);

    return `${barUrl}/${zoneCode}`;
  }

  public async isBarWaiterCodeValid(
    bar: Bar,
    waiterCode: string
  ): Promise<any> {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }
    if (!waiterCode) {
      throw new Error("error.waiter-code-is-not-defined");
    }

    const barSearch = await this.getBarSearch(bar.id);

    return barSearch.locator && barSearch.waiterCode === waiterCode;
  }

  public async getBarWaiterUrl(bar: Bar, domain: string): Promise<any> {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }
    const barSearch = await this.getBarSearch(bar.id);

    if (barSearch.locator && barSearch.waiterCode) {
      return barHelper.getWaiterUrl(
        domain,
        barSearch.locator,
        barSearch.waiterCode
      );
    } else {
      throw new Error(
        "barSearch.locator or barSearch.waiterCode is not defined"
      );
    }
  }

  public setBarName(bar: Bar, name: string) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }
    if (!name) {
      throw new Error("error.name-is-not-defined");
    }

    return this.getBarRef(bar.id).update({
      name
    });
  }

  public setBarIsLive(barId: string, isLive: boolean) {
    return this.getBarRef(barId).update({
      isLive
    });
  }

  public toggleBarIsLive(bar: Bar) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.setBarIsLive(bar.id, !bar.isLive);
  }

  public setBarLogoFilename(bar: Bar, logoFilename?: string) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.getBarRef(bar.id).update({
      logo: logoFilename ? logoFilename : serviceHelper.deleteField()
    });
  }

  public setBarCoverFilename(bar: Bar, coverFilename?: string) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.getBarRef(bar.id).update({
      cover: coverFilename ? coverFilename : serviceHelper.deleteField()
    });
  }

  public setBarColors(bar: Bar, primaryColor: string, secondaryColor: string) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }
    if (!utilsHelper.isValidHexColor(primaryColor)) {
      throw new Error("error.color-value-invalid");
    }
    if (!utilsHelper.isValidHexColor(secondaryColor)) {
      throw new Error("error.color-value-invalid");
    }

    return this.getBarRef(bar.id).update({
      colors: {
        primary: primaryColor,
        secondary: secondaryColor
      }
    });
  }

  public setBarLocales(bar: Bar, appLocale: string, orderLocale: string) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }
    if (!barHelper.isValidLocale(appLocale)) {
      throw new Error("error.app-locale-invalid");
    }
    if (!barHelper.isValidLocale(orderLocale)) {
      throw new Error("error.order-locale-invalid");
    }

    return this.getBarRef(bar.id).update({
      locales: {
        app: appLocale,
        order: orderLocale
      }
    });
  }

  public setBarDeliveryMethods(bar: Bar, deliveryMethods: DeliveryMethods) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.getBarRef(bar.id).update({
      deliveryMethods: deliveryMethods.toJSON()
    });
  }

  public setBarDeliveryColors(bar: Bar, deliveryColors: DeliveryColors) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.getBarRef(bar.id).update({
      deliveryColors: deliveryColors.toJSON()
    });
  }

  public setBarIntgrations(bar: Bar, showAds: boolean) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.getBarRef(bar.id).update({
      integrations: {
        partner: { showAds }
      }
    });
  }

  public async updateBar(bar: Bar) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    await this.getBarRef(bar.id).update(bar.updatedPropsToJSON());
  }

  public updateBarPin(
    userId: string,
    bar: Bar,
    oldPin: string,
    newPin: string,
    onProgress?: Function
  ) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const data: any = {
      barId: bar.id,
      newPinHash: paymentHelper.hashPin(bar.id, newPin)
    };
    if (oldPin) {
      data.oldPinHash = paymentHelper.hashPin(bar.id, oldPin);
    }

    return serviceHelper.createTask(
      userId,
      "bar",
      "updatePin",
      data,
      onProgress
    );
  }

  public setBarDeferredPaymentProviderIsActive(bar: Bar, isActive: boolean) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.setBarOnlinePaymentProviderIsActive(
      bar.id,
      PaymentProvider.DEFERRED,
      isActive
    );
  }

  public setBarVoucherPaymentProviderIsActive(bar: Bar, isActive: boolean) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.setBarOnlinePaymentProviderIsActive(
      bar.id,
      PaymentProvider.VOUCHER,
      isActive
    );
  }

  public async setBarMolliePaymentProviderIsActive(
    bar: Bar,
    isActive: boolean
  ) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    if (isActive) {
      const conf = await this.getBarPaymentProviderConf(
        bar,
        PaymentProvider.MOLLIE
      );
      if (!barHelper.isValidBarMolliePaymentProviderConf(conf)) {
        throw new Error("error.mollie-conf-invalid");
      }
    }

    await this.setBarOnlinePaymentProviderIsActive(
      bar.id,
      PaymentProvider.MOLLIE,
      isActive
    );
  }

  public async setBarVivaWalletPaymentProviderIsActive(
    bar: Bar,
    isOnlineActive: boolean,
    isPosActive: boolean
  ) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const isActive = isOnlineActive || isPosActive;

    if (isActive) {
      const conf = await this.getBarPaymentProviderConf(
        bar,
        PaymentProvider.VIVAWALLET
      );

      if (
        isOnlineActive &&
        !barHelper.isValidBarVivaWalletOnlinePaymentProviderConf(conf)
      ) {
        throw new Error("error.vivawallet-online-conf-invalid");
      }
      if (
        isPosActive &&
        !barHelper.isValidBarVivaWalletPosPaymentProviderConf(conf)
      ) {
        throw new Error("error.vivawallet-pos-conf-invalid");
      }
    }

    await this.setBarOnlinePaymentProvider(bar.id, PaymentProvider.VIVAWALLET, {
      isActive,
      isOnlineActive,
      isPosActive
    });
  }

  public async setBarStarnetPaymentProviderIsActive(
    bar: Bar,
    isActive: boolean
  ) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    if (isActive) {
      const conf = await this.getBarPaymentProviderConf(
        bar,
        PaymentProvider.STARNET
      );
      if (!barHelper.isValidBarStarnetPaymentProviderConf(conf)) {
        throw new Error("error.starnet-conf-invalid");
      }
    }

    await this.setBarOnlinePaymentProviderIsActive(
      bar.id,
      PaymentProvider.STARNET,
      isActive
    );
  }

  public async setBarCcvPaymentProviderIsActive(bar: Bar, isActive: boolean) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    if (isActive) {
      const conf = await this.getBarPaymentProviderConf(
        bar,
        PaymentProvider.CCV
      );
      if (!barHelper.isValidBarCcvPaymentProviderConf(conf)) {
        throw new Error("error.ccv-conf-invalid");
      }
    }

    await this.setBarOnlinePaymentProviderIsActive(
      bar.id,
      PaymentProvider.CCV,
      isActive
    );
  }

  public async setBarMultiSafePayPaymentProviderIsActive(
    bar: Bar,
    isActive: boolean
  ) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    if (isActive) {
      const conf = await this.getBarPaymentProviderConf(
        bar,
        PaymentProvider.MULTISAFEPAY
      );
      if (!barHelper.isValidBarMultiSafePayPaymentProviderConf(conf)) {
        throw new Error("error.multisafepay-conf-invalid");
      }
    }

    await this.setBarOnlinePaymentProviderIsActive(
      bar.id,
      PaymentProvider.MULTISAFEPAY,
      isActive
    );
  }

  public async setBarPayconiqPaymentProviderIsActive(
    bar: Bar,
    isActive: boolean
  ) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    if (isActive) {
      const conf = await this.getBarPaymentProviderConf(
        bar,
        PaymentProvider.PAYCONIQ
      );
      if (!barHelper.isValidBarPayconiqPaymentProviderConf(conf)) {
        throw new Error("error.payconiq-conf-invalid");
      }
    }

    await this.setBarOnlinePaymentProviderIsActive(
      bar.id,
      PaymentProvider.PAYCONIQ,
      isActive
    );
  }

  public async setBarMolliePaymentProviderConf(bar: Bar, conf: any) {
    await this.setBarPaymentProviderConf(bar, PaymentProvider.MOLLIE, conf);

    if (!barHelper.isValidBarMolliePaymentProviderConf(conf)) {
      await this.setBarMolliePaymentProviderIsActive(bar, false);
    }
  }

  public async setBarVivaWalletPaymentProviderConf(bar: Bar, conf: any) {
    const isValidOnlineConf =
      barHelper.isValidBarVivaWalletOnlinePaymentProviderConf(conf);
    const isValidPosConf =
      barHelper.isValidBarVivaWalletPosPaymentProviderConf(conf);
    const isValidConf = isValidOnlineConf || isValidPosConf;

    await this.setBarPaymentProviderConf(bar, PaymentProvider.VIVAWALLET, {
      ...conf,
      isVerified: isValidConf ? conf.isVerified === true : false
    });

    if (!isValidConf) {
      await this.setBarVivaWalletPaymentProviderIsActive(
        bar,
        isValidOnlineConf ? bar.isAllowingVivaWalletOnlinePayments() : false,
        isValidPosConf ? bar.isAllowingVivaWalletPosPayments() : false
      );
    }
  }

  public async setBarStarnetPaymentProviderConf(bar: Bar, conf: any) {
    await this.setBarPaymentProviderConf(bar, PaymentProvider.STARNET, conf);

    if (!barHelper.isValidBarStarnetPaymentProviderConf(conf)) {
      await this.setBarStarnetPaymentProviderIsActive(bar, false);
    }
  }

  public async setBarCcvPaymentProviderConf(bar: Bar, conf: any) {
    await this.setBarPaymentProviderConf(bar, PaymentProvider.CCV, conf);

    if (!barHelper.isValidBarCcvPaymentProviderConf(conf)) {
      await this.setBarCcvPaymentProviderIsActive(bar, false);
    }
  }

  public async setBarMultiSafePayPaymentProviderConf(bar: Bar, conf: any) {
    await this.setBarPaymentProviderConf(
      bar,
      PaymentProvider.MULTISAFEPAY,
      conf
    );

    if (!barHelper.isValidBarMultiSafePayPaymentProviderConf(conf)) {
      await this.setBarMultiSafePayPaymentProviderIsActive(bar, false);
    }
  }

  public async setBarPayconiqPaymentProviderConf(bar: Bar, conf: any) {
    await this.setBarPaymentProviderConf(bar, PaymentProvider.PAYCONIQ, conf);

    if (!barHelper.isValidBarPayconiqPaymentProviderConf(conf)) {
      await this.setBarPayconiqPaymentProviderIsActive(bar, false);
    }
  }

  public onBarMolliePaymentProviderConf(bar: Bar, cb: Function) {
    return this.onBarPaymentProviderConf(bar, PaymentProvider.MOLLIE, cb);
  }

  public onBarVivaWalletPaymentProviderConf(bar: Bar, cb: Function) {
    return this.onBarPaymentProviderConf(bar, PaymentProvider.VIVAWALLET, cb);
  }

  public onBarStarnetPaymentProviderConf(bar: Bar, cb: Function) {
    return this.onBarPaymentProviderConf(bar, PaymentProvider.STARNET, cb);
  }

  public onBarCcvPaymentProviderConf(bar: Bar, cb: Function) {
    return this.onBarPaymentProviderConf(bar, PaymentProvider.CCV, cb);
  }

  public onBarMultiSafePayPaymentProviderConf(bar: Bar, cb: Function) {
    return this.onBarPaymentProviderConf(bar, PaymentProvider.MULTISAFEPAY, cb);
  }

  public onBarPayconiqPaymentProviderConf(bar: Bar, cb: Function) {
    return this.onBarPaymentProviderConf(bar, PaymentProvider.PAYCONIQ, cb);
  }

  /*public setBarIsUsingBases(bar: bar, isUsingBases: boolean) {
    return this.updateBarParams(
      bar,
      isUsingBases ?
        { useBases: true, allowTips: false } :
        { useBases: false }
    );
  }

  public setBarIsUsingZones(bar: bar, isUsingZones: boolean, numberOfYourTableTranslation: string) {
    return this.updateBarParams(
      bar,
      isUsingZones ?
        { useZones: true, numberOfYourTableTranslation } :
        { useZones: false }
    );
  }

  public setBarIsAllowingOnlinePayments(bar: bar, isAllowingOnlinePayments: boolean) {
    return this.updateBarParams(
      bar,
      isAllowingOnlinePayments ?
        { allowOnlinePayments: true, orderCurrency: "euro" } :
        { allowOnlinePayments: false }
    );
  }*/

  public createBarParams(bar: Bar, data: any): Params {
    return new Params({ ...bar.params.toJSON(), ...data });
  }

  public updateBarParams(bar: Bar, paramsToUpdate: Params) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const paramsAsJSON = paramsToUpdate.toJSON();
    return this.getAndUpdateBarParams(bar.id, (params: any) => {
      return {
        ...params,
        ...paramsAsJSON
      };

      /*for (let key in paramsToUpdate) {
        params[key] = paramsToUpdate[key];
      }

      return params;*/
    });
  }
}
