import Zone from "../models/zone/Zone";
import { serviceHelper, utilsHelper } from "../helpers";
import { zoneParser } from "../parsers";
import ServiceOptionName from "../models/service/ServiceOptionName";

export default class ZoneService {
  private readonly fbStore;

  constructor(fbStore) {
    this.fbStore = fbStore;
  }

  protected getZonesRef(barId: string) {
    return this.fbStore.collection("bars").doc(barId).collection("zones");
  }

  protected getZoneRef(barId: string, zoneCode: string) {
    return this.getZonesRef(barId).doc(zoneCode);
  }

  private parseZoneCode(zoneCode: string) {
    return zoneCode ? zoneCode.trim().toUpperCase() : "";
  }

  private generateZoneCode(startAtCode: string): string {
    const nextChar = (c: string) =>
      c ? String.fromCharCode(c.charCodeAt(0) + 1) : "A";
    const nextCol = (s: string) =>
      s
        .toUpperCase()
        .replace(
          /([^Z]?)(Z*)$/,
          (_, a, z) => nextChar(a) + z.replace(/Z/g, "A")
        );

    return nextCol(startAtCode ? this.parseZoneCode(startAtCode) : "");
  }

  public async getAllZones(barId: string): Promise<Array<Zone>> {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    const docs = await this.getZonesRef(barId).get();

    const zones: Array<Zone> = [];
    docs.forEach((doc) => zones.push(zoneParser.parseDocToZone(barId, doc)));
    return zones;
  }

  public async getActiveZonesWithServiceOptions(
    barId: string,
    serviceOptionNames: ServiceOptionName[]
  ): Promise<Array<Zone>> {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    const ref = this.getZonesRef(barId)
      .where("isActive", "==", true)
      .where("serviceOptionNames", "array-contains-any", serviceOptionNames);

    const docs = await ref.get();
    const zones: Array<Zone> = [];
    docs.forEach((doc) => zones.push(zoneParser.parseDocToZone(barId, doc)));
    return zones;
  }

  public onAllZones(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    return this.getZonesRef(barId).onSnapshot((docs) => {
      const zones: Array<Zone> = [];
      docs.forEach((doc) => zones.push(zoneParser.parseDocToZone(barId, doc)));
      cb(zones);
    });
  }

  public onActiveZones(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    return this.getZonesRef(barId)
      .where("isActive", "==", true)
      .onSnapshot((docs) => {
        const zones: Array<Zone> = [];
        docs.forEach((doc) =>
          zones.push(zoneParser.parseDocToZone(barId, doc))
        );
        cb(zones);
      });
  }

  public async getNextFreeZoneCode(barId: string): Promise<string> {
    // TO FIX: optimize query
    const docs = await this.getZonesRef(barId).get();

    let startAtCode: string = "";
    docs.forEach((doc) => {
      const code = doc.id;
      if (code > startAtCode) {
        startAtCode = code;
      }
    });

    return this.generateZoneCode(startAtCode);
  }

  public createZone(barId: string, zoneCode: string, data: any) {
    return new Zone(barId, zoneCode, data);
  }

  public addZone(zone: Zone) {
    if (!zone.code) {
      throw new Error("error.zone-code-is-not-defined");
    }

    const ref = this.getZoneRef(zone.barId, zone.code);

    return this.fbStore.runTransaction((fTransaction) => {
      return fTransaction.get(ref).then((zoneDoc) => {
        if (zoneDoc.exists) {
          throw new Error("error.zone-already-exists");
        }

        fTransaction.set(ref, {
          ...zone.allPropsToJSON(),
          timestamp: serviceHelper.serverTimestamp()
        });
      });
    });
  }

  public removeZone(zone: Zone) {
    if (!zone.id) {
      throw new Error("error.id-is-not-defined");
    }

    return this.getZoneRef(zone.barId, zone.id).delete();
  }

  public async updateZone(zone: Zone) {
    if (!zone.id) {
      throw new Error("error.id-is-not-defined");
    }

    const updatedProps = zone.updatedPropsToJSON();

    if (!utilsHelper.isObjectEmpty(updatedProps)) {
      const ref = this.getZoneRef(zone.barId, zone.code);

      return this.fbStore.runTransaction((fTransaction) => {
        return fTransaction.get(ref).then((zoneDoc) => {
          if (!zoneDoc.exists) {
            throw new Error("error.zone-does-not-exist");
          }

          fTransaction.update(ref, updatedProps);
        });
      });
    }
  }
}
