class ScannerLogEntry {
  private readonly _id: string;
  private readonly _userId: string;
  private readonly _timestamp: Date;

  private readonly _baseId: string | undefined;
  private readonly _orderId: string | undefined;
  private readonly _orderName: string | undefined;
  private readonly _orderConfirmationCode: string | undefined;
  private readonly _previousOrderStatusName: string | undefined;
  private readonly _newOrderStatusName: string | undefined;

  constructor(id: string, props: any) {
    this._id = id;

    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    this._userId = props.userId;
    this._timestamp = props.timestamp;

    if (props.data) {
      switch (props.data.type) {
        case "order":
          if (!props.data.orderId) {
            throw new Error(
              "error.props-order-id-is-not-defined-while-props-type-is-order"
            );
          }
          if (!props.data.baseId) {
            throw new Error(
              "error.props-base-id-is-not-defined-while-props-type-is-order"
            );
          }
          if (!props.data.orderName) {
            throw new Error(
              "error.props-order-name-is-not-defined-while-props-type-is-order"
            );
          }
          if (!props.data.orderConfirmationCode) {
            throw new Error(
              "error.props-order-confirmation-code-is-not-defined-while-props-type-is-order"
            );
          }
          if (!props.data.newOrderStatusName) {
            throw new Error(
              "error.props-order-new-status-name-is-not-defined-while-props-type-is-order"
            );
          }

          this._orderId = props.data.orderId;
          this._baseId = props.data.baseId;
          this._orderName = props.data.orderName;
          this._orderConfirmationCode = props.data.orderConfirmationCode;
          this._previousOrderStatusName =
            props.data.previousOrderStatusName || undefined;
          this._newOrderStatusName = props.data.newOrderStatusName;
          break;
        default:
          throw new Error("error.props-type-is-not-valid");
          break;
      }
    }
  }

  public get id(): string {
    return this._id;
  }

  public get userId(): string {
    return this._userId;
  }

  public get timestamp(): Date {
    return this._timestamp;
  }

  public get baseId(): string | undefined {
    return this._baseId;
  }

  public get orderId(): string | undefined {
    return this._orderId;
  }

  public get orderName(): string | undefined {
    return this._orderName;
  }

  public get orderConfirmationCode(): string | undefined {
    return this._orderConfirmationCode;
  }

  public get previousOrderStatusName(): string | undefined {
    return this._previousOrderStatusName;
  }

  public get newOrderStatusName(): string | undefined {
    return this._newOrderStatusName;
  }

  public toJSON(): any {
    return {
      id: this.id,
      userId: this.userId,
      timestamp: this.timestamp,
      data: {
        type: "order",
        baseId: this.baseId,
        orderId: this.orderId,
        orderName: this.orderName,
        orderConfirmationCode: this.orderConfirmationCode,
        previousOrderStatusName: this.previousOrderStatusName,
        newOrderStatusName: this.newOrderStatusName
      }
    };
  }
}

export default ScannerLogEntry;
