import { serviceHelper, statsHelper } from "../../helpers";
import BarPropsModel from "../model/BarPropsModel";

import StatsDataCollection from "./StatsDataCollection";
import OrdersStatsDataCollection from "./OrdersStatsDataCollection";
import StatsDataTime from "./StatsDataTime";
import StatsDataUnit from "./StatsDataUnit";
import StatsData from "./StatsData";

export default class Stats extends BarPropsModel {
  private readonly _formats: Array<string> | undefined;
  private readonly _timestamp: Date | undefined;

  private readonly _unit: string | undefined;
  private readonly _perDay: OrdersStatsDataCollection | undefined;
  private readonly _perHour: StatsDataCollection | undefined;
  private readonly _perQuarter: StatsDataCollection | undefined;

  constructor(barId: string, id?: string, props?: any) {
    super(barId, id);

    if (!props) {
      throw new Error("props is not defined");
    }

    if (props.f) {
      this._formats = serviceHelper.parseArray(props.f);
    }

    if (props.t) {
      this._timestamp = serviceHelper.parseTimestamp(props.t);
    }

    const perDay: OrdersStatsDataCollection = new OrdersStatsDataCollection();
    const perHour: StatsDataCollection = new StatsDataCollection();
    const perQuarter: StatsDataCollection = new StatsDataCollection();

    // Populate perQuarter collection if it's available
    if (props[StatsDataUnit.QUARTER] && props[StatsDataUnit.QUARTER].d) {
      const perQuarterData = props[StatsDataUnit.QUARTER].d;
      const { minTime, maxTime } = this.getMinAndMaxTime(
        Object.keys(perQuarterData)
      );

      if (minTime && maxTime) {
        this.iterateTimes(
          minTime,
          maxTime,
          StatsDataUnit.QUARTER,
          (time: string) => {
            const statsDataTime = new StatsDataTime(time);
            const statsDataForQuarter = perQuarterData[time]
              ? statsHelper.dataToStatsData(perQuarterData[time])
              : new StatsDataCollection();

            perQuarter.set(
              statsDataTime.roundedToQuarterTime,
              statsDataForQuarter.clone()
            );
          }
        );
      }

      this._unit = StatsDataUnit.QUARTER;
    }

    // Populate perHour collection if it's available
    if (props[StatsDataUnit.HOUR] && props[StatsDataUnit.HOUR].d) {
      const perHourData = props[StatsDataUnit.HOUR].d;
      const { minTime, maxTime } = this.getMinAndMaxTime(
        Object.keys(perHourData)
      );

      if (minTime && maxTime) {
        this.iterateTimes(
          minTime,
          maxTime,
          StatsDataUnit.HOUR,
          (time: string) => {
            const statsDataTime = new StatsDataTime(time);
            const statsDataForHour = perHourData[time]
              ? statsHelper.dataToStatsData(perHourData[time])
              : new StatsDataCollection();

            perHour.set(
              statsDataTime.roundedToHourTime,
              statsDataForHour.clone()
            );
          }
        );
      }

      this._unit = StatsDataUnit.HOUR;
    }

    // Populate perDay collection if it's available
    if (props[StatsDataUnit.DAY]) {
      perDay.add(statsHelper.dataToStatsData(props[StatsDataUnit.DAY]));

      // When the least accurate unit is DAY; map all other unit collections to DAY
      perQuarter.forEach((time: string, quarterStatsData: StatsData) => {
        perDay.add(quarterStatsData.clone());
      });
      perHour.forEach((time: string, hourStatsData: StatsData) => {
        perDay.add(hourStatsData.clone());
      });

      // And omit the more accurate units
      this._unit = StatsDataUnit.DAY;
      this._perDay = perDay;
      this._perHour = undefined;
      this._perQuarter = undefined;
    } else if (perHour.length > 0) {
      // If the perQuarter collection is populated, as well as perHour collection,
      // map the perQuarter collection to the perHour collection
      // (minTime and maxTime might be different, so map the whole range)
      if (perQuarter.length > 0) {
        const { minTime, maxTime } = this.getMinAndMaxTime(perQuarter.ids);

        if (minTime && maxTime) {
          this.iterateTimes(
            minTime,
            maxTime,
            StatsDataUnit.QUARTER,
            (time: string) => {
              const statsDataTime = new StatsDataTime(time);
              const statsDataForQuarter =
                perQuarter.getStatsDataCollection(time);

              perHour
                .getStatsDataCollection(statsDataTime.roundedToHourTime)
                .add(statsDataForQuarter.clone());
            }
          );
        }
      }

      // Map the perHour collection to the less accurate perDay collection
      perHour.forEach((time: string, statsDataForHour: StatsData) => {
        perDay.add(statsDataForHour.clone());
      });

      // Only omit the perQuarter collection (as we don't have all data for this accuracy)
      this._unit = StatsDataUnit.HOUR;
      this._perDay = perDay;
      this._perHour = perHour;
      this._perQuarter = undefined;
    } else if (perQuarter.length > 0) {
      // Map perQuarter collection to the less accurate unit collections
      perQuarter.forEach((time: string, statsDataForQuarter: StatsData) => {
        const statsDataTime = new StatsDataTime(time);

        perDay.add(statsDataForQuarter.clone());
        perHour
          .getStatsDataCollection(statsDataTime.roundedToHourTime)
          .add(statsDataForQuarter.clone());
      });

      // Set all accuracy levels (as we have all the data)
      this._unit = StatsDataUnit.QUARTER;
      this._perDay = perDay;
      this._perHour = perHour;
      this._perQuarter = perQuarter;
    } else {
      // No data available
      this._perDay = perDay;
      this._perHour = perHour;
      this._perQuarter = perQuarter;
    }

    super.setAllProps([
      "u",
      "k",
      "s",
      "v",
      "t",
      StatsDataUnit.DAY,
      StatsDataUnit.HOUR,
      StatsDataUnit.QUARTER
    ]);
  }

  private getMinAndMaxTime(times: Array<string>): {
    minTime: string | undefined;
    maxTime: string | undefined;
  } {
    let minTime: string | undefined = undefined;
    let maxTime: string | undefined = undefined;

    times.forEach((time: string) => {
      if (!minTime || time < minTime) {
        minTime = time;
      }
      if (!maxTime || time > maxTime) {
        maxTime = time;
      }
    });

    return {
      minTime,
      maxTime
    };
  }

  private minutesToTime(minutes: number): string {
    return new StatsDataTime(minutes).preciseTime;
  }

  private timeToMinutes(time: string): number {
    return new StatsDataTime(time).timeInMinutes;
  }

  private iterateTimes(
    minTime: string,
    maxTime: string,
    unit: string,
    cb: Function
  ): void {
    const minutesToAddPerStep =
      unit === StatsDataUnit.QUARTER
        ? 15
        : unit === StatsDataUnit.HOUR
        ? 60
        : 1440;

    let timeAsMinutes = this.timeToMinutes(minTime);
    const maxTimeAsMinutes = this.timeToMinutes(maxTime);

    if (timeAsMinutes > maxTimeAsMinutes) {
      throw new Error("error.min-time-must-be-less-than-max-time");
    }

    while (timeAsMinutes <= maxTimeAsMinutes) {
      cb(this.minutesToTime(timeAsMinutes));

      timeAsMinutes += minutesToAddPerStep;
    }
  }

  public get date(): string | undefined {
    return this.id;
  }

  public get unit(): string | undefined {
    return this._unit;
  }

  public get formats(): Array<string> | undefined {
    return this._formats;
  }

  public get timestamp(): Date | undefined {
    return this._timestamp;
  }

  public hasOnlyFormat(format: string): boolean {
    return !!(
      this.formats &&
      this.formats.length === 1 &&
      this.formats[0] === format
    );
  }

  public get completeKeys(): Array<string> {
    if (this.formats && this.formats.length > 0) {
      const parsedFirstFormat = statsHelper.parseStatsFormat(this.formats[0]);

      if (parsedFirstFormat) {
        let completeStatsDataKeys = parsedFirstFormat.statsDataKeys;

        for (let i = 1; i < this.formats.length; i++) {
          const format = this.formats[i];
          const parsedFormat = statsHelper.parseStatsFormat(format);

          if (parsedFormat) {
            const { statsDataKeys } = parsedFormat;

            completeStatsDataKeys = completeStatsDataKeys.filter(
              (statsDataKey) => statsDataKeys.indexOf(statsDataKey) >= 0
            );
          }
        }

        return completeStatsDataKeys;
      }
    }

    return [];
  }

  public hasAtLeastAccurateUnit(statsDataUnit: string): boolean {
    switch (statsDataUnit) {
      case StatsDataUnit.QUARTER:
        return this.unit === StatsDataUnit.QUARTER;
        break;
      case StatsDataUnit.HOUR:
        return (
          this.unit === StatsDataUnit.QUARTER ||
          this.unit === StatsDataUnit.HOUR
        );
        break;
      case StatsDataUnit.DAY:
        return (
          this.unit === StatsDataUnit.QUARTER ||
          this.unit === StatsDataUnit.HOUR ||
          this.unit === StatsDataUnit.DAY
        );
        break;
    }

    throw new Error("error.stats-data-unit-is-invalid");
  }

  public hasLessAccurateUnitThan(statsDataUnit: string): boolean {
    switch (statsDataUnit) {
      case StatsDataUnit.QUARTER:
        return (
          this.unit === StatsDataUnit.DAY || this.unit === StatsDataUnit.HOUR
        );
        break;
      case StatsDataUnit.HOUR:
        return this.unit === StatsDataUnit.DAY;
        break;
      case StatsDataUnit.DAY:
        return false;
        break;
    }

    throw new Error("error.stats-data-unit-is-invalid");
  }

  public get perMostAccurateUnit(): StatsDataCollection | undefined {
    return this.perQuarter
      ? this.perQuarter
      : this.perHour
      ? this.perHour
      : this.perDay;
  }

  public perUnit(unit: string): StatsDataCollection | undefined {
    switch (unit) {
      case StatsDataUnit.DAY:
        return this.perDay;
        break;
      case StatsDataUnit.HOUR:
        return this.perHour;
        break;
      case StatsDataUnit.QUARTER:
        return this.perQuarter;
        break;
      default:
        throw new Error("error.unit-is-not-valid");
        break;
    }
  }

  public get perDay(): OrdersStatsDataCollection | undefined {
    return this._perDay;
  }

  public get perHour(): StatsDataCollection | undefined {
    return this._perHour;
  }

  public forHour(time: StatsDataTime): OrdersStatsDataCollection {
    if (!this.perHour) {
      throw new Error("error.per-hour-is-not-defined");
    }

    const statsData = this.perHour.getStatsDataCollection(time.toString());

    if (!statsHelper.isStatsDataCollection(statsData)) {
      throw new Error("error.per-day-must-be-order-stats-data");
    }

    return <OrdersStatsDataCollection>statsData;
  }

  public get perQuarter(): StatsDataCollection | undefined {
    return this._perQuarter;
  }

  public forQuarter(time: StatsDataTime): OrdersStatsDataCollection {
    if (!this.perQuarter) {
      throw new Error("error.per-hour-is-not-defined");
    }

    const statsData = this.perQuarter.getStatsDataCollection(time.toString());

    if (!statsHelper.isStatsDataCollection(statsData)) {
      throw new Error("error.per-day-must-be-order-stats-data");
    }

    return <OrdersStatsDataCollection>statsData;
  }

  public getRange(fromTime?: string, toTime?: string): Stats {
    if (this.perQuarter) {
      return new Stats(this.barId, this.id, {
        f: this.formats,
        t: this.timestamp,
        [StatsDataUnit.QUARTER]: this.perQuarter
          .range(fromTime, toTime)
          .toJSON()
      });
    } else if (this.perHour) {
      return new Stats(this.barId, this.id, {
        f: this.formats,
        t: this.timestamp,
        [StatsDataUnit.HOUR]: this.perHour.range(fromTime, toTime).toJSON()
      });
    }

    return this;
  }
}
