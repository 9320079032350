import React, { PureComponent } from "react";

import SocialButton from "./SocialButton";

class FacebookButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { url, size, margin } = this.props;

    return (
      <SocialButton
        icon="/img/facebook.png"
        url={url}
        size={size}
        margin={margin}
      />
    );
  }
}

export default FacebookButton;
