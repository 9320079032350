import LayoutPath from "./LayoutPath";

export default class LayoutLocation {
  private readonly _code: string;
  private readonly _name: string;
  private readonly _parent: LayoutLocation | null;

  constructor(code: string, name: string, parent?: LayoutLocation) {
    this._code = code;
    this._name = name;
    this._parent = parent || null;
  }

  public get name(): string {
    return this._name;
  }

  public get code(): string {
    return this._code;
  }

  public get parent(): LayoutLocation | null {
    return this._parent;
  }

  public get path(): LayoutPath {
    return this.parent
      ? new LayoutPath([...this.parent.path.elements, this.code])
      : new LayoutPath([this.code]);
  }

  public toString(): string {
    return this.parent ? `${this.parent.toString()} > ${this.name}` : this.name;
  }
}
