import React, { PureComponent } from "react";
import classNames from "classnames";

import animateScrollTo from "animated-scroll-to";

import withStyles from "@mui/styles/withStyles";

const CATEGORY_MARGIN = 8;

const Category = (props) => {
  const { classes, onClick, isSelected, category } = props;

  return (
    <div
      data-category-id={category.id}
      className={classNames(
        "BoCategory",
        classes.category,
        isSelected ? "BoCategory-selected" : "BoCategory-default",
        {
          [classes.selectedCategory]: isSelected
        }
      )}
      onClick={onClick}
    >
      <span className={classNames(classes.name, "notranslate")}>
        {category.name}
      </span>
    </div>
  );
};

const styles = (theme) => ({
  container: {
    position: "relative",
    display: "block",
    width: "100%",
    maxWidth: 600,
    margin: "0 auto",
    padding: theme.spacing(0.5, 0),
    borderRadius: 4,
    backgroundColor: "rgba(235, 235, 235, 0.95)",
    "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))": {
      backdropFilter: "saturate(180%) blur(20px)",
      backgroundColor: "rgba(235, 235, 235, 0.85)",
    }
  },
  categorySelector: {
    display: "flex",
    overflowX: "scroll",
    "-webkit-overflow-scrolling": "touch",
    //scrollSnapType: "x mandatory",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  leftBumper: {
    minWidth: "50%"
  },
  rightBumper: {
    minWidth: "50%"
    //scrollSnapAlign: "none"
  },
  fade: {
    /*position: "absolute",
    zIndex: 10,
    top: 0,
    bottom: 0,
    width: 50*/
  },
  leftFade: {
    /*left: 0,
    background:
      "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.7) 35%, rgba(255,255,255,0) 100%)"*/
  },
  rightFade: {
    /*right: 0,
    background:
      "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 35%, rgba(255,255,255,1) 100%)"*/
  },
  category: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100%",
    height: 40,
    marginLeft: CATEGORY_MARGIN,
    marginRight: CATEGORY_MARGIN,
    padding: "0 20px",
    //border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    //scrollSnapAlign: "start",
    //scrollMargin: `${CATEGORY_MARGIN / 2}px`,
    //scrollSnapMargin: `${CATEGORY_MARGIN / 2}px`,
    textAlign: "center",
    position: "relative"
  },
  selectedCategory: {
    fontWeight: "bold",
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  name: {
    overflow: "hidden",
    fontSize: 16,
    textTransform: "uppercase",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
});

class MenuCategorySelector extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategory: null
    };
  }

  componentDidMount() {
    const { selectCategory, categories } = this.props;

    selectCategory(this.handleSelectCategory);

    if (categories && categories.length > 0) {
      this.handleSelectCategory(categories[0]);
    }
  }

  componentWillUnmount() {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
  }

  handleSelectCategory = (category) => {
    this.setState({ selectedCategory: category });
    this.scrollToCategory(category);
  };

  scrollToCategory = (categoryToScrollTo) => {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    const container = document.querySelector(`.categorySelector`);
    const category = document.querySelector(
      `.categorySelector [data-category-id="${categoryToScrollTo.id}"]`
    );

    this.scrollTimeout = setTimeout(() => {
      animateScrollTo(category, {
        horizontalOffset: -(container.offsetWidth - category.offsetWidth) / 2,
        elementToScroll: container
      });
    }, 10);
  };

  handleCategoryClick = (category) => (event) => {
    const { onSelect } = this.props;

    this.handleSelectCategory(category);

    onSelect(category);
  };

  render() {
    const { classes, categories } = this.props;
    const { selectedCategory } = this.state;

    return (
      <div className={classes.container}>
        <div className={classNames(classes.fade, classes.leftFade)} />
        <div
          className={classNames("categorySelector", classes.categorySelector)}
        >
          <div className={classes.leftBumper} />
          {categories.map((category, index) => (
            <Category
              key={category.id}
              style={{ maxWidth: "100%" }}
              classes={classes}
              onClick={this.handleCategoryClick(category)}
              isSelected={
                selectedCategory && category.id === selectedCategory.id
              }
              category={category}
            />
          ))}
          <div className={classes.rightBumper} />
        </div>
        <div className={classNames(classes.fade, classes.rightFade)} />
      </div>
    );
  }
}

export default withStyles(styles)(MenuCategorySelector);
