import { firebase } from "../firebase";

import AuthHelper from "./AuthHelper";
import BarHelper from "./BarHelper";
import CatalogueHelper from "./CatalogueHelper";
import MenuHelper from "./MenuHelper";
import SoundHelper from "./SoundHelper";
import OrderHelper from "./OrderHelper";
import PaymentHelper from "./PaymentHelper";
import VoucherHelper from "./VoucherHelper";
import BaseHelper from "./BaseHelper";
import ZoneHelper from "./ZoneHelper";
import ScannerHelper from "./ScannerHelper";
import IntegrationHelper from "./IntegrationHelper";
import ServiceOptionHelper from "./ServiceOptionHelper";
import DeliveryHelper from "./DeliveryHelper";
import FulfilmentHelper from "./FulfilmentHelper";
import FeeHelper from "./FeeHelper";
import DepositHelper from "./DepositHelper";
import StatsHelper from "./StatsHelper";
import ServiceHelper from "./ServiceHelper";
import UtilsHelper from "./UtilsHelper";

const authHelper = new AuthHelper();
const barHelper = new BarHelper();
const catalogueHelper = new CatalogueHelper();
const menuHelper = new MenuHelper();
const soundHelper = new SoundHelper();
const orderHelper = new OrderHelper();
const paymentHelper = new PaymentHelper();
const voucherHelper = new VoucherHelper();
const baseHelper = new BaseHelper();
const zoneHelper = new ZoneHelper();
const scannerHelper = new ScannerHelper();
const integrationHelper = new IntegrationHelper();
const serviceOptionHelper = new ServiceOptionHelper();
const deliveryHelper = new DeliveryHelper();
const fulfilmentHelper = new FulfilmentHelper();
const feeHelper = new FeeHelper();
const depositHelper = new DepositHelper();
const statsHelper = new StatsHelper();
const serviceHelper = new ServiceHelper(firebase);
const utilsHelper = new UtilsHelper();

export {
  authHelper,
  barHelper,
  catalogueHelper,
  menuHelper,
  soundHelper,
  orderHelper,
  paymentHelper,
  voucherHelper,
  baseHelper,
  zoneHelper,
  scannerHelper,
  integrationHelper,
  serviceOptionHelper,
  deliveryHelper,
  fulfilmentHelper,
  feeHelper,
  depositHelper,
  statsHelper,
  serviceHelper,
  utilsHelper
};
