import { serviceHelper } from "../../helpers";

class OrderSubOrder {
  private readonly _type: string;

  private readonly _baseId: string;
  private readonly _externalBaseId: string;
  private readonly _menuIds: string[];
  private readonly _feeIds: string[];
  private readonly _label: string;

  private _reference: string | null;
  private _code: string | null;
  private _status: string | null;

  private readonly _createdAt: Date;
  private _lastSyncedAt: Date | null;

  constructor(baseId: string, props: any) {
    if (!baseId) {
      throw new Error("error.base-id-is-not-defined");
    }
    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    this._baseId = baseId;

    this._type = serviceHelper.parseString(props.type);
    this._externalBaseId = serviceHelper.parseString(props.externalBaseId);
    this._menuIds = serviceHelper.parseArray(props.menuIds);
    this._feeIds = props.feeIds ? serviceHelper.parseArray(props.feeIds) : [];
    this._label = props.label ? serviceHelper.parseString(props.label) : "";
    this._reference = props.reference
      ? serviceHelper.parseString(props.reference)
      : null;
    this._code = props.code ? serviceHelper.parseString(props.code) : null;
    this._status = props.status
      ? serviceHelper.parseString(props.status)
      : null;
    this._createdAt = serviceHelper.parseTimestamp(props.createdAt);
    this._lastSyncedAt = props.lastSyncedAt
      ? serviceHelper.parseTimestamp(props.lastSyncedAt)
      : null;
  }

  public get baseId(): string {
    return this._baseId;
  }

  public get type(): string {
    return this._type;
  }

  public get externalBaseId(): string {
    return this._externalBaseId;
  }

  public get menuIds(): string[] {
    return this._menuIds;
  }

  public get feeIds(): string[] {
    return this._feeIds;
  }

  public get label(): string {
    return this._label;
  }

  public get reference(): string | null {
    return this._reference;
  }

  public set reference(reference: string | null) {
    this._reference = reference;
  }

  public get code(): string | null {
    return this._code;
  }

  public set code(code: string | null) {
    this._code = code;
  }

  public get status(): string | null {
    return this._status;
  }

  public set status(status: string | null) {
    this._status = status;
  }

  public get createdAt(): Date {
    return this._createdAt;
  }

  public get lastSyncedAt(): Date | null {
    return this._lastSyncedAt;
  }

  public set lastSyncedAt(lastSyncedAt: Date | null) {
    this._lastSyncedAt = lastSyncedAt;
  }

  public get isSent(): boolean {
    return !!this.reference;
  }

  public toJSON(): any {
    return {
      type: this.type,
      externalBaseId: this.externalBaseId,
      menuIds: this.menuIds,
      reference: this.reference,
      code: this.code,
      status: this.status,
      createdAt: this.createdAt,
      lastSyncedAt: this.lastSyncedAt
    };
  }
}

export default OrderSubOrder;
