import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import queryString from "query-string";

import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import RedeemIcon from "@mui/icons-material/Redeem";
import withStyles from "@mui/styles/withStyles";

import { voucherHelper } from "../../../helpers";
import ScanQRCodeDialog from "../common/ScanQRCodeDialog";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  spacing: {
    height: 20
  },
  enterVoucherManuallyContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: 10,
    borderRadius: 4,
    backgroundColor: theme.palette.background.default
  },
  title: {
    fontWeight: "bold"
  },
  fields: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
    textAlign: "center"
  },
  field: {},
  actions: {
    paddingBottom: 20,
    justifyContent: "center"
  }
});

class ScanOrManuallyEnterVoucher extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isScanQRCodeDialogOpen: false,
      isEnterVoucherManuallyVisible: false
    };
  }

  handleVoucherIdChange = (event) => {
    const { onVoucherIdChange } = this.props;
    const val = event.target.value;
    onVoucherIdChange(val);
  };

  handleVoucherPinChange = (event) => {
    const { onVoucherPinChange } = this.props;
    const val = event.target.value.replace(/[^0-9]/g, "");
    onVoucherPinChange(val);
  };

  handleShowEnterVoucherManually = () => {
    this.setState({ isEnterVoucherManuallyVisible: true });
  };

  handleScanVoucherCode = () => {
    this.setState({ isScanQRCodeDialogOpen: true });
  };

  handleQRCodeScanned = (data) => {
    const { t, onConfirmVoucher } = this.props;

    try {
      const indexOfQuery = data.indexOf("?");
      if (indexOfQuery < 0) {
        throw new Error("error.voucher-invalid");
      }

      const query = data.substr(indexOfQuery);
      const params = queryString.parse(query);

      const { voucherId, voucherPin } = voucherHelper.decodeVoucherCode(
        params.voucherCode
      );

      onConfirmVoucher(voucherId, voucherPin);

      this.setState({
        isScanQRCodeDialogOpen: false
      });
    } catch (error) {
      console.warn(error);
      toast.error(`${t("label.something-went-wrong")} (${t(error.message)})`);
    }
  };

  handleScanQRCodeDialogClose = () => {
    this.setState({ isScanQRCodeDialogOpen: false });
  };

  handleConfirmVoucher = () => {
    const { onConfirmVoucher, voucherId, voucherPin } = this.props;

    onConfirmVoucher(voucherId, voucherPin);
  };

  render() {
    const {
      classes,
      t,
      isLoading,
      confirmVoucherLabel,
      voucherId,
      voucherPin
    } = this.props;
    const { isScanQRCodeDialogOpen, isEnterVoucherManuallyVisible } =
      this.state;

    return (
      <div className={classes.container}>
        <ScanQRCodeDialog
          isOpen={isScanQRCodeDialogOpen}
          onClose={this.handleScanQRCodeDialogClose}
          onScan={this.handleQRCodeScanned}
        />
        <Button
          variant="outlined"
          size="large"
          color="primary"
          startIcon={
            isLoading ? <CircularProgress size={30} /> : <RedeemIcon />
          }
          disabled={isLoading}
          onClick={this.handleScanVoucherCode}
        >
          {t("payment.scan-voucher")}
        </Button>
        <div className={classes.spacing} />
        {isEnterVoucherManuallyVisible ? (
          <div className={classes.enterVoucherManuallyContainer}>
            <Typography className={classes.title}>
              {t("payment.enter-voucher-manually")}
            </Typography>
            <div className={classes.fields}>
              <TextField
                className={classes.field}
                margin="dense"
                label={t("voucher.code")}
                disabled={isLoading}
                value={voucherId}
                onChange={this.handleVoucherIdChange}
              />
              <TextField
                className={classes.field}
                type="password"
                margin="dense"
                label={t("voucher.pin")}
                disabled={isLoading}
                value={voucherPin}
                onChange={this.handleVoucherPinChange}
              />
            </div>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              startIcon={
                isLoading ? <CircularProgress size={30} /> : <RedeemIcon />
              }
              disabled={isLoading}
              onClick={this.handleConfirmVoucher}
            >
              {confirmVoucherLabel}
            </Button>
          </div>
        ) : (
          <Button
            size="small"
            color="primary"
            disabled={isLoading}
            onClick={this.handleShowEnterVoucherManually}
          >
            {t("payment.i-have-trouble-scanning")}
          </Button>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(ScanOrManuallyEnterVoucher)
);
