import IntegrationName from "./IntegrationName";
import IntegrationStatus from "./IntegrationStatus";
import BarPropsModel from "../model/BarPropsModel";

class Integration extends BarPropsModel {
  private readonly _key: string;
  private _externalKey: string;
  private _isActive: boolean;
  private _status: IntegrationStatus;

  constructor(barId: string, name: IntegrationName, props: any) {
    super(barId, name);

    if (!name) {
      throw new Error("error.name-is-not-defined");
    }
    if (!props) {
      throw new Error("error.props-is-not-defined");
    }
    /*if (!props.key) {
      throw new Error("error.props-key-is-not-defined");
    }*/

    this._key = props.key;
    this._externalKey = props.externalKey;
    this._isActive = props.isActive === true;
    this._status = props.status || IntegrationStatus.UNLINKED;

    this.setAllProps([]);
  }

  protected setAllProps(props: Array<string>) {
    super.setAllProps([
      "name",
      //"key",
      //"externalKey",
      "isActive",
      //"status",
      ...props
    ]);
  }

  public get name(): string {
    if (!this.id) {
      throw new Error("error.id-is-not-defined");
    }

    return this.id;
  }

  public get key(): string {
    return this._key;
  }

  public get externalKey(): string {
    return this._externalKey;
  }

  public set externalKey(externalKey: string) {
    this._externalKey = externalKey;
    super.setPropUpdated("externalKey");
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public set isActive(isActive: boolean) {
    this._isActive = isActive;
    super.setPropUpdated("isActive");
  }

  public get status(): IntegrationStatus {
    return this._status;
  }

  public set status(status: IntegrationStatus) {
    this._status = status;
    super.setPropUpdated("status");
  }
}

export default Integration;
