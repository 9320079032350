import config from "../client/env";

import { ABIBarDefaults, BOBarDefaults } from "./BarDefaults";
import BarDefaultsInjector from "./BarDefaultsInjector";

const barDefaultsInjector = new BarDefaultsInjector({});

if (config.defaults) {
  switch (config.defaults.name) {
    case "bo":
      barDefaultsInjector.customerFields = BOBarDefaults.customerFields;
      barDefaultsInjector.orderConfirmationMessage = BOBarDefaults.orderConfirmationMessage;

      break;
    case "abi":
      barDefaultsInjector.customerFields = ABIBarDefaults.customerFields;
      barDefaultsInjector.orderConfirmationMessage = ABIBarDefaults.orderConfirmationMessage;

      break;
  }
}

export { barDefaultsInjector };
