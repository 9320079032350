import React, { PureComponent } from "react";
import { Redirect, withRouter } from "react-router-dom";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button, IconButton, Typography } from "@mui/material";
import CropFreeIcon from "@mui/icons-material/CropFree";
import WarningIcon from "@mui/icons-material/Warning";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import RefreshIcon from "@mui/icons-material/Refresh";
import withStyles from "@mui/styles/withStyles";

import { orderService } from "tap-io/client/services";
import withPageQuery from "tap-io/client/components/common/withPageQuery";
import { deviceStorage } from "tap-io/storage";
import Version from "tap-io/client/components/common/Version";
import { utilsHelper } from "tap-io/helpers";
import { AndroidWrapper } from "tap-io/client/native/AndroidWrapper";
import { IOSWrapper } from "tap-io/client/native/IOSWrapper";

import withPinAuthentication from "../components/auth/withPinAuthentication";
import LoadingScanner from "../components/scanner/LoadingScanner";
import ScanOrderDrawer from "../components/scanner/ScanOrderDrawer";
import OrderWithActionsDrawer from "../components/order/OrderWithActionsDrawer";
import ScannerMessage from "../components/scanner/ScannerMessage";
import ScannerLogDrawer from "../components/scanner/ScannerLogDrawer";
import EnterOrderDrawer from "../components/scanner/EnterOrderDrawer";
import { getCreateOrderUrl } from "../constants/routes";

const styles = (theme) => ({
  content: {
    //minHeight: "100vh",
    padding: "0 20px"
  },
  smallSpacing: {
    height: 10
  },
  mediumSpacing: {
    height: 20
  },
  largeSpacing: {
    height: 40
  },
  scannerName: {
    fontSize: 16,
    fontWeight: "bold"
  }
});

class ScanOrderPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isScanOrderDrawerOpen: false,
      isEnterOrderDrawerOpen: false,
      isScannerLogDrawerOpen: false,
      isOrderWithActionsDrawerOpen: false,
      order: null,
      redirectUrl: null,
      orderIdQueue: []
    };
  }

  componentDidMount() {
    const { scanner, queryParams } = this.props;

    const orderIdQueue = [];

    const lastClaimedOrderId = scanner?.lastClaimedOrderId || null;
    if (lastClaimedOrderId) {
      orderIdQueue.push(lastClaimedOrderId);
    }

    if (queryParams?.orderId) {
      orderIdQueue.push(queryParams?.orderId);
    }

    this.setState({ orderIdQueue });
  }

  componentDidUpdate(prevProps, prevState) {
    const { orderIdQueue, order } = this.state;

    if (orderIdQueue.length > 0 && !this.isRefreshingOrder && !order) {
      const queuedOrderId = orderIdQueue.length > 0 ? orderIdQueue[0] : null;
      const newOrderIdQueue = orderIdQueue.slice(1);

      this.refreshOrder(queuedOrderId);
      this.setState({ orderIdQueue: newOrderIdQueue });
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeOrder) {
      this.unsubscribeOrder();
    }
  }

  addOrderIdToQueue = (orderId) => {
    const { orderIdQueue } = this.state;

    if (orderIdQueue.indexOf(orderId) < 0) {
      this.setState({ orderIdQueue: [...orderIdQueue, orderId] });
    }
  };

  handleScanOrder = async () => {
    const { bar } = this.props;

    this.setState({ isScanOrderDrawerOpen: true });

    // TO FIX
    await deviceStorage.setDeviceSetting(bar.id, "mode", "scan");
  };

  handleOrderScanned = (barLocator, orderId) => {
    if (barLocator !== this.props.barLocator) {
      return toast.error("error.bar-locator-does-not-match");
    }

    this.addOrderIdToQueue(orderId);
    this.setState({ isScanOrderDrawerOpen: false });
  };

  handleScanOrderDrawerClose = () => {
    this.setState({ isScanOrderDrawerOpen: false });
  };

  handleEnterOrder = () => {
    this.setState({ isEnterOrderDrawerOpen: true });
  };

  handleOrderEntered = (orderId) => {
    this.addOrderIdToQueue(orderId);
    this.setState({ isEnterOrderDrawerOpen: false });
  };

  handleEnterOrderDrawerClose = () => {
    this.setState({ isEnterOrderDrawerOpen: false });
  };

  refreshOrder = (orderId) => {
    if (this.isRefreshingOrder) {
      return;
    }

    this.isRefreshingOrder = true;

    const { bar } = this.props;

    if (this.unsubscribeOrder) {
      this.unsubscribeOrder();
      this.unsubscribeOrder = undefined;
    }

    this.setState(
      {
        isOrderWithActionsDrawerOpen: !!orderId
      },
      () => {
        if (bar && orderId) {
          this.unsubscribeOrder = orderService.onById(bar, orderId, (order) => {
            this.setState(
              {
                order
              },
              () => (this.isRefreshingOrder = false)
            );
          });
        } else {
          this.setState(
            { order: null },
            () => (this.isRefreshingOrder = false)
          );
        }
      }
    );
  };

  handleCloseOrder = () => {
    this.refreshOrder();
  };

  handleCreateOrder = async () => {
    const { bar, scanner, barLocator } = this.props;

    this.setState({ redirectUrl: getCreateOrderUrl(barLocator, scanner.id) });

    // TO FIX
    await deviceStorage.setDeviceSetting(bar.id, "mode", "create");
  };

  handleCloseOrderAndContinue = async () => {
    const { bar } = this.props;
    const { orderIdQueue } = this.state;

    this.refreshOrder();

    if (orderIdQueue.length > 0) {
      return;
    }

    setTimeout(async () => {
      // TO FIX
      const mode =
        (await deviceStorage.getDeviceSetting(bar.id, "mode")) || "scan";

      switch (mode) {
        case "create":
          await this.handleCreateOrder();
          break;
        default:
          await this.handleScanOrder();
          break;
      }
    }, 50);
  };

  handleShowScannerLog = () => {
    this.setState({ isScannerLogDrawerOpen: true });
  };

  handleScannerLogDrawerClose = () => {
    this.setState({ isScannerLogDrawerOpen: false });
  };

  handleScannerLogEntryClick = (scannerLogEntry) => {
    this.setState({ isScannerLogDrawerOpen: false });

    this.addOrderIdToQueue(scannerLogEntry.orderId);
  };

  reloadApp = async () => {
    const { t } = this.props;

    const wrapper = AndroidWrapper.get() || IOSWrapper.get();

    toast.info(t("label.reloading-app"), { autoClose: false });
    if (wrapper) {
      await wrapper.reloadApp();
    } else {
      utilsHelper.reloadPage();
    }
  };

  render() {
    const { classes, t, barLocator, bar, scanner, base, getPinHash } =
      this.props;
    const {
      isScanOrderDrawerOpen,
      isEnterOrderDrawerOpen,
      isScannerLogDrawerOpen,
      isOrderWithActionsDrawerOpen,
      order,
      redirectUrl
    } = this.state;

    if (redirectUrl) {
      return <Redirect to={redirectUrl} />;
    }

    if (!base || !base.serviceOptions.deliveryMethods.isPickupActive) {
      return (
        <ScannerMessage
          Icon={WarningIcon}
          title={t("scanner.base-does-not-use-scanners")}
          description={t("scanner.base-does-not-use-scanners")}
        />
      );
    }

    return (
      <div className={classes.content}>
        {scanner ? (
          <div>
            <ScanOrderDrawer
              isOpen={isScanOrderDrawerOpen}
              onScan={this.handleOrderScanned}
              onClose={this.handleScanOrderDrawerClose}
            />
            <EnterOrderDrawer
              isOpen={isEnterOrderDrawerOpen}
              onEnter={this.handleOrderEntered}
              onClose={this.handleEnterOrderDrawerClose}
              scanner={scanner}
              getPinHash={getPinHash}
            />
            <OrderWithActionsDrawer
              isOpen={isOrderWithActionsDrawerOpen}
              onClose={this.handleCloseOrder}
              onCloseAndContinue={this.handleCloseOrderAndContinue}
              barLocator={barLocator}
              bar={bar}
              scanner={scanner}
              base={base}
              order={order}
              getPinHash={getPinHash}
            />
            <ScannerLogDrawer
              isOpen={isScannerLogDrawerOpen}
              onClose={this.handleScannerLogDrawerClose}
              scanner={scanner}
              onLogEntryClick={this.handleScannerLogEntryClick}
              getPinHash={getPinHash}
            />
            <div className={classes.smallSpacing} />
            <Typography variant="subtitle2">
              {t("scanner.scan-orders-below")}
            </Typography>
            <div className={classes.smallSpacing} />
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              startIcon={<CropFreeIcon />}
              onClick={this.handleScanOrder}
            >
              {t("scanner.scan-orders")}
            </Button>
            <div className={classes.smallSpacing} />
            <Button fullWidth size="small" onClick={this.handleEnterOrder}>
              {t("scanner.enter-order-manually")}
            </Button>
            <div className={classes.largeSpacing} />
            {(AndroidWrapper.get() || IOSWrapper.get()) && (
              /*scanner.allowCreatingOrders &&*/ <>
                <Typography variant="subtitle2">
                  {t("scanner.create-orders-below")}
                </Typography>
                <div className={classes.smallSpacing} />
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  startIcon={<AddToPhotosIcon />}
                  onClick={this.handleCreateOrder}
                >
                  {t("scanner.create-order")}
                </Button>
                <div className={classes.largeSpacing} />
              </>
            )}
            <Typography variant="subtitle2">
              {t("scanner.previously-scanned-orders")}
            </Typography>
            <div className={classes.smallSpacing} />
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              startIcon={<FormatListBulletedIcon />}
              onClick={this.handleShowScannerLog}
            >
              {t("scanner.previously-scanned-orders")}
            </Button>
            <div className={classes.largeSpacing} />
            <Typography variant="caption" className={classes.scannerName}>
              {scanner.name}
            </Typography>
            <div>
              <Version
                nativeWrapperVersion={
                  AndroidWrapper.get()?.getVersion() ||
                  IOSWrapper.get()?.getVersion()
                }
              />
              <IconButton edge="end" onClick={this.reloadApp} size="small">
                <RefreshIcon />
              </IconButton>
            </div>
          </div>
        ) : (
          <LoadingScanner />
        )}
      </div>
    );
  }
}

export default withPageQuery(
  withRouter(
    withPinAuthentication()(
      withStyles(styles)(withTranslation("common")(ScanOrderPage))
    )
  )
);
