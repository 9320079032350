import Base from "../models/base/Base";
import DeliveryMethod from "../models/delivery/DeliveryMethod";
import ServiceOptionName from "../models/service/ServiceOptionName";

export default class BaseHelper {
  constructor() {
    this.onlyBasesUsingScannersFilter =
      this.onlyBasesUsingScannersFilter.bind(this);
  }

  onlyBasesWithServiceOptionFilter(
    serviceOptionName: ServiceOptionName
  ): (base: Base) => boolean {
    return (base) => base.serviceOptions.has(serviceOptionName);
  }

  onlyBasesWithDeliveryMethodFilter(
    deliveryMethod: DeliveryMethod
  ): (base: Base) => boolean {
    return (base) => base.serviceOptions.deliveryMethods.isDeliveryMethodActive(deliveryMethod)
  }

  onlyBasesUsingScannersFilter(base: Base): boolean {
    return base.canUseScanner;
  }
}
