class BalanceLogEntry {
  private readonly _id: string;
  private readonly _amount: number;
  private readonly _previousCreditCount: number;
  private readonly _newCreditCount: number;
  private readonly _timestamp: Date;

  private readonly _type: string | undefined;
  private readonly _totalAmount: number | undefined;
  private readonly _totalPrice: number | undefined;

  constructor(id: string, data: any) {
    this._id = id;

    if (!data) {
      throw new Error("data is not defined");
    }

    this._amount = data.amount;
    this._previousCreditCount = data.previousCreditCount;
    this._newCreditCount = data.newCreditCount;
    this._timestamp = data.timestamp;

    if (data.data) {
      this._type = data.data.type;
      this._totalAmount = data.data.totalAmount;
      this._totalPrice = data.data.totalPrice;
    }
  }

  public get id(): string {
    return this._id;
  }

  public get amount(): number {
    return this._amount;
  }

  public get previousCreditCount(): number {
    return this._previousCreditCount;
  }

  public get newCreditCount(): number {
    return this._newCreditCount;
  }

  public get timestamp(): Date {
    return this._timestamp;
  }

  public get type(): string | undefined {
    return this._type;
  }

  public get totalAmount(): number | undefined {
    return this._totalAmount;
  }

  public get totalPrice(): number | undefined {
    return this._totalPrice;
  }
}

export default BalanceLogEntry;