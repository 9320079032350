import React from "react";

import { CircularProgress, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "20px 0"
  },
  spacing: {
    display: "block",
    height: 10
  }
});

const OrderLoading = ({ classes, label }) => (
  <div className={classes.container}>
    <CircularProgress />
    <div className={classes.spacing} />
    <Typography variant="h6">{label}</Typography>
  </div>
);

export default withStyles(styles)(OrderLoading);
