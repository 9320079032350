export default class AuthHelper {
  async generateAuthorizedLink(user: any, link: string, params?: any) {
    if (!user || !user.getIdToken) {
      throw new Error("no-authorized-user-found");
    }

    const token = await user.getIdToken();
    if (!token) {
      throw new Error("no-valid-token-found-for-this-user");
    }

    let url = `${link}?token=${token}`;

    if (params) {
      for (const key in params) {
        url += `&${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      }
    }

    return url;
  }
}
