export abstract class NativeWrapper<T> {
  protected readonly native: T;

  protected readonly promises: Record<
    string,
    { resolve: (value: any) => void; reject: (reason?: any) => void }
  > = {};
  protected readonly subscriptions: Record<string, (...args: any[]) => void> =
    {};

  protected constructor(protected readonly jsInterfaceName: string) {
    this.native = (window as any)[jsInterfaceName];

    (window as any).resolveNativePromise = this.resolvePromise.bind(this);
    (window as any).rejectNativePromise = this.rejectPromise.bind(this);
    (window as any).runNativeCallback = this.runCallback.bind(this);
    (window as any).cancelNativeCallback = this.cancelCallback.bind(this);
  }

  protected generateUniqueId() {
    return `${new Date().getTime()}_${Math.random()}`;
  }

  protected nativePromise<T>(call: (promiseId: string) => void) {
    return new Promise<T>((resolve, reject) => {
      const promiseId = this.generateUniqueId();
      this.promises[promiseId] = { resolve, reject };
      call(promiseId);
    });
  }

  protected async nativeSubscription(
    subscribe: (promiseId: string) => Promise<void>,
    unsubscribe: (promiseId: string) => Promise<void>,
    callback: (...args: any[]) => void | Promise<void>
  ) {
    const subscriptionId = this.generateUniqueId();
    this.subscriptions[subscriptionId] = callback;
    await subscribe(subscriptionId);

    return async () => {
      await unsubscribe(subscriptionId);
      delete this.subscriptions[subscriptionId];
    };
  }

  protected resolvePromise(promiseId: string, data: unknown) {
    const promise = this.promises[promiseId];

    if (promise) {
      promise.resolve(data);
    }

    delete this.promises[promiseId];
  }

  protected rejectPromise(promiseId: string, error: unknown) {
    const promise = this.promises[promiseId];

    if (promise) {
      promise.reject(error);
    }

    delete this.promises[promiseId];
  }

  protected runCallback(subscriptionId: string, data: unknown) {
    const callback = this.subscriptions[subscriptionId];

    if (callback) {
      callback(data);
    }
  }

  protected cancelCallback(subscriptionId: string) {
    delete this.subscriptions[subscriptionId];
  }
}
