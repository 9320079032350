import Voucher from "../models/voucher/Voucher";
import CurrencyVoucher from "../models/voucher/CurrencyVoucher";
import ProductVoucher from "../models/voucher/ProductVoucher";
import { utilsHelper, voucherHelper } from "./index";
import ProductVoucherValue from "../models/voucher/ProductVoucherValue";

export default class VoucherHelper {
  constructor() {
  }

  private voucherRedeemOrderCompareFunction(voucherA: Voucher, voucherB: Voucher): number {
    const voucherAIsProductVoucher: boolean = voucherHelper.isProductVoucher(voucherA);
    const voucherBIsProductVoucher: boolean = voucherHelper.isProductVoucher(voucherB);

    const voucherAValue: number = voucherAIsProductVoucher ?
      voucherA.remainingValue.length :
      voucherA.remainingValue;
    const voucherBValue: number = voucherBIsProductVoucher ?
      voucherB.remainingValue.length :
      voucherB.remainingValue;

    // Sort product vouchers first (least remaining products first)
    // If both are currency vouchers, sort least remaining value first
    return voucherAIsProductVoucher
      ? voucherBIsProductVoucher
        ? voucherAValue - voucherBValue
        : -1
      : voucherBIsProductVoucher
        ? 1
        : voucherAValue - voucherBValue;
  };

  public getVoucherUrl(barUrl: string, voucherCode: string): string {
    return `${barUrl}?voucherCode=${voucherCode}`;
  }

  public encodeVoucherCode(voucherId: string, voucherPin: string): string {
    if (!voucherId) {
      throw new Error("error.voucher-id-is-not-defined");
    }
    if (!voucherPin) {
      throw new Error("error.voucher-pin-is-not-defined");
    }

    return `${voucherId}-${voucherPin}`;
  }

  public decodeVoucherCode(voucherCode: string): { voucherId: string, voucherPin: string } {
    if (!voucherCode) {
      throw new Error("error.voucher-code-is-not-defined");
    }

    const matches = voucherCode.match(/(.+)\-(\d+)/);
    if (!matches || matches.length !== 3) {
      throw new Error("error.voucher-code-has-an-invalid-format");
    }

    return {
      voucherId: matches[1],
      voucherPin: matches[2]
    };
  }

  public isCurrencyVoucher(voucher: Voucher): boolean {
    return voucher instanceof CurrencyVoucher;
  }

  public isProductVoucher(voucher: Voucher): boolean {
    return voucher instanceof ProductVoucher;
  }

  public sortVouchersInRedeemOrder(vouchers: Array<Voucher>): Array<Voucher> {
    return vouchers.sort(this.voucherRedeemOrderCompareFunction);
  }

  public findProductVoucherValue(value: Array<ProductVoucherValue>, productId: string, discountPercentage?: number): ProductVoucherValue | null {
    const foundEntries = value.filter(entry =>
      entry &&
      entry.getProductId() === productId &&
      (discountPercentage === undefined || entry.discountPercentage === discountPercentage)
    );

    switch (foundEntries.length) {
      case 0:
        return null;
        break;
      case 1:
        return foundEntries[0];
        break;
      default:
        throw new Error("error.found-duplicate-entry-in-value");
        break;
    }
  }

  public areProductVoucherValuesEqual(valueA: Array<ProductVoucherValue>, valueB: Array<ProductVoucherValue>) {
    return utilsHelper.areArraysEqual(valueA, valueB, (valA, valB) => valA.isEqualTo(valB));
  }
}
