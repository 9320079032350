import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import Moment from "moment";
import ReactMoment from "react-moment";

import { List, ListItem, ListItemText, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import ShowCompleteOrderButton from "./ShowCompleteOrderButton";
import OrderDeliveryLabel from "./OrderDeliveryLabel";
import OrderPayment from "./OrderPayment";
import OrderVouchers from "./OrderVouchers";
import OrderItems from "./OrderItems";
import OrderFees from "./OrderFees";
import OrderFulfilmentLabel from "./OrderFulfilmentLabel";
import OrderPriorityLabel from "./OrderPriorityLabel";
import OrderNote from "./OrderNote";
import OrderTip from "./OrderTip";
import OrderConfirmationCode from "./OrderConfirmationCode";
import OrderName from "./OrderName";

const styles = (theme) => ({
  timestamp: {
    fontSize: 14,
    textAlign: "center",
    textTransform: "uppercase",
    color: "#aaa"
  },
  date: {
    display: "block"
  },
  time: {
    display: "block",
    fontWeight: "bold"
  },
  deliveryAndFulfilmentLabels: {
    display: "block",
    textAlign: "center",
    marginTop: 8
  },
  orderLabel: {
    margin: theme.spacing(0.5)
  },
  name: {
    justifyContent: "center",
    marginLeft: theme.spacing(4),
    fontSize: 22,
    textAlign: "center",
    textTransform: "uppercase",
    color: "#777"
  },
  total: {
    fontSize: 34,
    fontWeight: "bold",
    textAlign: "center",
    color: "#333"
  },
  currency: {
    marginLeft: 5,
    verticalAlign: "middle",
    fontSize: 22
  },
  multipleMenusWarning: {
    borderRadius: 4,
    border: `2px solid ${theme.palette.primary.main}`
  },
  multipleMenusWarningDescription: {
    color: theme.palette.warning.main
  }
});

class Order extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, base, order, showOrderContent } = this.props;

    const isToday = Moment(order.timestamp).isSame(new Date(), "day");

    return (
      <div>
        <List>
          <OrderPayment bar={bar} order={order} />
          <OrderVouchers order={order} />
          <ListItem>
            <ListItemText
              primary={
                <span className={classes.timestamp}>
                  {isToday ? (
                    <ReactMoment locale={bar.getOrderLocale()} fromNow>
                      {order.sequenceTimestamp}
                    </ReactMoment>
                  ) : (
                    <div>
                      <ReactMoment
                        locale={bar.getOrderLocale()}
                        format="DD/MM/YYYY"
                        className={classes.date}
                      >
                        {order.timestamp}
                      </ReactMoment>
                      <ReactMoment
                        locale={bar.getOrderLocale()}
                        format="LT"
                        className={classes.time}
                      >
                        {order.timestamp}
                      </ReactMoment>
                    </div>
                  )}
                </span>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <OrderName
                  order={order}
                  showInfoButton={true}
                  className={classes.name}
                />
              }
              secondary={
                <div className={classes.deliveryAndFulfilmentLabels}>
                  <div className={classes.orderLabel}>
                    <OrderDeliveryLabel order={order} base={base} />
                  </div>
                  <div className={classes.orderLabel}>
                    <OrderFulfilmentLabel order={order} base={base} />
                  </div>
                  <div className={classes.orderLabel}>
                    <OrderPriorityLabel order={order} base={base} />
                  </div>
                </div>
              }
            />
          </ListItem>
          {showOrderContent && (
            <div>
              <ListItem>
                <ListItemText
                  primary={
                    <div className={classes.total}>
                      {order.getFormattedTotal(
                        order.containsItemsFromMultipleMenus()
                          ? base
                          : undefined
                      )}
                      <span className={classes.currency}>{order.currency}</span>
                    </div>
                  }
                />
              </ListItem>
              <OrderNote order={order} />
              <OrderItems order={order} base={base} />
              {(!order.containsItemsFromMultipleMenus() || !base) && (
                <OrderFees order={order} />
              )}
              <OrderTip order={order} />
              <OrderConfirmationCode order={order} />
              {base && order.containsItemsFromMultipleMenus() && (
                <ListItem className={classes.multipleMenusWarning}>
                  <ShowCompleteOrderButton
                    bar={bar}
                    order={order}
                    size="small"
                  />
                  <Typography
                    variant="caption"
                    className={classes.multipleMenusWarningDescription}
                  >
                    {t("order.part-of-order-prepared-at-other-location")}
                  </Typography>
                </ListItem>
              )}
            </div>
          )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(Order));
