import { serviceHelper } from "../../helpers";
import { depositParser } from "../../parsers";
import DepositService from "../../services/DepositService";
import Deposit from "../../models/deposit/Deposit";

export default class ClientDepositService extends DepositService {
  public onAllDeposits(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.getDepositsRef(barId).onSnapshot(
      (snap: any) => {
        const deposits = snap.docs.map((doc: any) =>
          depositParser.parseDocToDeposit(barId, doc)
        );
        cb(deposits);
      },
      (error: any) => {
        cb([]);
      }
    );
  }

  public onActiveDeposits(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.getDepositsRef(barId)
      .where("isActive", "==", true)
      .onSnapshot(
        (snap: any) => {
          const deposits = snap.docs.map((doc: any) =>
            depositParser.parseDocToDeposit(barId, doc)
          );
          cb(deposits);
        },
        (error: any) => {
          cb([]);
        }
      );
  }

  public createDeposit(barId: string, data: any): Deposit {
    return new Deposit(barId, undefined, data);
  }

  public async addDeposit(deposit: Deposit): Promise<Deposit> {
    if (deposit.id) {
      throw new Error("error.id-already-defined");
    }

    const ref = await this.getDepositsRef(deposit.barId).add({
      ...deposit.allPropsToJSON(),
      timestamp: serviceHelper.serverTimestamp()
    });
    deposit.id = ref.id;
    return deposit;
  }
}
