import BalanceService from "../../services/BalanceService";
import Bar from "../../models/bar/Bar";
import { serviceHelper } from "../../helpers";

export default class ClientBalanceService extends BalanceService {
  constructor(fbStore) {
    super(fbStore);
  }

  redeemCoupon(
    userId: string,
    bar: Bar,
    couponCode: string,
    onProgress?: Function
  ) {
    return serviceHelper.createTask(
      userId,
      "coupon",
      "redeem",
      {
        barId: bar.id,
        code: couponCode
      },
      onProgress
    );
  }

  public updateDoNotChargeCredits(
    userId: string,
    bar: Bar,
    doNotChargeCredits: boolean,
    onProgress?: Function
  ) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const data: any = {
      barId: bar.id,
      doNotChargeCredits
    };

    return serviceHelper.createTask(
      userId,
      "balance",
      "updateDoNotChargeCredits",
      data,
      onProgress
    );
  }

  public updateAllowCreditDebt(
    userId: string,
    bar: Bar,
    allowCreditDebt: boolean,
    onProgress?: Function
  ) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }

    const data: any = {
      barId: bar.id,
      allowCreditDebt
    };

    return serviceHelper.createTask(
      userId,
      "balance",
      "updateAllowCreditDebt",
      data,
      onProgress
    );
  }
}
