import {
  validate,
  isTypedObject,
  isString,
  isEnum,
  isNumber,
  isTypedArray,
  isClass,
  isBoolean
} from "../helpers/ValidationHelper";
import Fee from "../models/fee/Fee";
import FeeType from "../models/fee/FeeType";
import FeeCondition from "../models/fee/FeeCondition";
import FeeConditionType from "../models/fee/FeeConditionType";
import FeeZoneCondition from "../models/fee/FeeZoneCondition";
import FeeServiceOptionCondition from "../models/fee/FeeServiceOptionCondition";
import FeeConditionOption from "../models/fee/FeeConditionOption";
import LayoutPath from "../models/zone/LayoutPath";
import ServiceOptionName from "../models/service/ServiceOptionName";

export default class FeeParser {
  public parseDocToFee(barId: string, doc): Fee {
    const data = doc.data();

    // TO FIX: isClass(FeeCondition)
    /*const props = validate(
      isTypedObject({
        type: isEnum(FeeType),
        conditions: isTypedArray(isClass(FeeCondition)),
        isActive: isBoolean,
        name: isString,
        value: isNumber
      })
    )(data);*/

    const props = {
      type: validate(isEnum(FeeType))(data.type),
      conditions: data.conditions.map((condition: any) =>
        this.parseDataToFeeCondition(condition)
      ),
      isActive: validate(isBoolean)(data.isActive),
      name: validate(isString)(data.name),
      value: validate(isNumber)(data.value),
      externalId: data.externalId
        ? validate(isString)(data.externalId)
        : undefined
    };

    return new Fee(barId, doc.id, props);
  }

  private parseDataToFeeCondition(data: any): FeeCondition {
    if (!data) {
      throw new Error("error.data-is-not-defined");
    }

    switch (data.type) {
      case FeeConditionType.SERVICE_OPTION:
        return new FeeServiceOptionCondition(
          validate(
            isTypedObject({
              serviceOptionNames: isTypedArray(isEnum(ServiceOptionName))
            })
          )(data)
        );
        break;
      case FeeConditionType.ZONE:
        return new FeeZoneCondition(
          validate(
            isTypedObject({
              option: isEnum(FeeConditionOption),
              zoneCode: isString,
              layoutPaths: isTypedArray(isClass(LayoutPath))
            })
          )(data)
        );
        break;
      default:
        throw new Error("error.data-type-is-not-valid");
        break;
    }
  }
}
