import Invoice from "../models/invoice/Invoice";

export default class InvoiceParser {
  public parseDocToInvoice(invoiceId: string, doc): Invoice {
    const data = doc.data();

    return new Invoice(
      invoiceId,
      {
        ...data,
        timestamp: data.timestamp.toDate()
      }
    );
  }
}
