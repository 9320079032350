import DefaultsInjector from "./DefaultsInjector";
import Bar from "../models/bar/Bar";
import CustomerField from "../models/bar/CustomerField";
import { utilsHelper } from "../helpers";

type Props = {
  customerFields?: CustomerField[];
  orderConfirmationMessage?: string;
};

export default class BarDefaultsInjector extends DefaultsInjector<Bar> {
  private _customerFields: CustomerField[];
  private _orderConfirmationMessage: string;

  constructor(props: Props) {
    super();

    const { customerFields, orderConfirmationMessage } = props;

    this._customerFields = customerFields || [];
    this._orderConfirmationMessage = orderConfirmationMessage || "";
  }

  private completeCustomerFieldVariables(bar: Bar, customerField: CustomerField) {
    for (let locale in customerField.localisedName.translations) {
      customerField.localisedName.translations[locale] =
        utilsHelper.completeText(
          customerField.localisedName.translations[locale],
          "barName",
          bar.name
        );
    }
  }

  public get customerFields() {
    return this._customerFields;
  }

  public set customerFields(customerFields: CustomerField[]) {
    this._customerFields = customerFields;
  }

  public get orderConfirmationMessage() {
    return this._orderConfirmationMessage;
  }

  public set orderConfirmationMessage(orderConfirmationMessage: string) {
    this._orderConfirmationMessage = orderConfirmationMessage;
  }

  injectDefaults(bar: Bar): Bar {
    // Customer Fields

    // Helper map
    const barDefaultCustomerFieldIds: string[] = [];

    // Default customer fields can be overridden IF the ID matches
    bar.params.defaultCustomerFields = this.customerFields.map(
      (defaultCustomerField) => {
        // Deep copy customerField because it is potentially altered (e.g. locale)
        const barDefaultCustomerField = defaultCustomerField.clone();

        barDefaultCustomerFieldIds.push(barDefaultCustomerField.id);

        // Fill in variables in field
        this.completeCustomerFieldVariables(bar, barDefaultCustomerField);

        const customerField = utilsHelper.findInArrayById(
          bar.params.customCustomerFields,
          barDefaultCustomerField.id
        );

        if (customerField) {
          if (!customerField.name) {
            customerField.localisedName = barDefaultCustomerField.localisedName;
          }
          if (!customerField.description) {
            customerField.localisedDescription =
              barDefaultCustomerField.localisedDescription;
          }

          return customerField;
        }

        return barDefaultCustomerField;
      }
    );

    // Remove any customField that overrides default field
    bar.params.customCustomerFields = bar.params.customCustomerFields.filter(
      (customerField) => {
        if (barDefaultCustomerFieldIds.indexOf(customerField.id) < 0) {
          // Fill in variables in field
          this.completeCustomerFieldVariables(bar, customerField);
          return true;
        }

        return false;
      }
    );


    // Order Confirmation Message
    if (this.orderConfirmationMessage && !bar.params.orderConfirmationMessage) {
      bar.params.orderConfirmationMessage = this.orderConfirmationMessage;
    }

    return bar;
  }
}
