import Fee from "../models/fee/Fee";
import { feeParser } from "../parsers";
import { utilsHelper } from "../helpers";

export default class FeeService {
  protected readonly fbStore;

  constructor(fbStore: any) {
    this.fbStore = fbStore;
  }

  protected getFeesRef(barId: string) {
    return this.fbStore.collection("bars").doc(barId).collection("fees");
  }

  protected getFeeRef(barId: string, feeId: string) {
    return this.getFeesRef(barId).doc(feeId);
  }

  public async getFeeById(barId: string, feeId: string): Promise<Fee> {
    if (!feeId) {
      throw new Error("error.fee-id-is-not-defined");
    }

    const doc = await this.getFeeRef(barId, feeId).get();

    return feeParser.parseDocToFee(barId, doc);
  }

  public async getActiveFees(barId: string): Promise<Fee[]> {
    const docs = await this.getFeesRef(barId)
      .where("isActive", "==", true)
      .get();

    const fees: Array<Fee> = [];
    docs.forEach((doc) => fees.push(feeParser.parseDocToFee(barId, doc)));
    return fees;
  }

  public async updateFee(fee: Fee) {
    if (!fee.id) {
      throw new Error("error.id-is-not-defined");
    }

    const updatedProps = fee.updatedPropsToJSON();
    if (!utilsHelper.isObjectEmpty(updatedProps)) {
      const ref = this.getFeeRef(fee.barId, fee.id);

      return ref.update(fee.updatedPropsToJSON());
    }
  }

  public removeFee(fee: Fee) {
    if (!fee.id) {
      throw new Error("error.id-is-not-defined");
    }

    return this.getFeeRef(fee.barId, fee.id).delete();
  }
}
