import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";

import { withTranslation } from "react-i18next";

import { CircularProgress, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import withPageQuery from "tap-io/client/components/common/withPageQuery";
import { orderService } from "tap-io/client/services";

import withPinAuthentication from "../components/auth/withPinAuthentication";
import PayOrderDialog from "../components/payment/PayOrderDialog";
import { getScanOrderUrl } from "../constants/routes";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(4, 1)
  },
  spinner: {
    margin: theme.spacing(4)
  }
});

class PayOrderPage extends PureComponent {
  constructor(props) {
    super(props);

    const { match } = props;

    let orderId = null;
    if (match && match.params) {
      // Make sure params are properly decoded
      if (match.params.orderId) {
        orderId = decodeURIComponent(match.params.orderId);
      }
    }

    this.state = {
      isPayOrderDialogOpen: true,
      orderId
    };
  }

  componentDidMount() {
    const { bar } = this.props;
    const { orderId } = this.state;

    if (orderId) {
      this.unsubscribeOrder = orderService.onById(bar, orderId, (order) => {
        if (order.canBePaid()) {
          this.setState({ order });
        } else if (
          order.isFree() ||
          order.isPaid() ||
          order.isStatusCancelled() ||
          order.isStatusError()
        ) {
          // Wait a bit and only close PayOrderDialog if it is still open
          if (this.closePayOrderDialogTimeout) {
            clearTimeout(this.closePayOrderDialogTimeout);
          }

          this.closePayOrderDialogTimeout = setTimeout(() => {
            if (this.state.isPayOrderDialogOpen) {
              this.setState({ order, isPayOrderDialogOpen: false });
            }
          }, 500);
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeOrder) {
      this.unsubscribeOrder();
    }
  }

  handlePayOrderDialogClose = () => {
    this.setState({ isPayOrderDialogOpen: false });
  };

  getScanCreatedOrderUrl = () => {
    const { barLocator, scanner } = this.props;
    const { order } = this.state;

    return barLocator && scanner && order
      ? `${getScanOrderUrl(barLocator, scanner.id)}?orderId=${order.id}`
      : null;
  };

  render() {
    const { classes, t, barLocator, scanner, bar } = this.props;
    const { isPayOrderDialogOpen, order } = this.state;

    if (!isPayOrderDialogOpen) {
      const scanCreatedOrderUrl = this.getScanCreatedOrderUrl();

      if (scanCreatedOrderUrl) {
        return <Redirect to={scanCreatedOrderUrl} />;
      }
    }

    return order ? (
      <PayOrderDialog
        isOpen={isPayOrderDialogOpen}
        onClose={this.handlePayOrderDialogClose}
        barLocator={barLocator}
        bar={bar}
        scanner={scanner}
        order={order}
      />
    ) : (
      <div className={classes.content}>
        <CircularProgress className={classes.spinner} />
        <Typography>{t("scanner.loading-order")}</Typography>
      </div>
    );
  }
}

export default withPageQuery(
  withPinAuthentication()(
    withStyles(styles)(withTranslation("common")(PayOrderPage))
  )
);
