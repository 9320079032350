import React, { PureComponent } from "react";

import AuthContext from "tap-io/client/components/auth/authContext";

import { authService } from "tap-io/client/services";

const withAuthentication = (Component) => {
  class WithAuthentication extends PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        isReady: false,
        isError: false
      };
    }

    componentDidMount() {
      // Fallback
      const fallbackTimout = setTimeout(() => {
        this.setState({ isError: true });
      }, 5000);

      this.unsubscribeOnAuthStateChanged = authService.onAuthStateChanged(
        (user) => {
          clearTimeout(fallbackTimout);

          this.setState(() => ({
            isReady: true,
            user
          }));
        }
      );
    }

    componentWillUnmount() {
      if (this.unsubscribeOnAuthStateChanged) {
        this.unsubscribeOnAuthStateChanged();
      }
    }

    render() {
      const { isReady, user, isError } = this.state;

      const auth = {
        user
      };

      return (
        <AuthContext.Provider value={auth}>
          {isReady ? (
            <Component {...this.props} />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100vw",
                height: "100vh",
                textAlign: "center"
              }}
            >
              {isError ? (
                <div style={{ margin: 20 }}>
                  <div>Dit lijkt lang te duren 😕</div>
                  <br />
                  <div>
                    Controleer je internetverbinding, sluit je browser en
                    probeer opnieuw
                  </div>
                </div>
              ) : (
                <div>Laden...</div>
              )}
            </div>
          )}
        </AuthContext.Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
