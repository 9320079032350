import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";

import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import EuroIcon from "@mui/icons-material/Euro";

import { getPayOrderUrl } from "../../constants/routes";

const styles = (theme) => ({});

class OrderPayButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      doRedirect: false
    };
  }

  handlePayOrder = () => {
    this.setState({ doRedirect: true });
  };

  getPayOrderLink = () => {
    const { barLocator, scanner, order } = this.props;

    return barLocator && scanner && order
      ? `${getPayOrderUrl(barLocator, scanner.id, order.id)}`
      : null;
  };

  render() {
    const { classes, t, order } = this.props;
    const { doRedirect } = this.state;

    if (doRedirect) {
      const payOrderLink = this.getPayOrderLink();

      return <Redirect to={payOrderLink} />;
    }

    return (
      <Button
        variant="contained"
        size="large"
        color="primary"
        fullWidth
        startIcon={<EuroIcon />}
        onClick={this.handlePayOrder}
      >
        {t("order.pay-order")}
      </Button>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderPayButton));
