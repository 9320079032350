import LayoutNodeType from "./LayoutNodeType";
import LayoutLocation from "./LayoutLocation";
import LayoutPath from "./LayoutPath";
import { serviceOptionHelper, zoneHelper } from "../../helpers";
import LayoutNodeBases from "./LayoutNodeBases";
import ServiceOptions from "../service/ServiceOptions";
import ServiceOptionName from "../service/ServiceOptionName";

export default abstract class LayoutNode {
  protected readonly _type: LayoutNodeType;
  protected _code: string;
  private _name: string;
  protected _bases: LayoutNodeBases | null;
  protected _parent: LayoutNode | null = null;
  protected _children: LayoutNode[];

  constructor(
    type: LayoutNodeType,
    code: string,
    name: string,
    bases: LayoutNodeBases | null,
    children?: LayoutNode[]
  ) {
    this._type = type;
    this._code = code;
    this._name = name;
    this._bases = bases;
    this._children = children || [];

    this.children.forEach((child) => (child.parent = this));
  }

  public get type() {
    return this._type;
  }

  public abstract get code();

  public abstract set code(code: string);

  public get name() {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get bases(): LayoutNodeBases {
    return this._bases
      ? this._bases
      : this.parent
      ? this.parent.bases
      : new LayoutNodeBases();
  }

  public get customBases(): LayoutNodeBases | null {
    return this._bases;
  }

  public set customBases(bases: LayoutNodeBases | null) {
    this._bases = bases;
  }

  public get serviceOptions(): ServiceOptions {
    return this.bases.serviceOptions;
  }

  public get childServiceOptions(): ServiceOptions {
    const childServiceOptions = serviceOptionHelper.createServiceOptions();

    this.children.forEach((child) => {
      childServiceOptions.merge(child.allServiceOptions);
    });

    return childServiceOptions;
  }

  public get allServiceOptions(): ServiceOptions {
    const allServiceOptions = this.customBases
      ? this.customBases.serviceOptions.clone()
      : serviceOptionHelper.createServiceOptions();

    this.children.forEach((child) => {
      allServiceOptions.merge(child.allServiceOptions);
    });

    return allServiceOptions;
  }

  public getBaseIdsForServiceOption(
    serviceOptionName: ServiceOptionName
  ): string[] {
    return this.bases.getBaseIdsForServiceOption(serviceOptionName);
  }

  public getChildBaseIdsForServiceOption(
    serviceOptionName: ServiceOptionName
  ): string[] {
    const baseIds: string[] = [];

    this.children.forEach((child) =>
      child.getBaseIdsForServiceOption(serviceOptionName).forEach((baseId) => {
        if (baseIds.indexOf(baseId) < 0) {
          baseIds.push(baseId);
        }
      })
    );

    return baseIds;
  }

  public get baseIds(): string[] {
    return this.bases.getBaseIdsWithAtLeastOneServiceOption();
  }

  public get allBaseIds(): string[] {
    const baseIds = this.customBases ? this.customBases.getBaseIds() : [];

    this.children.forEach((child) =>
      child.allBaseIds.forEach((baseId) => {
        if (baseIds.indexOf(baseId) < 0) {
          baseIds.push(baseId);
        }
      })
    );

    return baseIds;
  }

  public get isAllowedToOrder(): boolean {
    return this.bases.hasAnyServiceOption;
  }

  public isAllowedToOrderForPath(path: LayoutPath) {
    const layoutNode = this.find(path);
    return layoutNode ? layoutNode.isAllowedToOrder : false;
  }

  public get root(): LayoutNode {
    return this.parent ? this.parent.root : this;
  }

  public get parent(): LayoutNode | null {
    return this._parent;
  }

  public set parent(parent: LayoutNode | null) {
    this._parent = parent;
  }

  public get children(): LayoutNode[] {
    return this._children;
  }

  public get height(): number {
    const childMaxDepths = this.children.map((child) => child.height);

    return childMaxDepths.length > 0 ? Math.max(...childMaxDepths) + 1 : 0;
  }

  public get depth(): number {
    return this.parent ? this.parent.depth + 1 : 0;
  }

  private isCodeValid(code: string, nodes: LayoutNode[]) {
    if (!code) {
      return false;
    }

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];

      const locations = node.getLayoutLocations();
      for (let i = 0; i < locations.length; i++) {
        if (code === locations[i].code) {
          return false;
        }
      }
    }

    return true;
  }

  protected isCodeValidForMe(code: string): boolean {
    return this.parent
      ? this.isCodeValid(
          code,
          this.parent.children.filter((child) => child !== this)
        )
      : true;
  }

  protected isCodeValidForChild(
    code: string,
    exceptForChild?: LayoutNode
  ): boolean {
    return this.isCodeValid(
      code,
      exceptForChild
        ? this.children.filter((child) => child !== exceptForChild)
        : this.children
    );
  }

  public get hasChildren(): boolean {
    return this.children.length > 0;
  }

  public getChildNames(): string[] {
    const names: string[] = [];

    for (let i = 0; i < this.children.length; i++) {
      const name = this.children[i].name;

      if (names.indexOf(name) < 0) {
        names.push(name);
      }
    }

    return names;
  }

  public addChild(childToAdd: LayoutNode) {
    if (childToAdd.code) {
      const locations = childToAdd.getLayoutLocations();
      for (let i = 0; i < locations.length; i++) {
        if (!this.isCodeValidForChild(locations[i].code)) {
          throw new Error("error.code-is-not-valid");
        }
      }
    } else {
      const childCodes: string[] = [];
      this.children.forEach((child: LayoutNode) =>
        child
          .getLayoutLocations()
          .forEach((location) => childCodes.push(location.code))
      );
      while (!this.isCodeValidForChild(childToAdd.code)) {
        childToAdd.code = zoneHelper.generateLayoutNodeCode(childCodes);
      }
    }

    childToAdd.parent = this;
    this._children.push(childToAdd);
  }

  public removeChild(childToRemove: LayoutNode) {
    this._children = this._children.filter((child) => child !== childToRemove);
  }

  public replaceChild(prevChild: LayoutNode, newChild: LayoutNode) {
    const locations = newChild.getLayoutLocations();
    for (let i = 0; i < locations.length; i++) {
      if (!this.isCodeValidForChild(locations[i].code, prevChild)) {
        throw new Error("error.code-is-not-valid");
      }
    }

    for (let i = 0; i < this.children.length; i++) {
      if (this.children[i] === prevChild) {
        newChild.parent = this;
        this.children[i].parent = null;
        this.children[i] = newChild;
      }
    }
  }

  public find(path: LayoutPath): LayoutNode | undefined {
    if (path.length === 0) {
      return this;
    }

    for (let i = 0; i < this.children.length; i++) {
      const child = this.children[i];

      if (path.beginsWith(child.code)) {
        return path.length === 1 ? child : child.find(path.slice(1));
      } else {
        const locations = child.getLayoutLocations();

        for (let j = 0; j < locations.length; j++) {
          const location = locations[j];

          if (path.beginsWith(location.code)) {
            return path.length === 1 ? child : child.find(path.slice(1));
          }
        }
      }
    }
    return undefined;
  }

  public getPathLocations(path: LayoutPath): LayoutLocation[] {
    if (path.length === 0) {
      return [];
    }

    const pathLocations: LayoutLocation[] = [];

    for (let i = 0; i < this.children.length; i++) {
      const child = this.children[i];
      const locations = child.getLayoutLocations();

      for (let j = 0; j < locations.length; j++) {
        const location = locations[j];

        if (path.beginsWith(location.code)) {
          pathLocations.push(
            location,
            ...child.getPathLocations(path.slice(1))
          );
        }
      }
    }

    return pathLocations;
  }

  public abstract getLayoutLocations(
    parentLayoutLocation?: LayoutLocation
  ): LayoutLocation[];

  public getChildLayoutLocations(
    parentLayoutLocation?: LayoutLocation
  ): LayoutLocation[] {
    const locations: LayoutLocation[] = [];

    this.children.forEach((child) =>
      locations.push(...child.getLayoutLocations(parentLayoutLocation))
    );

    return locations;
  }

  public getOrderLayoutLocations(
    parentLayoutLocation?: LayoutLocation
  ): LayoutLocation[] {
    if (this.children.length > 0) {
      const locations: LayoutLocation[] = [];

      this.children.forEach((child) => {
        child.getLayoutLocations(parentLayoutLocation).forEach((location) => {
          if (child.isAllowedToOrder) {
            locations.push(location);
          }

          locations.push(...child.getOrderLayoutLocations(location));
        });
      });

      return locations;
    } else {
      return [];
    }
  }

  public getEdgeLayoutLocations(
    parentLayoutLocation?: LayoutLocation
  ): LayoutLocation[] {
    if (this.children.length > 0) {
      const locations: LayoutLocation[] = [];

      this.children.forEach((child) => {
        child
          .getLayoutLocations(parentLayoutLocation)
          .forEach((location) =>
            locations.push(...child.getEdgeLayoutLocations(location))
          );
      });

      return locations;
    } else if (parentLayoutLocation) {
      return [parentLayoutLocation];
    } else {
      return [];
    }
  }

  public toJSON() {
    const json: any = {
      type: this.type,
      code: this.code,
      name: this.name,
      bases: this.customBases ? this.customBases.toJSON() : null
    };

    if (this.children) {
      json.children = this.children.map((child) => child.toJSON());
    }

    return json;
  }

  public abstract toString(): string;

  public abstract clone(): LayoutNode;
}
