import AuthService from "../../services/AuthService";
import { authService } from "./index";

export default class ClientAuthService extends AuthService {
  private readonly fbEmailAuthProvider;

  constructor(fbAuth, fbEmailAuthProvider) {
    super(fbAuth);

    this.fbEmailAuthProvider = fbEmailAuthProvider;
  }

  // Auth Stage Changed
  onAuthStateChanged(cb: Function) {
    return this.fbAuth.onAuthStateChanged(cb);
  }

  // Sign Up
  createUserWithEmailAndPassword(email: string, password: string) {
    return this.fbAuth.createUserWithEmailAndPassword(email, password);
  }

  // Sign In (Anonymously)
  signInAnonymously() {
    return this.fbAuth.signInAnonymously();
  }

  // Sign In (E-mail & Password)
  signInWithEmailAndPassword(email: string, password: string) {
    return this.fbAuth.signInWithEmailAndPassword(email, password);
  }

  // Sign out
  signOut() {
    return this.fbAuth.signOut();
  }

  // Password Reset
  resetPasswordForEmail(email: string) {
    return this.fbAuth.sendPasswordResetEmail(email);
  }

  // Password Change
  updatePassword(password: string) {
    return this.fbAuth.currentUser.updatePassword(password);
  }

  // Verify password
  async verifyPassword(authenticatedUser: any, password: string) {
    const credential = authService.createEmailProviderCredential(
      authenticatedUser.email,
      password
    );

    try {
      await authenticatedUser.reauthenticateWithCredential(credential);

      return true;
    } catch (error) {
      throw error;
    }

    return false;
  }

  // Recaptcha Verifier
  /*get RecaptchaVerifier() {
    return this.fbAuth.RecaptchaVerifier;
  }*/

  // Create email provider creditial (email, password)
  createEmailProviderCredential(...args) {
    return this.fbEmailAuthProvider.credential(...args);
  }
}
