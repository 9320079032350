import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import QrReader from "./QrReader";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  spacing: {
    height: 20
  }
});

class ScanQRCodeDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleScan = (data) => {
    const { onScan } = this.props;

    if (data) {
      onScan(data);
    }
  };

  handleError = (error) => {
    const { t } = this.props;

    toast.error(
      `${t("label.something-went-wrong")} (${error ? error.toString() : t("error.unknown-error")
      })`
    );
  };

  render() {
    const { classes, t, isOpen, onClose } = this.props;

    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{t("label.scan-qr-code")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("label.position-qr-code-in-window-below")}
          </DialogContentText>
          <div className={classes.spacing} />
          <QrReader onSuccess={this.handleScan} onError={this.handleError} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t("label.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(ScanQRCodeDialog));
