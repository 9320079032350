import { serviceHelper } from "../../helpers";
import Data from "./Data";
import DataName from "./DataName";

class AdministrationData extends Data {
  private _barType: string = "";
  private _barTypeOther: string | null = null;
  private _barSize: string = "";
  private _contact: {
    firstName: string
    lastName: string,
    phoneNumber: string
  } = {
    firstName: "",
    lastName: "",
    phoneNumber: ""
  };

  constructor(barId: string, props: any) {
    super(barId, DataName.ADMINISTRATION);

    if (props) {
      if (props.barType) {
        this.barType = props.barType;
      }
      if (props.barTypeOther) {
        this.barTypeOther = props.barTypeOther;
      }
      if (props.barSize) {
        this.barSize = props.barSize;
      }
      if (props.contact) {
        if (props.contact.firstName) {
          this.contactFirstName = props.contact.firstName;
        }
        if (props.contact.lastName) {
          this.contactLastName = props.contact.lastName;
        }
        if (props.contact.phoneNumber) {
          this.contactPhoneNumber = props.contact.phoneNumber;
        }
      }
    }

    super.setAllProps(["barType", "barTypeOther", "barSize", "contact"]);
  }

  public get barType(): string {
    return this._barType;
  }

  public set barType(barType: string) {
    this._barType = serviceHelper.parseString(barType);
    super.setPropUpdated("barType");
  }

  public get barTypeOther(): string | null {
    return this._barTypeOther;
  }

  public set barTypeOther(barTypeOther: string | null) {
    if (this.barType === "other" && !barTypeOther) {
      throw new Error("error.string-is-not-defined");
    }

    this._barTypeOther = barTypeOther ? barTypeOther : null;
    super.setPropUpdated("barTypeOther");
  }

  public get barSize(): string {
    return this._barSize;
  }

  public set barSize(barSize: string) {
    this._barSize = serviceHelper.parseString(barSize);
    super.setPropUpdated("barSize");
  }

  protected get contact(): any {
    return this._contact;
  }

  public get contactFirstName(): string {
    return this._contact.firstName;
  }

  public set contactFirstName(firstName: string) {
    this._contact.firstName = serviceHelper.parseString(firstName);
    super.setPropUpdated("contact");
  }

  public get contactLastName(): string {
    return this._contact.lastName;
  }

  public set contactLastName(lastName: string) {
    this._contact.lastName = serviceHelper.parseString(lastName);
    super.setPropUpdated("contact");
  }

  public get contactPhoneNumber(): string {
    return this._contact.phoneNumber;
  }

  public set contactPhoneNumber(phoneNumber: string) {
    this._contact.phoneNumber = serviceHelper.parseString(phoneNumber);
    super.setPropUpdated("contact");
  }
}

export default AdministrationData;