import React, { PureComponent } from "react";
import classNames from "classnames";

import { Drawer, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  drawer: {
    backgroundColor: theme.palette.background.paper
  },
  header: {
    zIndex: 1,
    display: "flex",
    flexShrink: 0,
    padding: theme.spacing(1)
  },
  title: {
    width: "100%"
  },
  centeredTitle: {
    textAlign: "center"
  },
  titleLeftPadding: {
    paddingLeft: theme.spacing(7)
  },
  closeButton: {
    minWidth: 56
  },
  content: {
    position: "relative"
  }
});

class ContentDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClose = (event, reason) => {
    const { disableClose, onClose } = this.props;

    if (!disableClose && onClose) {
      onClose(event, reason);
    }
  };

  render() {
    const {
      classes,
      isOpen,
      onClose,
      title,
      description,
      children,
      disableClose,
      className,
      centerTitle
    } = this.props;

    return (
      <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={this.handleClose}
        classes={{ paper: classNames(className, classes.drawer) }}
      >
        <div className={classes.header}>
          <div className={classes.title}>
            <Typography
              variant="h6"
              className={classNames(classes.title, {
                [classes.centeredTitle]: centerTitle,
                [classes.titleLeftPadding]: centerTitle && !disableClose
              })}
            >
              {title}
            </Typography>
            <Typography className={classes.title}>{description}</Typography>
          </div>
          {!disableClose && (
            <IconButton
              className={classes.closeButton}
              onClick={onClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <div className={classes.content}>{children}</div>
      </Drawer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ContentDrawer);
