import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Redirect } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";

import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";
import ContentPage from "tap-io/client/components/common/ContentPage";
import ServiceOptionName from "tap-io/models/service/ServiceOptionName";
import withPageQuery from "tap-io/client/components/common/withPageQuery";
import { AndroidWrapper } from "tap-io/client/native/AndroidWrapper";
import { IOSWrapper } from "tap-io/client/native/IOSWrapper";

import * as routes from "../constants/routes";
import LoadingScanner from "../components/scanner/LoadingScanner";
import ScannerMessage from "../components/scanner/ScannerMessage";
import withPinAuthentication from "../components/auth/withPinAuthentication";
import CreateOrder from "../components/order/CreateOrder";

const styles = (theme) => ({
  smallSpacing: {
    height: 10
  }
});

class CreateOrderPage extends PureComponent {
  constructor(props) {
    super(props);

    const { barLocator } = this.props;

    this.state = {
      barLocator,
      // orderName,
      orderId: null
    };
  }

  componentDidMount() {
    utilsHelper.resetScrollPosition();
  }

  handleRedirectToScanQRCodePage = () => {
    this.setState({ doRedirect: true });
  };

  render() {
    const {
      classes,
      t,
      barLocator,
      scanner,
      bar,
      base,
      activeFees,
      activeDeposits,
      menuDeposits,
      menuElements,
      menuCategories,
      menuItems
    } = this.props;
    const { doRedirect } = this.state;

    if (doRedirect) {
      return <Redirect to={routes.SCAN_QR_CODE} />;
    }

    if (!AndroidWrapper.get() && !IOSWrapper.get() /*!scanner?.allowCreatingOrders*/) {
      return (
        <ContentPage>
          <Typography variant="h6" className={classes.titleWithIcon}>
            <ErrorIcon className={classes.titleIcon} />
            {t("label.error")}
          </Typography>
          <div className={classes.spacing} />
          <Typography>{t("error.create-order-needs-app")}</Typography>
          <div className={classes.spacing} />
          <div>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disableElevation={true}
              onClick={this.handleRedirectToScanQRCodePage}
            >
              {t("label.scan-qr-code")}
            </Button>
          </div>
        </ContentPage>
      );
    }

    if (!base || !base.canUseScanner) {
      return (
        <ScannerMessage
          Icon={WarningIcon}
          title={t("scanner.base-does-not-use-scanners")}
          description={t("scanner.base-does-not-use-scanners")}
        />
      );
    }

    return (
      <div className={classes.content}>
        {scanner ? (
          <>
            {bar.isLive ? (
              <CreateOrder
                headerProps={{ isLarge: true, height: 56 }}
                locale={bar.getAppLocale()}
                barLocator={barLocator}
                bar={bar}
                scanner={scanner}
                activeFees={activeFees}
                activeDeposits={activeDeposits}
                menuDeposits={menuDeposits}
                menuElements={menuElements}
                menuCategories={menuCategories}
                menuItems={menuItems}
                orderName={`${base.name} - ${scanner.name}`}
              />
            ) : (
              <Typography className={classes.message}>
                {t("order.ordering-currently-not-available")}
              </Typography>
            )}
          </>
        ) : (
          <LoadingScanner />
        )}
      </div>
    );
  }
}

export default withPageQuery(
  withPinAuthentication()(
    withStyles(styles)(withTranslation("common")(CreateOrderPage))
  )
);
