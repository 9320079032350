import Balance from "../models/balance/Balance";
import BalanceLogEntry from "../models/balance/BalanceLogEntry";
import Transaction from "../models/balance/Transaction";

export default class BalanceParser {
  public parseDocToBalance(barId: string, doc): Balance {
    return new Balance(
      barId,
      doc.data()
    );
  }

  public parseDocToBalanceLogEntry(doc): BalanceLogEntry {
    const data = doc.data();

    return new BalanceLogEntry(
      doc.id,
      {
        ...data,
        timestamp: data.timestamp.toDate()
      }
    );
  }

  public parseDocToTransaction(doc): Transaction {
    const data = doc.data();

    return new Transaction(
      doc.id,
      {
        ...data,
        timestamp: data.timestamp.toDate()
      }
    );
  }
}
