import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";
import { deliveryHelper } from "../../../helpers";

const styles = (theme) => ({
  deliveryLabel: {
    display: "inline-block",
    padding: "1px 8px",
    borderRadius: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "white"
  },
  deliveryMethodInfo: {
    display: "inline"
  },
  bulletDivider: {
    display: "inline-block",
    width: 16,
    textAlign: "center"
  },
  deliveryMethodColor: {},
  deliveryMethodCode: {
    fontFamily: "'Courier New', Courier, monospace",
    textTransform: "uppercase"
  }
});

class OrderDeliveryLabel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, base, order, hideDeliveryMethod, hideColorText, fontSize } =
      this.props;

    const deliveryColor = order.getDeliveryColor(base);

    if (hideDeliveryMethod && !order.isDeliveryMethodPickup) {
      return null;
    }

    const elements = [];

    if (!hideDeliveryMethod) {
      elements.push(
        <span>
          {t(
            order.isDeliveryMethodPickup ? "delivery.pickup" : "delivery.serve"
          )}
        </span>
      );
    }

    if (order.isDeliveryMethodPickup) {
      if (!hideColorText) {
        elements.push(
          <span className={classes.deliveryMethodColor}>
            {t(`color.${deliveryColor}`)}
          </span>
        );
      }

      elements.push(
        <span className={classes.deliveryMethodCode}>
          {order.delivery.code}
        </span>
      );
    }

    return (
      <div
        className={classes.deliveryLabel}
        style={{
          fontSize: fontSize || undefined,
          borderRadius: fontSize ? fontSize / 2 : undefined,
          backgroundColor: order.isDeliveryMethodPickup
            ? deliveryHelper.getDeliveryColorValue(deliveryColor)
            : "black"
        }}
      >
        {elements.map((element, index) => (
          <span key={index}>
            {element}
            {index < elements.length - 1 && (
              <span className={classes.bulletDivider}>&bull;</span>
            )}
          </span>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(OrderDeliveryLabel)
);
