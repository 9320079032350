import { serviceHelper } from "../../helpers";
import Fee from "../../models/fee/Fee";
import { feeParser } from "../../parsers";
import FeeService from "../../services/FeeService";

export default class ClientFeeService extends FeeService {
  public onAllFees(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.getFeesRef(barId).onSnapshot(
      (snap: any) => {
        const fees = snap.docs.map((doc: any) =>
          feeParser.parseDocToFee(barId, doc)
        );
        cb(fees);
      },
      (error: any) => {
        cb([]);
      }
    );
  }

  public onActiveFees(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }

    return this.getFeesRef(barId)
      .where("isActive", "==", true)
      .onSnapshot(
        (snap: any) => {
          const fees = snap.docs.map((doc: any) =>
            feeParser.parseDocToFee(barId, doc)
          );
          cb(fees);
        },
        (error: any) => {
          cb([]);
        }
      );
  }

  public createFee(barId: string, data: any): Fee {
    return new Fee(barId, undefined, data);
  }

  public async addFee(fee: Fee): Promise<Fee> {
    if (fee.id) {
      throw new Error("error.id-already-defined");
    }

    const ref = await this.getFeesRef(fee.barId).add({
      ...fee.allPropsToJSON(),
      timestamp: serviceHelper.serverTimestamp()
    });
    fee.id = ref.id;
    return fee;
  }
}
