export default class CrewService {
  private readonly fbStore;

  constructor(fbStore) {
    this.fbStore = fbStore;
  }

  protected getCrewRef() {
    return this.fbStore.collection("crew");
  }

  protected getCrewRefForUser(userId: string) {
    return this.getCrewRef().doc(userId);
  }

  private parseCrewDoc(crewDoc): any {
    const crewData = crewDoc.data();
    if (!crewData) {
      throw new Error("error.no-crew-data-found");
    }

    crewData.userId = crewDoc.id;
    crewData.activeBarIds = this.getActiveBarIdsFromCrewData(crewData);

    return crewData;
  }

  protected async getCrew(userId: string) {
    if (!userId) {
      throw new Error("error.user-id-is-not-defined");
    }

    const crewDoc = await this.getCrewRefForUser(userId).get();

    return this.parseCrewDoc(crewDoc);
  }

  protected onCrew(userId: string, cb: Function) {
    if (!userId) {
      throw new Error("error.user-id-is-not-defined");
    }

    return this.getCrewRefForUser(userId).onSnapshot((doc) => {
      cb(doc.exists ? this.parseCrewDoc(doc) : null);
    });
  }

  private getActiveBarIdsFromCrewData(crewData: any): string[] {
    const barIds: string[] = [];

    if (crewData && crewData.bars) {
      for (let barId in crewData.bars) {
        if (crewData.bars[barId]?.isActive === true) {
          barIds.push(barId);
        }
      }
    }

    return barIds;
  }

  private getActiveBarIdFromCrewData(crewData: any): string | undefined {
    // Check the user's crew data to find a barId (if no barId has been set yet)
    //if (barId === undefined) {
    const crewBarIds = this.getActiveBarIdsFromCrewData(crewData);
    if (crewBarIds.length === 1) {
      return crewBarIds[0];
    } else if (crewBarIds.length === 0) {
      throw new Error("error.no-crew-bar-id-found");
    } else {
      throw new Error("error.found-more-than-one-crew-bar-id");
    }

    return undefined;
  }

  async getBarIdForUser(userId: string): Promise<string | undefined> {
    const crewData = await this.getCrew(userId);
    return this.getActiveBarIdFromCrewData(crewData);
  }

  async getBarIdsForUser(userId: string): Promise<string[]> {
    const crewData = await this.getCrew(userId);
    return this.getActiveBarIdsFromCrewData(crewData);
  }

  onBarIdForUser(userId: string, cb: Function) {
    return this.onCrew(userId, (crewData) => {
      return cb(this.getActiveBarIdFromCrewData(crewData));
    });
  }

  onBarIdsForUser(userId: string, cb: Function) {
    return this.onCrew(userId, (crewData) => {
      return cb(this.getActiveBarIdsFromCrewData(crewData));
    });
  }
}
