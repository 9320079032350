import React from "react";

import WarningIcon from "@mui/icons-material/Warning";

import Message from "./Message";

const WarningMessage = ({ label }) => (
  <Message icon={<WarningIcon />} label={label} />
);

export default WarningMessage;
