import {
  validate,
  isTypedObject,
  isString,
  isEnum
} from "../helpers/ValidationHelper";
import Bar from "../models/bar/Bar";
import CustomerField from "../models/bar/CustomerField";
import CustomerFieldType from "../models/bar/CustomerFieldType";
import TextCustomerField from "../models/bar/TextCustomerField";
import EmailCustomerField from "../models/bar/EmailCustomerField";
import DateCustomerField from "../models/bar/DateCustomerField";
import ToggleCustomerField from "../models/bar/ToggleCustomerField";
import SelectCustomerField from "../models/bar/SelectCustomerField";
import SelectCustomerFieldOption from "../models/bar/SelectCustomerFieldOption";
import { barDefaultsInjector } from "../defaults";

export default class BarParser {
  public parseDocToBar(doc): Bar {
    const data = { ...doc.data() };

    if (data.timestamp) {
      data.timestamp = data.timestamp.toDate();
    }

    return barDefaultsInjector.injectDefaults(new Bar(doc.id, data));
  }

  public parseCustomerFields(fields: any[]): CustomerField[] {
    return fields.map((field) => {
      if (!field) {
        throw new Error("error.field-is-not-defined");
      }

      const props = {
        ...validate(
          isTypedObject({
            type: isEnum(CustomerFieldType),
            id: isString
          })
        )(field),
        name: field.name || "", // TODO: Validate
        description: field.description || "",
        isRequired: !!field.isRequired
      };

      switch (field.type) {
        case CustomerFieldType.TEXT:
          return new TextCustomerField(props);
          break;
        case CustomerFieldType.EMAIL:
          return new EmailCustomerField({
            ...props,
            sendOrderConfirmationToThisAddress:
              !!field.sendOrderConfirmationToThisAddress
          });
          break;
        case CustomerFieldType.DATE:
          // TO FIX: Date  validator
          return new DateCustomerField({
            ...props,
            minDate: field.minDate || null,
            maxDate: field.maxDate || null,
            exceptDays: field.exceptDays || null
          });
          break;
        case CustomerFieldType.TOGGLE:
          return new ToggleCustomerField(props);
          break;
        case CustomerFieldType.SELECT:
          return new SelectCustomerField({
            ...props,
            options: field.options
              ? field.options.map(
                (option: any) => new SelectCustomerFieldOption(option)
              )
              : []
          });
          break;
        default:
          throw new Error("error.field-type-is-not-valid");
          break;
      }
    });
  }
}
