import DeliveryMethod from "../delivery/DeliveryMethod";
import { deliveryHelper, utilsHelper } from "../../helpers";
import Base from "../base/Base";

class OrderDelivery {
  private readonly _method: DeliveryMethod;
  private readonly _code: string;
  private readonly _color: string;
  private readonly _contact: any;

  private readonly _bases: {
    [baseId: string]: {
      color: string;
      estimatedPreparationDurationInMinutes: number | null;
      preparationStartedAt: Date | null;
      preparationEstimatedToBeCompletedAt: Date | null;
    };
  };

  constructor(props: any) {
    this._method = this.parseMethod(props ? props.method : null);
    this._code = props && props.code ? props.code : "";

    this._color = this.parseColor(props ? props.color : null);

    this._bases = {};
    if (props) {
      if (props.bases) {
        for (let baseId in props.bases) {
          this._bases[baseId] = {
            color: this.parseColor(props.bases[baseId].color),
            estimatedPreparationDurationInMinutes:
              props.bases[baseId].estimatedPreparationDurationInMinutes || null,
            preparationStartedAt:
              props.bases[baseId].preparationStartedAt || null,
            preparationEstimatedToBeCompletedAt:
              props.bases[baseId].preparationEstimatedToBeCompletedAt || null
          };
        }
      } else if (props.colorForBase) {
        for (let baseId in props.colorForBase) {
          this.bases[baseId] = {
            color: this.parseColor(props.colorForBase[baseId]),
            estimatedPreparationDurationInMinutes: null,
            preparationStartedAt: null,
            preparationEstimatedToBeCompletedAt: null
          };
        }
      }
    }

    this._contact = this.parseContact(props ? props.contact : null);
  }

  private parseMethod(method: string): DeliveryMethod {
    switch (method) {
      case DeliveryMethod.PICKUP:
        return DeliveryMethod.PICKUP;
      //case DeliveryMethod.SERVE:
      //  return DeliveryMethod.SERVE;
      default:
        return DeliveryMethod.SERVE;
    }
  }

  parseColor(color: string): string {
    // TO FIX: temporary (color.[color] -> [color])
    const colorMatches = color ? color.match(/^(color\.)?(.*)$/) : [];
    const cleanColor =
      colorMatches && colorMatches.length === 3 ? colorMatches[2] : "";

    return deliveryHelper.doesDeliveryColorKeyExist(cleanColor)
      ? cleanColor
      : deliveryHelper.getDefaultDeliveryColorKey();
  }

  parseContact(contact: any): any {
    if (contact && contact.email) {
      const email = utilsHelper.parseEmail(contact.email);

      if (email) {
        return {
          email
        };
      }
    }

    return null;
  }

  public get method(): DeliveryMethod {
    return this._method;
  }

  public get code(): string {
    return this._code;
  }

  public get color(): string {
    return this._color;
  }

  public get bases() {
    return this._bases;
  }

  public getColor(base?: Base): string {
    return base && base.id && this.bases[base.id]
      ? this.bases[base.id].color
      : this.color;
  }

  public get contact(): any {
    return this._contact;
  }

  public getEstimatedPreparationDurationInMinutes(
    baseId: string
  ): number | null {
    return this.bases[baseId]
      ? this.bases[baseId].estimatedPreparationDurationInMinutes
      : null;
  }

  public setEstimatedPreparationDurationInMinutes(
    baseId: string,
    estimatedPreparationDurationInMinutes: number | null
  ) {
    if (!this.bases[baseId]) {
      throw new Error("error.base-is-not-defined");
    }

    this.bases[baseId].estimatedPreparationDurationInMinutes =
      estimatedPreparationDurationInMinutes;
    //this.bases[baseId].preparationEstimatedToBeCompletedAt = null;
  }

  public getPreparationStartedAt(baseId: string): Date | null {
    return this.bases[baseId] ? this.bases[baseId].preparationStartedAt : null;
  }

  public getPreparationEstimatedToBeCompletedAt(baseId: string): Date | null {
    return this.bases[baseId]
      ? this.bases[baseId].preparationEstimatedToBeCompletedAt
      : null;
  }

  public toJSON(): any {
    return {
      method: this.method,
      code: this.code,
      color: this.color,
      bases: this.bases,
      contact: this.contact
    };
  }
}

export default OrderDelivery;
