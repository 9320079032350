import React from "react";

import InfoIcon from "@mui/icons-material/Info";

import Message from "./Message";

const InfoMessage = ({ label }) => (
  <Message icon={<InfoIcon />} label={label} />
);

export default InfoMessage;
