import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";

import config from "../client/env";

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}

const db = firebase.database();
const auth = firebase.auth();
auth.useDeviceLanguage(); //.languageCode = 'nl';

const emailAuthProvider = firebase.auth.EmailAuthProvider;

const store = firebase.firestore();
const settings = {
  /* your settings... */
  /*timestampsInSnapshots: true*/
};
store.settings(settings);

const documentId = firebase.firestore.FieldPath.documentId;

const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
const incrementField = firebase.firestore.FieldValue.increment;
const deleteField = firebase.firestore.FieldValue.delete;
const arrayUnionField = firebase.firestore.FieldValue.arrayUnion;
const arrayRemoveField = firebase.firestore.FieldValue.arrayRemove;

const storage = firebase.storage();

export {
  db,
  store,
  auth,
  emailAuthProvider,
  serverTimestamp,
  documentId,
  incrementField,
  deleteField,
  arrayUnionField,
  arrayRemoveField,
  storage
};
