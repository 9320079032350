import Menu from "../models/menu/Menu";
import Element from "../models/menu/Element";
import Category from "../models/menu/Category";
import Item from "../models/menu/Item";

export default class MenuParser {
  public parseDocToMenu(barId: string, doc): Menu {
    const data = doc.data();

    return new Menu(
      barId,
      doc.id,
      {
        ...data,
        elements: this.parseElements(doc.id, data.elements),
        timestamp: data.timestamp ? data.timestamp.toDate() : undefined
      });
  }

  public parseElements(menuId: string, elements: Array<any>): Array<Element> {
    return elements ?
      elements.map(element => element.type === "category" ?
        new Category(menuId, element.id, element) :
        new Item(menuId, element.id, element)) :
      [];
  }
}
