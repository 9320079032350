import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, CircularProgress } from "@mui/material";
import ContactlessIcon from "@mui/icons-material/Contactless";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { authHelper } from "tap-io/helpers";
import { fetchBase64Data } from "tap-io/utils";
import PaymentProvider from "tap-io/models/payment/PaymentProvider";
import { AndroidWrapper } from "tap-io/client/native/AndroidWrapper";

const styles = (theme) => ({
  button: {
    margin: 20
  }
});

class PayOrderWithVivaWallet extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false
    };
  }

  componentDidMount() {
    const { autoRedirect } = this.props;

    if (autoRedirect) {
      setTimeout(this.handleVivaWalletPayment, 100);
    }
  }

  handleVivaWalletPayment = async (event) => {
    const { auth, scanner, order, onProcessing, onComplete, onError } =
      this.props;

    this.setState({ isDisabled: true });

    try {
      if (onProcessing) {
        onProcessing();
      }

      const link = `${config.functions.api}/public/payment`;

      const authorizedLink = await authHelper.generateAuthorizedLink(
        auth.user,
        link,
        {
          barId: scanner.barId,
          provider: PaymentProvider.VIVAWALLET,
          kind: "order",
          orderId: order.id,
          scannerId: scanner.id
        }
      );

      const intent = await fetchBase64Data(authorizedLink);
      const wrapper = AndroidWrapper.get();
      await wrapper?.launchSecureIntent(intent);

      setTimeout(onComplete, 500);
      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false }, () => {
        onError(error);
      });
    }
  };

  render() {
    const { classes, t } = this.props;
    const { isDisabled } = this.state;

    return (
      <div className={classes.button}>
        <Button
          variant="outlined"
          size="large"
          color="primary"
          startIcon={
            isDisabled ? <CircularProgress size={30} /> : <ContactlessIcon />
          }
          disabled={isDisabled}
          onClick={this.handleVivaWalletPayment}
        >
          {t("payment.pay-with-vivawallet")}
        </Button>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(PayOrderWithVivaWallet)
);
