import { utilsHelper } from "../helpers";
import { scannerParser } from "../parsers";
import Scanner from "../models/scanner/Scanner";
import Bar from "../models/bar/Bar";

export default class ScannerService {
  private readonly fbStore;

  constructor(fbStore) {
    this.fbStore = fbStore;
  }

  private sortScanners(scanners: Array<Scanner>): Array<Scanner> {
    return utilsHelper.sortObjectsArray(scanners, "name");
  }

  protected getScannersRef(barId: string) {
    return this.fbStore.collection("bars").doc(barId).collection("scanners");
  }

  protected getScannerRef(barId: string, scannerId: string) {
    return this.getScannersRef(barId).doc(scannerId);
  }

  protected getScannerLogRef(barId: string, scannerId: string) {
    return this.getScannerRef(barId, scannerId).collection("log");
  }

  public async getById(barId: string, scannerId: string): Promise<Scanner> {
    if (!barId) {
      throw new Error("error.bar-id-is-not-defined");
    }
    if (!scannerId) {
      throw new Error("error.scanner-id-is-not-defined");
    }

    const doc = await this.getScannerRef(barId, scannerId).get();
    return scannerParser.parseDocToScanner(barId, doc);
  }

  public onById(bar: Bar, scannerId: string, cb: Function, errCb: Function) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }
    if (!scannerId) {
      throw new Error("error.scanner-id-is-not-defined");
    }
    const barId = bar.id;

    return this.getScannerRef(barId, scannerId).onSnapshot((doc) => {
      if (doc.exists) {
        cb(scannerParser.parseDocToScanner(barId, doc));
      } else {
        const error = new Error("error.scanner-does-not-exist");
        if (errCb) {
          errCb(error);
        } else {
          console.warn(error);
        }
      }
    });
  }

  public async getAllScanners(barId: string): Promise<Array<Scanner>> {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    const docs = await this.getScannersRef(barId).orderBy("name", "asc").get();

    const scanners: Array<Scanner> = [];
    docs.forEach((doc) =>
      scanners.push(scannerParser.parseDocToScanner(barId, doc))
    );

    return this.sortScanners(scanners);
  }

  public onAllScanners(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    return this.getScannersRef(barId)
      .orderBy("name", "asc")
      .onSnapshot((docs) => {
        const scanners: Array<Scanner> = [];
        docs.forEach((doc) =>
          scanners.push(scannerParser.parseDocToScanner(barId, doc))
        );
        cb(this.sortScanners(scanners));
      });
  }

  public removeScanner(scanner: Scanner) {
    if (!scanner.id) {
      throw new Error("error.id-is-not-defined");
    }

    return this.getScannerRef(scanner.barId, scanner.id).delete();
  }

  public async updateScanner(scanner: Scanner) {
    if (!scanner.id) {
      throw new Error("error.id-is-not-defined");
    }

    const updatedProps = scanner.updatedPropsToJSON();
    if (!utilsHelper.isObjectEmpty(updatedProps)) {
      await this.getScannerRef(scanner.barId, scanner.id).update(
        scanner.updatedPropsToJSON()
      );
    }
  }

  public async getScannerPin(barId: string, scannerId: string) {
    const ref = this.getScannerRef(barId, scannerId)
      .collection("protected")
      .doc("verification");

    const doc = await ref.get();
    if (!doc.exists) {
      throw new Error("error.scanner-verification-does-not-exist");
    }

    const scannerVerification = doc.data();

    if (!scannerVerification || !scannerVerification.pin) {
      throw new Error("error.voucher-pin-is-not-defined");
    }

    return scannerVerification.pin;
  }
}
