import Base from "../models/base/Base";
import { serviceHelper, utilsHelper } from "../helpers";
import { baseParser } from "../parsers";
import BasePreparationOption from "../models/base/BasePreparationOption";

export default class BaseService {
  private readonly fbStore;

  constructor(fbStore) {
    this.fbStore = fbStore;

    this.getNextSequenceNumber = this.getNextSequenceNumber.bind(this);
  }

  private sortBases(bases: Array<Base>): Array<Base> {
    return utilsHelper.sortObjectsArray(bases, "name");
  }

  private checkIfBaseIdsArrayInQueryAreValid(baseIds: Array<string>) {
    if (!baseIds) {
      throw new Error("error.base-ids-is-not-defined");
    }
    if (baseIds.length === 0) {
      throw new Error("error.base-ids-empty");
    }
    if (baseIds.length > serviceHelper.IN_QUERY_ARRAY_MAX_SIZE) {
      throw new Error("error.base-ids-limit-exceeded");
    }
  }

  protected getBasesWithIdsRef(barId: string, baseIds: Array<string>) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    this.checkIfBaseIdsArrayInQueryAreValid(baseIds);

    return this.getBasesRef(barId).where(
      serviceHelper.documentId(),
      "in",
      baseIds
    );
  }

  protected getBasesWithMenuIdsRef(barId: string, menuIds: Array<string>) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    this.checkIfBaseIdsArrayInQueryAreValid(menuIds);

    return this.getBasesRef(barId).where(
      "menuIds",
      "array-contains-any",
      menuIds
    );
  }

  protected getBasesRef(barId: string) {
    return this.fbStore.collection("bars").doc(barId).collection("bases");
  }

  protected getBaseRef(barId: string, baseId: string) {
    return this.getBasesRef(barId).doc(baseId);
  }

  public async getAllBases(barId: string): Promise<Array<Base>> {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    const docs = await this.getBasesRef(barId).orderBy("name", "asc").get();

    const bases: Array<Base> = [];
    docs.forEach((doc) => bases.push(baseParser.parseDocToBase(barId, doc)));
    return bases;
  }

  public onAllBases(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    return this.getBasesRef(barId)
      .orderBy("name", "asc")
      .onSnapshot((docs) => {
        const bases: Array<Base> = [];
        docs.forEach((doc) =>
          bases.push(baseParser.parseDocToBase(barId, doc))
        );
        cb(bases);
      });
  }

  public async getActiveBases(barId: string): Promise<Array<Base>> {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    const docs = await this.getBasesRef(barId)
      .where("isActive", "==", true)
      //.orderBy("name", "asc")
      .get();

    const bases: Array<Base> = [];
    docs.forEach((doc) => bases.push(baseParser.parseDocToBase(barId, doc)));
    return bases;
  }

  public onActiveBases(barId: string, cb: Function) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    return (
      this.getBasesRef(barId)
        .where("isActive", "==", true)
        //.orderBy("name", "asc")
        .onSnapshot((docs) => {
          const bases: Array<Base> = [];
          docs.forEach((doc) =>
            bases.push(baseParser.parseDocToBase(barId, doc))
          );
          cb(this.sortBases(bases));
        })
    );
  }

  public async getActiveBasesWithIds(
    barId: string,
    baseIds: string[]
  ): Promise<Base[]> {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    return serviceHelper.getByIds(
      (baseIds: string[]) =>
        this.getBasesWithIdsRef(barId, baseIds).where("isActive", "==", true),
      (doc) => baseParser.parseDocToBase(barId, doc),
      baseIds
    );
  }

  public onActiveBasesWithIds(
    barId: string,
    baseIds: Array<string>,
    cb: Function
  ) {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    return serviceHelper.onByIds(
      (baseIds: string[]) =>
        this.getBasesWithIdsRef(barId, baseIds).where("isActive", "==", true),
      (doc) => baseParser.parseDocToBase(barId, doc),
      baseIds,
      (bases: Base[]) => cb(this.sortBases(bases))
    );
  }

  public async getBasesWithMenuIds(
    barId: string,
    menuIds: string[]
  ): Promise<Base[]> {
    if (!barId) {
      throw new Error("barId is not defined");
    }

    return serviceHelper.getByIds(
      (menuIds: string[]) => this.getBasesWithMenuIdsRef(barId, menuIds),
      (doc) => baseParser.parseDocToBase(barId, doc),
      menuIds
    );
  }

  public async getBaseById(barId: string, baseId: string): Promise<Base> {
    if (!barId) {
      throw new Error("barId is not defined");
    }
    if (!baseId) {
      throw new Error("baseId is not defined");
    }

    const doc = await this.getBaseRef(barId, baseId).get();
    return baseParser.parseDocToBase(barId, doc);
  }

  public onBaseById(barId: string, baseId: string, cb: Function) {
    if (!barId) {
      throw new Error("barId is not defined");
    }
    if (!baseId) {
      throw new Error("baseId is not defined");
    }

    return this.getBaseRef(barId, baseId).onSnapshot((doc) => {
      let base;

      try {
        base = baseParser.parseDocToBase(barId, doc);
      } catch (error) {
        console.warn(error);
      }

      cb(base);
    });
  }

  public createBase(barId: string, data: any): Base {
    return new Base(barId, undefined, data);
  }

  public createBasePreparationOption(data: any): BasePreparationOption {
    return new BasePreparationOption(data);
  }

  public async getNextSequenceNumber(
    barId: string,
    baseId: string
  ): Promise<number> {
    const baseRef = this.getBaseRef(barId, baseId);

    let sequenceNumber: number | null = null;
    await serviceHelper.runTransaction((fTransaction) => {
      return fTransaction.get(baseRef).then((baseDoc) => {
        if (!baseDoc.exists) {
          throw new Error("error.base-does-not-exist");
        }

        const base = baseParser.parseDocToBase(barId, baseDoc);
        sequenceNumber = base.sequenceNumberGenerator.next();

        fTransaction.update(baseRef, {
          sequenceNumberGenerator: base.sequenceNumberGenerator.toJSON()
        });
      });
    });

    if (sequenceNumber === null) {
      throw new Error("error.sequence-number-is-not-defined");
    }

    return sequenceNumber;
  }

  public async addBase(base: Base): Promise<Base> {
    if (base.id) {
      throw new Error("error.id-already-defined");
    }

    const ref = await this.getBasesRef(base.barId).add({
      ...base.allPropsToJSON(),
      timestamp: serviceHelper.serverTimestamp()
    });
    base.id = ref.id;
    return base;
  }

  public removeBase(base: Base) {
    if (!base.id) {
      throw new Error("error.id-is-not-defined");
    }

    return this.getBaseRef(base.barId, base.id).delete();
  }

  public async updateBase(
    base: Base,
    addSetMenuIsActiveByIdInBatchFunction: Function
  ) {
    if (!base.id) {
      throw new Error("error.id-is-not-defined");
    }

    const updatedProps = base.updatedPropsToJSON();
    if (!utilsHelper.isObjectEmpty(updatedProps)) {
      const fBatch = serviceHelper.batch();

      fBatch.update(this.getBaseRef(base.barId, base.id), updatedProps);

      // Update menu.isActive if base.isActive has changed
      if ("isActive" in updatedProps) {
        base.menuIds.forEach((menuId) =>
          addSetMenuIsActiveByIdInBatchFunction(
            fBatch,
            base.barId,
            menuId,
            updatedProps.isActive
          )
        );
      }

      await fBatch.commit();
      //await this.getBaseRef(base.barId, base.id).update(base.updatedPropsToJSON());
    }
  }

  public addSetBaseIsActiveInBatch(
    fBatch: any,
    base: Base,
    isActive: boolean,
    addSetMenuIsActiveByIdInBatchFunction: Function
  ) {
    if (!base.id) {
      throw new Error("error.base-id-is-not-defined");
    }

    fBatch.update(this.getBaseRef(base.barId, base.id), { isActive });
    base.menuIds.forEach((menuId) =>
      addSetMenuIsActiveByIdInBatchFunction(
        fBatch,
        base.barId,
        menuId,
        isActive
      )
    );
  }
}
