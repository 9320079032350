import React from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import WarningMessage from "../common/WarningMessage";
import OrderError from "./OrderError";
import ReactMoment from "react-moment";
import InfoMessage from "../common/InfoMessage";
import ErrorMessage from "../common/ErrorMessage";

const styles = (theme) => ({
  errorText: {
    fontWeight: "bold",
    color: theme.palette.error.main
  }
});

const OrderStatusErrorContent = ({ order, base }) => (
  <>
    <ErrorMessage label={<OrderError order={order} base={base} />} />
  </>
);

const OrderStatusCancelledContent = ({ classes, t }) => (
  <>
    <WarningMessage label={t("scanner.order-has-been-cancelled")} />
  </>
);

const OrderStatusCreatedContent = ({ t, order }) => (
  <>
    <InfoMessage
      label={
        <>
          {t("scanner.order-created")}
          {order && (
            <>
              {" "}
              {t("label.on")}{" "}
              <strong>
                <ReactMoment format="DD/MM/YYYY">{order.timestamp}</ReactMoment>
              </strong>{" "}
              {t("label.at")}{" "}
              <strong>
                <ReactMoment format="HH:mm:ss">{order.timestamp}</ReactMoment>
              </strong>
            </>
          )}
        </>
      }
    />
  </>
);

const OrderStatusQueuedContent = ({ t }) => (
  <>
    <InfoMessage label={t("scanner.order-has-been-queued")} />
  </>
);

const OrderStatusClaimedContent = ({ t, wasOrderAlreadyScanned }) => (
  <>
    {wasOrderAlreadyScanned ? (
      <WarningMessage label={t("scanner.order-was-already-claimed")} />
    ) : (
      <InfoMessage label={t("scanner.order-has-been-claimed")} />
    )}
  </>
);

const OrderStatusCompleteContent = ({ classes, t }) => (
  <>
    <WarningMessage label={t("scanner.order-is-already-complete")} />
  </>
);

const OrderStatusContent = ({
  classes,
  t,
  base,
  order,
  wasOrderAlreadyScanned
}) =>
  order.isStatusError() ? (
    <OrderStatusErrorContent order={order} base={base} />
  ) : order.isStatusCancelled(base) ? (
    <OrderStatusCancelledContent classes={classes} t={t} />
  ) : order.isStatusCreated(base) ? (
    <OrderStatusCreatedContent t={t} order={order} />
  ) : order.isStatusQueued(base) ? (
    <OrderStatusQueuedContent t={t} />
  ) : order.isStatusClaimed(base) ? (
    <OrderStatusClaimedContent
      t={t}
      wasOrderAlreadyScanned={wasOrderAlreadyScanned}
    />
  ) : order.isStatusComplete(base) ? (
    <OrderStatusCompleteContent classes={classes} t={t} />
  ) : null;

export default withStyles(styles)(
  withTranslation("common")(OrderStatusContent)
);
