import Deposit from "../models/deposit/Deposit";
import { utilsHelper } from "./index";

type DepositItem = {
  deposit: Deposit;
  amount: number;
};

type DepositItems = {
  [depositId: string]: DepositItem;
};

export default class DepositHelper {
  constructor() { }

  calculateTotalDepositValue(
    distributedDeposits: DepositItems = {},
    collectedDeposits: DepositItems = {}
  ): number {
    let value = 0;

    const tmpCollectedDeposits: DepositItems = {};
    for (let id in collectedDeposits) {
      tmpCollectedDeposits[id] = { ...collectedDeposits[id] }
    }

    for (let id in distributedDeposits) {
      const purchasedDepositItem = distributedDeposits[id];

      let amount = purchasedDepositItem.amount;

      if (tmpCollectedDeposits[id]) {
        const tradedInDepositItem = tmpCollectedDeposits[id];
        const decreaseAmount = Math.min(purchasedDepositItem.amount, tradedInDepositItem.amount);

        amount -= decreaseAmount;
        tradedInDepositItem.amount -= decreaseAmount;

        if (tradedInDepositItem.amount === 0) {
          delete tmpCollectedDeposits[id];
        }
      }

      value += amount * purchasedDepositItem.deposit.purchaseValue
    }

    return utilsHelper.roundToTwoDecimals(
      value - this.calculateDepositTradeInValue(tmpCollectedDeposits)
    );
  }

  calculateTotalDepositAmount(
    distributedDeposits: DepositItems,
    collectedDeposits: DepositItems
  ): number {
    return (
      this.calculateDepositAmount(distributedDeposits) -
      this.calculateDepositAmount(collectedDeposits)
    );
  }

  calculateDepositPurchaseValue(depositItems: DepositItems) {
    let value = 0;

    for (let id in depositItems) {
      const depositItem = depositItems[id];
      value += depositItem.amount * depositItem.deposit.purchaseValue;
    }

    return utilsHelper.roundToTwoDecimals(value);
  }

  calculateDepositTradeInValue(depositItems: DepositItems) {
    let value = 0;

    for (let id in depositItems) {
      const depositItem = depositItems[id];
      value += depositItem.amount * depositItem.deposit.tradeInValue;
    }

    return utilsHelper.roundToTwoDecimals(value);
  }

  calculateDepositAmount(depositItems: DepositItems) {
    let amount = 0;

    for (let id in depositItems) {
      const depositItem = depositItems[id];
      amount += depositItem.amount;
    }

    return utilsHelper.roundToTwoDecimals(amount);
  }
}
