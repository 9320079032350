import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import ReactMoment from "react-moment";

import { ListItem, ListItemText } from "@mui/material";
import withStyles from "@mui/styles/withStyles";


const styles = (theme) => ({
  sizeLarge: {
    fontSize: 16
  },
  noColor: { color: "black" },
  paymentText: {
    color: theme.palette.warning.success
  },
  partialPaymentText: {
    fontWeight: "bold",
    color: theme.palette.warning.main
  },
  noPaymentText: {
    //fontWeight: "bold",
    color: theme.palette.error.main
  },
  processingPaymentText: {
    fontWeight: "bold"
  },
  paymentLabel: {
    textTransform: "capitalize"
  }
});

class OrderPayment extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getPaymentLabel = (payment) => {
    const { t } = this.props;

    switch (payment.provider) {
      case "deferred":
        return `${t("payment.manually-settled")}${payment.method
          ? ` ${t("label.with")} ${t(
            `payment.payment-method-${payment.method}`
          )}`
          : ""
          }`;
        break;
      default:
        return payment.method || "";
        break;
    }
  };

  render() {
    const {
      classes,
      t,
      bar,
      order,
      size,
      noPaymentDetailsAvailableText,
      disableGutters,
      disableColor
    } = this.props;

    if (order.isPaid()) {
      return (
        <ListItem disableGutters={disableGutters}>
          <ListItemText
            classes={{
              secondary: classNames(classes.paymentText, {
                [classes.sizeLarge]: size === "large"
              })
            }}
            secondary={
              <span className={classNames({ [classes.noColor]: disableColor })}>
                <strong>{`${t("payment.paid")} ${order.getFormattedTotal()} ${order.currency}`}</strong>
                {` (`}
                <span className={classes.paymentLabel}>
                  {this.getPaymentLabel(order.payment)}
                </span>
                {` ${t("label.on")} `}
                <ReactMoment locale={bar.getOrderLocale()} format="DD/MM/YYYY">
                  {order.payment.timestamp}
                </ReactMoment>{" "}
                {t("label.at")}{" "}
                <ReactMoment locale={bar.getOrderLocale()} format="HH:mm">
                  {order.payment.timestamp}
                </ReactMoment>
                {order.payment.reference && `, ${order.payment.reference}`})
              </span>
            }
          />
        </ListItem>
      );
    }

    if (order.isPaymentProcessing()) {
      return (
        <ListItem disableGutters={disableGutters}>
          <ListItemText
            classes={{
              secondary: classNames(classes.processingPaymentText, {
                [classes.sizeLarge]: size === "large"
              })
            }}
            secondary={
              <span className={classNames({ [classes.noColor]: disableColor })}>
                {t("payment.payment-is-processing")}
              </span>
            }
          />
        </ListItem>
      );
    }

    if (order.isPartiallyPaid()) {
      return (
        <ListItem disableGutters={disableGutters}>
          <ListItemText
            classes={{
              secondary: classNames(classes.partialPaymentText, {
                [classes.sizeLarge]: size === "large"
              })
            }}
            secondary={
              <span className={classNames({ [classes.noColor]: disableColor })}>
                <strong>{t("payment.order-partially-paid")}</strong>
                <br />
                {t("payment.the-remaining-total-is-x", {
                  totalAlreadyPaid: `${order.getFormattedTotalAlreadyPaid()} ${order.currency}`,
                  totalToBePaid: `${order.getFormattedTotalToBePaid()} ${order.currency}`
                })}
              </span>
            }
          />
        </ListItem>
      );
    }

    if (
      !order.hasBeenCancelled() &&
      bar.isAllowingOnlinePayments() &&
      !order.isFree()
    ) {
      return (
        <ListItem disableGutters={disableGutters}>
          <ListItemText
            classes={{
              secondary: classNames(classes.noPaymentText, {
                [classes.sizeLarge]: size === "large"
              })
            }}
            secondary={
              <span className={classNames({ [classes.noColor]: disableColor })}>
                <strong>{t("payment.order-not-yet-paid")}</strong>
                <br />
                {t("payment.total-to-be-charged-is", {
                  totalToBePaid: `${order.getFormattedTotalToBePaid()} ${order.currency}`
                })}
              </span>
            }
          />
        </ListItem>
      );
    }

    return noPaymentDetailsAvailableText ? (
      <ListItem disableGutters={disableGutters}>
        <ListItemText
          classes={{
            secondary: classNames({
              [classes.sizeLarge]: size === "large"
            })
          }}
          secondary={
            <span className={classNames({ [classes.noColor]: disableColor })}>
              {noPaymentDetailsAvailableText}
            </span>
          }
        />
      </ListItem>
    ) : null;
  }
}

export default withStyles(styles)(withTranslation("common")(OrderPayment));
