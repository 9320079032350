import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Typography,
  Slide
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import BackspaceIcon from "@mui/icons-material/Backspace";
import withStyles from "@mui/styles/withStyles";

import withMediaQuery from "../hoc/withMediaQuery";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  pin: {
    display: "flex",
    justifyContent: "center"
  },
  pinDigit: {
    width: 18,
    height: 18,
    margin: 5,
    borderRadius: 9,
    border: "1px solid #888"
  },
  pinDigitPlaceholder: {
    width: 18,
    height: 18,
    margin: 5,
    borderRadius: 9,
    border: "1px solid #888",
    background: "#888"
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: 10,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)"
  },
  buttons: {
    position: "relative",
    margin: 20
  },
  buttonRow: {
    display: "flex",
    justifyContent: "center"
  },
  numberButton: {
    width: 64,
    height: 64,
    margin: 8,
    border: "2px solid #888",
    borderRadius: 32,
    fontSize: 24,
    color: "#333"
  },
  backspaceButton: {
    width: 64,
    height: 64,
    margin: 8,
    //border: "2px solid #888",
    borderRadius: 35,
    fontSize: 24,
    color: "#333"
  },
  emptySpace: {
    display: "inline-block",
    verticalAlign: "middle",
    width: 64,
    height: 64,
    margin: 8
  },
  accentuate: {
    color: "red"
  }
};

class EnterPinDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (rememberPin) => {
    return {
      rememberPin: rememberPin === true,
      pin: ""
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, showRememberPin } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState(), async () => {
        await this.checkPin(showRememberPin === true);
      });
    }
  }

  checkPin = async () => {
    const { showRememberPin, fetchPin } = this.props;

    if (showRememberPin) {
      const pin = await fetchPin();

      if (pin) {
        this.setState({ pin }, this.handleConfirm);
      }
    }
  };

  handleRememberPinChange = async (event) => {
    const { bar } = this.props;

    const rememberPin = event.target.checked === true;

    this.setState({ rememberPin });

    //await sessionStorage.setSessionItem(bar.id, SESSION_KEY_REMEMBER_PIN, rememberPin);
  };

  handleButtonClick = (digit) => (event) => {
    //event.stopPropagation();

    const { pinLength, onConfirm } = this.props;
    const { pin } = this.state;

    if (pin.length < pinLength) {
      const newPin = `${pin}${digit}`;

      this.setState({ pin: newPin }, () => {
        setTimeout(() => {
          if (newPin.length === pinLength) {
            this.handleConfirm();
          }
        }, 200);
      });
    }
  };

  handleBackspaceClick = (event) => {
    //event.stopPropagation();

    const { pin } = this.state;
    this.setState({ pin: pin.slice(0, -1) });
  };

  handleConfirm = (event) => {
    //event.stopPropagation();

    const { onConfirm, bar, storePin } = this.props;
    const { rememberPin, pin } = this.state;

    onConfirm(pin)
      .then(async () => {
        if (rememberPin) {
          storePin(pin);
        }
      })
      .catch((error) => {
        console.warn(error);
      });

    this.setState({ pin: "" });
  };

  handleCancel = (event) => {
    //event.stopPropagation();

    const { onCancel } = this.props;
    onCancel();
  };

  handleClose = (event, reason) => {
    //const { onClose } = this.props;

    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      //onClose();
    }
  };

  render() {
    const {
      classes,
      t,
      mediaQuery,
      isOpen,
      pinLength,
      isDisabled,
      showRememberPin,
      rememberPinMaxAge,
      title,
      description
    } = this.props;
    const { rememberPin, pin } = this.state;

    const digitArray = [];
    for (let i = 0; i < pinLength; i++) {
      if (i < pin.length) {
        digitArray.push("*");
      } else {
        digitArray.push(null);
      }
    }

    return (
      <Dialog
        onClose={this.handleClose}
        open={isOpen}
        disableEscapeKeyDown={true}
        fullScreen={mediaQuery}
        TransitionComponent={Transition}
      >
        <DialogTitle>{title ? title : t("label.enter-pin")}</DialogTitle>
        <DialogContent>
          {description && <DialogContentText>{description}</DialogContentText>}
          <div className={classes.content}>
            <div className={classes.pin}>
              {digitArray.map((digit, index) => (
                <div key={index}>
                  {digit ? (
                    <div className={classes.pinDigitPlaceholder}></div>
                  ) : (
                    <div className={classes.pinDigit}></div>
                  )}
                </div>
              ))}
            </div>
            {showRememberPin && (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isDisabled}
                      value={rememberPin}
                      onChange={this.handleRememberPinChange}
                      icon={<RadioButtonUncheckedIcon fontSize="small" />}
                      checkedIcon={<CheckCircleIcon fontSize="small" />}
                    />
                  }
                  label={
                    <Typography variant="overline">
                      {rememberPinMaxAge
                        ? t("label.remember-pin-for-x-minutes", {
                            amountOfMinutes: Math.floor(rememberPinMaxAge / 60)
                          })
                        : t("label.remember-pin")}
                    </Typography>
                  }
                />
              </div>
            )}
            <div className={classes.buttons}>
              {isDisabled && (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              )}
              <div className={classes.buttonRow}>
                <Button
                  className={classes.numberButton}
                  onClick={this.handleButtonClick("1")}
                  disabled={isDisabled}
                >
                  1
                </Button>
                <Button
                  className={classes.numberButton}
                  onClick={this.handleButtonClick("2")}
                  disabled={isDisabled}
                >
                  2
                </Button>
                <Button
                  className={classes.numberButton}
                  onClick={this.handleButtonClick("3")}
                  disabled={isDisabled}
                >
                  3
                </Button>
              </div>
              <div className={classes.buttonRow}>
                <Button
                  className={classes.numberButton}
                  onClick={this.handleButtonClick("4")}
                  disabled={isDisabled}
                >
                  4
                </Button>
                <Button
                  className={classes.numberButton}
                  onClick={this.handleButtonClick("5")}
                  disabled={isDisabled}
                >
                  5
                </Button>
                <Button
                  className={classes.numberButton}
                  onClick={this.handleButtonClick("6")}
                  disabled={isDisabled}
                >
                  6
                </Button>
              </div>
              <div className={classes.buttonRow}>
                <Button
                  className={classes.numberButton}
                  onClick={this.handleButtonClick("7")}
                  disabled={isDisabled}
                >
                  7
                </Button>
                <Button
                  className={classes.numberButton}
                  onClick={this.handleButtonClick("8")}
                  disabled={isDisabled}
                >
                  8
                </Button>
                <Button
                  className={classes.numberButton}
                  onClick={this.handleButtonClick("9")}
                  disabled={isDisabled}
                >
                  9
                </Button>
              </div>
              <div className={classes.buttonRow}>
                <div className={classes.emptySpace}></div>
                <Button
                  className={classes.numberButton}
                  onClick={this.handleButtonClick("0")}
                  disabled={isDisabled}
                >
                  0
                </Button>
                <Button
                  className={classes.backspaceButton}
                  onClick={this.handleBackspaceClick}
                  disabled={isDisabled}
                >
                  <BackspaceIcon />
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleCancel}
            color="secondary"
            disabled={isDisabled}
          >
            {t("label.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(withMediaQuery("(max-width:600px)")(EnterPinDialog))
);
