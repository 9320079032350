import Integration from "./Integration";
import IntegrationName from "./IntegrationName";

class PartnerIntegration extends Integration {
  private _label: string;

  constructor(barId: string, props: any) {
    if (!props) {
      throw new Error("error.props-is-not-defined");
    }

    super(barId, IntegrationName.PARTNER, props);

    this._label = props.label;

    super.setAllProps(["label"]);
  }

  public get label(): string {
    return this._label;
  }

  public set label(label: string) {
    this._label = label;
    super.setPropUpdated("label");
  }
}

export default PartnerIntegration;
