import Zone from "../models/zone/Zone";
import LayoutNodeType from "../models/zone/LayoutNodeType";
import SingleLayoutNode from "../models/zone/SingleLayoutNode";
import SeriesLayoutNode from "../models/zone/SeriesLayoutNode";
import SequenceLayoutNode from "../models/zone/SequenceLayoutNode";
import WholeNumbersLayoutNodeSequence from "../models/zone/WholeNumbersLayoutNodeSequence";
import LayoutNode from "../models/zone/LayoutNode";
import ZoneLayoutNode from "../models/zone/ZoneLayoutNode";
import LayoutNodeBases from "../models/zone/LayoutNodeBases";

export default class ZoneParser {
  public parseDocToZone(barId: string, doc): Zone {
    const data = doc.data();

    return new Zone(barId, doc.id, {
      ...data,
      timestamp: data.timestamp ? data.timestamp.toDate() : undefined
    });
  }

  public parseLayout(layout: any): LayoutNode {
    const { type, code, name, children } = layout;
    const bases = layout.bases ? new LayoutNodeBases(layout.bases) : null;

    let layoutNode: LayoutNode;

    switch (type) {
      case LayoutNodeType.ZONE: {
        layoutNode = new ZoneLayoutNode(code, name, bases);
        break;
      }
      case LayoutNodeType.SINGLE: {
        layoutNode = new SingleLayoutNode(code, name, bases);
        break;
      }
      case LayoutNodeType.SERIES: {
        const { series } = layout;

        layoutNode = new SeriesLayoutNode(code, name, series, bases);
        break;
      }
      case LayoutNodeType.SEQUENCE: {
        const { sequence } = layout;
        const { begin, end, incrementor } = sequence;

        layoutNode = new SequenceLayoutNode(
          code,
          name,
          new WholeNumbersLayoutNodeSequence(begin, end, incrementor),
          bases
        );
        break;
      }
      default: {
        throw new Error("error.layout-type-is-not-valid");
      }
    }

    if (children && children.length > 0) {
      children.forEach((child: any) =>
        layoutNode.addChild(this.parseLayout(child))
      );
    }

    return layoutNode;
  }
}
