import React, { PropsWithChildren, useState, useEffect } from "react";
import classNames from "classnames";

import { toast } from "react-toastify";

import { Fab, TextField, Theme, SvgIcon } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";

import { utilsHelper } from "../../../helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      visibility: "hidden"
    },
    amount: {
      display: "flex",
      alignItems: "center"
    },
    resize: {
      display: "inline-block",
      marginTop: 3,
      minWidth: 40,
      fontSize: 18,
      fontWeight: "bold",
      color: "black"
    },
    amountText: {
      marginBottom: 4
    },
    controlAmountButton: {
      zIndex: 0,
      margin: theme.spacing(0, 0.5)
    },
    roundAmountButton: {
      marginRight: theme.spacing(2)
    },
    icon: {
      display: "flex",
      marginRight: 10
    },
    unit: {
      display: "inline-block",
      marginRight: 15,
      textTransform: "uppercase"
    }
  })
);

type SelectAmountProps = {
  isDisabled?: boolean;
  allowInput?: boolean;
  hideZero?: boolean;
  icon?: SvgIcon;
  unit?: string;
  step?: number;
  min?: number;
  max?: number;
  amount?: number;
  roundAmount?: number;
  onChange?: (amount: number) => void;
};

function SelectAmount({
  isDisabled,
  allowInput,
  hideZero,
  icon,
  unit,
  step,
  min,
  max,
  amount,
  roundAmount,
  onChange
}: PropsWithChildren<SelectAmountProps>) {
  const classes = useStyles();

  const [selectedAmount, setSelectedAmount] = useState(0);

  useEffect(() => {
    if (amount !== undefined && amount !== null && amount !== selectedAmount) {
      setSelectedAmount(amount);
    }
  }, [amount]);

  const updateAmount = (updatedAmount) => {
    const parsedAmount = utilsHelper.roundToTwoDecimals(updatedAmount);

    const minAmount = min === undefined ? 0 : min;

    if (
      parsedAmount >= minAmount &&
      (max === undefined || parsedAmount <= max)
    ) {
      setSelectedAmount(parsedAmount);

      if (onChange) {
        onChange(parsedAmount);
      }
    }
  };

  const canDecrease = () => {
    const minAmount = min === undefined ? 0 : min;

    return selectedAmount > minAmount;
  };

  const canIncrease = () => {
    return max === undefined || selectedAmount < max;
  };

  const handleAmountChange = (event) => {
    const value = event.target.value;
    if (value) {
      try {
        const newAmount = parseInt(value);

        if (!isNaN(newAmount)) {
          updateAmount(newAmount);
        }
      } catch (error) {
        if (error) {
          toast.error(error.message);
        }
      }
    }
  };

  const handleDecreaseAmount = () => {
    let tmpAmount = selectedAmount;

    if (step !== undefined) {
      tmpAmount -= step;
    } else {
      tmpAmount--;
    }

    updateAmount(tmpAmount);
  };

  const handleIncreaseAmount = () => {
    let tmpAmount = selectedAmount;

    if (step !== undefined) {
      tmpAmount += step;
    } else {
      tmpAmount++;
    }

    updateAmount(tmpAmount);
  };

  const handleRoundAmount = () => {
    updateAmount(roundAmount);
  };

  return (
    <div className={classes.amount}>
      {icon && <div className={classes.icon}>{icon}</div>}
      {roundAmount &&
        <Fab
          size="small"
          color="primary"
          aria-label=">"
          className={classNames(classes.controlAmountButton, classes.roundAmountButton)}
          disabled={isDisabled || roundAmount === selectedAmount}
          onClick={handleRoundAmount}
        >
          <KeyboardTabIcon />
        </Fab>
      }
      <TextField
        variant="standard"
        color="secondary"
        id="begin"
        type="number"
        value={selectedAmount}
        className={classNames(classes.amountText, {
          [classes.hidden]: selectedAmount === 0 && hideZero
        })}
        onChange={handleAmountChange}
        label=""
        disabled={allowInput ? isDisabled : true}
        InputProps={{
          step: step === undefined ? 1 : step,
          disableUnderline: true,
          classes: {
            input: classes.resize
          }
        }}
        inputProps={{ style: { width: 15 + `${selectedAmount}`.length * 12 } }}
      />
      {unit && <span className={classes.unit}>{unit}</span>}
      <Fab
        size="small"
        color="secondary"
        aria-label="-"
        className={classes.controlAmountButton}
        disabled={isDisabled || !canDecrease()}
        onClick={handleDecreaseAmount}
      >
        <RemoveIcon />
      </Fab>
      <Fab
        size="small"
        color="primary"
        aria-label="+"
        className={classes.controlAmountButton}
        disabled={isDisabled || !canIncrease()}
        onClick={handleIncreaseAmount}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}

export default SelectAmount;
